import {
    WRITER_ADMIN_APPLICATION,
    WRITER_ADMIN_APPLICATION_SUCCESS,
    WRITER_ADMIN_APPLICATION_FAILURE,
    EDITOR_ADMIN_APPLICATION,
    EDITOR_ADMIN_APPLICATION_SUCCESS,
    EDITOR_ADMIN_APPLICATION_FAILURE,
    SAVE_ADMIN_APPLICATION,
    SAVE_ADMIN_APPLICATION_SUCCESS,
    SAVE_ADMIN_APPLICATION_FAILURE,
    PATCH_WRITER_ADMIN_APPLICATION,
    PATCH_WRITER_ADMIN_APPLICATION_SUCCESS,
    PATCH_WRITER_ADMIN_APPLICATION_FAILURE,
    PATCH_EDITOR_ADMIN_APPLICATION,
    PATCH_EDITOR_ADMIN_APPLICATION_SUCCESS,
    PATCH_EDITOR_ADMIN_APPLICATION_FAILURE,
    PATCH_SAVE_ADMIN_APPLICATION,
    PATCH_SAVE_ADMIN_APPLICATION_SUCCESS,
    PATCH_SAVE_ADMIN_APPLICATION_FAILURE
} from "./type"


export const writerAdminAppli = () => ({
    type: WRITER_ADMIN_APPLICATION
})

export const writerAdminAppliSuccess = data => ({
    type: WRITER_ADMIN_APPLICATION_SUCCESS,
    data
})

export const writerAdminAppliFailure = data => ({
    type: WRITER_ADMIN_APPLICATION_FAILURE,
    data
})

export const editorAdminAppli = () => ({
    type: EDITOR_ADMIN_APPLICATION
})

export const editorAdminAppliSuccess = data => ({
    type: EDITOR_ADMIN_APPLICATION_SUCCESS,
    data
})

export const editorAdminAppliFailure = data => ({
    type: EDITOR_ADMIN_APPLICATION_FAILURE,
    data
})

export const saveAdminAppli = () => ({
    type: SAVE_ADMIN_APPLICATION
})

export const saveAdminAppliSuccess = data => ({
    type: SAVE_ADMIN_APPLICATION_SUCCESS,
    data
})

export const saveAdminAppliFailure = data => ({
    type: SAVE_ADMIN_APPLICATION_FAILURE,
    data
})

export const patchWriterApplication = (id, status) => ({
    type: PATCH_WRITER_ADMIN_APPLICATION,
    id,
    status
})

export const patchWriterApplicationSuccess = data => ({
    type: PATCH_WRITER_ADMIN_APPLICATION_SUCCESS,
    data
})

export const patchWriterApplicationFailure = data => ({
    type: PATCH_WRITER_ADMIN_APPLICATION_FAILURE,
    data
})

export const patchEditorApplication = (id, status) => ({
    type: PATCH_EDITOR_ADMIN_APPLICATION,
    id,
    status
})

export const patchEditorApplicationSuccess = data => ({
    type: PATCH_EDITOR_ADMIN_APPLICATION_SUCCESS,
    data
})

export const patchEditorApplicationFailure = data => ({
    type: PATCH_EDITOR_ADMIN_APPLICATION_FAILURE,
    data
})

export const patchSaveApplication = (id, status) => ({
    type: PATCH_SAVE_ADMIN_APPLICATION,
    id,
    status
})

export const patchSaveApplicationSuccess = data => ({
    type: PATCH_SAVE_ADMIN_APPLICATION_SUCCESS,
    data
})

export const patchSaveApplicationFailure = data => ({
    type: PATCH_SAVE_ADMIN_APPLICATION_FAILURE,
    data
})