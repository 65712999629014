import React from "react"
import imageIcon from "../../../../../assets/images/image-icon.svg"
import greaterWhiteIcon from "../../../../../assets/images/greater-white.svg"
import closeIcon from "../../../../../assets/images/close-icon-modal.svg";
import { history } from "../../../../../reduxStore/store";
import { saveMemberID } from "../../../../../pages/AuthScreens/Login/redux/action";
import { connect } from 'react-redux'

const OpenAdverts = ({
  setModalShow,
  setCruntTab,
  Paragraph,
  novelPic,
  novelName,
  authorName,
  writerName,
  genre,
  authorId,
  saveMemberID,
  loginInfo
}) => {

  const onSaveData = () => {
    if(authorId){
      saveMemberID(authorId)
      history.push({ pathname: `/${loginInfo.user.mode}/member_profile/${authorName.toLocaleLowerCase().replace(/\s+/g, '_')}` })
    }
  }

  return (
    <>
      <div className="modal-body-left">
        <div className={`image-wrapper ${novelPic ? '' : 'no-img-wrapper'}`}>
          <img src={novelPic ? novelPic : imageIcon} alt="" />
        </div>
      </div>
      <div className="modal-body-right">
        <div className="modal-right-header">
          <h3>{genre}</h3>

          <div className="novel">{novelName}</div>
          <div className="author-details">
            <p className="author" >{authorName}</p>
            <p className="writer">{writerName}</p>
          </div>
          <div className="close-icon">
            <img src={closeIcon} alt="" onClick={() => setModalShow(false)} />
          </div>
        </div>
        <div className="modal-right-body">
          <div className="body-text-wrapper">
            <div className="text-wrapper">
              <p>{Paragraph}</p>
            </div>
          </div>
        </div>
        <div
          className="modal-right-footer"
          onClick={() => setCruntTab("Apply")}
        >
          <div className="text">Apply for Position</div>
          <div className="image-wrapper">
            <img src={greaterWhiteIcon} alt="" />
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  loginInfo: state.login.loginInfo
})

const mapDispatchToProps = (dispatch) => ({
  saveMemberID: data => dispatch(saveMemberID(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(OpenAdverts)