import {
  GET_AUTHOR_PROFILE,
  GET_AUTHOR_PROFILE_SUCCESS,
  GET_AUTHOR_PROFILE_FAILURE,
  GET_NOVEL_DATA,
  GET_NOVEL_DATA_SUCCESS,
  GET_NOVEL_DATA_FAILURE,
  GET_BOOK_DATA,
  GET_BOOK_DATA_SUCCESS,
  GET_BOOK_DATA_FAILURE,
  GET_CHAPTER_DATA,
  GET_CHAPTER_DATA_SUCCESS,
  GET_CHAPTER_DATA_FAILURE,
  UPLOADED_DATA_CONCAT,
  GET_READER_UPLOADED,
  GET_READER_UPLOADED_SUCCESS,
  GET_READER_UPLOADED_FAILURE,
  GET_READER_FAVORITES,
  GET_READER_FAVORITES_SUCCESS,
  GET_READER_FAVORITES_FAILURE,
  GET_READER_TOP_STORIES,
  GET_READER_TOP_STORIES_SUCCESS,
  GET_READER_TOP_STORIES_FAILURE,
  GET_READER_TOP_WRITERS,
  GET_READER_TOP_WRITERS_SUCCESS,
  GET_READER_TOP_WRITERS_FAILURE,
  GET_READER_TOP_CHARACTERS,
  GET_READER_TOP_CHARACTERS_SUCCESS,
  GET_READER_TOP_CHARACTERS_FAILURE,
  GET_READER_TOP_CHAPTERS,
  GET_READER_TOP_CHAPTERS_SUCCESS,
  GET_READER_TOP_CHAPTERS_FAILURE
} from "./type"

// GET AUTHOR PROFILE
export const getAuthorProfile = data => ({
  type: GET_AUTHOR_PROFILE,
  data
})

export const getAuthorProfileSuccess = data => ({
  type: GET_AUTHOR_PROFILE_SUCCESS,
  data
})

export const getAuthorProfileFailure = data => ({
  type: GET_AUTHOR_PROFILE_FAILURE,
  data
})
// GET NOVEL DETAILS
export const getNovelData = data => ({
  type: GET_NOVEL_DATA,
  data
})

export const getNovelDataSuccess = data => ({
  type: GET_NOVEL_DATA_SUCCESS,
  data
})

export const getNovelDataFailure = data => ({
  type: GET_NOVEL_DATA_FAILURE,
  data
})
// GET BOOK DATA
export const getBookData = data => ({
  type: GET_BOOK_DATA,
  data
})

export const getBookDataSuccess = data => ({
  type: GET_BOOK_DATA_SUCCESS,
  data
})

export const getBookDataFailure = data => ({
  type: GET_BOOK_DATA_FAILURE,
  data
})
// GET NAVEL CHAPTER
export const getNovelChapter = data => ({
  type: GET_CHAPTER_DATA,
  data
})

export const getNovelChapterSuccess = data => ({
  type: GET_CHAPTER_DATA_SUCCESS,
  data
})

export const getNovelChapterFailure = data => ({
  type: GET_CHAPTER_DATA_FAILURE,
  data
})
// UPLOADED DATA CONCAT
export const uploadedDataConcat = data => ({
  type: UPLOADED_DATA_CONCAT,
  data
})
// READER UPLOADED
export const getReaderUploaded = (data, page) => ({
  type: GET_READER_UPLOADED,
  data,
  page
})
export const getReaderUploadedSuccess = data => ({
  type: GET_READER_UPLOADED_SUCCESS,
  data
})
export const getReaderUploadedFailure = data => ({
  type: GET_READER_UPLOADED_FAILURE,
  data
})
// READER FAVORITES
export const getReaderFavorites = data => ({
  type: GET_READER_FAVORITES,
  data
})
export const getReaderFavoritesSuccess = data => ({
  type: GET_READER_FAVORITES_SUCCESS,
  data
})
export const getReaderFavoritesFailure = data => ({
  type: GET_READER_FAVORITES_FAILURE,
  data
})
// Top 10 Stories
export const getReaderStories = data => ({
  type: GET_READER_TOP_STORIES,
  data
})
export const getReaderStoriesSuccess = data => ({
  type: GET_READER_TOP_STORIES_SUCCESS,
  data
})
export const getReaderStoriesFailure = data => ({
  type: GET_READER_TOP_STORIES_FAILURE,
  data
})
// Top 10 Writers
export const getReaderWriters = data => ({
  type: GET_READER_TOP_WRITERS,
  data
})
export const getReaderWritersSuccess = data => ({
  type: GET_READER_TOP_WRITERS_SUCCESS,
  data
})
export const getReaderWritersFailure = data => ({
  type: GET_READER_TOP_WRITERS_FAILURE,
  data
})
// Top 10 Characters
export const getReaderCharacters = data => ({
  type: GET_READER_TOP_CHARACTERS,
  data
})
export const getReaderCharactersSuccess = data => ({
  type: GET_READER_TOP_CHARACTERS_SUCCESS,
  data
})
export const getReaderCharactersFailure = data => ({
  type: GET_READER_TOP_CHARACTERS_FAILURE,
  data
})
// Top 10 Intro Chapters
export const getReaderChapters = data => ({
  type: GET_READER_TOP_CHAPTERS,
  data
})
export const getReaderChaptersSuccess = data => ({
  type: GET_READER_TOP_CHAPTERS_SUCCESS,
  data
})
export const getReaderChaptersFailure = data => ({
  type: GET_READER_TOP_CHAPTERS_FAILURE,
  data
})
