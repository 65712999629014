import {
  GET_FAVORITES,
  GET_FAVORITES_FAILURE,
  GET_FAVORITES_SUCCESS,
  PUT_FAVORITES,
  PUT_FAVORITES_SUCCESS,
  PUT_FAVORITES_FAILURE,
  PATCH_FAVORITES_NOVEL,
  PATCH_FAVORITES_NOVEL_SUCCESS,
  PATCH_FAVORITES_NOVEL_FAILURE,
  GET_ADVENTURE_LOG,
  GET_ADVENTURE_LOG_SUCCESS,
  GET_ADVENTURE_LOG_FAILURE,
  UPDATE_ADVENTURE_LOG,
  UPDATE_ADVENTURE_LOG_SUCCESS,
  UPDATE_ADVENTURE_LOG_FAILURE
} from "./type"

const initialState = {
  favoritesData:false,
  requesting: false,
  error: false,

  putData: false,
  putRequesting: false,
  putError: false,

  patchData: false,
  patchRequesting: false,
  patchError: false,

  adventureLog: false,
  advRequesting: false,
  advError: false,

  updateAdvData: false,
  updateAdvRequesting: false,
  updateAdvError: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_FAVORITES:
      return {
        ...state,
        requesting: true
      }
    case GET_FAVORITES_SUCCESS:
      return {
        ...state,
        requesting: false,
        favoritesData: action.data
      }
    case GET_FAVORITES_FAILURE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }
      case PUT_FAVORITES:
      return {
        ...state,
        putRequesting: true
      }
    case PUT_FAVORITES_SUCCESS:
      return {
        ...state,
        putRequesting: false,
        putData: action.data
      }
    case PUT_FAVORITES_FAILURE:
      return {
        ...state,
        putRequesting: false,
        putError: action.data
      }
      case PATCH_FAVORITES_NOVEL:
      return {
        ...state,
        patchRequesting: true
      }
    case PATCH_FAVORITES_NOVEL_SUCCESS:
      return {
        ...state,
        patchRequesting: false,
        patchData: action.data
      }
    case PATCH_FAVORITES_NOVEL_FAILURE:
      return {
        ...state,
        patchRequesting: false,
        patchError: action.data
      }
      case GET_ADVENTURE_LOG:
      return {
        ...state,
        advRequesting: true
      }
    case GET_ADVENTURE_LOG_SUCCESS:
      return {
        ...state,
        advRequesting: false,
        adventureLog: action.data
      }
    case GET_ADVENTURE_LOG_FAILURE:
      return {
        ...state,
        advRequesting: false,
        advError: action.data
      }
      case UPDATE_ADVENTURE_LOG:
      return {
        ...state,
        updateAdvRequesting: true
      }
    case UPDATE_ADVENTURE_LOG_SUCCESS:
      return {
        ...state,
        updateAdvRequesting: false,
        updateAdvData: action.data
      }
    case UPDATE_ADVENTURE_LOG_FAILURE:
      return {
        ...state,
        updateAdvRequesting: false,
        updateAdvError: action.data
      }
    default:
      return state
  }
}
