import React, { useEffect, useState } from "react"
import { Space, Table } from "antd"
import Button from "../../Button"
import optionIcon from "../../../assets/images/options-white.svg"
import flagActiveIcon from "../../../assets/images/flag-blue.svg"
import starIcon from "../../../assets/images/star.svg"
import "./style.scss"
import {
  getSubscribedUsers,
  setSubscribedConcat
} from "../../../pages/AdminScreens/AdminFinances/redux/action"
import { connect } from "react-redux"
import { useVT } from "virtualizedtableforantd4"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"

const SubscribedUsersTable = props => {
  const {
    getSubscribedUsers,
    subUsersData,
    subUserRequesting,
    currentPage,
    subConcat,
    setSubscribedConcat,
    isLoading
  } = props

  const [showTooltip, setShowTooltip] = useState(false)
  const [tooltip, setTooltip] = useState(false)
  const [concatUser, setConcatUser] = useState([])

  useEffect(() => {
    getSubscribedUsers()
  }, [])

  useEffect(() => {
    if (subUsersData && currentPage?.current_page == 1) {
      setConcatUser(subUsersData)
    } else if (subConcat) {
      setConcatUser([...concatUser, ...subUsersData])
    } else {
      setConcatUser(subUsersData)
    }
  }, [subUsersData])

  const onAction = id => {
    setShowTooltip(!showTooltip)
    setTooltip(id)
  }

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: text => <a>ID{text}</a>
    },
    {
      title: "Name (A-Z)",
      dataIndex: "name",
      key: "name",
      render: (_, name) => (
        <Space size="middle">
          <img src={name.flags ? flagActiveIcon : starIcon} alt="" />
          <p className="flag-name mb-0" title={name.name ? name.name : "---"}>
            {name.name ? name.name : "---"}
          </p>
        </Space>
      )
    },
    {
      title: "Role",
      dataIndex: "user_type",
      key: "user_type",
      render: role => (
        <Button
          title={role.charAt(0).toUpperCase() + role.slice(1)}
          className="radius-btn no-cursor"
        />
      )
    },
    {
      title: "Email",
      key: "email",
      dataIndex: "email",
      render: (_, email) => (
        <p className="email mb-0" title={email.email}>
          {email.email ? email.email : "---"}
        </p>
      )
    },
    {
      title: "Phone",
      key: "phone_number",
      render: (_, phone_number) => (
        <p
          className="phone mb-0"
          title={phone_number.phone_number ? phone_number.phone_number : "---"}
        >
          {phone_number.phone_number ? phone_number.phone_number : "---"}
        </p>
      )
    },
    {
      title: "Earned",
      key: "token_earn",
      render: (_, token_earn) => (
        <p
          className="phone mb-0"
          title={token_earn.token_earn ? token_earn.token_earn.token_earn.total_token__sum : "0"}
        >
          {token_earn.token_earn.token_earn.total_token__sum ? token_earn.token_earn.token_earn.total_token__sum : "0"}
        </p>
      )
    },{
      title: "Current balance",
      key: "available_grt",
      render: (_, available_grt) => (
        <p
          className="phone mb-0"
          title={available_grt.available_grt ? available_grt.available_grt : "0"}
        >
          {available_grt.available_grt? available_grt.available_grt : "0"}
        </p>
      )
    },
  ]

  const [vt] = useVT(
    () => ({
      onScroll: ({ top, isEnd }) => {
        if (isEnd) {
          const currentNo = currentPage.current_page + 1
          if (currentNo <= currentPage.total_page) {
            getSubscribedUsers(currentNo)
            setSubscribedConcat(true)
          }
        }
      },
      scroll: {
        y: 600
      },
      debug: false
    }),
    [concatUser]
  )

  return (
    <>
      {isLoading || subUserRequesting ? (
        <SkeletonTheme baseColor="#6f8bdf40" highlightColor="#6F8BDF">
        <Table
          rowKey="key"
          pagination={false}
          dataSource={[...Array(10)].map((_, index) => ({
            key: `key${index}`
          }))}
          columns={columns.map(column => {
            return {
              ...column,
              render: function renderPlaceholder() {
                return <Skeleton width='40%' height='10px'/>
              }
            }
          })}
        />
        </SkeletonTheme>
      ) : (
        <Table
          scroll={{
            scrollToFirstRowOnChange: false,
            y: 600,
            x: 1000
          }}
          components={vt}
          columns={columns}
          dataSource={concatUser}
          pagination={false}
          loading={false}
        />
      )}

      {/* )} */}
    </>
  )
}

const mapStateToProps = state => ({
  subUsersData: state.adminFinances.subUsersData,
  subUserRequesting: state.adminFinances.subUserRequesting,
  error: state.adminFinances.subUserError,
  subConcat: state.adminFinances.subConcat,
  currentPage: state.adminUsers.currentPage
})

const mapDispatchToProps = dispatch => ({
  getSubscribedUsers: (data, name) => dispatch(getSubscribedUsers(data, name)),
  setSubscribedConcat: data => dispatch(setSubscribedConcat(data))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SubscribedUsersTable)
