import React, { useState, useEffect } from "react"
import AdminLayout from "../../../layout/AdminLayout"
import FeedbackTable from "../../../components/AdminComponents/FeedbackTable"
import "./style.scss"
import { connect } from "react-redux"
import { getAdminFeedback } from "./redux/action"
import { Spinner } from "reactstrap"

const AdminFeeback = props => {
  const { getAdminFeedback, feedbackData, requesting } = props

  useEffect(() => {
    getAdminFeedback()
  }, [])

  const OnSearch = (value) => {
    setTimeout(() => {
      getAdminFeedback(value)
    }, [])
  }


  return (
    <AdminLayout onChange={OnSearch} className="feedback-header" headerName={"Support/Feedback"} searchBarWrapper={true}>
      {requesting ?
        (
          <div className="spinner-style">
            <Spinner color="primary" />
          </div>
        )
        :
        (
          <div className="content-section admin-content-section">
            <div className="admin-users-wrapper">
              <div className="container-fluid admin-users-container">
                <div className="row admin-users-row">
                  <div className="table-wrapper">
                    <div className="users-title-wrapper">
                      <div className="title-wrapper">
                        <h3>All</h3>
                      </div>
                    </div>
                    <FeedbackTable feedbackData={feedbackData} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        )
      }

    </AdminLayout>
  )
}

const mapStateToProps = state => ({
  feedbackData: state.adminFeeback.data,
  requesting: state.adminFeeback.requesting,
  error: state.adminFeeback.error
})

const mapDispatchToProps = dispatch => ({
  getAdminFeedback: (data) => dispatch(getAdminFeedback(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminFeeback)
