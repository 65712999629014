import {
    ADD_YOUR_INFORMATION,
    ADD_YOUR_INFORMATION_SUCCESS,
    ADD_YOUR_INFORMATION_FAILURE,
   } from "./type";

   const initialState = {
    requesting: false,
    error: false,
    personalInfo: false
  };

  export default (state = initialState, action) => {
    switch (action.type) {
      case ADD_YOUR_INFORMATION:
        return {
          ...state,
          requesting: true,
        };
      case ADD_YOUR_INFORMATION_SUCCESS:
        return {
          ...state,
          requesting: false,
          personalInfo: action.data
        };
      case ADD_YOUR_INFORMATION_FAILURE:
        return {
          ...state,
          requesting: false,
          error: action.data,
        };
      default:
        return state;
    }
  };
  
  
  
  
  
  
  
  