import { all, call, put, takeLatest } from "redux-saga/effects"
import { BASE_URL } from "../../../../config/app"
import XHR from "../../../../utils/XHR"
import {
  GET_ADMIN_SUBSCRIPTION,
  POST_ADMIN_SUBSCRIPTION,
  POST_EDIT_PLANS,
  GET_SUBSCRIBED_USER,
  GET_FINANCES_TOP_BOX,
  GET_GRT_DATA
} from "./type"
import {
  getAdminSubscriptionSuccess,
  getAdminSubscriptionFailure,
  postAdminSubscriptionSuccess,
  postAdminSubscriptionFailure,
  getAdminSubscription,
  postEditPlanSuccess,
  postEditPlanFailure,
  getSubscribedUsersSuccess,
  getSubscribedUsersFailure,
  getFinanceDataSuccess,
  getFinanceDataFailure,
  getGRTRequestSuccess,
  getGRTRequestFailure
} from "./action"
import { setCurrentPages } from "../../Users/redux/action"
import { toast } from "react-hot-toast"

async function adminSubscriptionAPI() {
  const URL = `${BASE_URL}api/v1/admin_plan/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

async function financeTopBoxAPI() {
  const URL = `${BASE_URL}api/v1/finances/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

async function grtFinanceAPI(name = '', page = 1) {
  const URL = `${BASE_URL}api/v1/finance_grt/?name=${name}&page=${page}`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

async function subscribedUsersAPI(page = 1, name = "") {
  const URL = `${BASE_URL}api/v1/subscribed_user/?name=${name}&page=${page}`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

async function postSubscriptionAPI(data) {
  const URL = `${BASE_URL}api/v1/turn_subscription/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "POST",
    data
  }
  return XHR(URL, options)
}

async function postEditPlanAPI(data) {
  const URL = `${BASE_URL}api/v1/edit_plan/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "POST",
    data
  }
  return XHR(URL, options)
}

function* postEditPlan({ data, setEditCard }) {
  try {
    const response = yield call(postEditPlanAPI, data)
    yield put(postEditPlanSuccess(response.data))
    toast.success("Updated Successful", {
      id: "card fields"
    })
    yield put(getAdminSubscription())
    yield put(setEditCard(false))
  } catch (e) {
    const { response } = e
    yield put(postEditPlanFailure(response?.data))
  }
}

function* postAdminSubscription({ data }) {
  try {
    const response = yield call(postSubscriptionAPI, data)
    yield put(postAdminSubscriptionSuccess(response.data))
    toast.success("Plan active status changed", {
      id: "card fields"
    })
    yield put(getAdminSubscription())
  } catch (e) {
    const { response } = e
    yield put(postAdminSubscriptionFailure(response?.data))
    toast.error("Something went wrong", {
      id: "card fields"
    })
  }
}

function* AdminSubscription() {
  try {
    const response = yield call(adminSubscriptionAPI)
    yield put(getAdminSubscriptionSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(getAdminSubscriptionFailure(response?.data))
  }
}

function* grtFinanceData({data, page}) {
  try {
    const response = yield call(grtFinanceAPI, data, page)
    yield put(getGRTRequestSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(getGRTRequestFailure(response?.data))
  }
}

function* SubscriptionUsers({ data, name }) {
  try {
    const response = yield call(subscribedUsersAPI, data, name)
      yield put(getSubscribedUsersSuccess(response.data.results))
      yield put(setCurrentPages(response.data))
  } catch (e) {
    const { response } = e
    yield put(getSubscribedUsersFailure(response?.data))
  }
}

function* financeTopBox() {
  try {
    const response = yield call(financeTopBoxAPI)
    yield put(getFinanceDataSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(getFinanceDataFailure(response?.data))
  }
}

export default all([
  takeLatest(GET_ADMIN_SUBSCRIPTION, AdminSubscription),
  takeLatest(POST_ADMIN_SUBSCRIPTION, postAdminSubscription),
  takeLatest(POST_EDIT_PLANS, postEditPlan),
  takeLatest(GET_SUBSCRIBED_USER, SubscriptionUsers),
  takeLatest(GET_FINANCES_TOP_BOX, financeTopBox),
  takeLatest(GET_GRT_DATA, grtFinanceData)
])
