import {
     POST_PAYMENT_INFORMATION,
     POST_PAYMENT_INFORMATION_SUCCESS,
     POST_PAYMENT_INFORMATION_FAILURE,
    } from "./type";

// POST_PAYMENT_INFORMATION
export const postPaymentInformation = (data, setModalShow) => ({
    type: POST_PAYMENT_INFORMATION,
    data,
    setModalShow
})

export const postPaymentInformationSuccess = (data) => ({
    type: POST_PAYMENT_INFORMATION_SUCCESS,
    data
})

export const postPaymentInformationFailure = (data) => ({
    type: POST_PAYMENT_INFORMATION_FAILURE,
    data
})