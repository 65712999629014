import React, { useEffect, useState } from "react"
import dummyPic from "../../assets/images/dummyPic.png"
import centerImg from "../../assets/images/gallery.svg"
import ImageCard from "../../components/ImageCard"
import HomeLayout from "../../layout/HomeLayout"
import nextIcon from "../../assets/images/greater-blue-icon.svg"
import WorkRoomCard from "../../components/WorkRoomCard"
import {
  getEditAdverts,
  getEditCurrent,
  getEditSave,
  getEditWorkroom
} from "./redux/action"
import { connect } from "react-redux"
import PositionPopUp from "../../components/WriterModal/PositionPopUp"
import { useHistory } from "react-router-dom"
import { saveWorkroomID } from "../AuthScreens/Login/redux/action"
import { setOpenAdvertIndex } from "../WriterHome/redux/action"
import SavedforReviewModal from "../../components/SavedReviewModal"

const EditorHome = props => {
  const {
    loginInfo,
    saveWorkroomID,
    setOpenAdvertIndex,
    getEditAdverts,
    getEditCurrent,
    getEditSave,
    getEditWorkroom,
    editorAdverts,
    editorAdvertsLoad,
    editorCurrent,
    editorCurrentLoad,
    editorSave,
    editorSaveLoad,
    editorWorkroom,
    editorWorkroomLoad
  } = props
  const [showModal, setShowModal] = useState(false)
  const [activeCardFlex, setActiveCardFlex] = useState(false)
  const [checkModal, setCheckModal] = useState(false)
  const [currentApp, setCurrentApp] = useState({})
  const [saveModalShow, setSaveModalShow] = useState(false)
  const [apendCharacter, setApendCharacter] = useState([])
  const [thumbnail, setThumbnail] = useState("")

  const history = useHistory()

  useEffect(() => {
    getEditAdverts()
    getEditCurrent()
    getEditSave()
  }, [])

  useEffect(() => {
    if (!editorWorkroom?.editor_characters || !editorWorkroom?.editor_novels) {
      getEditWorkroom()
    }
  }, [])

  useEffect(() => {
    if (editorWorkroom?.editor_characters || editorWorkroom?.editor_novels) {
      editorWorkroom?.editor_characters &&
        editorWorkroom?.editor_characters?.map(item =>
          setApendCharacter(prev => [...prev, item])
        )
      editorWorkroom?.editor_novels &&
        editorWorkroom?.editor_novels?.map(item => {
          setThumbnail(item?.thumbnail)
          return item?.character.map(item =>
            setApendCharacter(prev => [...prev, item])
          )
        }
        )
    }
  }, [editorWorkroom])

  const openHomeModal = index => {
    setOpenAdvertIndex(index)
    setCheckModal(false)
    setShowModal(true)
    setCurrentApp({})
  }

  const setWorkRoomID = id => {
    saveWorkroomID(id)
    history.push({ pathname: `/${loginInfo.user.mode}/workroom/${id}` })
  }

  const openCurrentApplication = index => {
    setOpenAdvertIndex(index)
    setShowModal(true)
    setCheckModal(true)
  }

  const filterBySearch = value => {
    getEditAdverts(value)
  }

  const saveModalOpen = index => {
    setOpenAdvertIndex(index)
    setSaveModalShow(true)
  }

  return (
    <HomeLayout
      headerName={"Home"}
      searchBarWrapper={true}
      cardFlex={activeCardFlex}
      setCardFlex={setActiveCardFlex}
      onChange={filterBySearch}
    >
      <section className="content-section">
        <div className="home-card-wrapper upload-wrapper">
          <div className="container-fluid home-card-container">
            <div className="section-description">
              <p>
                The editor is the member responsible for running the writing workshop that will develop the stories. They also can edit the novel, enter and edit all related workrooms, and review and select the writers to play each roll. Their main task is to ensure all chapters are written and check the cross interactions.
                <br /><br />
                The Writing Production Workshop follows this basic outline:<br />
                Information related to the Character and where they should be at the end of the chapter is added to each workroom.<br />
                A cycle start meeting is held where the interaction between Character is identified.<br />
                Each interaction is roll played between the writers, with the editor being the interaction host.<br />
                Each writer then produces the story from their perspective / point of view ending at the assigned location for the next cycle.<br />
                The editor reviews each chapter, provides feedback, and can edit the writing if necessary. When they are satisfied, they then enter the setup information for the next writing cycle.
              </p>
            </div>
            <h2 className="title">Open Adverts</h2>
            <div className="row home-card-row">
              {editorAdvertsLoad
                ? [1, 2, 3, 4, 5, 6, 7].map(item => (
                  <div className={`card-data`}>
                    <ImageCard isLoader={true} />
                  </div>
                ))
                : editorAdverts &&
                editorAdverts?.results?.map((item, index) => (
                  <div
                    className={`card-data ${activeCardFlex ? "card-toggle-flex" : ""
                      }`}
                    key={item.id}
                  >
                    <ImageCard
                      src={item?.thumbnail ? item.thumbnail : dummyPic}
                      hoverName={item?.name}
                      onClick={() => openHomeModal(index)}
                    />
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className="home-card-wrapper favorite-wrapper">
          <div className="container-fluid home-card-container">
            <h2 className="title">Current Applications</h2>
            <div className="row home-card-row">
              {editorCurrentLoad
                ? [1, 2, 3, 4, 5, 6, 7].map(item => (
                  <div className={`card-data`}>
                    <ImageCard isLoader={true} />
                  </div>
                ))
                : editorCurrent &&
                editorCurrent?.map((item, index) => (
                  <div
                    className={`card-data ${activeCardFlex ? "card-toggle-flex" : ""
                      }`}
                    key={item.id}
                  >
                    <ImageCard
                      src={
                        item?.character?.novel?.thumbnail
                          ? item?.character?.novel?.thumbnail
                          : dummyPic
                      }
                      onClick={() => openCurrentApplication(index)}
                      hoverName={item.character?.name}
                    />
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className="home-card-wrapper stories-wrapper">
          <div className="container-fluid home-card-container">
            <h2 className="title">Saved for Review by the Producer</h2>
            <div className="row home-card-row">
              {editorSaveLoad
                ? [1, 2, 3, 4, 5, 6, 7].map(item => (
                  <div className={`card-data`}>
                    <ImageCard isLoader={true} />
                  </div>
                ))
                : editorSave &&
                editorSave?.map((item, index) => (
                  <div
                    className={`card-data ${activeCardFlex ? "card-toggle-flex" : ""
                      }`}
                    key={item.id}
                  >
                    <ImageCard
                      src={
                        item?.character?.novel?.thumbnail
                          ? item?.character?.novel?.thumbnail
                          : dummyPic
                      }
                      hoverName={item.character?.name}
                      onClick={() => saveModalOpen(index)}
                    />
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className="home-card-wrapper workroom-wrapper">
          <div className="container-fluid home-card-container">
            <h2 className="title">Available Workrooms</h2>
            <div className="row home-card-row">
              {editorWorkroomLoad
                ? [1, 2, 3].map(item => <WorkRoomCard isLoader={true} />)
                : apendCharacter &&
                apendCharacter
                  ?.filter(
                    (v, i, s) =>
                      s.findIndex(v2 => ["id"].every(k => v2[k] === v[k])) ===
                      i
                  )
                  ?.map(item => (
                    <WorkRoomCard
                      src={item?.novel?.thumbnail ? item?.novel?.thumbnail : thumbnail ? thumbnail : centerImg}
                      className="center-img"
                      iconSrc={nextIcon}
                      novelName={item.name}
                      nextText={"Enter Workroom"}
                      keyValue={item.id}
                      onClick={() => setWorkRoomID(item.id)}
                    />
                  ))}
            </div>
          </div>
        </div>
      </section>
      {checkModal ? (
        <PositionPopUp
          modalShow={showModal}
          setModalShow={setShowModal}
          currentApp={editorCurrent}
          checkModal={checkModal}
        />
      ) : (
        <PositionPopUp
          modalData={editorAdverts?.results}
          modalShow={showModal}
          setModalShow={setShowModal}
          currentApp={currentApp}
        />
      )}
      <SavedforReviewModal
        modalShow={saveModalShow}
        setModalShow={setSaveModalShow}
        controls={true}
        saveReviewData={editorSave}
      />
    </HomeLayout>
  )
}

const mapStateToProps = state => ({
  editorAdverts: state.editorHome.editorAdverts,
  editorAdvertsLoad: state.editorHome.editorAdvertsLoad,
  editorCurrent: state.editorHome.editorCurrent,
  editorCurrentLoad: state.editorHome.editorCurrentLoad,
  editorSave: state.editorHome.editorSave,
  editorSaveLoad: state.editorHome.editorSaveLoad,
  editorWorkroom: state.editorHome.editorWorkroom,
  editorWorkroomLoad: state.editorHome.editorWorkroomLoad,
  loginInfo: state.login.loginInfo
})

const mapDispatchToProps = dispatch => ({
  getEditAdverts: data => dispatch(getEditAdverts(data)),
  getEditCurrent: data => dispatch(getEditCurrent(data)),
  getEditSave: data => dispatch(getEditSave(data)),
  getEditWorkroom: data => dispatch(getEditWorkroom(data)),
  saveWorkroomID: data => dispatch(saveWorkroomID(data)),
  setOpenAdvertIndex: data => dispatch(setOpenAdvertIndex(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(EditorHome)
