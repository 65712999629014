// Open Adverts
export const GET_EDIT_ADVERTS = 'GET_EDIT_ADVERTS';
export const GET_EDIT_ADVERTS_SUCCESS = 'GET_EDIT_ADVERTS_SUCCESS';
export const GET_EDIT_ADVERTS_FAILURE = 'GET_EDIT_ADVERTS_FAILURE';
// Current Applications
export const GET_EDIT_CURRENT = 'GET_EDIT_CURRENT';
export const GET_EDIT_CURRENT_SUCCESS = 'GET_EDIT_CURRENT_SUCCESS';
export const GET_EDIT_CURRENT_FAILURE = 'GET_EDIT_CURRENT_FAILURE';
// Saved for Review by the Producer
export const GET_EDIT_SAVED = 'GET_EDIT_SAVED';
export const GET_EDIT_SAVED_SUCCESS = 'GET_EDIT_SAVED_SUCCESS';
export const GET_EDIT_SAVED_FAILURE = 'GET_EDIT_SAVED_FAILURE';
// Available Workrooms
export const GET_EDIT_WORKROOMS = 'GET_EDIT_WORKROOMS';
export const GET_EDIT_WORKROOMS_SUCCESS = 'GET_EDIT_WORKROOMS_SUCCESS';
export const GET_EDIT_WORKROOMS_FAILURE = 'GET_EDIT_WORKROOMS_FAILURE';