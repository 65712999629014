import {
  GET_ADMIN_CHAPTERS,
  GET_ADMIN_CHAPTERS_SUCCESS,
  GET_ADMIN_CHAPTERS_FAILURE,
  ADMIN_CHAPTERS_DELETE,
  ADMIN_CHAPTERS_DELETE_SUCCESS,
  ADMIN_CHAPTERS_DELETE_FAILURE,
  FLAGS_UPDATE,
  FLAGS_UPDATE_SUCCESS,
  FLAGS_UPDATE_FAILURE,
  ADD_ADMIN_CHAPTERS,
  ADD_ADMIN_CHAPTERS_SUCCESS,
  ADD_ADMIN_CHAPTERS_FAILURE,
  UPDATE_ADMIN_CHAPTERS,
  UPDATE_ADMIN_CHAPTERS_SUCCESS,
  UPDATE_ADMIN_CHAPTERS_FAILURE,
  SET_CURRENT_PAGE,
  SET_CONCAT_CHAPTERS_DATA
} from "./type"

const initialState = {
  chapterInfo: false,
  concatChapterss: false,
  requesting: false,
  error: false,
  addChaptersInfo: false,
  addChaptersRequesting: false,
  addChaptersError: false,
  updateChaptersInfo: false,
  updateRequesting: false,
  updateError: false,

  currentPage: false,
  flagInfo: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ADMIN_CHAPTERS:
      return {
        ...state,
        requesting: true,
        error: false,
        chapterInfo: []
      }
    case GET_ADMIN_CHAPTERS_SUCCESS:
      return {
        ...state,
        requesting: false,
        chapterInfo: action.data
      }
    case GET_ADMIN_CHAPTERS_FAILURE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }
    case ADMIN_CHAPTERS_DELETE:
      return {
        ...state,
        requesting: true,
        error: false
      }
    case ADMIN_CHAPTERS_DELETE_SUCCESS:
      return {
        ...state,
        requesting: false
      }
    case ADMIN_CHAPTERS_DELETE_FAILURE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }
    case FLAGS_UPDATE:
      return {
        ...state,
        requesting: true,
        error: false
      }
    case FLAGS_UPDATE_SUCCESS:
      return {
        ...state,
        requesting: false,
        flagInfo: action.data
      }
    case FLAGS_UPDATE_FAILURE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }
    case ADD_ADMIN_CHAPTERS:
      return {
        ...state,
        addChaptersRequesting: true
      }
    case ADD_ADMIN_CHAPTERS_SUCCESS:
      return {
        ...state,
        addChaptersRequesting: false,
        addChaptersInfo: action.data
      }
    case ADD_ADMIN_CHAPTERS_FAILURE:
      return {
        ...state,
        addChaptersRequesting: false,
        addChaptersError: action.data
      }
    case UPDATE_ADMIN_CHAPTERS:
      return {
        ...state,
        updateRequesting: true
      }
    case UPDATE_ADMIN_CHAPTERS_SUCCESS:
      return {
        ...state,
        updateRequesting: false,
        updateChaptersInfo: action.data
      }
    case UPDATE_ADMIN_CHAPTERS_FAILURE:
      return {
        ...state,
        updateRequesting: false,
        updateError: action.data
      }
    case SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.data
      }
    case SET_CONCAT_CHAPTERS_DATA:
      return {
        ...state,
        requesting: false,
        concatChapterss: action.data
      }
    default:
      return state
  }
}
