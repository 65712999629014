import React from 'react';
import walletImg from '../../../../assets/images/wallet.svg';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from "@stripe/stripe-js/pure";
import CheckoutForm from './checkoutForm';
import { STRIPE_PUBLIC_TEST_KEY } from '../../../../config/app';

const stripePromise = loadStripe(STRIPE_PUBLIC_TEST_KEY);


const PaymentInfo = ({setModalShow, setJoinClubTab, addCard, storePlanID, availgrt, cardNoShow}) => {

  return (
    <>
      <div className="personal-info-section">
        <div className="personal-info-header">
          <img src={walletImg} alt="" />
          <h3 className='payment-head'>Payment information:</h3>
        </div>
        <div className="personal-info-input-wrapper">
          <Elements stripe={stripePromise}>
            <CheckoutForm setModalShow={setModalShow} setJoinClubTab={setJoinClubTab} cardNoShow={cardNoShow} addCard={addCard} storePlanID={storePlanID} availgrt={availgrt} />
          </Elements>
        </div>
      </div>
    </>
  )
}

export default PaymentInfo;
