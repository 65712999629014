import React, { useEffect, useState } from "react"
import notificationMsg from "../../../../../assets/images/notification-msg.svg"
import add from "../../../../../assets/images/add-blue.svg"
import Button from "../../../../Button"
import { Toaster } from "react-hot-toast"
import BoardRangePicker from "../../../../BoardRangePicker"
import BoardTimePicker from "../../../../BoardTimePicker"
import Input from "../../../../Input"
import TextArea from "../../../../TextArea"
import searchIcon from "../../../../../assets/images/search-icon.svg"
import clockIcon from "../../../../../assets/images/clock.svg"
import pencilIcon from "../../../../../assets/images/textarea-write.svg"
import closeIcon from "../../../../../assets/images/close-icon-modal.svg"
import { connect } from "react-redux"
import {
  getAdminGroup,
  postUserPushNotification,
  postUserPushNotificationFailure
} from "../../../../../pages/AdminScreens/PushNotifications/redux/action"


const CreateGroupNotification = props => {
  const {
    getAdminGroup,
    postUserPushNotification,
    userRequesting,
    getGroupData,
    requesting,
    error,
    userError,
    setShowModal,
    setModalShow,
    postUserPushNotificationFailure,
    userPush
  } = props
  const [showSearch, setShowSearch] = useState(false)
  const [showTimePicker, setShowTimePicker] = useState(false)
  const [value, onChange] = useState([])
  const [title, setTitle] = useState("")
  const [message, setMessage] = useState("")
  const [selectGroup, setSelectGroup] = useState("")
  const [selectDays, setGroupDays] = useState("")
  const [selectGroupDays, setSelectGroupDays] = useState("")
  const [active, setActive] = useState(false)
  const [checkBtn, setCheckBtn] = useState(false)
  const [rangPic, setRangPic] = useState({})
  const [timeRangPic, setTimeRangPic] = useState([])
  const [groupParam, setGroupParam] = useState('')
  const [daysError, setDaysError] = useState('')



  useEffect(() => {
    getAdminGroup()
  }, [])

  const onChanges = (value, dateString) => {
    const [first, second] = dateString
    setRangPic({ start: first, end: second })
  };

  const onSubmit = type => {
    let [to_time, from_time] = value
    setCheckBtn(type)
    const data = {
      title: title,
      message: message,
      notification_type: type,
      selected_type: "group",
      group_name: selectGroupDays,
      to_time: to_time,
      from_time: from_time,
      start_date: rangPic.start,
      end_date: rangPic.end,
      local_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    }
    postUserPushNotification(data, setShowModal, setModalShow)
  }

  const addGroup = () => {
    if (!selectDays == '') {
      setShowSearch(false)
      setSelectGroupDays(`${selectGroup}, ${selectDays}`)
    } else {
      setDaysError('Required days')
    }
  }

  const onTimeSubmit = () => {
    setShowTimePicker(false)
    setTimeRangPic(value)
  }

  const searchParam = (val) => {
    setGroupParam(val.target.value)
    if (groupParam) {
      getAdminGroup(val.target.value)
    }
  }

  return (
    <section className="add-user-section create-group-notification-section">
      <div className="add-user-header">
        <div>
          <Toaster position="top-center" />
        </div>
        <img src={notificationMsg} alt="" />
        <h3>New Push Notification</h3>
      </div>
      <div className="notification-creation-wrapper group-creation-wrapper">
        <Input
          type="text"
          placeholder="Title"
          showIcon={true}
          src={pencilIcon}
          value={title}
          onChange={e => {
            setTitle(e.target.value)
            postUserPushNotificationFailure(false)
          }}
          showError={true}
          errorMessage={userError.title && userError.title}
        />
        <TextArea
          placeholder="Message"
          value={message}
          onChange={e => {
            setMessage(e.target.value)
            postUserPushNotificationFailure(false)
          }}
          showError={true}
          errorMessage={userError.message && userError.message}
        />
        <Input
          type="text"
          className="form-control mt-3"
          placeholder={"Search groups"}
          showIcon={true}
          src={searchIcon}
          onFocus={() => {
            setSelectGroupDays('')
            setShowSearch(true)
            postUserPushNotificationFailure(false)
          }}
          value={selectGroupDays ? selectGroupDays : groupParam}
          onChange={(val) => searchParam(val)}
          showError={true}
          errorMessage={userError.group_name && userError.group_name}
        />
        {showSearch && (
          <div className="create-group-wrapper">
            <div className="create-group-details">
              <div className="close-icon" onClick={() => setShowSearch(false)}>
                <img src={closeIcon} alt="" />
              </div>
              <div
                className="new-group"
                onClick={() => setShowModal("createGroup")}
              >
                <img src={add} alt="" />
                <span>Create New Group</span>
              </div>
              <div className="users-details">
                {getGroupData && getGroupData.length > 0 ?
                  (getGroupData?.map(item => (
                    <div
                      className={`user ${active == item.id && "with-input"}`}
                    >
                      <h4
                        onClick={() => {
                          setSelectGroup(item.name)
                          setActive(item.id)
                          setDaysError('')
                        }}
                      >
                        {item.name}
                      </h4>
                      {active == item.id && (<>
                        <input
                          type="text"
                          placeholder="Add for how many days"
                          onFocus={() => setDaysError('')}
                          onChange={val => setGroupDays(val.target.value)}
                          value={selectDays} /><div className="ms-3" style={{ color: 'red' }}>{daysError}</div>
                      </>)}
                    </div>
                  ))) : <h3 className="mt-4" style={{ textAlign: 'center' }}>No Group Found</h3>}
              </div>
              <div className="button-wrapper">
                <Button title="Submit" onClick={addGroup} />
              </div>
            </div>
          </div>
        )}
        <h3>Set Schedule</h3>
        <div className="field-row">
          <div className="field">
            <BoardRangePicker onChange={onChanges} />
            <div className={`error-message ${userError.msg ? 'opacity-1' : ''}`}>{userError.msg && userError.msg}</div>
          </div>
          <div className="field">
            {showTimePicker && (
              <BoardTimePicker
                value={value}
                onChange={onChange}
                onClick={onTimeSubmit}
              />
            )}
            <Input
              placeholder="Select Time"
              type="text"
              showIcon={true}
              src={clockIcon}
              value={timeRangPic.join("   To   ")}
              onClick={() => setShowTimePicker(!showTimePicker)}
            />
          </div>
        </div>
      </div>
      <div className="button-wrapper">
        <Button
          title="Set Notification"
          className="transparent-btn"
          showSpinner={checkBtn === 'scheduled' && userRequesting}
          onClick={() => onSubmit('scheduled')}
          disabled={title.trim() === '' || message.trim() === '' || selectGroup.trim() === '' ? true : false}
        />
        <Button
          title="Send Immediately"
          showSpinner={checkBtn === 'send_now' && userRequesting}
          onClick={() => onSubmit('send_now')}
        />
      </div>
    </section>
  )
}

const mapStateToProps = state => ({
  getGroupData: state.pushNotifications.getGroupData,
  userRequesting: state.pushNotifications.userRequesting,
  requesting: state.pushNotifications.getGroupRequesting,
  userError: state.pushNotifications.userError,
  userPush: state.pushNotifications.userPush
})

const mapDispatchToProps = dispatch => ({
  getAdminGroup: data => dispatch(getAdminGroup(data)),
  postUserPushNotification: (data, setShowModal, setModalShow) => dispatch(postUserPushNotification(data, setShowModal, setModalShow)),
  postUserPushNotificationFailure: data => dispatch(postUserPushNotificationFailure(data))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateGroupNotification)
