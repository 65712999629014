import React from "react"
import closeIcon from "../../../assets/images/close-icon-modal.svg"
import greaterWhiteIcon from "../../../assets/images/greater-white.svg"
import { history } from "../../../reduxStore/store"
import {
  saveMemberID,
  saveBookID
} from "../../../pages/AuthScreens/Login/redux/action"
import { connect } from "react-redux"

const SavedforReviewDetails = ({
  openAdvertIndex,
  saveReviewData,
  setModalShow,
  loginInfo,
  saveMemberID,
  saveBookID,
}) => {

    
  const setBookID = () => {
    if (saveReviewData[openAdvertIndex]?.character?.novel?.id) {
      saveBookID(saveReviewData[openAdvertIndex]?.character?.novel?.id)
      history.push({
        pathname: `/${loginInfo.user.mode}/reading_experience/`
      })
    }
  }

  const onSaveData = id => {
    if (id) {
      saveMemberID(id)
      history.push({
        pathname: `/${loginInfo.user.mode}/member_profile/${saveReviewData[openAdvertIndex]?.character?.novel?.author.name.toLowerCase().replace(/\s+/g, "_")}`
          
      })
    }
  }


  return (
    <div className="modal-body-right">
      <div className="modal-right-header">
        <h3>{saveReviewData && saveReviewData[openAdvertIndex]?.character?.novel?.genre}</h3>
        <div className="novel">{saveReviewData && saveReviewData[openAdvertIndex]?.character?.name}</div>
        <div className="author-details">
          <p
            className="author"
            onClick={() =>
              onSaveData(saveReviewData && saveReviewData[openAdvertIndex]?.character?.novel?.author?.id)
            }
          >
            {saveReviewData && saveReviewData[openAdvertIndex]?.character?.novel?.author?.name}
          </p>
          <p className="writer">
            {(saveReviewData && saveReviewData[openAdvertIndex]?.character?.writer?.name) ||
              ""}
          </p>
        </div>
        <div className="close-icon">
          <img src={closeIcon} alt="" onClick={() => setModalShow(false)} />
        </div>
      </div>
      <div className="modal-right-body">
        <div className="body-text-wrapper">
          <div className="text-wrapper">
            <p>{saveReviewData && saveReviewData[openAdvertIndex]?.character?.novel?.about_novel}</p>
          </div>
        </div>
      </div>
      <div className="modal-right-footer" onClick={setBookID}>
        <div className="text">Open Demo Reading Experience</div>
        <div className="image-wrapper">
          <img src={greaterWhiteIcon} alt="" />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  loginInfo: state.login.loginInfo
})

const mapDispatchToProps = dispatch => ({
  saveMemberID: data => dispatch(saveMemberID(data)),
  saveBookID: data => dispatch(saveBookID(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(SavedforReviewDetails)
