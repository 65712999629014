import {
 PUT_REQUEST_PASSWORD,
 PUT_REQUEST_PASSWORD_SUCCESS,
 PUT_REQUEST_PASSWORD_FAILURE,
 GET_REQUEST_PROFILE_DATA,
 GET_REQUEST_PROFILE_DATA_SUCCESS,
 GET_REQUEST_PROFILE_DATA_FAILURE,
 PUT_REQUEST_ABOUT_ME,
 PUT_REQUEST_ABOUT_ME_SUCCESS,
 PUT_REQUEST_ABOUT_ME_FAILURE
} from "./type"

const initialState = {
  data: false,
  requesting: false,
  error: false,
  profileData: false,
  updatePassrequest:false,
  updateProfileRequest:false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case PUT_REQUEST_PASSWORD:
      return {
        ...state,
        updatePassrequest: true
      }
    case PUT_REQUEST_PASSWORD_SUCCESS:
      return {
        ...state,
        updatePassrequest: false,
        data: action.data
      }
    case PUT_REQUEST_PASSWORD_FAILURE:
      return {
        ...state,
        updatePassrequest: false,
        error: action.data
      }
      case GET_REQUEST_PROFILE_DATA:
        return {
          ...state,
          requesting: true,
        }
        case GET_REQUEST_PROFILE_DATA_SUCCESS:
          return {
            ...state,
            requesting: false,
            profileData: action.data
          }
          case GET_REQUEST_PROFILE_DATA_FAILURE:
            return {
              ...state,
              requesting: false,
              error: action.data
            }
            case PUT_REQUEST_ABOUT_ME:
              return {
                ...state,
                updateProfileRequest: true,
              }
              case PUT_REQUEST_ABOUT_ME_SUCCESS:
                return {
                  ...state,
                  updateProfileRequest: false,
                  data: action.data
                }
                case PUT_REQUEST_ABOUT_ME_FAILURE:
                  return {
                    ...state,
                    updateProfileRequest: false,
                    error: action.data
                  }
    default:
      return state
  }
}
