import { all, call, put, takeLatest } from "redux-saga/effects"
// config
import { BASE_URL } from "../../../../../config/app"
// utils
import XHR from "../../../../../utils/XHR"
// types
import { POST_PAYMENT_INFORMATION } from "./type"
import { loginRequestFailure } from "../../../../../pages/AuthScreens/Login/redux/action";

// actions
import {
  postPaymentInformationSuccess,
  postPaymentInformationFailure
} from "./action"
import toast from 'react-hot-toast';


async function postPaymentAPI(data) {
  const URL = `${BASE_URL}api/v1/save-stripe-info/`
  const token = await localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "POST",
    data
  }
  return XHR(URL, options)
}

function* postPaymentInfo({ data, setModalShow }) {
  try {
    const response = yield call(postPaymentAPI, data)
    yield put(postPaymentInformationSuccess(response.data))
    toast.success('Payment Successful', {
      id: 'card fields',
    })
    setModalShow(false)
    yield put(loginRequestFailure(false))
  } catch (error) {
    const { response } = error
    yield put(loginRequestFailure(false))
    yield put(postPaymentInformationFailure(response.data))
    toast.error(response?.data?.msg, {
      id: 'card fields',
    })
  }
}

export default all([takeLatest(POST_PAYMENT_INFORMATION, postPaymentInfo)])
