import React, { useState } from "react"
import { DatePicker, Space } from "antd"
import Button from "../Button"

import "./style.scss"

const { RangePicker } = DatePicker

const BoardRangePicker = ({ onChange, onOk }) => {
  return (
    <div className="date-picker-space">
      <Space direction="vertical">
        <RangePicker
          size="middle"
          allowClear={false}
          suffixIcon={false}
          onChange={onChange}
          onOk={onOk}
          // renderExtraFooter={
          //   () =>
          //     <Button
          //       title='Submit'
          //     >
          //       Apply
          //     </Button>
          // }
        />
      </Space>
    </div>
  )
}

export default BoardRangePicker
