import React from "react"
import { connect } from "react-redux"
import { Route, Redirect } from "react-router-dom"

const RouteGuard = ({
  isProtected = false,
  accessToken,
  loginInfo,
  component: Component,
  ...rest
}) => {
  return (
    <Route
      {...rest}
      render={props => {
        if (isProtected) {
          return accessToken ? (
            <Component {...props} />
          ) : (
            <Redirect to={{ pathname: "/" }} />
          )
        } else {
          return accessToken ? (
            loginInfo?.user?.mode == "reader" ? (
              <Redirect
                to={{
                  pathname: "/reader/home"
                }}
              />
            ) : loginInfo?.user?.mode == "writer" ? (
              <Redirect
                to={{
                  pathname: "/writer/home"
                }}
              />
            ) : loginInfo?.user?.mode == "editor" ? (
              <Redirect
                to={{
                  pathname: "/editor/home"
                }}
              />
            ) : loginInfo?.user?.mode == "producer" ? (
              <Redirect
                to={{
                  pathname: "/producer/home"
                }}
              />
            ) : (
              loginInfo?.user?.mode == "admin" && (
                <Redirect
                  to={{
                    pathname: "/admin/home"
                  }}
                />
              )
            )
          ) : (
            <Component {...props} />
          )
        }
      }}
    />
  )
}

const mapStateToProps = state => ({
  accessToken: state.login.accessToken,
  loginInfo: state.login.loginInfo
})

export default connect(mapStateToProps, null)(RouteGuard)
