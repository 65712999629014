import React, { useState, useEffect } from "react"
import "./style.scss"
import landingImage from "../../../assets/images/landing-image.png"
import dummyPic from "../../../assets/images/dummyPic.png"
import Button from "../../../components/Button"
import ImageCard from "../../../components/ImageCard"
import JoinClubModal from "../../../components/JoinClub"
import { connect } from "react-redux"
import { getNovalsTop } from "./redux/action"
import { history } from "../../../reduxStore/store"
import { Images } from "../../../theme/Images"

const Landing = props => {
  const { getNovalsTop, novalsTopData, requesting, error } = props

  const [modalShow, setModalShow] = useState(false)

  useEffect(() => {
    getNovalsTop()
  }, [])

  return (
    <>
      <section className="landing-page-section">
        <div className="container-fluid landing-content-container">
          <div className="row landing-content-row">
            <div className="col-md-6">
              <div className="landing-left-content">
                <div className="content-details">
                  {/* <div className="align-items-center d-flex justify-content-center ">

                  <img src={Images.Logo} className="img-fluid" />
                  </div> */}
                   
            
{/* +                  <img src={Images.Logo} className="img-fluid" /> */}
            
                  <h1 >Story Host</h1>
                  <h2 >
                    Home of the Multi-Perspective Novel
                  </h2>
                  <div className="landing-content-wrapper">
                    <p>
                      We are the premier destination for readers and writers who
                      are looking for a unique storytelling experience. Our
                      multi-perspective novels are like nothing you've ever read
                      before. We bring together a group of talented writers to
                      create a collection of stories that all come together in
                      the same set of events.
                    </p>
                    <p>
                      At Story Host, we believe that reading and writing should
                      be a collaborative experience. That's why we host
                      workshops where writers can come together and share their
                      ideas. Each writer plays a different role, and together,
                      they create a set of interrelated stories that are sure to
                      captivate and inspire.
                    </p>
                    <p>
                      But our work doesn't stop there. We release these novels
                      in weekly episodes, so our readers can follow along with
                      the story and watch it unfold in real time. What better
                      way to truly immerse yourself in a storyline?
                    </p>
                    <p>
                      The best part? Membership is absolutely free. That's
                      right, you can join our community of writers and readers
                      today without spending a dime. So what are you waiting
                      for? Click the button below to join now and experience the
                      magic of Story Host for yourself.
                    </p>
                  </div>
                </div>
                <div className="button-wrapper">
                  <Button title="Sign Up" onClick={() => setModalShow(true)} />
                  <Button
                    title="Sign In"
                    className="transparent-btn"
                    onClick={() => history.push("/auth/login")}
                  />
                </div>
                <div className="browse">
                  {/* <a href="">Browse as Guest</a> */}
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="landing-right-content">
                <img src={landingImage} className="img-fluid" alt="Image" />
              </div>
            </div>
          </div>
        </div>
        <div className="landing-slider-section">
          <div className="landing-slider-wrapper">
            <div className="container-fluid slider-container">
              <div className="row slider-card-row">
                {requesting
                  ? [1, 2, 3, 4, 5, 6].map(item => (
                    <div className="card-data" key={item.id}>
                      <ImageCard isLoader={true} />
                    </div>
                  ))
                  : novalsTopData &&
                  novalsTopData
                    ?.map(item => (
                      <div className="card-data" key={item.id}>
                        <ImageCard
                          src={item?.thumbnail ? item?.thumbnail : dummyPic}
                        />
                      </div>
                    ))
                    .filter((k, i) => i < 6)}
                {!requesting && (
                  <div className="slider-card top-list">Top lists</div>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      <JoinClubModal modalShow={modalShow} setModalShow={setModalShow} />
    </>
  )
}

const mapStateToProps = state => ({
  novalsTopData: state.landing.novalsTopData,
  requesting: state.landing.requesting,
  error: state.landing.error
})

const mapDispatchToProps = dispatch => ({
  getNovalsTop: data => dispatch(getNovalsTop(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Landing)
