import { all, call, put, takeLatest } from "redux-saga/effects"
// import AsyncStorage from '@react-native-community/async-storage';
// config
import { BASE_URL } from "../../../../config/app"
// utils
import XHR from "../../../../utils/XHR"
// types
import {
  GET_AUTHOR_PROFILE,
  GET_NOVEL_DATA,
  GET_BOOK_DATA,
  GET_CHAPTER_DATA,
  GET_READER_UPLOADED,
  GET_READER_FAVORITES,
  GET_READER_TOP_STORIES,
  GET_READER_TOP_WRITERS,
  GET_READER_TOP_CHARACTERS,
  GET_READER_TOP_CHAPTERS
} from "./type"
// actions
import {
  getAuthorProfileSuccess,
  getAuthorProfileFailure,
  getNovelDataSuccess,
  getNovelDataFailure,
  getBookDataSuccess,
  getBookDataFailure,
  getNovelChapterSuccess,
  getNovelChapterFailure,
  getReaderUploadedSuccess,
  getReaderUploadedFailure,
  getReaderFavoritesSuccess,
  getReaderFavoritesFailure,
  getReaderStoriesSuccess,
  getReaderStoriesFailure,
  getReaderWritersSuccess,
  getReaderWritersFailure,
  getReaderCharactersSuccess,
  getReaderCharactersFailure,
  getReaderChaptersSuccess,
  getReaderChaptersFailure
} from "./action"
import toast from "react-hot-toast"
import { putFavoritesSuccess } from "../../Favorites/redux/action"

async function getUploadedAPI(name = "", page = 1) {
  const URL = `${BASE_URL}api/v1/uploaded_home/?name=${name}&page=${page}`
  const token = await localStorage.getItem("token")

  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

async function getFavoritesAPI() {
  const URL = `${BASE_URL}api/v1/favorite_home/`
  const token = await localStorage.getItem("token")

  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

async function getStoriesAPI() {
  const URL = `${BASE_URL}api/v1/top_stories/`
  const token = await localStorage.getItem("token")

  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

async function getWritersAPI() {
  const URL = `${BASE_URL}api/v1/top_writer/`
  const token = await localStorage.getItem("token")

  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

async function getCharactersAPI() {
  const URL = `${BASE_URL}api/v1/top_character/`
  const token = await localStorage.getItem("token")

  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

async function getChaptersAPI() {
  const URL = `${BASE_URL}api/v1/top_chapter/`
  const token = await localStorage.getItem("token")

  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

async function getAuthorAPI(id) {
  const URL = `${BASE_URL}api/v1/author-profile/${id}/`
  const token = await localStorage.getItem("token")

  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

async function getNovelAPI(id) {
  const URL = `${BASE_URL}api/v1/novel-data/${id}/`
  const token = await localStorage.getItem("token")

  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

async function getBookAPI(id) {
  const URL = `${BASE_URL}api/v1/book/${id}/`
  const token = await localStorage.getItem("token")

  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

async function getChapterAPI(id) {
  const URL = `${BASE_URL}api/v1/novel_chapter/${id}/`
  const token = await localStorage.getItem("token")

  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

function* getAuthorProfile({ data }) {
  try {
    const response = yield call(getAuthorAPI, data)
    yield put(getAuthorProfileSuccess(response.data))
    yield put(putFavoritesSuccess(response.data.author))
  } catch (e) {
    const { response } = e
    yield put(getAuthorProfileFailure(response.data))
  }
}

function* getNovelData({ data }) {
  try {
    const response = yield call(getNovelAPI, data)
    yield put(getNovelDataSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(getNovelDataFailure(response.data))
    toast.error(response.data, {
      id: "card fields"
    })
  }
}

function* getBookData({ data }) {
  try {
    const response = yield call(getBookAPI, data)
    yield put(getBookDataSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(getBookDataFailure(response.data))
  }
}

function* getChapterData({ data }) {
  try {
    const response = yield call(getChapterAPI, data)
    yield put(getNovelChapterSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(getNovelChapterFailure(response.data))
  }
}

function* getUploaded({ data, page }) {
  try {
    const response = yield call(getUploadedAPI, data, page)
    yield put(getReaderUploadedSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(getReaderUploadedFailure(response.data))
  }
}

function* getFavorits() {
  try {
    const response = yield call(getFavoritesAPI)
    yield put(getReaderFavoritesSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(getReaderFavoritesFailure(response.data))
  }
}

function* getStories() {
  try {
    const response = yield call(getStoriesAPI)
    yield put(getReaderStoriesSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(getReaderStoriesFailure(response.data))
  }
}

function* getWriters() {
  try {
    const response = yield call(getWritersAPI)
    yield put(getReaderWritersSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(getReaderWritersFailure(response.data))
  }
}

function* getCharacters() {
  try {
    const response = yield call(getCharactersAPI)
    yield put(getReaderCharactersSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(getReaderCharactersFailure(response.data))
  }
}

function* getChapters() {
  try {
    const response = yield call(getChaptersAPI)
    yield put(getReaderChaptersSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(getReaderChaptersFailure(response.data))
  }
}

export default all([
  takeLatest(GET_AUTHOR_PROFILE, getAuthorProfile),
  takeLatest(GET_NOVEL_DATA, getNovelData),
  takeLatest(GET_BOOK_DATA, getBookData),
  takeLatest(GET_CHAPTER_DATA, getChapterData),
  takeLatest(GET_READER_UPLOADED, getUploaded),
  takeLatest(GET_READER_FAVORITES, getFavorits),
  takeLatest(GET_READER_TOP_STORIES, getStories),
  takeLatest(GET_READER_TOP_WRITERS, getWriters),
  takeLatest(GET_READER_TOP_CHARACTERS, getCharacters),
  takeLatest(GET_READER_TOP_CHAPTERS, getChapters)
])
