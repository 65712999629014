export const GET_ALL_USERS_USERS = "GET_ALL_USERS_USERS"
export const GET_ALL_USERS_USERS_SUCCESS = "GET_ALL_USERS_USERS_SUCCESS"
export const GET_ALL_USERS_USERS_FAILURE = "GET_ALL_USERS_USERS_FAILURE"

export const SET_DIRECT_USERS = "SET_DIRECT_USERS"
export const SET_GROUP_USERS = "SET_GROUP_USERS"

export const POST_GROUP_CHAT_IMAGE = "POST_GROUP_CHAT_IMAGE"
export const POST_GROUP_CHAT_IMAGE_SUCCESS = "POST_GROUP_CHAT_IMAGE_SUCCESS"

export const GET_PUBNUB_CHANNELS = "GET_PUBNUB_CHANNELS"
export const GET_PUBNUB_CHANNELS_SUCCESS = "GET_PUBNUB_CHANNELS_SUCCESS"
export const GET_PUBNUB_CHANNELS_FAILURE = "GET_PUBNUB_CHANNELS_FAILURE"

export const POST_PUBNUB_CHANNELS = "POST_PUBNUB_CHANNELS"
export const POST_PUBNUB_CHANNELS_SUCCESS = "POST_PUBNUB_CHANNELS_SUCCESS"
export const POST_PUBNUB_CHANNELS_FAILURE = "POST_PUBNUB_CHANNELS_FAILURE"

export const UPDATE_PUBNUB_CHANNELS = "UPDATE_PUBNUB_CHANNELS"
export const UPDATE_PUBNUB_CHANNELS_SUCCESS = "UPDATE_PUBNUB_CHANNELS_SUCCESS"
export const UPDATE_PUBNUB_CHANNELS_FAILURE = "UPDATE_PUBNUB_CHANNELS_FAILURE"

export const REMOVE_PUBNUB_USER = "REMOVE_PUBNUB_USER"
export const REMOVE_PUBNUB_USER_SUCCESS = "REMOVE_PUBNUB_USER_SUCCESS"
export const REMOVE_PUBNUB_USER_FAILURE = "REMOVE_PUBNUB_USER_FAILURE"

export const DELETE_PUBNUB_USER = "DELETE_PUBNUB_USER"
export const DELETE_PUBNUB_USER_SUCCESS = "DELETE_PUBNUB_USER_SUCCESS"
export const DELETE_PUBNUB_USER_FAILURE = "DELETE_PUBNUB_USER_FAILURE"

export const SEARCH_LIST_SHOW = "SEARCH_LIST_SHOW"
