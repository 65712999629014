import React, { useEffect, useState } from 'react';
import downloadIcon from '../../assets/images/download.svg';
import writerSideImg from '../../assets/images/writer-side-img.svg';
import HomeLayout from '../../layout/HomeLayout';
import Button from '../../components/Button';
import './style.scss';
import { BASE_URL } from '../../config/app';
import { connect } from 'react-redux';
// import { patchApplicationData } from '../OpenApplications/redux/action';
import { history } from '../../reduxStore/store';

const Applications = (props) => {
    const {setApplicateData, patchApplicationData, patchRequesting, patchData} = props
    const [checkStatus, setCheckStatus] = useState('')

    useEffect(() => {
        if(patchData?.status){
            history.push('/producer/producer_applications')
        }
    },[patchData?.status])

    // const downloadFile = id => {
    //     if(id){
    //         window.open(`${BASE_URL}api/v1/download_pdf/${id}/`)
    //     }
    //   }

      const patchRequest = (id, status) => {
        setCheckStatus(status)
        const data = {
          status: status
        }
        // patchApplicationData(id, data);
      }

    return (
        <HomeLayout headerName={"Applications"} showBack={true} >
            <section className="content-section application-section">
                <div className="application-details-section">
                    <div className="container-fluid applicant-wrapper">
                        <h3>{setApplicateData?.user?.name}</h3>
                        <h4>Example of Work</h4>
                        <div className="row download-sample-row">
                            <div className="download-sample-card-wrapper">
                                <div className="download-sample-card" onClick={() => "downloadFile(setApplicateData.id)"}>
                                    <img src={downloadIcon} alt="" />
                                    <div className="title">Download <br /> Sample Work</div>
                                </div>
                            </div>
                        </div>
                        <div className="button-wrapper">
                            {setApplicateData?.status !== "saved" && (<Button
                                title='Save Candidate'
                                onClick={() => patchRequest(setApplicateData.id, 'saved')}
                                showSpinner={checkStatus == 'saved' && patchRequesting}
                            />)}
                            <Button
                                title='Accept Candidate'
                                className='orange-btn'
                                onClick={() => patchRequest(setApplicateData.id, 'accepted')}
                                showSpinner={checkStatus == 'accepted' && patchRequesting}
                            />
                            <Button
                                title='Reject Candidate'
                                className='black-btn'
                                onClick={() => patchRequest(setApplicateData.id, 'rejected')}
                                showSpinner={checkStatus == 'rejected' && patchRequesting}
                            />
                        </div>
                    </div>
                </div>
                <div className="side-img">
                    <img src={writerSideImg} alt="Image" />
                </div>
            </section>
        </HomeLayout>
    )
}

const mapStateToProps = (state) => ({
    setApplicateData: state.login.setApplicateData,
    patchRequesting: state.openApplications.patchRequesting,
    patchData: state.openApplications.patchData
})

const mapDispatchToProps = (dispatch) => ({
    // patchApplicationData: (id, status) => dispatch(patchApplicationData(id, status))
})

export default connect(mapStateToProps,mapDispatchToProps)(Applications)