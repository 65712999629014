import {
  APPLY_FOR_POSITION,
  APPLY_FOR_POSITION_SUCCESS,
  APPLY_FOR_POSITION_FAILURE,
  SET_OPEN_ADVERT_INDEX,
  GET_WRITER_ADVERTS,
  GET_WRITER_ADVERTS_SUCCESS,
  GET_WRITER_ADVERTS_FAILURE,
  GET_WRITER_CURRENT,
  GET_WRITER_CURRENT_SUCCESS,
  GET_WRITER_CURRENT_FAILURE,
  GET_WRITER_WORKROOMS,
  GET_WRITER_WORKROOMS_SUCCESS,
  GET_WRITER_WORKROOMS_FAILURE
} from "./type"

const initialState = {
  loading: false,
  errorMsg: false,
  applySuccess: false,
  openAdvertIndex: 0,

  writerAdverts: false,
  writerAdvertsLoad: false,
  writerAdvertsError: false,

  writerCurrent: false,
  writerCurrentLoad: false,
  writerCurrentError: false,

  writerWorkroom: false,
  writerWorkroomLoad: false,
  writerWorkroomError: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case APPLY_FOR_POSITION:
      return {
        ...state,
        loading: true
      }
    case APPLY_FOR_POSITION_SUCCESS:
      return {
        ...state,
        loading: false,
        applySuccess: action.data
      }
    case APPLY_FOR_POSITION_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: action.data
      }
    case SET_OPEN_ADVERT_INDEX:
      return {
        ...state,
        openAdvertIndex: action.data
      }
      case GET_WRITER_ADVERTS:
      return {
        ...state,
        writerAdvertsLoad: true
      }
    case GET_WRITER_ADVERTS_SUCCESS:
      return {
        ...state,
        writerAdvertsLoad: false,
        writerAdverts: action.data
      }
    case GET_WRITER_ADVERTS_FAILURE:
      return {
        ...state,
        writerAdvertsLoad: false,
        writerAdvertsError: action.data
      }
      case GET_WRITER_CURRENT:
      return {
        ...state,
        writerCurrentLoad: true
      }
    case GET_WRITER_CURRENT_SUCCESS:
      return {
        ...state,
        writerCurrentLoad: false,
        writerCurrent: action.data
      }
    case GET_WRITER_CURRENT_FAILURE:
      return {
        ...state,
        writerCurrentLoad: false,
        writerCurrentError: action.data
      }
      case GET_WRITER_WORKROOMS:
      return {
        ...state,
        writerWorkroomLoad: true
      }
    case GET_WRITER_WORKROOMS_SUCCESS:
      return {
        ...state,
        writerWorkroomLoad: false,
        writerWorkroom: action.data
      }
    case GET_WRITER_WORKROOMS_FAILURE:
      return {
        ...state,
        writerWorkroomLoad: false,
        writerWorkroomError: action.data
      }
    default:
      return state
  }
}
