import React, { useState } from "react"
import Modal from "react-bootstrap/Modal"
import CreateGroupNotification from "./components/CreateGroupNotification"
import CreatePushNotification from "./components/CreatePushNotification"
import PushGroupChat from "./components/PushGroupChat"
import SelectNotification from "./components/SelectNotification"
import { postUserPushNotificationFailure } from '../../../pages/AdminScreens/PushNotifications/redux/action'

import "./style.scss"
import { connect } from "react-redux"

const AdminPushModal = props => {
  const { modalShow, setModalShow, postUserPushNotificationFailure } = props
  const [showModal, setShowModal] = useState(false)

  const ModalClose = () => {
    setTimeout(() => setShowModal(false),500)
    setModalShow(false);
    postUserPushNotificationFailure(false);
  }

  return (
    <>
      <Modal
        show={modalShow}
        onHide={ModalClose}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="admin-modal push-group-chat-modal"
      >
        <Modal.Body>
          {!showModal && <SelectNotification setShowModal={setShowModal} />}
          {showModal === 'selectUser' && <CreatePushNotification setShowModal={setShowModal} setModalShow={setModalShow} />}
          {showModal === 'selectGroup' && <CreateGroupNotification setShowModal={setShowModal} setModalShow={setModalShow} />}
          {showModal === 'createGroup' && <PushGroupChat setShowModal={setShowModal} />}
        </Modal.Body>
      </Modal>
    </>
  )
}

const mapDispatchToProps = (dispatch) => ({
  postUserPushNotificationFailure: data => dispatch(postUserPushNotificationFailure(data))
})


export default connect(null, mapDispatchToProps)(AdminPushModal)
