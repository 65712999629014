import { all, call, put, takeLatest } from "redux-saga/effects"
import { BASE_URL } from "../../../config/app"
import XHR from "../../../utils/XHR"
import {
  getWorkRoomSuccess,
  getWorkRoomFailure,
  patchWorkRoomSuccess,
  patchWorkRoomFailure,
  getUpcomingDataSuccess,
  getUpcomingDataFailure,
  patchUpcomingDataSuccess,
  patchUpcomingDataFailure
} from "./action"
import {
  GET_WORK_ROOM_DATA,
  PATCH_CHARACTER_DETAILS,
  GET_UPCOMING_DATA,
  PATCH_UPCOMING_DATA
} from "./type"
import { toast } from "react-hot-toast"

const getWorkRoomAPI = async id => {
  const URL = `${BASE_URL}api/v1/workroom/${id}/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

const getUpcomingAPI = async id => {
  const URL = `${BASE_URL}api/v1/upcoming_chapter/${id}/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

const patchWorkRoomAPI = (id, data) => {
  const URL = `${BASE_URL}api/v1/novel_character/${id}/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "PATCH",
    data
  }
  return XHR(URL, options)
}

const patchUpcomingAPI = (id, data) => {
  const URL = `${BASE_URL}api/v1/upcoming_chapter/${id}/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "PATCH",
    data
  }
  return XHR(URL, options)
}

function* getWorkRoomData({ data }) {
  try {
    const response = yield call(getWorkRoomAPI, data)
    yield put(getWorkRoomSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(getWorkRoomFailure(response.data))
  }
}

function* getUpcomingData({ data }) {
  try {
    const response = yield call(getUpcomingAPI, data)
    yield put(getUpcomingDataSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(getUpcomingDataFailure(response.data))
  }
}

function* patchWorkRoomData({ id, data }) {
  try {
    const response = yield call(patchWorkRoomAPI, id, data)
    yield put(patchWorkRoomSuccess(response.data))
    toast.success("successfully changed", {
      id: "card"
    })
  } catch (e) {
    const { response } = e
    yield put(patchWorkRoomFailure(response.data))
  }
}

function* patchUpcomingData({ id, data }) {
  try {
    const response = yield call(patchUpcomingAPI, id, data)
    yield put(patchUpcomingDataSuccess(response.data))
    toast.success("successfully changed", {
      id: "card"
    })
  } catch (e) {
    const { response } = e
    yield put(patchUpcomingDataFailure(response.data))
  }
}

export default all([
  takeLatest(GET_WORK_ROOM_DATA, getWorkRoomData),
  takeLatest(PATCH_CHARACTER_DETAILS, patchWorkRoomData),
  takeLatest(GET_UPCOMING_DATA, getUpcomingData),
  takeLatest(PATCH_UPCOMING_DATA, patchUpcomingData)
])
