import React from 'react';
import './style.scss';
import favoriteIcon from '../../assets/images/favorites.svg';
import favoriteIconActive from '../../assets/images/filled-favorite-active.svg';
import goldenLike from '../../assets/images/like-golden.svg';
import goldenToken from '../../assets/images/token-golden.svg';

const FeatureNovelCard = ({
    key,
    src,
    novelName,
    nextText,
    iconSrc,
    descriptionText,
    total_likes,
    grt,
    title,
    onClick,
    novelFavClick,
    showFavorite,
    readClick,
}) => {

    return (
        <div className="feature-novel-card-wrapper" key={key}>
            <div className="feature-novel-card">
                <div className="feature-novel-card-left">
                    <div className="feature-novel-card-img">
                        <img src={src} alt="" />
                    </div>
                </div>
                <div className="feature-novel-card-right">
                    <div className="text-details">
                        <div className="top">
                            <div className="novel-name" title={title} >
                                {novelName}
                            </div>
                            <img src={showFavorite ? favoriteIconActive : favoriteIcon} onClick={novelFavClick} alt="" />
                        </div>
                        <div className="description">
                            <p>{
                                descriptionText ?
                                    descriptionText
                                    : 'Tempor sed lectus mauris luctus euismod. At tristique sed ut suspendisse nam. Malesuada magna.'
                            }
                            </p>
                        </div>
                        <div className="bottom-details-wrapper">
                            <div className="read-details-wrapper">
                                <div className="icon-wrapper">
                                    <img src={goldenLike} alt="" />
                                    <span>Likes ({total_likes})</span>
                                </div>
                                <div className="icon-wrapper">
                                    <img src={goldenToken} alt="" />
                                    <span>Good Read Tokens ({grt ? grt.toFixed(0) : 0})</span>
                                </div>
                            </div>
                            <div className="action-btn" onClick={onClick}>
                                <div className="text"
                                    onClick={readClick}>
                                    {nextText}
                                </div>
                                <div className="image-wrapper">
                                    <img src={iconSrc} alt="" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FeatureNovelCard