import {
  GET_ADMIN_PUSH_NOTIFICATION,
  GET_ADMIN_PUSH_NOTIFICATION_SUCCESS,
  GET_ADMIN_PUSH_NOTIFICATION_FAILURE,
  POST_USER_PUSH_NOTIFICATION,
  POST_USER_PUSH_NOTIFICATION_SUCCESS,
  POST_USER_PUSH_NOTIFICATION_FAILURE,
  GET_ADMIN_GROUP,
  GET_ADMIN_GROUP_SUCCESS,
  GET_ADMIN_GROUP_FAILURE,
  POST_ADMIN_GROUP,
  POST_ADMIN_GROUP_SUCCESS,
  POST_ADMIN_GROUP_FAILURE,
  PUT_FOR_ACTIVE,
  PUT_FOR_ACTIVE_SUCCESS,
  PUT_FOR_ACTIVE_FAILURE,
  DELETE_ADMIN_NOTIFICATION,
  DELETE_ADMIN_NOTIFICATION_SUCCESS,
  DELETE_ADMIN_NOTIFICATION_FAILURE,
  CONCAT_ADMIN_NOTIFICATION,
} from "./type";

const initialState = {
  pushInfo: false,
  requesting: false,
  error: false,

  userPush: false,
  userRequesting: false,
  userError: false,

  getGroupData: false,
  getGroupRequesting: false,
  getGroupError: false,
  
  postGroupData: false,
  postGroupRequesting: false,
  postGroupError: false,

  activeInfo: false,
  activeRequesting: false,
  activeError: false,

  deleteInfo: false,
  deleteRequesting: false,
  deleteError: false,

  pushConcat: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ADMIN_PUSH_NOTIFICATION:
      return {
        ...state,
        requesting: true,
        error: false
      }
    case GET_ADMIN_PUSH_NOTIFICATION_SUCCESS:
      return {
        ...state,
        requesting: false,
        pushInfo: action.data
      }
    case GET_ADMIN_PUSH_NOTIFICATION_FAILURE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }
      case POST_USER_PUSH_NOTIFICATION:
      return {
        ...state,
        userRequesting: true,
        userError: false
      }
    case POST_USER_PUSH_NOTIFICATION_SUCCESS:
      return {
        ...state,
        userRequesting: false,
        userPush: action.data
      }
    case POST_USER_PUSH_NOTIFICATION_FAILURE:
      return {
        ...state,
        userRequesting: false,
        userError: action.data
      }
      case GET_ADMIN_GROUP:
      return {
        ...state,
        getGroupRequesting: true,
        getGroupError: false
      }
    case GET_ADMIN_GROUP_SUCCESS:
      return {
        ...state,
        getGroupRequesting: false,
        getGroupData: action.data
      }
    case GET_ADMIN_GROUP_FAILURE:
      return {
        ...state,
        getGroupRequesting: false,
        getGroupError: action.data
      }
      case POST_ADMIN_GROUP:
      return {
        ...state,
        postGroupRequesting: true,
        postGroupError: false
      }
    case POST_ADMIN_GROUP_SUCCESS:
      return {
        ...state,
        postGroupRequesting: false,
        postGroupData: action.data
      }
    case POST_ADMIN_GROUP_FAILURE:
      return {
        ...state,
        postGroupRequesting: false,
        postGroupError: action.data
      }
      case PUT_FOR_ACTIVE:
        return {
          ...state,
          activeRequesting: true,
          activeError: false
        }
      case PUT_FOR_ACTIVE_SUCCESS:
        return {
          ...state,
          activeRequesting: false,
          activeInfo: action.data
        }
      case PUT_FOR_ACTIVE_FAILURE:
        return {
          ...state,
          activeRequesting: false,
          activeError: action.data
        }
        case DELETE_ADMIN_NOTIFICATION:
        return {
          ...state,
          deleteRequesting: true,
          deleteError: false
        }
      case DELETE_ADMIN_NOTIFICATION_SUCCESS:
        return {
          ...state,
          deleteRequesting: false,
          deleteInfo: action.data
        }
      case DELETE_ADMIN_NOTIFICATION_FAILURE:
        return {
          ...state,
          deleteRequesting: false,
          deleteError: action.data
        }
        case CONCAT_ADMIN_NOTIFICATION:
        return {
          ...state,
          requesting: false,
          pushConcat: action.data
        }
    default:
      return state
  }
}
