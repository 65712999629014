import {
    GET_ADMIN_SUBSCRIPTION,
    GET_ADMIN_SUBSCRIPTION_SUCCESS,
    GET_ADMIN_SUBSCRIPTION_FAILURE,
    POST_ADMIN_SUBSCRIPTION,
    POST_ADMIN_SUBSCRIPTION_SUCCESS,
    POST_ADMIN_SUBSCRIPTION_FAILURE,
    POST_EDIT_PLANS,
    POST_EDIT_PLANS_SUCCESS,
    POST_EDIT_PLANS_FAILURE,
    GET_SUBSCRIBED_USER,
    GET_SUBSCRIBED_USER_SUCCESS,
    GET_SUBSCRIBED_USER_FAILURE,
    SET_SUB_USER_CONCAT,
    GET_FINANCES_TOP_BOX,
    GET_FINANCES_TOP_BOX_SUCCESS,
    GET_FINANCES_TOP_BOX_FAILURE,
    GET_GRT_DATA,
    GET_GRT_DATA_SUCCESS,
    GET_GRT_DATA_FAILURE,
    GRT_DATA_CONCAT
} from "./type";

// GET
export const getAdminSubscription = () => ({
    type: GET_ADMIN_SUBSCRIPTION,
});

export const getAdminSubscriptionSuccess = (data) => ({
    type: GET_ADMIN_SUBSCRIPTION_SUCCESS,
    data
});

export const getAdminSubscriptionFailure = (data) => ({
    type: GET_ADMIN_SUBSCRIPTION_FAILURE,
    data
});
// POST
export const postAdminSubscription = (data) => ({
    type: POST_ADMIN_SUBSCRIPTION,
    data
});

export const postAdminSubscriptionSuccess = (data) => ({
    type: POST_ADMIN_SUBSCRIPTION_SUCCESS,
    data
});

export const postAdminSubscriptionFailure = (data) => ({
    type: POST_ADMIN_SUBSCRIPTION_FAILURE,
    data
});
// POST EDIT PLAN
export const postEditPlan = (data, setEditCard) => ({
    type: POST_EDIT_PLANS,
    data,
    setEditCard
});

export const postEditPlanSuccess = (data) => ({
    type: POST_EDIT_PLANS_SUCCESS,
    data
});

export const postEditPlanFailure = (data) => ({
    type: POST_EDIT_PLANS_FAILURE,
    data
});
// SUBSCRIBED USER
export const getSubscribedUsers = (data, name) => ({
    type: GET_SUBSCRIBED_USER,
    data, 
    name
});

export const getSubscribedUsersSuccess = (data) => ({
    type: GET_SUBSCRIBED_USER_SUCCESS,
    data
});

export const getSubscribedUsersFailure = (data) => ({
    type: GET_SUBSCRIBED_USER_FAILURE,
    data
});

export const setSubscribedConcat = (data) => ({
    type: SET_SUB_USER_CONCAT,
    data
});
// GET FINANCES TOP BOX
export const getFinanceData = (data) => ({
    type: GET_FINANCES_TOP_BOX,
    data
});

export const getFinanceDataSuccess = (data) => ({
    type: GET_FINANCES_TOP_BOX_SUCCESS,
    data
});

export const getFinanceDataFailure = (data) => ({
    type: GET_FINANCES_TOP_BOX_FAILURE,
    data
});
// GRT
export const getGRTRequest = (data, page) => ({
    type: GET_GRT_DATA,
    data,
    page
});

export const getGRTRequestSuccess = (data) => ({
    type: GET_GRT_DATA_SUCCESS,
    data
});

export const getGRTRequestFailure = (data) => ({
    type: GET_GRT_DATA_FAILURE,
    data
});

export const grtDataConcat = (data) => ({
    type: GRT_DATA_CONCAT,
    data
})