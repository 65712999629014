import {
  CREATE_NEW_NOVEL,
  CREATE_NEW_NOVEL_SUCCESS,
  CREATE_NEW_NOVEL_FAILURE,
  GET_ROLE_TYPE_REQUEST,
  GET_ROLE_TYPE_SUCCESS,
  GET_ROLE_TYPE_FAILURE,
  POST_ROLE_TYPE_REQUEST,
  POST_ROLE_TYPE_SUCCESS,
  POST_ROLE_TYPE_FAILURE,
  DELETE_REQUEST,
  DELETE_SUCCESS,
  DELETE_FAILURE,
  EDIT_CHARACTER_REQUEST,
  EDIT_CHARACTER_SUCCESS,
  EDIT_CHARACTER_FAILURE,
  GET_CHARACTER_REQUEST,
  GET_CHARACTER_REQUEST_SUCCESS,
  GET_CHARACTER_REQUEST_FAILURE,
  GET_EDITOR_TYPE_SUCCESS,
  DELETE_NOVEL_REQUEST,
  DELETE_NOVEL_SUCCESS,
  DELETE_NOVEL_FAILURE
} from "./type"

const initialState = {
  newNovelInfo: false,
  requesting: false,
  error: false,

  GetRoleData: false,
  getEditorData: false,
  postRoleError: false,
  postRoleInfo: false,
  postRoleRequesting: false,
  editData: false,

  characterData: false,
  characterLoad: false,
  characterError: false,
  deleteNovelRequesting:false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_NEW_NOVEL:
      return {
        ...state,
        requesting: true
      }
    case CREATE_NEW_NOVEL_SUCCESS:
      return {
        ...state,
        requesting: false,
        newNovelInfo: action.data
      }
    case CREATE_NEW_NOVEL_FAILURE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }
    case GET_ROLE_TYPE_REQUEST:
      return {
        ...state,
      }
    case GET_ROLE_TYPE_SUCCESS:
      return {
        ...state,
        GetRoleData: action.data
      }
      case GET_EDITOR_TYPE_SUCCESS:
      return {
        ...state,
        getEditorData: action.data
      }
    case GET_ROLE_TYPE_FAILURE:
      return {
        ...state,
        roleError: action.data
      }
    case POST_ROLE_TYPE_REQUEST:
      return {
        ...state,
        postRoleRequesting: true
      }
    case POST_ROLE_TYPE_SUCCESS:
      return {
        ...state,
        postRoleRequesting: false,
        postRoleInfo: action.data,
        postRoleError: false

      }
    case POST_ROLE_TYPE_FAILURE:
      return {
        ...state,
        postRoleRequesting: false,
        postRoleError: action.data
      }
    case DELETE_REQUEST:
      return {
        ...state,
        deleteRequest: true,
        deleteStatus: false
      }
    case DELETE_SUCCESS:
      return {
        ...state,
        deleteRequest: false,
        deleteStatus: true,
        postRoleInfo: action.data
      }
    case DELETE_FAILURE:
      return {
        ...state,
        deleteRequest: false,
        deleteStatus: false,
        error: action.data
      }
    case EDIT_CHARACTER_REQUEST:
      return {
        ...state,
        requesting: true
      }
    case EDIT_CHARACTER_SUCCESS:
      return {
        ...state,
        requesting: false,
        editData: action.data
      }
    case EDIT_CHARACTER_FAILURE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }
      case GET_CHARACTER_REQUEST:
      return {
        ...state,
        characterLoad: true
      }
    case GET_CHARACTER_REQUEST_SUCCESS:
      return {
        ...state,
        characterLoad: false,
        characterData: action.data
      }
    case GET_CHARACTER_REQUEST_FAILURE:
      return {
        ...state,
        characterLoad: false,
        characterError: action.data
      }
      case DELETE_NOVEL_REQUEST:
        return {
          ...state,
          deleteNovelRequesting: true,
         
        }
      case DELETE_NOVEL_SUCCESS:
        return {
          ...state,
          deleteNovelRequesting: false,
          
        }
      case DELETE_NOVEL_FAILURE:
        return {
          ...state,
          deleteNovelRequesting: false,
       
        }
    default:
      return state
  }
}
