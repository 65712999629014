import {
  
  POST_NEW_CHAPTER_REQUEST,
  POST_NEW_CHAPTER_SUCCESS,
  POST_NEW_CHAPTER_FAILURE,
  POST_NEW_CHAPTER_RESET,
} from "./type"

export const postNewChapterRequest = (data) => ({
  type: POST_NEW_CHAPTER_REQUEST,
  data
})

export const postNewChapterSuccess = data => ({
  type: POST_NEW_CHAPTER_SUCCESS,
  data
})

export const postNewChapterFailure = data => ({
  type:POST_NEW_CHAPTER_FAILURE,
  data
})

export const postNewChapterReset = data => ({
  type:POST_NEW_CHAPTER_RESET,
  data
})
