import React from "react"
import Modal from "react-bootstrap/Modal"
import imageIcon from "../../assets/images/image-icon.svg"
import greaterWhiteIcon from "../../assets/images/greater-white.svg"
import lessWhiteIcon from "../../assets/images/less-white-icon.svg"
import HomeModal from "./component/index"
import { connect } from "react-redux"
import { setProducerIndex, setCharacterIndex } from "../../pages/ProducerHome/redux/action"

const ProducerModal = ({ modalShow, setModalShow, homeData, producerIndex, setProducerIndex, setCharacterIndex }) => {

  const handleBackControl = () => {
    if (producerIndex > 0) {
      setProducerIndex(producerIndex - 1)
      setCharacterIndex(0)
    }
  }

  const handleNextControl = () => {
    if (producerIndex < homeData.length - 1) {
      setProducerIndex(producerIndex + 1)
      setCharacterIndex(0)
    }
  }

  return (
    <>
      <Modal
        show={modalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="home-genre-modal producer-home-modal"
      >
        <Modal.Body>
            <div className="controls-group">
              <div className="control left-control" onClick={handleBackControl}>
                <img src={lessWhiteIcon} alt="left" />
              </div>
              <div className="control right-control" onClick={handleNextControl}>
                <img src={greaterWhiteIcon} alt="right" />
              </div>
            </div>
          <div className="modal-body-left producer-modal-body-left">
            <div className={`image-wrapper ${homeData[producerIndex]?.thumbnail ? '' : 'no-img-wrapper'}`}>
              <img src={homeData[producerIndex]?.thumbnail ? homeData[producerIndex]?.thumbnail : imageIcon} alt="" />
            </div>
          </div>
          <HomeModal setModalShow={setModalShow} homeData={homeData} producerIndex={producerIndex} />
        </Modal.Body>
      </Modal>
    </>
  )
}

const mapStateToProps = state => ({
  producerIndex: state.producerHome.producerIndex,
})

const mapDispatchToProps = (dispatch) => ({
  setProducerIndex: data => dispatch(setProducerIndex(data)),
  setCharacterIndex: data => dispatch(setCharacterIndex(data))
})

export default connect(mapStateToProps,mapDispatchToProps)(ProducerModal)
