import {
  CardNumberElement,
  CardExpiryElement,
  CardCvcElement,
  useElements,
  useStripe
} from "@stripe/react-stripe-js"
import React, { useEffect, useState } from "react"
import Button from "../../../../Button"
import profileIcon from "../../../../../assets/images/profile.svg"
import creditIcon from "../../../../../assets/images/credit-card-icon.svg"
import cvvIcon from "../../../../../assets/images/cvv-icon.svg"
import calenderIcon from "../../../../../assets/images/calendar.svg"
import { connect } from "react-redux"
import { postPaymentInformation } from "../redux/action"
import { postCardRequest ,postNewCardRequest } from "../../../../../pages/HomeScreens/SelectAccount/redux/action"
import toast from "react-hot-toast"
import { Radio } from "antd"
import { getCardRequest } from "../../../../../pages/HomeScreens/SelectAccount/redux/action"
import { upgradeTokenStore, upgradeTokenStoreSuccess } from "../../../../../pages/HomeScreens/TokenStore/redux/action"

const CheckoutForm = props => {
  const {
    postPaymentInformation,
    postCardRequest,
    planId,
    requesting,
    addCard,
    setModalShow,
    setJoinClubTab,
    storePlanID,
    getCardRequest,
    accountCardData,
    upgradeTokenStore,
    upgradeRequesting,
    loginInfo,
    availgrt,
    upgradeData,
    upgradeTokenStoreSuccess,
    cardNoShow,
    postNewCardRequest
  } = props
  const [userName, setUserName] = useState("")
  const [disabledBtn, setDisabledBtn] = useState(true)
  const [radioValue, setRadioValue] = useState("")

  const stripe = useStripe()
  const elements = useElements()

  useEffect(() => {
    getCardRequest()
  }, [])

  useEffect(() => {
    if(upgradeData?.msg?.id){
      setModalShow(false)
      setJoinClubTab("PersonalInfo")
      upgradeTokenStoreSuccess(false)
    }
  },[upgradeData?.msg?.id])

  const cardNumberPlaceholder = {
    placeholder: "Enter Card Number",
    style: {
      base: {
        "::placeholder": {
          color: "#a2a2a2",
          fontWeight: 500
        }
      }
    }
  }
  const expiryDatePlaceholder = {
    placeholder: "Enter Expiration Date",
    style: {
      base: {
        "::placeholder": {
          color: "#a2a2a2",
          fontWeight: 500
        }
      }
    }
  }
  const cvvPlaceholder = {
    placeholder: "Enter CVV Number",
    style: {
      base: {
        "::placeholder": {
          color: "#a2a2a2",
          fontWeight: 500
        }
      }
    }
  }

  const handleSubmit = async event => {
    if (storePlanID && radioValue) {
      const data = {
        price_id: storePlanID,
        payment_method_id: radioValue
      }
      await upgradeTokenStore(data)
      setDisabledBtn(true)
    } else {
      if (addCard == "PaymentInfo" && !storePlanID) {
        const card = elements.getElement(CardNumberElement)
        const { paymentMethod, error } = await stripe.createPaymentMethod({
          type: "card",
          card: card,
          billing_details: {
            name: userName
          }
        })
        if (error) {
          toast.error(error.message, {
            id: "card fields"
          })
        }
        const data = {
          payment_method_id: paymentMethod.id
        }
        if (accountCardData?.data.length ==0) {
          
          postNewCardRequest(data, setModalShow)
        } else {
          postCardRequest(data, setModalShow)
          
        }
        setDisabledBtn(true)
      } else if (storePlanID) {
        if (!loginInfo?.user?.subscription && storePlanID) {
          const card = elements.getElement(CardNumberElement)
          const { paymentMethod, error } = await stripe.createPaymentMethod({
            type: "card",
            card: card,
            billing_details: {
              name: userName
            }
          })
          if (error) {
            toast.error(error.message, {
              id: "card fields"
            })
          }
          const data = {
            price_id: storePlanID,
            payment_method_id: paymentMethod.id
          }
          await postPaymentInformation(data)
          setDisabledBtn(true)
        } else if (accountCardData?.data.length < 3) {
          const card = elements.getElement(CardNumberElement)
          const { paymentMethod, error } = await stripe.createPaymentMethod({
            type: "card",
            card: card,
            billing_details: {
              name: userName
            }
          })
          if (error) {
            toast.error(error.message, {
              id: "card fields"
            })
          }
          const data = {
            price_id: storePlanID,
            payment_method_id: paymentMethod.id,
            new_user: true
          }
          await upgradeTokenStore(data)
          setDisabledBtn(true)
        } else {
          toast.error(
            "Max 3 cards are allowed. Please remove one existing card.",
            {
              id: "card fields"
            }
          )
        }
      } else {
        if (availgrt == 0 && planId && loginInfo?.user?.subscription) {
          if (planId && radioValue) {
            const data = {
              price_id: planId,
              payment_method_id: radioValue
            }
            await upgradeTokenStore(data)
            setDisabledBtn(true)
          } else {
            const card = elements.getElement(CardNumberElement)
            const { paymentMethod, error } = await stripe.createPaymentMethod({
              type: "card",
              card: card,
              billing_details: {
                name: userName
              }
            })
            if (error) {
              toast.error(error.message, {
                id: "card fields"
              })
            }
            const data = {
              price_id: planId,
              payment_method_id: paymentMethod.id,
              new_user: true
            }
            await upgradeTokenStore(data)
            setDisabledBtn(true)
          }
        } else {
          if (planId && radioValue) {
            const data = {
              price_id: planId,
              payment_method_id: radioValue
            }
            await postPaymentInformation(data, setModalShow)
            setDisabledBtn(true)
          } else {
            const card = elements.getElement(CardNumberElement)
            const { paymentMethod, error } = await stripe.createPaymentMethod({
              type: "card",
              card: card,
              billing_details: {
                name: userName
              }
            })
            if (error) {
              toast.error(error.message, {
                id: "card fields"
              })
            }
            const data = {
              price_id: planId,
              payment_method_id: paymentMethod.id,
            }
            await postPaymentInformation(data, setModalShow)
            setDisabledBtn(true)
          }
        }
      }
    }
  }

  const onRadioChange = ({ target: { value } }) => {
    elements.getElement(CardNumberElement).clear()
    elements.getElement(CardExpiryElement).clear()
    elements.getElement(CardCvcElement).clear()
    setUserName("")
    setRadioValue(value)
    setDisabledBtn(false)
  }

  return (
    <>
      <form className="stripe-form">
        <div className="form-group">
          <span className="icon left-icon">
            <img src={creditIcon} className="left-icon" alt="icon" />
          </span>
          <CardNumberElement
            options={cardNumberPlaceholder}
            className="form-control"
            id="card-element"
            onChange={() => {
              setDisabledBtn(false)
            }}
            onFocus={() => setRadioValue("")}
          />
        </div>
        <div className="form-group">
          <span className="icon left-icon">
            <img src={profileIcon} className="left-icon" alt="icon" />
          </span>
          <input
            className="form-control"
            id="cardHolderName"
            name="name"
            type="text"
            placeholder="Enter Cardholder Name"
            required
            value={userName}
            onChange={event => {
              setUserName(event.target.value)
              setDisabledBtn(false)
            }}
          />
        </div>
        <div className="input-row">
          <div className="stripe-input-wrapper">
            <span className="icon left-icon">
              <img src={calenderIcon} className="left-icon" alt="icon" />
            </span>
            <CardExpiryElement
              options={expiryDatePlaceholder}
              className="form-control"
              onChange={() => setDisabledBtn(false)}
            />
          </div>
          <div className="stripe-input-wrapper">
            <span className="icon left-icon">
              <img src={cvvIcon} className="left-icon cvv-icon" alt="icon" />
            </span>
            <CardCvcElement
              options={cvvPlaceholder}
              className="form-control"
              onChange={() => setDisabledBtn(false)}
            />
          </div>
        </div>
        {accountCardData?.data && !cardNoShow && (
          <div className="radio-button-row">
            <div className="radio-wrapper">
              <Radio.Group
                name="radiogroup"
                value={radioValue}
                onChange={onRadioChange}
              >
                {accountCardData?.data.map(item => (
                  <Radio value={item?.id}>{`${
                    item?.billing_details?.name
                      ? item?.billing_details?.name
                      : ""
                  } Card ending in... ${item?.card?.last4}`}</Radio>
                ))}
              </Radio.Group>
            </div>
          </div>
        )}
      </form>
      <div className="button-wrapper">
        <Button
          title={addCard ? "Confirm" : "Pay"}
          showSpinner={requesting || upgradeRequesting}
          onClick={handleSubmit}
          className={`${disabledBtn ? "disabled-btn" : ""}`}
        />
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  paymentResponse: state.paymentInfo.paymentResponse,
  requesting: state.paymentInfo.requesting,
  errorMsg: state.paymentInfo.error,
  planId: state.priceCard.planId,
  postRequest: state.selectAccount.postRequest,
  accountCardData: state.selectAccount.data.cards,
  upgradeRequesting: state.tokenStore.upgradeRequesting,
  loginInfo: state.login.loginInfo,
  upgradeData: state.tokenStore.upgradeData,
})

const mapDispatchToProps = dispatch => ({
  postPaymentInformation: (data, setModalShow) =>
    dispatch(postPaymentInformation(data, setModalShow)),
  postCardRequest: (data, setModalShow) =>
    dispatch(postCardRequest(data, setModalShow)),
  getCardRequest: data => dispatch(getCardRequest(data)),
  upgradeTokenStore: data => dispatch(upgradeTokenStore(data)),
  upgradeTokenStoreSuccess: data => dispatch(upgradeTokenStoreSuccess(data)),
  postNewCardRequest:(data, setModalShow) =>
  dispatch(postNewCardRequest(data, setModalShow)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CheckoutForm)
