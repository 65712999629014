import React, { useEffect, useState } from "react"
import loginSideImage from "../../../assets/images/login-side-img.png"
import Input from "../../../components/Input"
import emailIcon from "../../../assets/images/mail-icon.svg"
import lockIcon from "../../../assets/images/lock-icon.svg"
import Button from "../../../components/Button"
import "./style.scss"
import useForm from "../../../utils/useForm"
import { connect } from "react-redux"
import { loginRequest } from "./redux/action"
import { history } from "../../../reduxStore/store"
import { Link } from "react-router-dom"
import { loginRequestFailure } from "./redux/action"
import JoinClubModal from "../../../components/JoinClub"
import { postPaymentInformationSuccess } from "../../../components/JoinClub/components/Payment/redux/action"
import { Images } from "../../../theme/Images"

const Login = props => {
  const { loginRequestFailure, loginRequest, requesting, error } = props
  const [modalShow, setModalShow] = useState(false)

  useEffect(() => {
    loginRequestFailure(false)
  }, [])

  const stateSchema = {
    email: {
      value: "",
      error: ""
    },
    password: {
      value: "",
      error: ""
    }
  }

  const validationStateSchema = {
    email: {
      required: true
    },
    password: {
      required: true
    }
  }
  const { state, handleOnChange, disable } = useForm(
    stateSchema,
    validationStateSchema
  )

  const onSubmit = () => {
    const data = {
      username: state.email.value,
      password: state.password.value
    }
    loginRequest(data)
  }

  return (
    <section className="landing-page-section auth-page-section">
      <div className="container-fluid landing-content-container">
        <div className="row auth-content-row">

          <div className="col-lg-6">
            <div className="align-items-center d-flex justify-content-center mb-5 ">

              <img src={Images.Logo} className="img-fluid" />
            </div>
            <div className="landing-left-content auth-left-content">
              <div className="content-details">
                {/* <h1>Story Host</h1>
                <h2>Home of the Multi-Perspective Novel</h2> */}
              </div>
              <div className="input-wrapper">
                <Input
                  type="email"
                  showIcon={true}
                  src={emailIcon}
                  placeholder="Enter Email Address"
                  value={state.email.value}
                  onFocus={() => loginRequestFailure(false)}
                  onChange={val => handleOnChange("email", val.target.value)}
                  showError={true}
                  errorMessage={error.username ? error.username[0] : ""}
                />
                <Input
                  type="password"
                  showHideIcon={true}
                  showIcon={true}
                  src={lockIcon}
                  placeholder="Enter Password"
                  value={state.password.value}
                  onChange={val => handleOnChange("password", val.target.value)}
                  onFocus={() => loginRequestFailure(false)}
                  showError={true}
                  errorMessage={error.password && error.password[0]}
                />
              </div>
              <div className="priv-terms-links">
                <Link to="/auth/terms_conditions">Terms & Conditions</Link>
                &nbsp;&nbsp;
                <Link to="/auth/privacy">Privacy Policy</Link>
              </div>
              <div className="button-wrapper">
                <Button
                  title="Sign In"
                  onClick={onSubmit}
                  showSpinner={requesting}
                />
                <Button
                  title="Forgot Password?"
                  className="transparent-btn"
                  onClick={() => history.push("/auth/forgot_password")}
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="auth-right-content">
              <img src={loginSideImage} className="img-fluid" alt="Image" />
            </div>
          </div>
        </div>
      </div>
      <JoinClubModal
        modalShow={modalShow}
        setModalShow={setModalShow}
        checkError={error.msg}
      />
    </section>
  )
}

const mapStateToProps = state => ({
  loginInfo: state.login,
  requesting: state.login.requesting,
  error: state.login.error,
  paymentResponse: state.paymentInfo.paymentResponse
})

const mapDispatchToProps = dispatch => ({
  loginRequest: data => dispatch(loginRequest(data)),
  loginRequestFailure: data => dispatch(loginRequestFailure(data)),
  postPaymentInformationSuccess: data =>
    dispatch(postPaymentInformationSuccess(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)
