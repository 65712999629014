// GET AUTHOR PROFILE
export const GET_AUTHOR_PROFILE = "GET_AUTHOR_PROFILE"
export const GET_AUTHOR_PROFILE_SUCCESS = "GET_AUTHOR_PROFILE_SUCCESS"
export const GET_AUTHOR_PROFILE_FAILURE = "GET_AUTHOR_PROFILE_FAILURE"
// GET NOVEL DETAILS
export const GET_NOVEL_DATA = "GET_NOVEL_DATA"
export const GET_NOVEL_DATA_SUCCESS = "GET_NOVEL_DATA_SUCCESS"
export const GET_NOVEL_DATA_FAILURE = "GET_NOVEL_DATA_FAILURE"
// GET BOOK DATA
export const GET_BOOK_DATA = "GET_BOOK_DATA"
export const GET_BOOK_DATA_SUCCESS = "GET_BOOK_DATA_SUCCESS"
export const GET_BOOK_DATA_FAILURE = "GET_BOOK_DATA_FAILURE"
// GET NOVEL CHAPTER
export const GET_CHAPTER_DATA = "GET_CHAPTER_DATA"
export const GET_CHAPTER_DATA_SUCCESS = "GET_CHAPTER_DATA_SUCCESS"
export const GET_CHAPTER_DATA_FAILURE = "GET_CHAPTER_DATA_FAILURE"
// concat pagination
export const UPLOADED_DATA_CONCAT = "UPLOADED_DATA_CONCAT"
// READER UPLOADED
export const GET_READER_UPLOADED = "GET_READER_UPLOADED"
export const GET_READER_UPLOADED_SUCCESS = "GET_READER_UPLOADED_SUCCESS"
export const GET_READER_UPLOADED_FAILURE = "GET_READER_UPLOADED_FAILURE"
// READER FAVORITES
export const GET_READER_FAVORITES = "GET_READER_FAVORITES"
export const GET_READER_FAVORITES_SUCCESS = "GET_READER_FAVORITES_SUCCESS"
export const GET_READER_FAVORITES_FAILURE = "GET_READER_FAVORITES_FAILURE"
// Top 10 Stories
export const GET_READER_TOP_STORIES = "GET_READER_TOP_STORIES"
export const GET_READER_TOP_STORIES_SUCCESS = "GET_READER_TOP_STORIES_SUCCESS"
export const GET_READER_TOP_STORIES_FAILURE = "GET_READER_TOP_STORIES_FAILURE"
// Top 10 Writers
export const GET_READER_TOP_WRITERS = "GET_READER_TOP_WRITERS"
export const GET_READER_TOP_WRITERS_SUCCESS = "GET_READER_TOP_WRITERS_SUCCESS"
export const GET_READER_TOP_WRITERS_FAILURE = "GET_READER_TOP_WRITERS_FAILURE"
// Top 10 Characters
export const GET_READER_TOP_CHARACTERS = "GET_READER_TOP_CHARACTERS"
export const GET_READER_TOP_CHARACTERS_SUCCESS =
  "GET_READER_TOP_CHARACTERS_SUCCESS"
export const GET_READER_TOP_CHARACTERS_FAILURE =
  "GET_READER_TOP_CHARACTERS_FAILURE"
// Top 10 Intro Chapters
export const GET_READER_TOP_CHAPTERS = "GET_READER_TOP_CHAPTERS"
export const GET_READER_TOP_CHAPTERS_SUCCESS = "GET_READER_TOP_CHAPTERS_SUCCESS"
export const GET_READER_TOP_CHAPTERS_FAILURE = "GET_READER_TOP_CHAPTERS_FAILURE"
