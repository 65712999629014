export const GET_ADMIN_USER = 'GET_ADMIN_USER';
export const GET_ADMIN_USER_SUCCESS = 'GET_ADMIN_USER_SUCCESS';
export const GET_ADMIN_USER_FAILURE = 'GET_ADMIN_USER_FAILURE';
// Delete
export const ADMIN_USER_DELETE = 'ADMIN_USER_DELETE';
export const ADMIN_USER_DELETE_SUCCESS = 'ADMIN_USER_DELETE_SUCCESS';
export const ADMIN_USER_DELETE_FAILURE = 'ADMIN_USER_DELETE_FAILURE';
// FLAGS
export const FLAGS_UPDATE = 'FLAGS_UPDATE';
export const FLAGS_UPDATE_SUCCESS = 'FLAGS_UPDATE_SUCCESS';
export const FLAGS_UPDATE_FAILURE = 'FLAGS_UPDATE_FAILURE';
// ADD USER
export const ADD_ADMIN_USER = 'ADD_ADMIN_USER';
export const ADD_ADMIN_USER_SUCCESS = 'ADD_ADMIN_USER_SUCCESS';
export const ADD_ADMIN_USER_FAILURE = 'ADD_ADMIN_USER_FAILURE';
// Edit User
export const UPDATE_ADMIN_USER = 'UPDATE_ADMIN_USER';
export const UPDATE_ADMIN_USER_SUCCESS = 'UPDATE_ADMIN_USER_SUCCESS';
export const UPDATE_ADMIN_USER_FAILURE = 'UPDATE_ADMIN_USER_FAILURE';
// current page
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
// 
export const SET_CONCAT_USER_DATA = 'SET_CONCAT_USER_DATA';
