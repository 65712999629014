import React, { useRef } from 'react';
import uploadIcon from '../../assets/images/upload.svg';
import './style.scss';

const UploadFile = (props) => {

    const fileRef = useRef()

    const handleUploadClick = () => {
        fileRef.current?.click()
        return false
    }

    return (
        <div className="upload-file-wrapper">
            <div className="upload-file">
                <div className="icon-wrapper" onClick={handleUploadClick}>
                    <img
                        src={uploadIcon}
                        alt="upload"
                    />
                    <div
                        className="sample"
                    >
                        {props.title}
                    </div>
                    <input
                        type="file"
                        name="file"
                        ref={fileRef}
                        onChange={props.onChange}
                        hidden
                        value=''
                        onFocus={props.onFocus}
                        disabled={props.disabled}
                    />
                    <div>
                        <p style={{ margin: "0px", marginLeft: "5px" }}>
                            {props.selectedFile}
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UploadFile