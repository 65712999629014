// CREATE NEW CHAPTER
export const CREATE_NEW_CHAPTER = 'CREATE_NEW_CHAPTER';
export const CREATE_NEW_CHAPTER_SUCCESS = 'CREATE_NEW_CHAPTER_SUCCESS';
export const CREATE_NEW_CHAPTER_FAILURE = 'CREATE_NEW_CHAPTER_FAILURE';
// CREATE NEW SUBMISSION
export const CREATE_NEW_SUBMISSION = 'CREATE_NEW_SUBMISSION';
export const CREATE_NEW_SUBMISSION_SUCCESS = 'CREATE_NEW_SUBMISSION_SUCCESS';
export const CREATE_NEW_SUBMISSION_FAILURE = 'CREATE_NEW_SUBMISSION_FAILURE';
// POST NEW SUBMISSION
export const POST_CREATE_NEW_SUBMISSION = 'POST_CREATE_NEW_SUBMISSION';
export const POST_CREATE_NEW_SUBMISSION_SUCCESS = 'POST_CREATE_NEW_SUBMISSION_SUCCESS';
export const POST_CREATE_NEW_SUBMISSION_FAILURE = 'POST_CREATE_NEW_SUBMISSION_FAILURE';
// UPLOAD FILE
export const SUBMISSION_UPLOAD_FILE = 'SUBMISSION_UPLOAD_FILE';
export const SUBMISSION_UPLOAD_FILE_SUCCESS = 'SUBMISSION_UPLOAD_FILE_SUCCESS';
export const SUBMISSION_UPLOAD_FILE_FAILURE = 'SUBMISSION_UPLOAD_FILE_FAILURE';
// PATCH NOVEL CHARACTER
export const PATCH_NOVEL_CHAPTER = 'PATCH_NOVEL_CHAPTER';
export const PATCH_NOVEL_CHAPTER_SUCCESS = 'PATCH_NOVEL_CHAPTER_SUCCESS';
export const PATCH_NOVEL_CHAPTER_FAILURE = 'PATCH_NOVEL_CHAPTER_FAILURE';
// DELETE MP3 FILE
export const DELETE_MP3_FILE = 'DELETE_MP3_FILE';
export const DELETE_MP3_FILE_SUCCESS = 'DELETE_MP3_FILE_SUCCESS';
export const DELETE_MP3_FILE_FAILURE = 'DELETE_MP3_FILE_FAILURE';