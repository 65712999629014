import React from "react"
import Modal from "react-bootstrap/Modal"
import imageIcon from "../../assets/images/image-icon.svg"
import greaterWhiteIcon from "../../assets/images/greater-white.svg"
import lessWhiteIcon from "../../assets/images/less-white-icon.svg"
import "./style.scss"
import SavedforReviewDetails from "./Save&Review"
import { connect } from "react-redux"
import { setOpenAdvertIndex } from "../../pages/WriterHome/redux/action"


const SavedforReviewModal = ({
  modalShow,
  setModalShow,
  controls,
  setOpenAdvertIndex,
  saveReviewData,
  openAdvertIndex,
}) => {

  const handleBackControl = () => {
    if (openAdvertIndex > 0) {
      setOpenAdvertIndex(openAdvertIndex - 1)
    }
  }

  const handleNextControl = () => {
    if (openAdvertIndex < saveReviewData?.length - 1) {
      setOpenAdvertIndex(openAdvertIndex + 1)
    }
  }

  return (
    <>
      <Modal
        show={modalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="home-genre-modal"
      >
        <Modal.Body>
          {controls && (
            <div className="controls-group">
              <div className="control left-control" onClick={handleBackControl}>
                <img src={lessWhiteIcon} alt="left" />
              </div>
              <div
                className="control right-control"
                onClick={handleNextControl}
              >
                <img src={greaterWhiteIcon} alt="right" />
              </div>
            </div>
          )}
          <div className="modal-body-left">
            <div
              className={`image-wrapper ${
                saveReviewData && saveReviewData[openAdvertIndex]?.character?.novel?.thumbnail
                  ? ""
                  : "no-img-wrapper"
              }`}
            >
              <img
                src={
                  (saveReviewData &&
                    saveReviewData[openAdvertIndex]?.character?.novel
                      ?.thumbnail) ||
                  imageIcon
                }
                alt=""
              />
            </div>
          </div>
            <SavedforReviewDetails
              setModalShow={setModalShow}
              saveReviewData={saveReviewData}
              openAdvertIndex={openAdvertIndex}
            />
        </Modal.Body>
      </Modal>
    </>
  )
}

const mapStateToProps = state => ({
  openAdvertIndex: state.writerHome.openAdvertIndex
})

const mapDispatchToProps = dispatch => ({
  setOpenAdvertIndex: data => dispatch(setOpenAdvertIndex(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(SavedforReviewModal)
