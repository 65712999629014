import {
  TERM_AND_CONDITION_DATA,
  TERM_AND_CONDITION_DATA_SUCCESS,
  TERM_AND_CONDITION_DATA_FAILURE
} from "./type"

// POST_PAYMENT_INFORMATION
export const getTermsConditionsRequest = data => ({
  type: TERM_AND_CONDITION_DATA,
  data
})

export const getTermsConditionsSuccess = data => ({
  type: TERM_AND_CONDITION_DATA_SUCCESS,
  data
})

export const getTermsConditionsFailure = data => ({
  type: TERM_AND_CONDITION_DATA_FAILURE,
  data
})
