import {
GET_EDIT_ADVERTS,
GET_EDIT_ADVERTS_SUCCESS,
GET_EDIT_ADVERTS_FAILURE,
GET_EDIT_CURRENT,
GET_EDIT_CURRENT_SUCCESS,
GET_EDIT_CURRENT_FAILURE,
GET_EDIT_SAVED,
GET_EDIT_SAVED_SUCCESS,
GET_EDIT_SAVED_FAILURE,
GET_EDIT_WORKROOMS,
GET_EDIT_WORKROOMS_SUCCESS,
GET_EDIT_WORKROOMS_FAILURE,
} from "./type"

// GET EDIT ADVERTS
export const getEditAdverts = (data) => ({
  type: GET_EDIT_ADVERTS,
  data
})
export const getEditAdvertsSuccess = data => ({
  type: GET_EDIT_ADVERTS_SUCCESS,
  data
})
export const getEditAdvertsFailure = data => ({
  type: GET_EDIT_ADVERTS_FAILURE,
  data
})
// GET EDIT CURRENT
export const getEditCurrent = (data) => ({
  type: GET_EDIT_CURRENT,
  data
})
export const getEditCurrentSuccess = data => ({
  type: GET_EDIT_CURRENT_SUCCESS,
  data
})
export const getEditCurrentFailure = data => ({
  type: GET_EDIT_CURRENT_FAILURE,
  data
})
// GET EDIT SAVE
export const getEditSave = (data) => ({
  type: GET_EDIT_SAVED,
  data
})
export const getEditSaveSuccess = data => ({
  type: GET_EDIT_SAVED_SUCCESS,
  data
})
export const getEditSaveFailure = data => ({
  type: GET_EDIT_SAVED_FAILURE,
  data
})
// GET EDIT WORKROOM
export const getEditWorkroom = (data) => ({
  type: GET_EDIT_WORKROOMS,
  data
})
export const getEditWorkroomSuccess = data => ({
  type: GET_EDIT_WORKROOMS_SUCCESS,
  data
})
export const getEditWorkroomFailure = data => ({
  type: GET_EDIT_WORKROOMS_FAILURE,
  data
})