import {
  GET_TOKEN_STORE,
  GET_TOKEN_STORE_FAILURE,
  GET_TOKEN_STORE_SUCCESS,
  CANCEL_TOKEN_STORE,
  CANCEL_TOKEN_STORE_SUCCESS,
  CANCEL_TOKEN_STORE_FAILURE,
  UPGRADE_TOKEN_STORE,
  UPGRADE_TOKEN_STORE_SUCCESS,
  UPGRADE_TOKEN_STORE_FAILURE,
} from "./type"

const initialState = {
  data: false,
  requesting: false,
  error: false,
  cancelData: false,
  cancelRequesting: false,
  cancelError: false,
  upgradeData: false,
  upgradeRequesting: false,
  upgradeError: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_TOKEN_STORE:
      return {
        ...state,
        requesting: true
      }
    case GET_TOKEN_STORE_SUCCESS:
      return {
        ...state,
        requesting: false,
        data: action.data
      }
    case GET_TOKEN_STORE_FAILURE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }
      case CANCEL_TOKEN_STORE:
        return {
          ...state,
          cancelRequesting: true
        }
      case CANCEL_TOKEN_STORE_SUCCESS:
        return {
          ...state,
          cancelRequesting: false,
          cancelData: action.data
        }
      case CANCEL_TOKEN_STORE_FAILURE:
        return {
          ...state,
          cancelRequesting: false,
          cancelError: action.data
        }
        case UPGRADE_TOKEN_STORE:
          return {
            ...state,
            upgradeRequesting: true
          }
        case UPGRADE_TOKEN_STORE_SUCCESS:
          return {
            ...state,
            upgradeRequesting: false,
            upgradeData: action.data
          }
        case UPGRADE_TOKEN_STORE_FAILURE:
          return {
            ...state,
            upgradeRequesting: false,
            upgradeError: action.data
          }
    default:
      return state
  }
}
