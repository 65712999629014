import {
    GET_ADMIN_FEEDBACK,
    GET_ADMIN_FEEDBACK_SUCCESS,
    GET_ADMIN_FEEDBACK_FAILURE,
    GET_FEEDBACK_BY_ID,
    GET_FEEDBACK_BY_ID_SUCCESS,
    GET_FEEDBACK_BY_ID_FAILURE,
    PUT_ADMIN_FEEDBACK,
    PUT_ADMIN_FEEDBACK_SUCCESS,
    PUT_ADMIN_FEEDBACK_FAILURE
} from "./type"

// GET
export const getAdminFeedback = (data) => ({
    type: GET_ADMIN_FEEDBACK,
    data,
})

export const getAdminFeedbackSuccess = data => ({
    type: GET_ADMIN_FEEDBACK_SUCCESS,
    data
})

export const getAdminFeedbackFailure = data => ({
    type: GET_ADMIN_FEEDBACK_FAILURE,
    data
})
// GET BY ID
export const getFeedbackById = (id) => ({
    type: GET_FEEDBACK_BY_ID,
    id
})

export const getFeedbackByIdSuccess = data => ({
    type: GET_FEEDBACK_BY_ID_SUCCESS,
    data
})

export const getFeedbackByIdFailure = data => ({
    type: GET_FEEDBACK_BY_ID_FAILURE,
    data
})
// PUT
export const putAdminFeedback = (id, data, setShowReplyFeedback, setReplyFeedback) => ({
    type: PUT_ADMIN_FEEDBACK,
    id,
    data,
    setShowReplyFeedback,
    setReplyFeedback
})

export const putAdminFeedbackSuccess = data => ({
    type: PUT_ADMIN_FEEDBACK_SUCCESS,
    data
})

export const putAdminFeedbackFailure = data => ({
    type: PUT_ADMIN_FEEDBACK_FAILURE,
    data
})
