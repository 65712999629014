import React, { useState } from "react"
import goldenLike from "../../../assets/images/like-golden.svg"
import goldenToken from "../../../assets/images/token-golden.svg"
import goldenBook from "../../../assets/images/golden-book.svg"
import closeIcon from "../../../assets/images/close-icon-modal.svg"
import greaterWhiteIcon from "../../../assets/images/greater-white.svg"
import lessWhiteIcon from "../../../assets/images/less-white-icon.svg"
import { history } from "../../../reduxStore/store"
import {
  saveWorkroomID,
  saveMemberID,
  setUpdateNovelID
} from "../../../pages/AuthScreens/Login/redux/action"
import "./style.scss"
import Button from "../../../components/Button"
import { connect } from "react-redux"
import { setCharacterIndex } from "../../../pages/ProducerHome/redux/action"
import { deleteNovelRequest } from "../../../pages/CreateNovel/redux/action"
import { toast } from "react-hot-toast"

const HomeModal = ({
  saveWorkroomID,
  setCharacterIndex,
  characterIndex,
  homeData,
  setModalShow,
  loginInfo,
  producerIndex,
  setUpdateNovelID,
  deleteNovelRequest,
  deleteNovelRequesting
}) => {
  const setWorkRoomID = () => {
    const characterID = homeData[producerIndex]?.character[characterIndex]?.id
    if (characterID) {
      saveWorkroomID(characterID)
      history.push({ pathname: `/producer/workroom/${characterID}` })
    }
  }

  const onSaveData = () => {
    if (homeData[producerIndex]?.author?.name) {
      saveMemberID(homeData[producerIndex]?.author?.name)
      history.push({
        pathname: `/${loginInfo.user.mode}/member_profile/${homeData[
          producerIndex
        ]?.author?.name
          .toLocaleLowerCase()
          .replace(/\s+/g, "_")}`
      })
    }
  }

  const updateNovel = id => {
    if (id) {
      setUpdateNovelID(id)
      history.push(`/producer/create_novel/${id}`)
    }
  }

  const handleBack = () => {
    if (characterIndex > 0) {
      setCharacterIndex(characterIndex - 1)
    }
  }

  const handleNext = () => {
    if (characterIndex < homeData[producerIndex]?.character.length - 1) {
      setCharacterIndex(characterIndex + 1)
    }
  }
const [delNovelRequesting,setdelNovelRequesting]=useState(deleteNovelRequesting)
  const deleteNovel = async (id) => {
    if (!deleteNovelRequesting) {
      try {
        // Start the loading state by setting deleteNovelRequesting to true
        setdelNovelRequesting(true);
  
        await new Promise((resolve) => setTimeout(resolve, 500)); // Add a delay for the loader
  
        toast.success("Novel removed successfully", {
          id: "ds",
        });
  
        await new Promise((resolve) => setTimeout(resolve, 2000));
  
        setModalShow(false);
      } catch (error) {
        console.log("Error deleting novel:", error);
      } finally {
        // Reset the loading state by setting deleteNovelRequesting to false
        setdelNovelRequesting(false);
      }
    }
  
    deleteNovelRequest(id);
  };
  return (
    <div className="modal-body-right producer-modal-body-right">
      <div className="modal-right-header">
        <h3>{homeData && homeData[producerIndex]?.genre}</h3>
        {/* <img src={favoriteIcon} alt="" /> */}
        <div className="novel">{homeData && homeData[producerIndex]?.name}</div>
        <div className="author-details">
          <p className="author" >
            {homeData && homeData[producerIndex]?.author?.name}
          </p>
          <p className="writer">
            {homeData && homeData[producerIndex]?.character
              ? homeData[producerIndex]?.character[0]?.writer?.name
              : ""}
          </p>
        </div>
        <div className="close-icon">
          <img src={closeIcon} alt="" onClick={() => setModalShow(false)} />
        </div>
      </div>
      <div className="modal-right-body producer-modal-right">
        <div className="body-text-wrapper">
          <div className="text-wrapper">
            <p>{homeData && homeData[producerIndex]?.synopsis}</p>
          </div>
        </div>
        <div className="read-details-wrapper">
          <div className="icon-wrapper">
            <img src={goldenLike} alt="" />
            <span>
              Likes ({(homeData && homeData[producerIndex]?.total_likes) || "0"}
              )
            </span>
          </div>
          <div className="icon-wrapper">
            <img src={goldenToken} alt="" />
            <span>
              Good Read Tokens (
              {homeData[producerIndex]?.grt
                ? homeData[producerIndex]?.grt.toFixed(0)
                : "0"}
              )
            </span>
          </div>
          <div className="icon-wrapper">
            <img src={goldenBook} alt="" />
            <span>
              Total Reads (
              {(homeData && homeData[producerIndex]?.total_readers) || "0"})
            </span>
          </div>
        </div>
        <div className="control-button-wrapper">
          <h3 title={homeData[producerIndex]?.character[characterIndex]?.name}>
            {(homeData[producerIndex]?.character &&
              homeData[producerIndex]?.character[characterIndex]?.name) ||
              ""}
          </h3>
          <Button title="Enter Workroom" onClick={setWorkRoomID} />
          {homeData[producerIndex]?.character.length > 1 && (
            <>
              <Button
                showIcon={true}
                src={lessWhiteIcon}
                onClick={handleBack}
                className="control-btn"
              />
              <Button
                showIcon={true}
                src={greaterWhiteIcon}
                className="control-btn"
                onClick={handleNext}
              />
            </>
          )}
        </div>
      </div>
      {/* <div
        className="modal-right-footer"
      onClick={() => updateNovel(homeData[producerIndex]?.id)}
      >
        <div className="text">Update Novel</div>
        <div className="image-wrapper">
          <img src={greaterWhiteIcon} alt="" />
        </div>
      </div> */}
      <div className="modal-footer">
        <div>
          <Button
            title="Update Novel"
            onClick={() => updateNovel(homeData[producerIndex]?.id)}
          />
        </div>
        {console.log("deleteNovelRequesting", deleteNovelRequesting)}
        <div>
          <Button
            title="Delete Novel"
            className="red-btn"
            onClick={() => {
              deleteNovel(homeData[producerIndex]?.id)
            }}
            showSpinner={delNovelRequesting}
          />
        </div>

        {/* <div className="text">Update Novel</div>
        <div className="image-wrapper">
      
        </div> */}
      </div>
    </div>
  )
}
const mapStateToProps = state => ({
  loginInfo: state.login.loginInfo,
  characterIndex: state.producerHome.characterIndex,

  deleteNovelRequesting: state.createNovel.deleteNovelRequesting
})

const mapDispatchToProps = dispatch => ({
  saveWorkroomID: data => dispatch(saveWorkroomID(data)),
  setCharacterIndex: data => dispatch(setCharacterIndex(data)),
  setUpdateNovelID: data => dispatch(setUpdateNovelID(data)),
  deleteNovelRequest: data => dispatch(deleteNovelRequest(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(HomeModal)
