import {
  GET_TOKEN_STORE,
  GET_TOKEN_STORE_FAILURE,
  GET_TOKEN_STORE_SUCCESS,
  CANCEL_TOKEN_STORE,
  CANCEL_TOKEN_STORE_SUCCESS,
  CANCEL_TOKEN_STORE_FAILURE,
  UPGRADE_TOKEN_STORE,
  UPGRADE_TOKEN_STORE_SUCCESS,
  UPGRADE_TOKEN_STORE_FAILURE,
} from "./type"
// GET
export const getTokenStore = () => ({
  type: GET_TOKEN_STORE
})

export const getTokenStoreSuccess = data => ({
  type: GET_TOKEN_STORE_SUCCESS,
  data
})

export const getTokenStoreFailure = data => ({
  type: GET_TOKEN_STORE_FAILURE,
  data
})
// CANCEL
export const cancelTokenStore = (data) => ({
  type: CANCEL_TOKEN_STORE,
  data
})

export const cancelTokenStoreSuccess = data => ({
  type: CANCEL_TOKEN_STORE_SUCCESS,
  data
})

export const cancelTokenStoreFailure = data => ({
  type: CANCEL_TOKEN_STORE_FAILURE,
  data
})
// UPGRADE
export const upgradeTokenStore = (data) => ({
  type: UPGRADE_TOKEN_STORE,
  data
})

export const upgradeTokenStoreSuccess = data => ({
  type: UPGRADE_TOKEN_STORE_SUCCESS,
  data
})

export const upgradeTokenStoreFailure = data => ({
  type: UPGRADE_TOKEN_STORE_FAILURE,
  data
})