import React, { useEffect, useState } from "react"
import dummyPic from "../../../assets/images/dummyPic.png"
import FeatureNovelCard from "../../../components/FeatureNovelCard"
import HomeLayout from "../../../layout/HomeLayout"
import nextIcon from "../../../assets/images/greater-blue-icon.svg"
import aboutMeImg from "../../../assets/images/about-me-img.svg"
import { Spinner } from "reactstrap"
import "./style.scss"
import { getAuthorProfile } from "../ReaderHome/redux/action"
import { putFavorites, patchFavoriteNovel } from "../Favorites/redux/action"
import { connect } from "react-redux"
import { history } from "../../../reduxStore/store"
import { saveBookID } from "../../AuthScreens/Login/redux/action"

const MemberProfile = props => {

  const {
    saveBookID,
    patchFavoriteNovel,
    putFavorites,
    getAuthorProfile,
    authorProfileData,
    requesting,
    memberID,
    error,
    putData,
    patchData,
    loginInfo
  } = props

  useEffect(() => {
    memberID && getAuthorProfile(memberID)
  }, [memberID])

  const putRequest = () => {
    const data = { authors: [memberID] }
    putFavorites(data)
  }

  const favoritsNovel = id => {
    const data = { novels: [id] }
    patchFavoriteNovel(data, memberID)
  }

  const setBookID = id => {
    saveBookID(id)
    history.push({ pathname: `/${loginInfo.user.mode}/reading_experience/` })
  }

  return (
    <HomeLayout
      headerName="Member Profile"
      showBack={true}
      showFavorite={true}
      addFav={putData?.favorite}
      favOnClick={putRequest}
    >
      {!authorProfileData?.Featured_Novels && requesting ? (
        <div className="spinner-style">
          <Spinner color="primary" className="table-spinner" size="lg" />
        </div>
      ) : (
        <section className="content-section member-profile-section">
          <div className="about-me-section">
            <div className="container-fluid about-me-container">
              <div className="row">
                <div className="col-md-3">
                  <div className="about-me-left">
                    <div className="profile">
                      <img
                        src={
                          authorProfileData?.author?.profile_picture || dummyPic
                        }
                        alt="profile"
                      />
                      <div className="name">
                        {authorProfileData?.author?.name}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="about-me-mid">
                    <h2>About Me</h2>
                    <p>{authorProfileData?.author?.about_me || "No Data"}</p>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="about-me-right">
                    <div className="image-wrapper">
                      <img src={aboutMeImg} alt="About Me" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="home-card-wrapper">
            <div className="container-fluid home-card-container">
              <h3>Featured Novels</h3>
              <div className="row home-card-row feature-card-row">
                {authorProfileData?.Featured_Novels?.length > 0 ? (
                  authorProfileData?.Featured_Novels?.map(item => (
                    <FeatureNovelCard
                      src={item?.thumbnail}
                      iconSrc={nextIcon}
                      novelName={item?.name}
                      nextText={"Read Novel"}
                      readClick={() => setBookID(item?.id)}
                      descriptionText={item?.synopsis}
                      key={item?.id}
                      total_likes={item?.total_likes}
                      grt={item?.grt}
                      title={item?.name}
                      novelFavClick={() => favoritsNovel(item.id)}
                      showFavorite={item?.favorite}
                    />
                  ))
                ) : (
                  <div className="no-data">No Data</div>
                )}
              </div>
            </div>
          </div>
        </section>
      )}
    </HomeLayout>
  )
}

const mapStateToProps = state => ({
  authorProfileData: state.home.authorProfileData,
  requesting: state.home.requesting,
  error: state.home.error,
  memberID: state.login.memberID,
  putData: state.Favorites.putData,
  patchData: state.Favorites.patchData,
  loginInfo: state.login.loginInfo
})

const mapDispatchToProps = dispatch => ({
  getAuthorProfile: data => dispatch(getAuthorProfile(data)),
  putFavorites: data => dispatch(putFavorites(data)),
  patchFavoriteNovel: (data, memberID) =>
    dispatch(patchFavoriteNovel(data, memberID)),
  saveBookID: data => dispatch(saveBookID(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(MemberProfile)
