export const POST_BECOME_WRITER = 'POST_BECOME_WRITER';
export const POST_BECOME_WRITER_SUCCESS = 'POST_BECOME_WRITER_SUCCESS';
export const POST_BECOME_WRITER_FAILURE = 'POST_BECOME_WRITER_FAILURE';
// GET BECOME WRITER
export const GET_BECOME_WRITER_DATA = 'GET_BECOME_WRITER_DATA';
export const GET_BECOME_WRITER_DATA_SUCCESS = 'GET_BECOME_WRITER_DATA_SUCCESS';
export const GET_BECOME_WRITER_DATA_FAILURE = 'GET_BECOME_WRITER_DATA_FAILURE';
// GET BECOME WRITER AGAINST NOVEL
export const GET_BECOME_NOVEL_DATA = 'GET_BECOME_NOVEL_DATA';
export const GET_BECOME_NOVEL_DATA_SUCCESS = 'GET_BECOME_NOVEL_DATA_SUCCESS';
export const GET_BECOME_NOVEL_DATA_FAILURE = 'GET_BECOME_NOVEL_DATA_FAILURE';