import React, { useEffect, useState } from "react"
import "./style.scss"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.snow.css"
import pencilIcon from "../../assets/images/textarea-write.svg"
import Input from "../../components/Input"
import Button from "../../components/Button"
import UploadFile from "../../components/UploadFile"
import deleteIcon from "../../assets/images/delete-white.svg"
import HomeLayout from "../../layout/HomeLayout"
import EditorToolbar, { modules, formats } from "./EditorToolsBar"
import {
  createNewChapter,
  createNewChapterSuccess,
  createNewChapterFailure,
  patchNovelChapter,
  patchNovelChapterSuccess,
  deleteMp3File,
  deleteMp3FileSuccess
} from "../CreateChapter/redux/action"
import { getNovelChapter } from "../HomeScreens/ReaderHome/redux/action"
import { connect } from "react-redux"
import { history } from "../../reduxStore/store"
import BoardAudioPlayer from "../../components/BoardAudioPlayer"
import { Spinner } from "reactstrap"
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const QuillEditor = props => {
  const {
    postNewChapter,
    newChapterInfo,
    createNewChapterSuccess,
    createNewChapterFailure,
    requesting,
    characterID,
    chapterData,
    patchNovelChapter,
    patchChapterLoad,
    deleteMp3File,
    getNovelChapter,
    chapterID,
    patchChapter,
    deleteFile,
    deleteMp3FileSuccess,
    chapterLoading,
    patchNovelChapterSuccess,
    error
  } = props

  const [chapterTital, setChapterTital] = useState("")
  const [chapterAudio, setChapterAudio] = useState([])
  const [editorValue, setEditorValue] = useState("")
  const [audioError, setAudioError] = useState("")
  const [deleteID, setDeleteID] = useState([])

  useEffect(() => {
    if (newChapterInfo?.msg == "Create New Chapter Successfully") {
      setChapterTital("")
      setChapterAudio([])
      setEditorValue("")
      createNewChapterSuccess(false)
      history.goBack()
    }
  }, [newChapterInfo?.msg])

  const { permission } = props.location

  useEffect(() => {
    if (chapterID) {
      getNovelChapter(chapterID)
    }
  }, [chapterID])

  useEffect(() => {
    if (patchChapter?.id) {
      if (deleteID.length > 0) {
        for (let key in deleteID) {
          deleteMp3File(deleteID[key])
        }
        setDeleteID([])
      }
      patchNovelChapterSuccess(false)
    }
  }, [patchChapter?.id])

  useEffect(() => {
    if (deleteFile.msg == "Delete Successfully") {
      getNovelChapter(chapterID)
    }
    deleteMp3FileSuccess(false)
  }, [deleteFile.msg])

  useEffect(() => {
    if (chapterID) {
      chapterData?.name && setChapterTital(chapterData?.name)
      chapterData?.content && setEditorValue(chapterData?.content)
      chapterData?.upload_mp3 && setChapterAudio([...chapterData?.upload_mp3])
    }
  }, [chapterData])

  const onChangeValue = (type, val) => {
    if (type == "tital") {
      setChapterTital(val.target.value)
    } else if (type == "editor") {
      setEditorValue(val)
    } else if (type == "audioFile") {
      const file = val.target.files[0]
      if (file.type == "audio/mpeg") {
        setAudioError("")
        setChapterAudio(prev => [...prev, file])
      } else {
        setAudioError("Only audio file.")
      }
    }
  }

  const deleteAudio = (id, index) => {
    if (id) {
      const delAudio = chapterAudio.filter((item, ind) => ind !== index)
      setChapterAudio(delAudio)
      setDeleteID(prev => [...prev, id])
    } else {
      const delAudio = chapterAudio.filter((item, ind) => ind !== index)
      setChapterAudio(delAudio)
    }
  }

  const onSubmit = () => {
    if (chapterID) {
      const formData = new FormData()
      formData.append("name", chapterTital)
      formData.append("content", editorValue)
      for (let key in chapterAudio) {
        if (!chapterAudio[key].id) {
          formData.append(`upload_mp3_${key}`, chapterAudio[key])
        }
      }
      patchNovelChapter(chapterID, formData)
    } else {
      const formData = new FormData()
      formData.append("name", chapterTital)
      formData.append("content", editorValue)
      formData.append("character", characterID)
      for (let key in chapterAudio) {
        formData.append(`upload_mp3_${key}`, chapterAudio[key])
      }
      postNewChapter(formData)
    }
  }

  return (
    <HomeLayout
      showBack={true}
      headerName={!chapterData && chapterLoading ? <Skeleton highlightColor='#ddd' borderRadius={30} height={30} width={150} /> : chapterID ? `Edit ${chapterData?.name}` : "Add New Chapter"}
    >
      {!chapterData && chapterLoading ? (
        <div className="spinner-style">
          <Spinner color="primary" />
        </div>
      ) : chapterData?.permissions || permission ? (
        <div className="content-section quil-section">
          <div className="quil-editor-wrapper">
            <div className="quil-top-actions">
              <Input
                showIcon={true}
                src={pencilIcon}
                placeholder="Chapter Title Name"
                onChange={val => onChangeValue("tital", val)}
                onFocus={() => createNewChapterFailure(false)}
                value={chapterTital}
                showError={true}
                BackendErrorMessage={error && error?.name}

              />
              <div className="action-wrapper">
                <UploadFile
                  onChange={val => onChangeValue("audioFile", val)}
                  title="Upload Sound"
                />
                <div
                  className={`error-message ${audioError ? "opacity-1" : ""}`}
                >
                  {audioError}
                </div>
              </div>
            </div>
            <div className="sound-actions-wrapper">
              {chapterAudio.length > 0 &&
                chapterAudio.map((item, i) => (
                  <div className="sound-actions">
                    <div className="button-wrapper">
                      <BoardAudioPlayer
                        src={
                          item.name
                            ? URL.createObjectURL(item)
                            : item.upload_mp3
                        }
                      />
                    </div>
                    <div
                      className="action"
                      onClick={() => deleteAudio(item.id, i)}
                    >
                      <img src={deleteIcon} alt="" />
                    </div>
                  </div>
                ))}
            </div>
            <EditorToolbar />
            <ReactQuill
              theme="snow"
              value={editorValue}
              onChange={val => onChangeValue("editor", val)}
              placeholder={"Write something awesome..."}
              modules={modules}
              formats={formats}
            />
            <div className="button-wrapper">
              <Button
                showSpinner={chapterID ? patchChapterLoad : requesting}
                onClick={onSubmit}
                title={chapterID ? "Save Changes" : "Create Chapter"}
              />
            </div>
          </div>
        </div>
      ) : (
        <section className="reading-section perspective-section">
          <div className="chapter-button-wrapper"></div>
          <div className="container-fluid perspective-section-container">
            <div className="top-actions d-flex justify-content-between">
              <h3>{(chapterData && chapterData?.name) || ""}</h3>
              <div className="button-wrapper d-flex">
                {chapterData?.upload_mp3 &&
                  chapterData?.upload_mp3.map(item => (
                    <BoardAudioPlayer src={item?.upload_mp3} />
                  ))}
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 perspective-details-left">
                <div className="perspective-details">
                  <p>
                    {
                      <ReactQuill
                        theme="bubble"
                        value={chapterData && chapterData?.content}
                        readOnly={true}
                      />
                    }
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </HomeLayout>
  )
}

const mapStateToProps = state => ({
  requesting: state.createChapter.requesting,
  patchChapterLoad: state.createChapter.patchChapterLoad,
  patchChapter: state.createChapter.patchChapter,
  newChapterInfo: state.createChapter.newChapterInfo,
  error: state.createChapter.error,
  deleteFile: state.createChapter.deleteFile,
  characterID: state.login.characterID,
  chapterID: state.login.chapterID,
  chapterData: state.home.chapterData,
  chapterLoading: state.home.chapterLoading
})

const mapDispatchToProps = dispatch => ({
  postNewChapter: data => dispatch(createNewChapter(data)),
  createNewChapterSuccess: data => dispatch(createNewChapterSuccess(data)),
  createNewChapterFailure: data => dispatch(createNewChapterFailure(data)),
  patchNovelChapter: (id, data) => dispatch(patchNovelChapter(id, data)),
  patchNovelChapterSuccess: data => dispatch(patchNovelChapterSuccess(data)),
  deleteMp3File: id => dispatch(deleteMp3File(id)),
  getNovelChapter: data => dispatch(getNovelChapter(data)),
  deleteMp3FileSuccess: data => dispatch(deleteMp3FileSuccess(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(QuillEditor)
