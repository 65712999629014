import React, { useState, useRef, useEffect } from "react"
import Input from "../../../components/Input"
import HomeLayout from "../../../layout/HomeLayout"
import mailIcon from "../../../assets/images/mail-icon.svg"
import profileIcon from "../../../assets/images/profile.svg"
import tokenIcon from "../../../assets/images/token-blue.svg"
import textAreaWriteIcon from "../../../assets/images/textarea-write.svg"
import lockIcon from "../../../assets/images/lock-icon.svg"
import "./style.scss"
import Button from "../../../components/Button"
import useForm from "../../../utils/useForm"
import validator from "../../../utils/validation"
import { connect } from "react-redux"
import {
  putRequestPassword,
  getRequestProfileData,
  putRequestPasswordFailure,
  putRequestAboutMe,
  putRequestAboutMeFailure,
  putRequestAboutMeSuccess
} from "./redux/action"
import { useHistory } from "react-router-dom"
import BoardPhoneInput from "../../../components/PhoneInput"
import { logOut } from "../../AuthScreens/Login/redux/action"
import dummyPic from "../../../assets/images/dummyPic.png"
import moment from "moment"
import { CopyOutlined } from "@ant-design/icons"
import { toast } from "react-hot-toast"

const MyProfile = props => {
  const {
    putRequestPassword,
    putRequestAboutMe,
    putRequestAboutMeFailure,
    getRequestProfileData,
    data,
    profileData,
    requesting,
    error,
    loginInfo,
    updatePassrequest,
    updateProfileRequest,
    logOut,
    putRequestAboutMeSuccess,
    putRequestPasswordFailure
  } = props

  const history = useHistory()
  const [conPasError, SetConPasError] = useState("")
  const [grtInfo, setGrtInfo] = useState([])
  const [isChange, setIsChange] = useState(false)
  const [profilePic, setProfilePic] = useState({})

  const fileRef = useRef()

  const handleUploadClick = () => {
    fileRef.current?.click()
    return false
  }

  useEffect(() => {
    if (data?.email_updated) {
      setTimeout(() => {
        data?.email_updated && logOut()
        putRequestAboutMeSuccess(false)
      }, 1000)
    }
  }, [data?.email_updated])

  const stateSchema = {
    email: {
      value: "",
      error: ""
    },
    userName: {
      value: "",
      error: ""
    },
    phoneNumber: {
      value: "",
      error: ""
    },
    aboutMe: {
      value: "",
      error: ""
    },
    oldPassword: {
      value: "",
      error: ""
    },
    newPassword: {
      value: "",
      error: ""
    },
    confirmPassword: {
      value: "",
      error: ""
    }
  }
  const validationStateSchema = {
    email: {
      required: true,
      validator: validator.email
    },
    userName: {
      required: false,
      validator: ""
    },
    phoneNumber: {
      required: false,
      validator: ""
    },
    aboutMe: {
      required: true,
      validator: ""
    },
    oldPassword: {
      required: true
    },
    newPassword: {
      required: true,
      validator: validator.password
    },
    confirmPassword: {
      required: true
    }
  }
  const { state, handleOnChange, disable } = useForm(
    stateSchema,
    validationStateSchema
  )

  useEffect(() => {
    getRequestProfileData()
    if (
      data?.message == "Password updated successfully" ||
      error?.old_password == "Wrong password."
    ) {
      state.oldPassword.value = ""
      state.newPassword.value = ""
      state.confirmPassword.value = ""
    }
    putRequestPasswordFailure(false)
  }, [data])

  useEffect(() => {
    profileData &&
      handleOnChange(
        "aboutMe",
        profileData[0]?.about_me && profileData[0]?.about_me
      )
    profileData &&
      handleOnChange(
        "email",
        profileData[0]?.email ? profileData[0]?.email : ""
      )
    profileData &&
      handleOnChange(
        "userName",
        profileData[0]?.name ? profileData[0]?.name : ""
      )
    profileData &&
      handleOnChange(
        "phoneNumber",
        profileData[0]?.phone_number ? profileData[0]?.phone_number : ""
      )
    profileData && setGrtInfo(profileData[0])

    profileData &&
      setProfilePic(
        profileData[0]?.profile_picture
          ? profileData[0]?.profile_picture
          : dummyPic
      )
  }, [profileData])

  const onSubmit = async () => {
    const data = {
      old_password: state.oldPassword.value,
      new_password: state.newPassword.value
    }
    if (data.new_password == state.confirmPassword.value) {
      await putRequestPassword(data)
    } else {
      SetConPasError("The password and confirmation password do not match.")
    }
  }

  const aboutMeSubmit = async () => {
    if (
      isChange ||
      state.aboutMe.value ||
      state.userName.value ||
      state.email.value ||
      state.phoneNumber.value ||
      profilePic
    ) {
      const formData = new FormData()
      formData.append("about_me", state.aboutMe.value)
      formData.append("name", state.userName.value)
      formData.append("phone_number", `+${state.phoneNumber.value}`)
      formData.append("email", state.email.value)
      if (profilePic.name !== undefined) {
        formData.append("profile_picture", profilePic)
      }
      await putRequestAboutMe(formData)
    }
  }

  const handleFormatChanger = value => {
    setIsChange(true)
    handleOnChange("phoneNumber", value)
  }

  const onFileChange = val => {
    setIsChange(true)
    setProfilePic(val.target.files[0])
  }

  const onCopy = () => {
    navigator.clipboard.writeText(profileData[0]?.referrer_number)
    toast.success("Copied", {
      id: "cards"
    })
  }

  return (
    <HomeLayout headerName={"My Profile"}>
      <section className="content-section my-profile-section">
        <div className="profile-detail-section">
          <div className="container-fluid profile-detail-container">
            <div className="token-detail-wrapper">
              <div className="token-details">
                <h3>Available GRT</h3>
                <div className="token-icon">
                  <img src={tokenIcon} alt="" />
                  <span title={grtInfo?.available_grt}>
                    {grtInfo?.available_grt
                      ? grtInfo?.available_grt.toFixed(2)
                      : "0"}
                  </span>
                </div>
              </div>
              <div className="token-details">
                <h3>Given Out GRT</h3>
                <div className="token-icon">
                  <img src={tokenIcon} alt="" />
                  <span title={grtInfo?.giveout_grt}>
                    {grtInfo?.giveout_grt
                      ? grtInfo?.giveout_grt.toFixed(2)
                      : "0"}
                  </span>
                </div>
              </div>
              <div className="referrer-name">
                <h3>My Referrer Code: </h3>
                <p>
                  {profileData[0]?.referrer_number || ""}{" "}
                  {profileData[0]?.referrer_number && (
                    <span onClick={onCopy}>
                      <CopyOutlined />
                    </span>
                  )}
                </p>
                <p>Share your code to earn referral credits.</p>
              </div>
            </div>
            {loginInfo?.user?.user_type !== "admin" && (
              <div className="profile-referral-container">
                <div className="referrer-name">
                  <h3>Renewal Date: </h3>
                  <p>
                    {profileData[0]?.subscription_expiry_date &&
                      moment(profileData[0]?.subscription_expiry_date).format(
                        "DD MMM YYYY"
                      )}
                  </p>
                </div>
              </div>
            )}
            {loginInfo?.user?.user_type !== "admin" && (
              <div className="profile-form-wrapper">
                <div className="basic-info-wrapper">
                  <h3>Basic Information</h3>
                  <div className="profile-picture" onClick={handleUploadClick}>
                    <img
                      src={
                        profilePic.name
                          ? URL.createObjectURL(profilePic)
                          : profilePic
                      }
                    />
                    <p>Upload Profile Picture</p>
                    <input
                      type="file"
                      name="file"
                      ref={fileRef}
                      onChange={val => onFileChange(val)}
                      hidden
                      value=""
                    />
                  </div>
                  <div className="input-row">
                    <Input
                      showIcon={true}
                      src={mailIcon}
                      placeholder="mail@mail.com"
                      type="email"
                      value={state.email.value}
                      onChange={e => {
                        handleOnChange("email", e.target.value)
                        setIsChange(true)
                      }}
                      showError={true}
                      errorMessage={
                        (error.email && error.email[0]) || state.email.error
                      }
                    />
                    <Input
                      showIcon={true}
                      src={profileIcon}
                      placeholder="User Name"
                      type="text"
                      value={state.userName.value}
                      onChange={e => {
                        setIsChange(true)
                        handleOnChange("userName", e.target.value)
                      }}
                      onFocus={() => putRequestAboutMeFailure(false)}
                      showError={true}
                      errorMessage={error?.name && error?.name}
                    />
                  </div>
                  <div className="input-row">
                    <BoardPhoneInput
                      placeholder={"+1 234 567-2345"}
                      value={state.phoneNumber.value}
                      onChange={handleFormatChanger}
                      onFocus={() => putRequestAboutMeFailure(false)}
                      showError={true}
                      errorMessage={
                        (error.phone_number && error.phone_number[0]) ||
                        state.phoneNumber.error
                      }
                    />
                  </div>
                  <div className="text-area-field">
                    <span>
                      <img src={textAreaWriteIcon} />
                    </span>
                    <textarea
                      name="textarea"
                      placeholder="About Me"
                      value={state.aboutMe.value}
                      onChange={e => {
                        setIsChange(true)
                        handleOnChange("aboutMe", e.target.value)
                      }}
                    ></textarea>
                  </div>
                </div>
                {/* ))} */}
                <div className="password-info-wrapper">
                  <h3>Password</h3>
                  <div className="input-row">
                    <Input
                      showIcon={true}
                      src={lockIcon}
                      placeholder="Enter Old Password"
                      type="password"
                      showHideIcon={true}
                      value={state.oldPassword.value}
                      onChange={val => {
                        handleOnChange("oldPassword", val.target.value)
                        putRequestPasswordFailure(false)
                      }}
                      showError={true}
                      errorMessage={state.oldPassword.error}
                      BackendErrorMessage={error?.old_password}
                    />
                    <Input
                      showIcon={true}
                      src={lockIcon}
                      placeholder="Create New Password"
                      type="password"
                      showHideIcon={true}
                      value={state.newPassword.value}
                      onChange={val => {
                        handleOnChange("newPassword", val.target.value)
                        putRequestPasswordFailure(false)
                      }}
                      showError={true}
                      errorMessage={state.newPassword.error}
                      BackendErrorMessage={error?.new_password}
                    />
                  </div>
                  <div className="input-row">
                    <Input
                      showIcon={true}
                      src={lockIcon}
                      placeholder="Confirm New Password"
                      type="password"
                      showHideIcon={true}
                      onFocus={() => SetConPasError("")}
                      value={state.confirmPassword.value}
                      onChange={val =>
                        handleOnChange("confirmPassword", val.target.value)
                      }
                      showError={true}
                      errorMessage={state.confirmPassword.error || conPasError}
                    />
                    <div className="button-wrapper">
                      <Button
                        title="Update Password"
                        onClick={onSubmit}
                        showSpinner={updatePassrequest}
                      />
                    </div>
                  </div>
                </div>
                <div className="bank-info-wrapper">
                  <div className="top">
                    <h3>Bank Account</h3>
                    <Button
                      className="transparent-btn"
                      title="Link Bank Account"
                      onClick={() =>
                        history.push(
                          `/${loginInfo && loginInfo?.user?.mode}/account`
                        )
                      }
                    />
                  </div>
                  <div className="button-wrapper">
                    <Button
                      className={`orange-btn ${!isChange && "disabled-btn"}`}
                      title="Update Profile"
                      onClick={aboutMeSubmit}
                      showSpinner={updateProfileRequest}
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
      {/* <WriterModeModal /> */}
    </HomeLayout>
  )
}

const mapStateToProps = state => ({
  data: state.myProfile.data,
  requesting: state.myProfile.requesting,
  updatePassrequest: state.myProfile.updatePassrequest,
  updateProfileRequest: state.myProfile.updateProfileRequest,
  error: state.myProfile.error,
  profileData: state.myProfile.profileData,
  loginInfo: state.login.loginInfo
})

const mapDispatchToProps = dispatch => ({
  putRequestPassword: data => dispatch(putRequestPassword(data)),
  putRequestPasswordFailure: data => dispatch(putRequestPasswordFailure(data)),
  logOut: data => dispatch(logOut(data)),
  getRequestProfileData: data => dispatch(getRequestProfileData(data)),
  putRequestAboutMe: data => dispatch(putRequestAboutMe(data)),
  putRequestAboutMeFailure: data => dispatch(putRequestAboutMeFailure(data)),
  putRequestAboutMeSuccess: data => dispatch(putRequestAboutMeSuccess(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(MyProfile)
