import React, { useState } from "react"
import Input from "../../components/Input"
import HomeLayout from "../../layout/HomeLayout"
import pencilIcon from "../../assets/images/textarea-write.svg"
import editIcon from "../../assets/images/writer.svg"
import deleteIcon from "../../assets/images/delete.svg"
import "./style.scss"
import TextArea from "../../components/TextArea"
import BoardDatePicker from "../../components/BoardDatePicker"
import BoardSelect from "../../components/BoardSelect"
import addIcon from "../../assets/images/add.svg"
import Button from "../../components/Button"
import UploadFile from "../../components/UploadFile"
import moment from "moment/moment"
import { connect } from "react-redux"
import {
  createNewNovel,
  createNewNovelFailure,
  getRoleTypeRequest,
  postRoleTypeRequest,
  getCharacterRequest,
  deleteRequest,
  postRoleTypeFailure,
  getCharacterSuccess
} from "./redux/action"
import { getBecomeWriterData } from "../HomeScreens/BecomeWriter/redux/action"
import useForm from "../../utils/useForm"
import { useEffect } from "react"
import { Spinner } from "reactstrap"
import EditChracters from "../../components/EditChracters"
import { Checkbox } from "antd"
import {
  getNovelData,
  patchNovelData,
  patchNovelDataFailure
} from "../ProducerHome/redux/action"

const CreateNovel = props => {
  const {
    postCreateNewNovel,
    requesting,
    error,
    createNewNovelFailure,
    getRoleTypeRequest,
    GetRoleData,
    postRoleError,
    postRoleTypeRequest,
    postRoleRequesting,
    postRoleInfo,
    deleteRequest,
    postRoleTypeFailure,
    newNovelInfo,
    characterData,
    getCharacterRequest,
    characterLoad,
    getCharacterSuccess,
    getNovelID,
    getNovelData,
    novelData,
    novelDataLoad,
    patchNovelLoad,
    patchNovelData,
    patchNovelError,
    patchNovelDataFailure
  } = props

  const [thumbnail, setThumbnail] = useState(false)
  const [userID, setUserID] = useState([])
  const [editorUserID, setEditorUserID] = useState([])
  const [characterID, setCharacterID] = useState([])
  const [characterList, setCharacterList] = useState([])
  const [writerId, setWriterId] = useState("")
  const [editorId, setEditorId] = useState("")
  const [selectedCharacter, setSelectedCharacter] = useState(false)
  const [characterError, setCharacterError] = useState("")
  const [modalShow, setModalShow] = useState(false)
  const [checkBoxValue, setCheckBoxValue] = useState(false)

  useEffect(() => {
    getCharacterRequest()
    getRoleTypeRequest("")
  }, [])

  useEffect(() => {
    if (getNovelID) {
      getNovelData(getNovelID)
    }
  }, [getNovelID])

  useEffect(() => {
    if (characterData || novelData?.character) {
      characterData && setCharacterList(characterData)
      novelData?.character &&
        novelData?.character?.map(item =>
          setCharacterList(prev => [...prev, item])
        )
    }
  }, [characterData, novelData?.character])

  useEffect(() => {
    let writerID = []
    if (GetRoleData) {
      GetRoleData &&
        GetRoleData?.data?.map(val =>
          writerID.push({
            value: val.id,
            label: val.name
          })
        )
      setUserID(writerID)
      setEditorUserID(writerID)
    }
  }, [GetRoleData])


  useEffect(() => {
    if (postRoleInfo?.id) {
      getCharacterRequest()
      postRoleInfo?.name && handleOnChange("CharacterName", "")
      postRoleInfo?.personality && handleOnChange("personality", "")
      postRoleInfo?.about && handleOnChange("about", "")
      setWriterId("")
    }
    getCharacterSuccess(false)
  }, [postRoleInfo?.id])

  useEffect(() => {
    if (writerId) {
      setCheckBoxValue(true)
    } else {
      setCheckBoxValue(false)
    }
  }, [writerId])

  useEffect(() => {
    if (novelData) {
      novelData &&
        handleOnChange("name", novelData?.name ? novelData?.name : "")
      novelData &&
        handleOnChange(
          "synopsis",
          novelData?.synopsis ? novelData?.synopsis : ""
        )
      novelData &&
        handleOnChange(
          "closing_date",
          novelData?.closing_date ? novelData?.closing_date : ""
        )
      novelData && setEditorId(novelData?.editor ? novelData?.editor : "")
      // novelData &&
      //   setWriterId(
      //     novelData?.character ? novelData?.character[0]?.writer?.id : ""
      //   )
      novelData &&
        novelData?.character.map(item =>
          setCharacterID(prev => [...prev, item?.id])
        )
      novelData &&
        handleOnChange(
          "opening_scene",
          novelData?.opening_scene ? novelData?.opening_scene : ""
        )
      novelData &&
        handleOnChange("genre", novelData?.genre ? novelData?.genre : "")
    }
  }, [novelData])

  const stateSchema = {
    name: {
      value: "",
      error: ""
    },
    closing_date: {
      value: "",
      error: ""
    },
    writer: {
      value: "",
      error: ""
    },
    novel_id: {
      value: "",
      error: ""
    },
    synopsis: {
      value: "",
      error: ""
    },
    opening_scene: {
      value: "",
      error: ""
    },
    genre: {
      value: "",
      error: ""
    },
    CharacterName: {
      value: "",
      error: ""
    },
    personality: {
      value: "",
      error: ""
    },
    about: {
      value: "",
      error: ""
    }
  }

  const validationStateSchema = {
    name: {
      required: true
    },
    closing_date: {
      required: true
    },
    writer: {
      required: false
    },
    novel_id: {
      required: true
    },
    synopsis: {
      required: true
    },
    opening_scene: {
      required: true
    },
    genre: {
      required: true
    },
    CharacterName: {
      required: true
    },
    personality: {
      required: true
    },
    about: {
      required: true
    }
  }
  const { state, handleOnChange, disable, setState } = useForm(
    stateSchema,
    validationStateSchema
  )
  const onSubmit = () => {
    const closingDate = moment(state.closing_date.value).format(
      "YYYY-MM-DDT00:00:00"
    )
    if (getNovelID) {
      const formData = new FormData()
      formData.append("name", state.name.value)
      if (state.closing_date.value) {
        formData.append("closing_date", closingDate)
      }
      formData.append("synopsis", state.synopsis.value)
      formData.append("opening_scene", state.opening_scene.value)
      formData.append("genre", state.genre.value)
      formData.append("character_id", JSON.stringify(characterID))
      if (thumbnail) {
        formData.append("thumbnail", thumbnail)
      }
      formData.append("editor", editorId ? editorId : "")
      patchNovelData(getNovelID, formData)
    } else {
      setCharacterError("")
      const formData = new FormData()
      if (characterID.length > 0) {
        formData.append("name", state.name.value)
        if (state.closing_date.value) {
          formData.append("closing_date", closingDate)
        }
        formData.append("synopsis", state.synopsis.value)
        formData.append("opening_scene", state.opening_scene.value)
        formData.append("genre", state.genre.value)
        formData.append("character_id", JSON.stringify(characterID))
        formData.append("thumbnail", thumbnail)
        formData.append("editor_id", editorId)

        postCreateNewNovel(formData)
      } else {
        setCharacterError("Please select character")
      }
    }
  }

  useEffect(() => {
    if (newNovelInfo) {
      setState(stateSchema)
      setThumbnail(false)
      createNewNovelFailure(false)
      setCharacterID([])
      setEditorId("")
      // setCheckBoxValue(prev => ({ ...prev, editor: false }))
    }
  }, [newNovelInfo])

  const onAddBtnClick = () => {

    const data = {
      name: state.CharacterName.value,
      personality: state.personality.value,
      about: state.about.value,
      writer: writerId ? writerId : ''
    }
    if (data.name) {
      postRoleTypeRequest(data)

    }
  }

  const handleChange = (type, value) => {
    if (type == "writer") {
      setWriterId(value)
    } else if (type == "editor") {
      setEditorId(value)
    }
  }

  const handleRemove = member => {
    deleteRequest(member)
  }

  const onChSelect = id => {
    if (characterID.includes(id)) {
      setCharacterID(prv => prv.filter(item => item !== id))
    } else {
      patchNovelDataFailure(false)
      setCharacterID(prev => [...prev, id])
    }
  }

  return (
    <HomeLayout
      showBack={true}
      headerName={getNovelID ? "Update Novel" : "Create New Novel"}
    >
      {novelDataLoad ? (
        <div className="spinner-style">
          <Spinner color="primary" />
        </div>
      ) : (
        <section className="content-section new-novel-section">
          <div className="fill-box-section">
            <h2>Fill the boxes below:</h2>
            <div className="container-fluid fill-box-container">
              <div className="row">
                <div className="col-lg-12 fill-box-left">
                  <div className="left-details">
                    <Input
                      placeholder="Create Novel Name"
                      showIcon={true}
                      src={pencilIcon}
                      value={state.name.value}
                      showError={true}
                      onChange={val => handleOnChange("name", val.target.value)}
                      errorMessage={state.name.error || error.name}
                      onFocus={() => createNewNovelFailure(false)}
                    />
                  </div>
                </div>
                <div className="col-md-12 fill-box-bottom">
                  <div className="bottom-details">
                    <TextArea
                      placeholder="Create Synopsis"
                      value={state.synopsis.value}
                      showError={true}
                      errorMessage={state.synopsis.error}
                      onChange={val =>
                        handleOnChange("synopsis", val.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="row date-picker-row">
                  <BoardDatePicker
                    className="board-date-picker-item first"
                    placeholder={moment(
                      novelData?.created_at ? novelData?.created_at : new Date()
                    ).format("D MMM YY")}
                    disabled={true}
                  />
                  <BoardDatePicker
                    placeholder={
                      novelData?.closing_date
                        ? moment(novelData?.closing_date).format("D MMM YY")
                        : "Select Closing Date"
                    }
                    onChange={(val, string) =>
                      handleOnChange("closing_date", string)
                    }
                    format="D MMM YY"
                    disabledDate={true}
                  />
                </div>
                <div className="col-lg-6 fill-box-right">
                  <div className="field-column d-flex gap-5 align-items-center mb-4">
                    {/* <Checkbox
                      onChange={val =>
                        setCheckBoxValue(prev => ({
                          ...prev,
                          editor: val.target.checked
                        }))
                      }
                      value={checkBoxValue.editor}
                      className="check-box"
                    > */}
                    Select Editor
                    {/* </Checkbox> */}
                    <div className="input-row flex-grow-1">
                      <BoardSelect
                        options={editorUserID}
                        placeholder="ID"
                        onChange={event => handleChange("editor", event)}
                        value={editorId}
                        allowClear={true}
                        showSearch={true}
                      />
                    </div>
                  </div>
                </div>
                <h2 className="ps-0">Create Characters</h2>
                <div className="col-lg-12 fill-box-right">
                  <div className="right-details">
                    <Input
                      placeholder="Create Character Name"
                      showIcon={true}
                      src={pencilIcon}
                      value={state.CharacterName.value}
                      showError={true}
                      onChange={val =>
                        handleOnChange("CharacterName", val.target.value)
                      }
                      onFocus={() => postRoleTypeFailure(false)}
                      // errorMessage={state.CharacterName.error}
                      BackendErrorMessage={
                        postRoleError?.name && postRoleError?.name
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-lg-12 fill-box-right">
                  <div className="row right-details">
                    <div className="col-lg-6">
                      <div className="left-details">
                        <TextArea
                          placeholder="Charater's Personality Traits"
                          value={state.personality.value}
                          showError={true}
                          onChange={val =>
                            handleOnChange("personality", val.target.value)
                          }
                          onFocus={() => postRoleTypeFailure(false)}
                        // errorMessage={
                        //   postRoleError?.personality
                        //     ? postRoleError?.personality
                        //     : state.personality.error
                        // }
                        />
                      </div>
                    </div>
                    <div className="col-lg-6">
                      <div className="left-details">
                        <TextArea
                          placeholder="About the Character"
                          value={state.about.value}
                          showError={true}
                          onChange={val =>
                            handleOnChange("about", val.target.value)
                          }
                          onFocus={() => postRoleTypeFailure(false)}
                        // errorMessage={
                        //   postRoleError?.about
                        //     ? postRoleError?.about
                        //     : state.about.error
                        // }
                        />
                      </div>
                    </div>
                    <div className="col-lg-6 fill-box-right">
                      <div className="field-wrapper">
                        <div className="field-row">
                          <div className="field-column-wrapper">
                            <div className="field-column">
                              <div className="left">

                                Default Writer
                              </div>
                              <div className="right">
                                <div className="input-row">
                                  <BoardSelect
                                    options={userID}
                                    placeholder="ID"
                                    onChange={event =>
                                      handleChange("writer", event)
                                    }
                                    value={writerId}
                                    allowClear={true}
                                    showSearch={true}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                          <div
                            className={`add`}
                            onClick={() => onAddBtnClick()}
                          >
                            {postRoleRequesting ? (
                              <Spinner size={"sm"} color="white" />
                            ) : (
                              <img src={addIcon} alt="add" />
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="character-involve-section">
            <h2>Characters Involved</h2>
            <div className="container-fluid character-involve-container">
              <div className="row character-involve-row">
                {!characterList && characterLoad ? (
                  <div className="spinner-style">
                    <Spinner color="primary" className="mt-5" />
                  </div>
                ) : (
                  characterList &&
                  characterList?.map(item =>{
                    return  (
                      <div className="role-card-wrapper">
                        <div
                          className={`role-card ${characterID.includes(item.id) ? "active-card" : ""
                            }`}
                        >
                          <div
                            className="role-card-description"
                            onClick={() => onChSelect(item.id)}
                          >
                            <h3 title={item.name ? `Character : ${item.name}` : '' }>{item.name}</h3>
                            <p title={item.writer?.id ? `Writer : ${item.writer?.name}`: 'No writer is assign to this character' } >{item.writer?.id ? `Writer : ${item.writer?.name}` : 'Writer : ---'}</p>
                          </div>
                          {!characterID.includes(item.id) && (
                            <div className="role-card-actions">
                              <div
                                className="action"
                                onClick={() => {
                                  handleRemove(item.id)
                                }}
                              >
                                <img src={deleteIcon} alt="" />
                              </div>
                              <div
                                className="action"
                                onClick={() => {
                                  setModalShow(true)
                                  setSelectedCharacter(item)
                                }}
                              >
                                <img src={editIcon} alt="" />
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    )
                  })
                )}
              </div>
              <div className="mt-2" style={{ color: "red" }}>
                {patchNovelError ? patchNovelError?.msg : characterError}
              </div>
            </div>
            <h2>Add Additional Info</h2>
            <div className="container-fluid additional-info-container">
              <div className="row additional-info-row">
                <div className="col-md-6 additional-left">
                  <div className="left-details">
                    <UploadFile
                      title="Upload Thumbnail"
                      onChange={val => {
                        createNewNovelFailure(false)
                        setThumbnail(val.target.files[0])
                      }}
                      selectedFile={thumbnail?.name}
                    />
                    <div
                      className={`error-message ${error?.thumbnail ? "opacity-1" : ""
                        }`}
                    >
                      {error?.thumbnail}
                    </div>
                    <Input
                      placeholder="Enter Genre"
                      showIcon={true}
                      src={pencilIcon}
                      value={state.genre.value}
                      showError={true}
                      errorMessage={state.genre.error}
                      onChange={val =>
                        handleOnChange("genre", val.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="col-md-6 additional-right">
                  <div className="right-details">
                    <TextArea
                      placeholder="Set the Opening Scene"
                      value={state.opening_scene.value}
                      showError={true}
                      errorMessage={state.opening_scene.error}
                      onChange={val =>
                        handleOnChange("opening_scene", val.target.value)
                      }
                    />
                  </div>
                </div>
              </div>
              <div className="button-wrapper">
                <Button
                  title={getNovelID ? "Update" : "Submit & Create"}
                  onClick={onSubmit}
                  showSpinner={getNovelID ? patchNovelLoad : requesting}
                />
              </div>
            </div>
          </div>
        </section>
      )}
      <EditChracters
        modalShow={modalShow}
        setModalShow={setModalShow}
        modalData={selectedCharacter}
      />
    </HomeLayout>
  )
}

const mapStateToProps = state => ({
  newNovelInfo: state.createNovel.newNovelInfo,
  requesting: state.createNovel.requesting,
  error: state.createNovel.error,
  postRoleError: state.createNovel.postRoleError,
  GetRoleData: state.createNovel.GetRoleData,
  postRoleInfo: state.createNovel.postRoleInfo,
  characterData: state.createNovel.characterData,
  postRoleRequesting: state.createNovel.postRoleRequesting,
  characterLoad: state.createNovel.characterLoad,
  getNovelID: state.login.getNovelID,
  novelData: state.producerHome.novelData,
  novelDataLoad: state.producerHome.novelDataLoad,
  patchNovelLoad: state.producerHome.patchNovelLoad,
  patchNovelError: state.producerHome.patchNovelError
})

const mapDispatchToProps = dispatch => ({
  postCreateNewNovel: data => dispatch(createNewNovel(data)),
  getRoleTypeRequest: data => dispatch(getRoleTypeRequest(data)),
  createNewNovelFailure: data => dispatch(createNewNovelFailure(data)),
  getCharacterRequest: data => dispatch(getBecomeWriterData(data)),
  postRoleTypeRequest: data => dispatch(postRoleTypeRequest(data)),
  deleteRequest: data => dispatch(deleteRequest(data)),
  postRoleTypeFailure: data => dispatch(postRoleTypeFailure(data)),
  getCharacterRequest: data => dispatch(getCharacterRequest(data)),
  getCharacterSuccess: data => dispatch(getCharacterSuccess(data)),
  getNovelData: data => dispatch(getNovelData(data)),
  patchNovelData: (id, data) => dispatch(patchNovelData(id, data)),
  patchNovelDataFailure: data => dispatch(patchNovelDataFailure(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateNovel)
