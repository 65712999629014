import React, { useEffect, useState } from "react"
import { Space, Table, Popover } from "antd"
import Button from "../../Button"
import optionIcon from "../../../assets/images/options-white.svg"
import pencilIcon from "../../../assets/images/writer.svg"
import pencilActiveIcon from "../../../assets/images/writer-blue.svg"
import moment from "moment"
import { useVT } from "virtualizedtableforantd4"
import deleteIcon from "../../../assets/images/delete.svg"
import deleteActiveIcon from "../../../assets/images/delete-blue.svg"
import { connect } from "react-redux"
import {
  getAdminPushNotification,
  putRequestActive,
  deleteNotification,
  concatNotification
} from "../../../pages/AdminScreens/PushNotifications/redux/action"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"


const NotificationsTable = props => {
  const {
    pushInfo,
    pushConcat,
    requesting,
    getUserPushNotification,
    currentPage,
    deleteNotification,
    putRequestActive,
    concatNotification
  } = props

  const [checkID, setCheckID] = useState(false)
  const [activeClass, setActiveClass] = useState(false)
  const [concatUser, setConcatUser] = useState([]);

  useEffect(() => {
    getUserPushNotification("", 1)
  }, [])

  useEffect(() => {
    if (pushInfo && currentPage?.current_page == 1) {
      setConcatUser(pushInfo)
    } else if (pushConcat) {
      setConcatUser([...concatUser, ...pushInfo])
    } else {
      setConcatUser(pushInfo)
    }
  }, [pushInfo])

  const onAction = id => {
    setActiveClass(id)
    setCheckID(id)
  }

  const onDelete = id => {
    deleteNotification(id)
  }

  const statusChange = value => {
    if (value.notification_status) {
      let data = {
        task_ids: value.task_ids,
        status: false
      }
      putRequestActive(data, value.id)
    } else {
      let data = {
        task_ids: value.task_ids,
        status: true,

      }
      putRequestActive(data, value.id)
    }
  }

  const text = <span></span>

  const popoverContent = users => {
    return (
      checkID === users.id && (
        <div className="option-items">
          <div className="item" onClick={() => statusChange(users)}>
            <img src={pencilIcon} className="img" alt="" />
            <img src={pencilActiveIcon} className="active-img" alt="" />
            <div className="text">
              {users.notification_status ? "Inactive" : "Active"}
            </div>
          </div>
          <div className="item" onClick={() => onDelete(users.id)}>
            <img src={deleteActiveIcon} className="active-img" alt="" />
            <img src={deleteIcon} className="img" alt="" />
            <div className="text">Delete</div>
          </div>
        </div>
      )
    )
  }

  const columns = [
    {
      title: "Status",
      dataIndex: "status",
      key: "status",
      render: (_, text) => (
        <Button
          title={text.notification_status ? "Active" : "Inactive"}
          className={`radius-btn no-cursor ${!text.notification_status && "inactive-btn"
            }`}
        />
      )
    },
    {
      title: "Name/Message",
      dataIndex: "name_message",
      key: "name_message",
      render: (_, name_message) => (
        <Space size="middle" className="name-message-field">
          <div className="date">
            {moment(name_message.created).format("YYYY-MM-DD")}
          </div>
          <p className="mb-0">{name_message.message ? name_message.message : '---'}</p>
        </Space>
      )
    },
    {
      title: "Users",
      key: "group",
      render: (_, users) => (
        <Space size="middle" className="phone-details">
          <p className="mb-0">{users.group ? users.group : '---'}</p>
          <Popover
            placement="left"
            title={text}
            content={popoverContent(users)}
            trigger="click"
            getPopupContainer={trigger => trigger.parentNode}

          >
            {
              <div
                className={`options ${checkID === users.id && activeClass === users.id ? "active" : ""
                  }`}
                onClick={() => onAction(users.id)}
              >
                <img src={optionIcon} alt="" />
              </div>
            }
          </Popover>
        </Space>
      )
    }
  ]

  const [vt] = useVT(
    () => ({
      onScroll: ({ top, isEnd }) => {
        if (isEnd) {
          const currentNo = currentPage.current_page + 1
          if (currentNo <= currentPage.total_page) {
            concatNotification(true)
            getUserPushNotification("", currentNo)
          }
        }
      },
      scroll: {
        y: 600,
        x: 1000
      },
      debug: false
    }),
    [concatUser]
  )
  if (requesting || !pushInfo?.length) {
    return (
      <>
        <SkeletonTheme baseColor="#6f8bdf40" highlightColor="#6F8BDF">
          <Table
            rowKey="key"
            pagination={false}
            dataSource={[...Array(10)].map((_, index) => ({
              key: `key${index}`
            }))}
            columns={columns.map(column => {
              return {
                ...column,
                render: function renderPlaceholder() {
                  return <Skeleton width='30%' height='10px' />
                }
              }
            })}
          />

        </SkeletonTheme>
      </>
    )
  }
  else {
    return (
      <>
        <Table
          components={vt}
          columns={columns}
          dataSource={concatUser}
          pagination={false}
          loading={requesting}
          scroll={{
            scrollToFirstRowOnChange: false,
            y: 600,
            x: 1000
          }}
        />
      </>
    )
  }

}

const mapStateToProps = state => ({
  pushInfo: state.pushNotifications.pushInfo,
  requesting: state.pushNotifications.requesting,
  error: state.pushNotifications.error,
  currentPage: state.adminUsers.currentPage,
  pushConcat: state.pushNotifications.pushConcat
})

const mapDispatchToProps = dispatch => ({
  getUserPushNotification: (data, page, type) =>
    dispatch(getAdminPushNotification(data, page, type)),
  putRequestActive: (data, id) => dispatch(putRequestActive(data, id)),
  deleteNotification: data => dispatch(deleteNotification(data)),
  concatNotification: data => dispatch(concatNotification(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(NotificationsTable)
