import {
    GET_ADMIN_PUSH_NOTIFICATION,
    GET_ADMIN_PUSH_NOTIFICATION_SUCCESS,
    GET_ADMIN_PUSH_NOTIFICATION_FAILURE,
    POST_USER_PUSH_NOTIFICATION,
    POST_USER_PUSH_NOTIFICATION_SUCCESS,
    POST_USER_PUSH_NOTIFICATION_FAILURE,
    GET_ADMIN_GROUP,
    GET_ADMIN_GROUP_SUCCESS,
    GET_ADMIN_GROUP_FAILURE,
    POST_ADMIN_GROUP,
    POST_ADMIN_GROUP_SUCCESS,
    POST_ADMIN_GROUP_FAILURE,
    PUT_FOR_ACTIVE,
    PUT_FOR_ACTIVE_SUCCESS,
    PUT_FOR_ACTIVE_FAILURE,
    DELETE_ADMIN_NOTIFICATION,
    DELETE_ADMIN_NOTIFICATION_SUCCESS,
    DELETE_ADMIN_NOTIFICATION_FAILURE,
    CONCAT_ADMIN_NOTIFICATION
} from "./type";

export const getAdminPushNotification = (data, page) => ({
    type: GET_ADMIN_PUSH_NOTIFICATION,
    data,
    page
});

export const getAdminPushNotificationSuccess = (data) => ({
    type: GET_ADMIN_PUSH_NOTIFICATION_SUCCESS,
    data
});

export const getAdminPushNotificationFailure = (data) => ({
    type: GET_ADMIN_PUSH_NOTIFICATION_FAILURE,
    data
});
// POST USER
export const postUserPushNotification = (data, setShowModal, setModalShow, nType) => ({
    type: POST_USER_PUSH_NOTIFICATION,
    data,
    setShowModal,
    setModalShow,
    nType
});

export const postUserPushNotificationSuccess = (data) => ({
    type: POST_USER_PUSH_NOTIFICATION_SUCCESS,
    data
});

export const postUserPushNotificationFailure = (data) => ({
    type: POST_USER_PUSH_NOTIFICATION_FAILURE,
    data
});
// GET GROUP
export const getAdminGroup = (data) => ({
    type: GET_ADMIN_GROUP,
    data
});

export const getAdminGroupSuccess = (data) => ({
    type: GET_ADMIN_GROUP_SUCCESS,
    data
});

export const getAdminGroupFailure = (data) => ({
    type: GET_ADMIN_GROUP_FAILURE,
    data
});
// POST GROUP
export const postAdminGroup = (data, setShowModal) => ({
    type: POST_ADMIN_GROUP,
    data,
    setShowModal
});

export const postAdminGroupSuccess = (data) => ({
    type: POST_ADMIN_GROUP_SUCCESS,
    data
});

export const postAdminGroupFailure = (data) => ({
    type: POST_ADMIN_GROUP_FAILURE,
    data
});
// PUT REAQUEST FOR ACTIVE INACTIVE
export const putRequestActive = (data,id) => ({
    type: PUT_FOR_ACTIVE,
    data,
    id
});

export const putRequestActiveSuccess = (data) => ({
    type: PUT_FOR_ACTIVE_SUCCESS,
    data
});

export const putRequestActiveFailure = (data) => ({
    type: PUT_FOR_ACTIVE_FAILURE,
    data
});
// DELETE
export const deleteNotification = (data) => ({
    type: DELETE_ADMIN_NOTIFICATION,
    data
});

export const deleteNotificationSuccess = () => ({
    type: DELETE_ADMIN_NOTIFICATION_SUCCESS
});

export const deleteNotificationFailure = () => ({
    type: DELETE_ADMIN_NOTIFICATION_FAILURE
});

export const concatNotification = (data) => ({
    type: CONCAT_ADMIN_NOTIFICATION,
    data
});
