import {
  GET_CARD_DATA,
  GET_CARD_DATA_SUCCESS,
  GET_CARD_DATA_FAILURE,
  GET_ACTIVE_CARD_DATA,
  GET_ACTIVE_CARD_DATA_SUCCESS,
  GET_ACTIVE_CARD_DATA_FAILURE,
  POST_CARD_DATA,
  POST_CARD_DATA_SUCCESS,
  POST_CARD_DATA_FAILURE,
  PATCH_ACTIVE_CARD_DATA,
  PATCH_ACTIVE_CARD_DATA_SUCCESS,
  PATCH_ACTIVE_CARD_DATA_FAILURE,
  DELETE_CARD_DATA,
  DELETE_CARD_DATA_SUCCESS,
  DELETE_CARD_DATA_FAILURE,
  POST_NEW_CARD_DATA,
  POST_NEW_CARD_DATA_SUCCESS,
  POST_NEW_CARD_DATA_FAILURE
} from "./type"

// Card
export const getCardRequest = () => ({
  type: GET_CARD_DATA
})

export const getCardRequestSuccess = data => ({
  type: GET_CARD_DATA_SUCCESS,
  data
})

export const getCardRequestFailure = data => ({
  type: GET_CARD_DATA_FAILURE,
  data
})
// Active Card
export const getActiveCardRequest = () => ({
  type: GET_ACTIVE_CARD_DATA
})

export const getActiveCardSuccess = data => ({
  type: GET_ACTIVE_CARD_DATA_SUCCESS,
  data
})

export const getActiveCardFailure = data => ({
  type: GET_ACTIVE_CARD_DATA_FAILURE,
  data
})
// POST
export const postCardRequest = (data, setModalShow) => ({
  type: POST_CARD_DATA,
  data,
  setModalShow
})

export const postCardRequestSuccess = data => ({
  type: POST_CARD_DATA_SUCCESS,
  data
})

export const postCardRequestFailure = data => ({
  type: POST_CARD_DATA_FAILURE,
  data
})
// PATCH
export const patchActiveCardRequest = data => ({
  type: PATCH_ACTIVE_CARD_DATA,
  data
})

export const patchActiveCardRequestSuccess = data => ({
  type: PATCH_ACTIVE_CARD_DATA_SUCCESS,
  data
})

export const patchActiveCardRequestFailure = data => ({
  type: PATCH_ACTIVE_CARD_DATA_FAILURE,
  data
})
// DELETE
export const deleteCardRequest = data => ({
  type: DELETE_CARD_DATA,
  data
})

export const deleteCardRequestSuccess = data => ({
  type: DELETE_CARD_DATA_SUCCESS,
  data
})

export const deleteCardRequestFailure = data => ({
  type: DELETE_CARD_DATA_FAILURE,
  data
})

//  NEW CARD POST
export const postNewCardRequest = (data, setModalShow) => ({
  type: POST_NEW_CARD_DATA,
  data,
  setModalShow
})

export const postNewCardRequestSuccess = data => ({
  type: POST_NEW_CARD_DATA_SUCCESS,
  data
})

export const postNewCardRequestFailure = data => ({
  type: POST_NEW_CARD_DATA_FAILURE,
  data
})
