import React, { useEffect } from "react"
import Button from "../../../components/Button"
import HomeLayout from "../../../layout/HomeLayout"
import greaterIcon from "../../../assets/images/greater-blue-icon.svg"
import { useHistory } from "react-router-dom"
import "./style.scss"
import { connect } from "react-redux"
import { getBookData } from "../ReaderHome/redux/action"
import moment from "moment"
import { Spinner } from "reactstrap"
import { saveChapterID, setNextChapterID } from "../../AuthScreens/Login/redux/action"
import {
  updateAdventureLog,
  patchFavoriteNovel
} from "../Favorites/redux/action"
import { toast } from "react-hot-toast"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"

const ReadingExperience = props => {
  const {
    saveChapterID,
    updateAdventureLog,
    patchFavoriteNovel,
    getBookData,
    bookID,
    bookData,
    bookLoading,
    setNextChapterID,
    loginInfo
  } = props

  const history = useHistory()

  useEffect(() => {
    bookID && getBookData(bookID)
    setNextChapterID(false)
  }, [bookID])

  const setChapterID = (id, index, unlock, nextChapter) => {
    if (unlock && id) {
      saveChapterID(id)
      setNextChapterID(nextChapter)
      history.push({
        pathname: `/reader/chapter/${id}`
      })
    } else if (index == 0 && id) {
      saveChapterID(id)
      setNextChapterID(nextChapter)
      history.push({
        pathname: `/reader/chapter/${id}`
      })
    } else {
      toast.error("Please award previous chapter to unlock next chapter", {
        id: "card"
      })
    }
  }

  const updateLog = () => {
    if (loginInfo.user.mode == "reader") {
      const data = {
        character: [bookData?.character[0]?.id]
      }
      patchFavoriteNovel(data, "", bookID)
    } else {
      const data = {
        novels: [bookData?.character[0]?.novel?.id],
        authors: [bookData?.character[0]?.novel?.author?.id]
      }
      updateAdventureLog(data, bookID)
    }
  }
  return (
    <HomeLayout
      headerName={
        bookLoading ? (
          <Skeleton
            highlightColor="#ddd"
            borderRadius={30}
            height={30}
            width={150}
          />
        ) : (
          bookData?.novel
        )
      }
      showBack={true}
      showFavorite={true}
      showTitle={bookData?.bookData?.novel}
      addFav={bookData?.favorite}
      favOnClick={updateLog}
    >
      {bookLoading ? (
        <div className="spinner-style">
          <Spinner color="primary" />
        </div>
      ) : (
        <section className="reading-section">
          <div className="container-fluid reading-section-container">
          <div className="reading-card col-md-12">
                <h3>Opening Scene:</h3>
                <p>{bookData?.opening_scene || ""}</p>
              </div>
            {bookData?.character?.map(item => (
              <div className="reading-card col-md-12" key={item.id}>
                <div className="reading-card-header">
                  <div className="character-title">
                    <div className="title-text" title={item.name}>
                      {item.character_no} {item.name}
                    </div>
                    <div className="author">{`${
                      item?.novel?.author?.name || ""
                    } ${item?.writer?.name ? "& Written by " + item?.writer?.name : ""}`}</div>
                  </div>
                  <div className="button-wrapper">
                   {item?.chapter.length > 0 && <Button
                      title="Read from Character Perspective"
                      onClick={() => setChapterID(item?.chapter[0]?.id, 0)}
                    />}
                  </div>
                </div>
                <div className="row reading-description-row">
                  <div className="col-md-6 reading-description">
                    <div className="title">Personality Traits</div>
                    <div className="text-description">{item?.personality}</div>
                  </div>
                  <div className="col-md-6 reading-description">
                    <div className="title">About Character</div>
                    <div className="text-description">{item?.about}</div>
                  </div>
                </div>
                <div className="row chapter-details-row">
                  {item?.chapter?.map((val, i) => (
                    <div
                      className="col-xl-3 col-sm-6 chapter-details-wrapper"
                      key={val.id}
                    >
                      <div className="chapter-details">
                        {i == 0 && <h3>Chapters</h3>}
                        <div className="date">
                          {moment(val.created_at).format("DD MMM YY")}
                        </div>
                        <div className="chapter-description">
                          <div
                            className="button read-chapter"
                            onClick={() => setChapterID(val.id, i, val?.Unlock, val?.next_chapter_id)}
                            title={val.name}
                          >
                            {val.name}
                            <img src={greaterIcon} alt="" />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              ))}
          </div>
        </section>
      )}
    </HomeLayout>
  )
}

const mapStateToProps = state => ({
  bookData: state.home.bookData,
  bookLoading: state.home.bookLoading,
  bookError: state.home.bookError,
  bookID: state.login.bookID,
  loginInfo: state.login.loginInfo,
  updateAdvData: state.Favorites.updateAdvData
})

const mapDispatchToProps = dispatch => ({
  getBookData: data => dispatch(getBookData(data)),
  saveChapterID: data => dispatch(saveChapterID(data)),
  updateAdventureLog: (data, bookID) =>
    dispatch(updateAdventureLog(data, bookID)),
  patchFavoriteNovel: (data, memberID, bookID) =>
    dispatch(patchFavoriteNovel(data, memberID, bookID)),
    setNextChapterID: data => dispatch(setNextChapterID(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(ReadingExperience)
