import React, { Component } from 'react'
import Switch from 'react-switch'

const ToggleSwitch = ({ onChange, checked }) => {

    const SwitchToggle = () => {
        return (
            <label className="switch-label">
                <Switch
                    onChange={onChange}
                    checked={checked}
                    checkedIcon={false}
                    uncheckedIcon={false}
                    onColor="#A2A2A2"
                    offColor="#A2A2A2"
                    offHandleColor="#2B292D"
                    onHandleColor="#C0E5E6"
                    activeBoxShadow={null}
                />
            </label>
        );
    };
    return (
        <>
            {<SwitchToggle />}
        </>
    )
}

export default ToggleSwitch