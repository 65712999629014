import React, { useState, useEffect } from "react"
import HomeLayout from "../../../layout/HomeLayout"
import Button from "../../../components/Button"
import liskCheckIcon from "../../../assets/images/list-check.svg"
import {
  getTokenStore,
  cancelTokenStore,
  upgradeTokenStoreSuccess,
  cancelTokenStoreSuccess
} from "./redux/action"
import { getReaderClubData } from "../../../components/JoinClub/components/PriceCard/redux/action"
import "./style.scss"
import { connect } from "react-redux"
import toast from "react-hot-toast"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
import { Modal } from "antd"
import JoinClubModal from "../../../components/JoinClub"
import { loginRequestSuccess } from "../../AuthScreens/Login/redux/action"
import { postPaymentInformationSuccess } from "../../../components/JoinClub/components/Payment/redux/action"

const TokenStore = props => {
  const {
    cancelTokenStore,
    getReaderClubData,
    getTokenStore,
    data,
    upgradeRequesting,
    cancelRequesting,
    requesting,
    planData,
    cancelData,
    cancelTokenStoreSuccess,
    upgradeData,
    loginInfo,
    loginRequestSuccess,
    upgradeTokenStoreSuccess,
    paymentResponse,
    postPaymentInformationSuccess
  } = props
  const [activeId, setActiveId] = useState(null)
  const [filteredList, setFilteredList] = useState([])
  const [input, setInput] = useState("")
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [confirmMessage, setConfirmMessage] = useState({})
  const [modalShow, setModalShow] = useState(false)
  const [checkModal, setCheckModal] = useState(false)
  const [storePlanID, setStorePlanID] = useState({})

  useEffect(() => {
    getReaderClubData()
    getTokenStore()
  }, [])

  useEffect(() => {
    if (upgradeData?.user) {
      loginRequestSuccess({
        user: upgradeData?.user
      })
      getTokenStore()
      setCheckModal(false)
      setModalShow(false)
      upgradeTokenStoreSuccess(false)
    }
  }, [upgradeData?.user])

  useEffect(() => {
    if (paymentResponse?.msg) {
      paymentResponse?.user &&
        loginRequestSuccess({
          user: paymentResponse?.user
        })
      getTokenStore()
      setCheckModal(false)
      setModalShow(false)
      postPaymentInformationSuccess(false)
    }
  }, [paymentResponse.msg])

  useEffect(() => {
    if (cancelData?.user) {
      loginRequestSuccess({
        user: cancelData?.user
      })
      setActiveId(null)
      setIsModalOpen(false)
      cancelTokenStoreSuccess(false)
    }
  }, [cancelData?.user])

  useEffect(() => {
    planData && setFilteredList(planData)
  }, [planData])

  useEffect(() => {
    planData && setFilteredList(planData)
  }, [input == ""])

  useEffect(() => {
    data && setActiveId(data?.active_subscription?.default_price)
  }, [data])

  const cancelToken = () => {
    if (activeId) {
      const data = {
        price_id: activeId
      }
      cancelTokenStore(data)
    }
  }

  const filterBySearch = value => {
    if (value[0] == "") {
      setInput("")
    } else {
      setInput(value.trim())
    }
    const updatedList = planData.filter(item =>
      item.product.name.toLowerCase().includes(input.toLowerCase())
    )
    setFilteredList(updatedList)
  }

  const confirmModal = id => {
    if (!loginInfo?.user?.subscription && !activeId) {
      setCheckModal("PaymentInfo")
      setModalShow(true)
      setStorePlanID(id)
    } else {
      if (activeId == id) {
        setConfirmMessage({
          title: "Deselect Option?",
          message: "Are you want to deselect your subscripion plan?"
        })
        setIsModalOpen(true)
      } else {
        toast.error("Please deselect plan", {
          id: "card fields"
        })
      }
    }
  }
  return (
    <HomeLayout
      headerName={"Good Read Token Store"}
      // searchBarWrapper={true}
      // onChange={filterBySearch}
    >
      <section className="content-section token-store-section">
        <div className="home-card-wrapper token-store-wrapper">
          <div className="container-fluid home-card-container">
            <div className="price-card-section">
              <div className="section-description">
                <p>
                  Welcome to the Good Read Tokens store, where you can purchase tokens that reward good writing and support your favorite writers on our platform. A Story Host recognizes the hard work and dedication that goes into creating great content. That's why we've created a system that rewards writers with tokens for their exceptional writing. These tokens are distributed to the teams who create the writing, allowing our users to easily identify which stories are the most popular on our platform.<br /><br />

                  With Good Read Tokens, you can play a part in supporting your favorite writers and the content they create. By purchasing tokens, you can help boost their visibility and encourage them to continue producing high-quality content for our community.<br /><br />

                  For writers and producers, tokens are a great way to get paid for your amazing work. The more Good Read Tokens you get, the more you earn. For readers, tokens are a way to show your appreciation for great writing and producing.  <br /><br />

                  So why wait? Get your Good Read Tokens today to support your favorite creators on our platform. Thank you for being a part of our community!
                </p>
              </div>
              <div className="container-fluid price-card-container">
                <div className="row">
                  {!filteredList && requesting
                    ? [1, 2, 3, 4].map(item => (
                      <div className={`col-md-6 col-lg-3 price-card-wrapper`}>
                        <div className="price-card">
                          <h3>
                            {
                              <Skeleton
                                highlightColor="#ddd"
                                width={150}
                                height={28}
                                borderRadius={20}
                              />
                            }
                          </h3>
                          <Skeleton
                            highlightColor="#ddd"
                            width={170}
                            height={98}
                            borderRadius={20}
                          />
                          <div className="price">
                            {
                              <Skeleton
                                highlightColor="#ddd"
                                width={120}
                                height={45}
                                borderRadius={20}
                              />
                            }
                          </div>
                          <div className="button-wrapper">
                            <Skeleton
                              highlightColor="#ddd"
                              width={185}
                              height={50}
                              borderRadius={20}
                            />
                          </div>
                          <div className="list-wrapper">
                            <ul>
                              <li>
                                <span className="text">
                                  {
                                    <Skeleton
                                      highlightColor="#ddd"
                                      width={130}
                                      height={42}
                                      borderRadius={20}
                                    />
                                  }
                                </span>
                              </li>
                              <li>
                                <span className="text">
                                  {
                                    <Skeleton
                                      highlightColor="#ddd"
                                      width={130}
                                      height={42}
                                      borderRadius={20}
                                    />
                                  }
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    ))
                    : filteredList &&
                    filteredList?.map((item, index) => (
                      <div
                        className={`col-md-6 col-lg-3 price-card-wrapper ${activeId && activeId == item.id
                            ? "subscribed-card"
                            : ""
                          }`}
                      >
                        {/* subscribed-card */}
                        <div className="price-card">
                          {activeId && activeId == item.id ? (
                            <div className="active-subscription">
                              Current Subscription
                            </div>
                          ) : null}
                          {/* Add this in case of active subscripion */}
                          <h3>{item.product.name}</h3>
                          <img
                            src={item.product.images}
                            className="img-fluid book-img"
                            alt=""
                          />
                          <div className="price">
                            {`$${item.amount_decimal}`}
                            <span className="month">/month</span>
                          </div>
                          <div className="button-wrapper">
                            <Button
                              key={index}
                              onClick={() => confirmModal(item.id)}
                              title={
                                activeId === item.id
                                  ? "Deselect Option"
                                  : "Select Option"
                              }
                              className="transparent-btn"
                            />
                          </div>
                          <div className="list-wrapper">
                            <ul>
                              <li>
                                <span className="icon">
                                  <img src={liskCheckIcon} alt="" />
                                </span>
                                <span className="text">
                                  <span style={{ color: "#6F8BDF" }}>
                                    Follow Story from
                                  </span>
                                  {` ${item.product.metadata.characters_perspective} `}
                                  <br /> Characters Perspective
                                </span>
                              </li>
                              <li>
                                <span className="icon">
                                  <img src={liskCheckIcon} alt="" />
                                </span>
                                <span className="text">
                                  <span style={{ color: "#6F8BDF" }}>
                                    Receive
                                  </span>
                                  {` ${item.product.metadata.good_read_tokens} `}
                                  Good Read <br /> Tokens
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal
        title={confirmMessage.title}
        confirmLoading={cancelRequesting || upgradeRequesting}
        open={isModalOpen}
        closeIcon={true}
        onOk={cancelToken}
        centered={true}
        onCancel={() => setIsModalOpen(false)}
      >
        <p>{confirmMessage.message}</p>
      </Modal>
      <JoinClubModal
        modalShow={modalShow}
        setModalShow={setModalShow}
        addCard={checkModal}
        storePlanID={storePlanID}
      />
    </HomeLayout>
  )
}

const mapStateToProps = state => ({
  paymentResponse: state.paymentInfo.paymentResponse,
  data: state.tokenStore.data,
  requesting: state.tokenStore.requesting,
  cancelRequesting: state.tokenStore.cancelRequesting,
  upgradeData: state.tokenStore.upgradeData,
  upgradeRequesting: state.tokenStore.upgradeRequesting,
  error: state.tokenStore.error,
  planData: state.priceCard.readerData.plans,
  cancelData: state.tokenStore.cancelData,
  loginInfo: state.login.loginInfo
})

const mapDispatchToProps = dispatch => ({
  getTokenStore: data => dispatch(getTokenStore(data)),
  getReaderClubData: data => dispatch(getReaderClubData(data)),
  cancelTokenStore: data => dispatch(cancelTokenStore(data)),
  cancelTokenStoreSuccess: data => dispatch(cancelTokenStoreSuccess(data)),
  loginRequestSuccess: data => dispatch(loginRequestSuccess(data)),
  upgradeTokenStoreSuccess: data => dispatch(upgradeTokenStoreSuccess(data)),
  postPaymentInformationSuccess: data =>
    dispatch(postPaymentInformationSuccess(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(TokenStore)
