import React, { useState } from "react"
import Button from "../Button"
import downloadIcon from "../../assets/images/download-blue.svg"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
import "./style.scss"
import { connect } from "react-redux"
import { setApplicationData } from "../../pages/AuthScreens/Login/redux/action"
import { BASE_URL } from "../../config/app"

const ApplicaticationCard = props => {
  const {
    id,
    name,
    about,
    item,
    patchRequest,
    // usertype,
    patchRequesting,
    writerName,
    isLoader
  } = props

  const [checkID, setCheckID] = useState(0)
  const [checkStatus, setCheckStatus] = useState("")

  const capitalize = str => str[0].toUpperCase() + str.slice(1)

  const onSubmit = status => {
    setCheckID(id)
    setCheckStatus(status)
    patchRequest(id, status)
  }

  const downloadFile = id => {
    if (id) {
      window.open(`${BASE_URL}api/v1/download_pdf/${id}/`)
    }
  }

  return (
    <div className="col-md-6 application-card-wrapper">
      <div className="application-card-details">
        <div className="application-card-head">
          <div className="writer-details">
            <div className="info">
              {isLoader ? (
                <Skeleton highlightColor="#ddd" width={200} borderRadius={5} />
              ) : (
                <>
                  <p>{writerName}</p>
                  {/* <p>{usertype?.length ? capitalize(`${usertype}`) : "Role"}</p> */}
                </>
              )}
            </div>
            <h3>
              {isLoader ? (
                <Skeleton
                  highlightColor="#ddd"
                  width={100}
                  height={10}
                  borderRadius={5}
                />
              ) : (
                name
              )}
            </h3>
          </div>
          {/* <div className="button-wrapper">
            {isLoader ? (
              <Skeleton
                highlightColor="#ddd"
                width={200}
                height={60}
                borderRadius={10}
              />
            ) : (
              <Button
                title="Demo Work"
                className="transparent-btn"
                showIcon={true}
                src={downloadIcon}
                onClick={() => downloadFile(id)}
              />
            )}
          </div> */}
        </div>
        <div className="application-card-body">
          <h3>{item?.title || ""}</h3>
          <p>
            {isLoader ? (
              <>
                <Skeleton highlightColor="#ddd" width={300} height={10} />
                <Skeleton highlightColor="#ddd" width={300} height={10} />
                <Skeleton highlightColor="#ddd" width={300} height={10} />
              </>
            ) : (
              about
            )}
          </p>
        </div>
        <div className="application-card-footer">
          <div className="button-wrapper">
            {isLoader ? (
              <Skeleton
                highlightColor="#ddd"
                width={200}
                height={60}
                borderRadius={10}
              />
            ) : (
              item?.status !== "saved" && (
                <Button
                  title="Save Candidate"
                  onClick={() => onSubmit("saved")}
                  showSpinner={
                    checkID == id && checkStatus == "saved" && patchRequesting
                  }
                />
              )
            )}
            {isLoader ? (
              <Skeleton
                highlightColor="#ddd"
                width={200}
                height={60}
                borderRadius={10}
              />
            ) : (
              <Button
                title="Accept"
                className="orange-btn" // ms-0 add this class in case of saved card
                onClick={() => onSubmit("accepted")}
                showSpinner={
                  checkID == id && checkStatus == "accepted" && patchRequesting
                }
              />
            )}
            {isLoader ? (
              <Skeleton
                highlightColor="#ddd"
                width={200}
                height={60}
                borderRadius={10}
              />
            ) : (
              <Button
                title="Reject"
                className="black-btn"
                onClick={() => onSubmit("rejected")}
                showSpinner={
                  checkID == id && checkStatus == "rejected" && patchRequesting
                }
              />
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

const mapDispatchToProps = dispatch => ({
  setApplicationData: data => dispatch(setApplicationData(data))
})

export default connect(null, mapDispatchToProps)(ApplicaticationCard)
