// APPLY FOR POSITION
export const APPLY_FOR_POSITION = "APPLY_FOR_POSITION"
export const APPLY_FOR_POSITION_SUCCESS = "APPLY_FOR_POSITION_SUCCESS"
export const APPLY_FOR_POSITION_FAILURE = "APPLY_FOR_POSITION_FAILURE"
// 
export const SET_OPEN_ADVERT_INDEX = "SET_OPEN_ADVERT_INDEX";
// GET WRITER ADVERTS
export const GET_WRITER_ADVERTS = "GET_WRITER_ADVERTS"
export const GET_WRITER_ADVERTS_SUCCESS = "GET_WRITER_ADVERTS_SUCCESS"
export const GET_WRITER_ADVERTS_FAILURE = "GET_WRITER_ADVERTS_FAILURE"
// GET WRITER Current Applications
export const GET_WRITER_CURRENT = "GET_WRITER_CURRENT"
export const GET_WRITER_CURRENT_SUCCESS = "GET_WRITER_CURRENT_SUCCESS"
export const GET_WRITER_CURRENT_FAILURE = "GET_WRITER_CURRENT_FAILURE"
// Available Workrooms
export const GET_WRITER_WORKROOMS = "GET_WRITER_WORKROOMS"
export const GET_WRITER_WORKROOMS_SUCCESS = "GET_WRITER_WORKROOMS_SUCCESS"
export const GET_WRITER_WORKROOMS_FAILURE = "GET_WRITER_WORKROOMS_FAILURE"