import React, { useEffect } from "react"
import Input from "../../../../Input"
import personalInfoImg from "../../../../../assets/images/personal-info-img.svg"
import inputMailIcon from "../../../../../assets/images/input-mail-icon.svg"
import inputProfileIcon from "../../../../../assets/images/input-profile-icon.svg"
import flagIcon from "../../../../../assets/images/flag-black.svg"
import flagIconActive from "../../../../../assets/images/flag-blue.svg"
import Button from "../../../../Button"
import BoardSelect from "../../../../BoardSelect"
import useForm from "../../../../../utils/useForm"
import validator from "../../../../../utils/validation"
import { Toaster } from "react-hot-toast"
import { connect } from "react-redux"
import {
  addAdminUser,
  updateAdminUser,
  addAdminUserFailure
} from "../../../../../pages/AdminScreens/Users/redux/action"
import BoardPhoneInput from "../../../../PhoneInput"

const AddUserModal = props => {
  const {
    setModalShow,
    setShow,
    updateData,
    setUpdateData,
    postAdminUser,
    addAdminUserFailure,
    updateAdminUser,
    addUserInfo,
    addUserRequesting,
    addUserError,
    updateError,
    update
  } = props

  const onCloseModal = () => {
    setModalShow(false)
    setShow(false)
    setUpdateData({})
    addAdminUserFailure(false)
  }

  const onSubmit = () => {
    addAdminUserFailure(false)
    if (update) {
      const data = {
        email: state.email.value,
        name: state.fullName.value,
        user_type: state.role.value,
        reffering_member: state.memberId.value,
        phone_number: `+${state.phoneNumber.value}`,
      }
      updateAdminUser(data, updateData?.id, setModalShow, setShow, setUpdateData)
    } else {
      const payload = {
        email: state.email.value,
        name: state.fullName.value,
        user_type: state.role.value,
        flags: state.flag.value,
        reffering_member: state.memberId.value,
        phone_number: `+${state.phoneNumber.value}`,
      }
      postAdminUser(payload, setModalShow)
    }
  }

  const stateSchema = {
    email: {
      value: "",
      error: ""
    },
    fullName: {
      value: "",
      error: ""
    },
    role: {
      value: "",
      error: ""
    },
    flag: {
      value: false,
      error: ""
    },
    memberId: {
      value: "",
      error: ""
    },
    phoneNumber: {
      value: "",
      error: ""
    },
  }

  const validationStateSchema = {
    email: {
      required: false,
    },
    fullName: {
      required: false // validator: validator.userName
    },
    role: {
      required: false
    },
    flag: {
      required: true // validator: validator.
    },
    memberId: {
      required: false
    },
    phoneNumber: {
      required: true
    },
  }
  const { state, setState, handleOnChange, disable } = useForm(
    stateSchema,
    validationStateSchema
  )

  const options = [
    {
      value: "reader",
      label: "Reader"
    },
    {
      value: "writer",
      label: "Writer"
    },
    {
      value: "editor",
      label: "Editor"
    },
    {
      value: "producer",
      label: "Producer"
    }
  ]

  useEffect(() => {
    updateData &&
      handleOnChange(
        "email",
        updateData?.email ? updateData?.email : ''
      )
    updateData &&
      handleOnChange(
        "fullName",
        updateData?.name ? updateData?.name : ""
      )
    updateData &&
      handleOnChange(
        "role",
        updateData?.user_type ? updateData?.user_type : ""
      )
    updateData &&
      handleOnChange(
        "memberId",
        updateData?.member_id ? updateData?.member_id : ""
      )
    updateData &&
      handleOnChange(
        "phoneNumber",
        updateData?.phone_number ? updateData?.phone_number : ""
      )
  }, [updateData])
  const handleFormatChanger = (value) => {
    handleOnChange("phoneNumber", value)
  }
  return (
    <section className="add-user-section">
      <div className="add-user-header">
        <img src={personalInfoImg} alt="" />
        <h3>{update ? "Edit User" : "Add User"}</h3>
      </div>
      <div className="add-user-input-wrapper">
        <Input
          type="email"
          placeholder="Enter Email Address"
          showIcon={true}
          src={inputMailIcon}
          value={state.email.value}
          onChange={val => handleOnChange("email", val.target.value)}
          onFocus={() => addAdminUserFailure(false)}
          showError={true}
          errorMessage={update? (updateError.email && updateError?.email[0] === "user with this email already exists." ? 'Same Email Already Exits.' : updateError.email):(addUserError.email && addUserError?.email[0] === "user with this email already exists." ? 'Same Email Already Exits.' : addUserError.email)}
        />
        <Input
          type="text"
          placeholder="Enter Full Name"
          showIcon={true}
          src={inputProfileIcon}
          value={state.fullName.value}
          onChange={val => handleOnChange("fullName", val.target.value)}
          showError={true}
          errorMessage={addUserError.name}
          onFocus={() => addAdminUserFailure(false)}
        />
        <BoardSelect
          onSelect={(val) => handleOnChange('role', val)}
          options={options}
          placeholder={"Select Role"}
          onFocus={() => addAdminUserFailure(false)}
          defaultValue={state.updateData?.user_type ? 'Select Role' : updateData?.user_type}
        />
        <label className={`error-message ${addUserError.user_type ? 'opacity-1' : ''}`} >{addUserError.user_type && addUserError.user_type[0]} </label>
        <Input
          type="text"
          placeholder="Referring Member ID (Optional)"
          showIcon={true}
          src={inputProfileIcon}
          value={state.memberId.value}
          onChange={val => handleOnChange("memberId", val.target.value)}
          showError={true}
          errorMessage={addUserError.memberId}
          onFocus={() => addAdminUserFailure(false)}
        />
        <BoardPhoneInput
          placeholder={"+1 234 567-2345"}
          value={state.phoneNumber.value}
          onChange={handleFormatChanger}
          showError={true}
          errorMessage={update? (updateError.phone_number && updateError.phone_number[0] || updateError.phoneNumber):(addUserError.phone_number && addUserError.phone_number[0] || addUserError.phoneNumber)}
          width="100%"
        />
      </div>
      {!update && (
        <div className="checkbox-wrapper">
          <input
            onChange={val => handleOnChange("flag", val.target.checked)}
            className="styled-checkbox"
            id="styled-checkbox-1"
            type="checkbox"
            checked={state.flag.value}
            value="value1"
          />
          <label htmlFor="styled-checkbox-1">
            <img src={state.flag.value ? flagIconActive : flagIcon} alt="" />
            <span>Flag User</span>
          </label>
        </div>
      )}
      <div className={`button-wrapper ${update && 'mt-4'}`}>
        <Button
          title="Cancel"
          onClick={onCloseModal}
          className="transparent-btn"
        />
        <Button
          title={update ? "Edit User" : "Add User"}
          disabled={addUserRequesting}
          showSpinner={addUserRequesting}
          onClick={onSubmit}
        />
      </div>
    </section>
  )
}

const mapStateToProps = state => ({
  addUserInfo: state.adminUsers.addUserInfo,
  addUserRequesting: state.adminUsers.addUserRequesting,
  addUserError: state.adminUsers.addUserError,
  updateError: state.adminUsers.updateError,
})

const mapDispatchToProps = dispatch => ({
  postAdminUser: (data, setModalShow) => dispatch(addAdminUser(data, setModalShow)),
  addAdminUserFailure: data => dispatch(addAdminUserFailure(data)),
  updateAdminUser: (data, id, setModalShow, setShow, setUpdateData) => dispatch(updateAdminUser(data, id, setModalShow, setShow, setUpdateData))
})

export default connect(mapStateToProps, mapDispatchToProps)(AddUserModal)
