import {
  GET_CURRENT_NOVELS,
  GET_CURRENT_NOVELS_FAILURE,
  GET_CURRENT_NOVELS_SUCCESS,
  SET_CURRENT_NOVEL_INDEX
} from "./types"

const initialState = {
  data: false,
  requesting: false,
  error: false,

  novelIndex: 0
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CURRENT_NOVELS:
      return {
        ...state,
        requesting: true
      }
    case GET_CURRENT_NOVELS_SUCCESS:
      return {
        ...state,
        requesting: false,
        data: action.data
      }
    case GET_CURRENT_NOVELS_FAILURE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }
      case SET_CURRENT_NOVEL_INDEX:
      return {
        ...state,
        novelIndex: action.data
      }
    default:
      return state
  }
}
