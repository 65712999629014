import React from 'react';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import './style.scss';

const ImageCard = ({
    src = '',
    key,
    onClick,
    hoverName,
    isLoader
}) => {
    return (
        <div className="slider-card" key={key} onClick={onClick}>
            <div className={isLoader ? "" : "hover-data"}>
                <p>{hoverName}</p>
            </div>
            {isLoader ? <Skeleton height={310} width={212} borderRadius={20} /> : <img src={src} alt="image" />}
        </div>
    )
}

export default ImageCard