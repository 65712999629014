import React, { useState } from 'react';
import Button from '../Button';
import TimeRangePicker from '@wojtekmaj/react-timerange-picker';


import './style.scss';


const BoardTimePicker = ({
    onChange,
    value,
    onClick
}) => {

    return (

        <div className="board-time-picker">
            <TimeRangePicker
                format="h:m a"
                clearIcon={null}
                clockIcon={false}
                minutePlaceholder="00"
                hourPlaceholder="12"
                rangeDivider="To"
                disableClock={true}
                onChange={onChange}
                value={value}
            />
            <div className="button-wrapper">
                <Button
                    title='Submit'
                    onClick={onClick}
                />
            </div>
        </div>
    )
}
export default BoardTimePicker;