import {all, call, put, takeLatest} from "redux-saga/effects";
import {BASE_URL} from "../../../../config/app";
import XHR from "../../../../utils/XHR";
import {GET_ADMIN_HOME, GET_ADMIN_HOME_BOX} from "./type";
import {
    getAdminHomeSuccess,
    getAdminHomeFailure,
    getAdminHomeBoxSuccess,
    getAdminHomeBoxFailure
} from "./action";

async function getAdminHomeAPI(data) {
    const URL = `${BASE_URL}api/v1/admin_home/`;
    const token = localStorage.getItem('token')
    const options = {
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `Token ${token}`,
        }
    }
    return XHR(URL, options)
}

async function getAdminHomeBoxAPI(data) {
    const URL = `${BASE_URL}api/v1/admin_home_log/`;
    const token = localStorage.getItem('token')
    const options = {
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `Token ${token}`,
        }
    }
    return XHR(URL, options)
}

function* getAdminHomeG({data}) {
    try {
        const response = yield call(getAdminHomeAPI,data);
        yield put(getAdminHomeSuccess(response.data))
    } catch (e) {
        const {response} = e
        yield put(getAdminHomeFailure(response?.data))
    }
}

function* getAdminHomeBox({data}) {
    try {
        const response = yield call(getAdminHomeBoxAPI,data);
        yield put(getAdminHomeBoxSuccess(response.data))
    } catch (e) {
        const {response} = e
        yield put(getAdminHomeBoxFailure(response?.data))
    }
}

export default all([
    takeLatest(GET_ADMIN_HOME, getAdminHomeG),
    takeLatest(GET_ADMIN_HOME_BOX, getAdminHomeBox)
])