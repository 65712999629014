import {
GET_EDIT_ADVERTS,
GET_EDIT_ADVERTS_SUCCESS,
GET_EDIT_ADVERTS_FAILURE,
GET_EDIT_CURRENT,
GET_EDIT_CURRENT_SUCCESS,
GET_EDIT_CURRENT_FAILURE,
GET_EDIT_SAVED,
GET_EDIT_SAVED_SUCCESS,
GET_EDIT_SAVED_FAILURE,
GET_EDIT_WORKROOMS,
GET_EDIT_WORKROOMS_SUCCESS,
GET_EDIT_WORKROOMS_FAILURE,
} from "./type"

const initialState = {
  editorAdverts: false,
  editorAdvertsLoad: false,
  editorAdvertsError: false,

  editorCurrent: false,
  editorCurrentLoad: false,
  editorCurrentError: false,

  editorSave: false,
  editorSaveLoad: false,
  editorSaveError: false,

  editorWorkroom: false,
  editorWorkroomLoad: false,
  eeditorWorkroomError: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_EDIT_ADVERTS:
      return {
        ...state,
        editorAdvertsLoad: true
      }
    case GET_EDIT_ADVERTS_SUCCESS:
      return {
        ...state,
        editorAdvertsLoad: false,
        editorAdverts: action.data
      }
    case GET_EDIT_ADVERTS_FAILURE:
      return {
        ...state,
        editorAdvertsLoad: false,
        editorAdvertsError: action.data
      }
      case GET_EDIT_CURRENT:
      return {
        ...state,
        editorCurrentLoad: true
      }
    case GET_EDIT_CURRENT_SUCCESS:
      return {
        ...state,
        editorCurrentLoad: false,
        editorCurrent: action.data
      }
    case GET_EDIT_CURRENT_FAILURE:
      return {
        ...state,
        editorCurrentLoad: false,
        editorCurrentError: action.data
      }
      case GET_EDIT_SAVED:
      return {
        ...state,
        editorSaveLoad: true
      }
    case GET_EDIT_SAVED_SUCCESS:
      return {
        ...state,
        editorSaveLoad: false,
        editorSave: action.data
      }
    case GET_EDIT_SAVED_FAILURE:
      return {
        ...state,
        editorSaveLoad: false,
        editorSaveError: action.data
      }
      case GET_EDIT_WORKROOMS:
      return {
        ...state,
        editorWorkroomLoad: true
      }
    case GET_EDIT_WORKROOMS_SUCCESS:
      return {
        ...state,
        editorWorkroomLoad: false,
        editorWorkroom: action.data
      }
    case GET_EDIT_WORKROOMS_FAILURE:
      return {
        ...state,
        editorWorkroomLoad: false,
        eeditorWorkroomError: action.data
      }
    default:
      return state
  }
}
