import React, { useEffect, useState } from "react"
import AdminLayout from "../../../layout/AdminLayout"
import "./style.scss"
import Button from "../../../components/Button"
import AdminPushModal from "../../../components/AdminComponents/AdminPushModal"
import NotificationsTable from "../../../components/AdminComponents/NotificationsTable"
import { getAdminPushNotification, putRequestActive, concatNotification } from "./redux/action"
import { connect } from "react-redux"

const PushNotifications = props => {
  const {
    getUserPushNotification,
    concatNotification
  } = props

  const [modalShow, setModalShow] = useState(false)

  const filterOnChange = val => {
    getUserPushNotification(val, 1)
    concatNotification(false)
  }

  return (
    <>
      <AdminLayout
        onChange={val => filterOnChange(val)}
        headerName={"Push Notifications"}
        searchBarWrapper={true}
        className="push-notification-header"
      >
        <div className="content-section admin-notification-section">
          <div className="admin-notification-wrapper">
            <div className="container-fluid admin-notification-container">
              <div className="row admin-notification-row">
                <div className="table-wrapper">
                  <div className="notification-title-wrapper">
                    <div className="title-wrapper">
                      <h3>All</h3>
                    </div>
                    <div className="button-wrapper">
                      <Button
                        title="Create Push Notification"
                        onClick={() => setModalShow(!modalShow)}
                      />
                    </div>
                  </div>
                  <NotificationsTable />
                </div>
              </div>
            </div>
          </div>
        </div>
        <AdminPushModal modalShow={modalShow} setModalShow={setModalShow} />
      </AdminLayout>
    </>
  )
}

const mapStateToProps = state => ({
  pushInfo: state.pushNotifications.pushInfo,
  requesting: state.pushNotifications.requesting,
  error: state.pushNotifications.error,
  currentPage: state.adminUsers.currentPage,
  pushConcat: state.pushNotifications.pushConcat
})

const mapDispatchToProps = dispatch => ({
  getUserPushNotification: (data, page, type) =>
    dispatch(getAdminPushNotification(data, page, type)),
  putRequestActive: (data, id) => dispatch(putRequestActive(data, id)),
  concatNotification: data => dispatch(concatNotification(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(PushNotifications)
