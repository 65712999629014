export const GET_ADMIN_CHAPTERS = 'GET_ADMIN_CHAPTERS';
export const GET_ADMIN_CHAPTERS_SUCCESS = 'GET_ADMIN_CHAPTERS_SUCCESS';
export const GET_ADMIN_CHAPTERS_FAILURE = 'GET_ADMIN_CHAPTERS_FAILURE';
// Delete
export const ADMIN_CHAPTERS_DELETE = 'ADMIN_CHAPTERS_DELETE';
export const ADMIN_CHAPTERS_DELETE_SUCCESS = 'ADMIN_CHAPTERS_DELETE_SUCCESS';
export const ADMIN_CHAPTERS_DELETE_FAILURE = 'ADMIN_CHAPTERS_DELETE_FAILURE';
// FLAGS
export const FLAGS_UPDATE = 'FLAGS_UPDATE';
export const FLAGS_UPDATE_SUCCESS = 'FLAGS_UPDATE_SUCCESS';
export const FLAGS_UPDATE_FAILURE = 'FLAGS_UPDATE_FAILURE';
// ADD CHAPTERS
export const ADD_ADMIN_CHAPTERS = 'ADD_ADMIN_CHAPTERS';
export const ADD_ADMIN_CHAPTERS_SUCCESS = 'ADD_ADMIN_CHAPTERS_SUCCESS';
export const ADD_ADMIN_CHAPTERS_FAILURE = 'ADD_ADMIN_CHAPTERS_FAILURE';
// Edit User
export const UPDATE_ADMIN_CHAPTERS = 'UPDATE_ADMIN_CHAPTERS';
export const UPDATE_ADMIN_CHAPTERS_SUCCESS = 'UPDATE_ADMIN_CHAPTERS_SUCCESS';
export const UPDATE_ADMIN_CHAPTERS_FAILURE = 'UPDATE_ADMIN_CHAPTERS_FAILURE';
// current page
export const SET_CURRENT_PAGE = 'SET_CURRENT_PAGE';
// 
export const SET_CONCAT_CHAPTERS_DATA = 'SET_CONCAT_CHAPTERS_DATA';
