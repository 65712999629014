import {
  CREATE_NEW_NOVEL,
  CREATE_NEW_NOVEL_SUCCESS,
  CREATE_NEW_NOVEL_FAILURE,
  GET_ROLE_TYPE_REQUEST,
  GET_ROLE_TYPE_SUCCESS,
  GET_ROLE_TYPE_FAILURE,
  POST_ROLE_TYPE_REQUEST,
  POST_ROLE_TYPE_SUCCESS,
  POST_ROLE_TYPE_FAILURE,
  DELETE_REQUEST,
  DELETE_SUCCESS,
  DELETE_FAILURE,
  EDIT_CHARACTER_REQUEST,
  EDIT_CHARACTER_SUCCESS,
  EDIT_CHARACTER_FAILURE,
  GET_CHARACTER_REQUEST,
  GET_CHARACTER_REQUEST_SUCCESS,
  GET_CHARACTER_REQUEST_FAILURE,
  GET_EDITOR_TYPE_SUCCESS,
  DELETE_NOVEL_REQUEST,
  DELETE_NOVEL_SUCCESS,
  DELETE_NOVEL_FAILURE
} from "./type"

export const createNewNovel = data => ({
  type: CREATE_NEW_NOVEL,
  data
})

export const createNewNovelSuccess = data => ({
  type: CREATE_NEW_NOVEL_SUCCESS,
  data
})

export const createNewNovelFailure = data => ({
  type: CREATE_NEW_NOVEL_FAILURE,
  data
})

export const getRoleTypeRequest = data => ({
  type: GET_ROLE_TYPE_REQUEST,
  data
})

export const getRoleTypelSuccess = data => ({
  type: GET_ROLE_TYPE_SUCCESS,
  data
})

export const getEditorSuccess = data => ({
  type: GET_EDITOR_TYPE_SUCCESS,
  data
})

export const getRoleTypeFailure = data => ({
  type: GET_ROLE_TYPE_FAILURE,
  data
})

export const postRoleTypeRequest = data => ({
  type: POST_ROLE_TYPE_REQUEST,
  data
})

export const postRoleTypeSuccess = data => ({
  type: POST_ROLE_TYPE_SUCCESS,
  data
})

export const postRoleTypeFailure = data => ({
  type: POST_ROLE_TYPE_FAILURE,
  data
})

export const deleteRequest = data => ({
  type: DELETE_REQUEST,
  data
})

export const deleteSuccess = data => ({
  type: DELETE_SUCCESS,
  data
})
export const deleteFailure = data => ({
  type: DELETE_FAILURE,
  data
})

export const editCharactersRequest = data => ({
  type: EDIT_CHARACTER_REQUEST,
  data
})

export const editCharactersSuccess = data => ({
  type: EDIT_CHARACTER_SUCCESS,
  data
})
export const editCharactersFailure = data => ({
  type: EDIT_CHARACTER_FAILURE,
  data
})
// CHARACTER LIST
export const getCharacterRequest = data => ({
  type: GET_CHARACTER_REQUEST,
  data
})
export const getCharacterSuccess = data => ({
  type: GET_CHARACTER_REQUEST_SUCCESS,
  data
})
export const getCharacterFailure = data => ({
  type: GET_CHARACTER_REQUEST_FAILURE,
  data
})
export const deleteNovelRequest = data => ({
  type: DELETE_NOVEL_REQUEST,
  data
})

export const deleteNovelSuccess = data => ({
  type: DELETE_NOVEL_SUCCESS,
  data
})
export const deleteNovelFailure = data => ({
  type: DELETE_NOVEL_FAILURE,
  data
})
