import React from "react"
import goldenLike from "../../../../assets/images/like-golden.svg"
import goldenToken from "../../../../assets/images/token-golden.svg"
import closeIcon from "../../../../assets/images/close-icon-modal.svg"
import greaterWhiteIcon from "../../../../assets/images/greater-white.svg"
import { history } from "../../../../reduxStore/store"
import {
  saveChapterID,
  saveMemberID
} from "../../../../pages/AuthScreens/Login/redux/action"
import { connect } from "react-redux"

const NovelDetails = ({
  setModalShow,
  NovelsData,
  loginInfo,
  saveChapterID,
  saveMemberID,
  controlIndex
}) => {
    
  const setBookID = () => {
    if (NovelsData[controlIndex]?.chapter?.id) {
      saveChapterID(NovelsData[controlIndex]?.chapter?.id)
      history.push({
        pathname: `/reader/chapter/${NovelsData[controlIndex]?.chapter?.id}`,
        page_no: NovelsData[controlIndex]?.page_no
      })
    }
  }

  const onSaveData = id => {
    if (id) {
      saveMemberID(id)
      history.push({
        pathname: `/${loginInfo.user.mode}/member_profile/${NovelsData[
          controlIndex
        ]?.novel?.author?.name
          .toLocaleLowerCase()
          .replace(/\s+/g, "_")}`
      })
    }
  }

  return (
    <div className="modal-body-right">
      <div className="modal-right-header">
        <h3>{NovelsData[controlIndex]?.novel?.genre}</h3>
        <div className="novel">{NovelsData[controlIndex]?.novel?.name}</div>
        <div className="author-details"   >
          <a
            className="author"
            onClick={() =>
              onSaveData(NovelsData[controlIndex]?.novel?.author?.id)
            }
          >
            {NovelsData[controlIndex]?.novel?.author?.name}
          </a>
          <a className="writer"  onClick={() =>
              onSaveData(
                NovelsData[controlIndex]?.chapter?.character &&
                NovelsData[controlIndex]?.chapter?.character[0]?.writer?.id) 
            } >
            {(NovelsData[controlIndex]?.chapter?.character &&
              NovelsData[controlIndex]?.chapter?.character[0]?.writer?.name) ||
              ""}
          </a>
        </div>
        <div className="close-icon">
          <img src={closeIcon} alt="" onClick={() => setModalShow(false)} />
        </div>
      </div>
      <div className="modal-right-body">
        <div className="body-text-wrapper">
          <div className="text-wrapper">
            <p>{NovelsData[controlIndex]?.novel?.about_novel}</p>
          </div>
        </div>
        <div className="read-details-wrapper">
          <div className="icon-wrapper">
            <img src={goldenLike} alt="" />
            <span>Likes ({NovelsData[controlIndex]?.novel?.total_likes})</span>
          </div>
          <div className="icon-wrapper">
            <img src={goldenToken} alt="" />
            <span>Good Read Tokens ({NovelsData[controlIndex]?.novel?.grt ? NovelsData[controlIndex]?.novel?.grt : '0'})</span>
          </div>
        </div>
      </div>
      <div className="modal-right-footer" onClick={setBookID}>
        <div className="text">Continue reading where you left off</div>
        <div className="image-wrapper">
          <img src={greaterWhiteIcon} alt="" />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  loginInfo: state.login.loginInfo
})

const mapDispatchToProps = dispatch => ({
  saveChapterID: data => dispatch(saveChapterID(data)),
  saveMemberID: data => dispatch(saveMemberID(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(NovelDetails)
