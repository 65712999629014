import React, { useEffect, useState } from "react"
import UsersTable from "../../../components/AdminComponents/UsersTable"
import AdminLayout from "../../../layout/AdminLayout"
import filterIcon from "../../../assets/images/filter.svg"
import filterActiveIcon from "../../../assets/images/filter-blue.svg"
import "./style.scss"
import Button from "../../../components/Button"
import AdminModal from "../../../components/AdminComponents/AdminModals"
import { getAdminUserData } from "./redux/action"
import { connect } from "react-redux"
import { setConcatUsers } from "./redux/action"

const Users = props => {
  const { getUserRequest, setConcatUsers, requesting } = props

  const [showFilters, setShowFilters] = useState(false)
  const [modalShow, setModalShow] = useState(false)
  const [search, setSearch] = useState("")
  const [param, setParam] = useState("")
  const [show, setShow] = useState(false)
  const [updateData, setUpdateData] = useState({})

  const [filters, setFilters] = useState({
    reader: false,
    writer: false,
    editor: false,
    producer: false
  })

  const handleFilterChange = e => {
    if (filters[e]) {
      setFilters({ [e]: false })
      setParam("")
    } else {
      setParam(e)
      setFilters({
        [e]: true
      })
    }
  }

  useEffect(() => {
    if (param == "") {
      getUserRequest("", "", 1)
      setConcatUsers(false)
    } else {
      param && getUserRequest(param, search, 1)
      setConcatUsers(false)
    }
  }, [param])

  useEffect(() => {
    if (!search) {
      getUserRequest("", "", 1)
      setConcatUsers(false)
    } else {
      search && getUserRequest(param, search, 1)
      setConcatUsers(false)
    }
  }, [search])

  return (
    <>
      <AdminLayout
        onChange={setSearch}
        headerName={"Users"}
        searchBarWrapper={true}
      >
        <>
          <div className="content-section admin-users-section">
            <div className="admin-users-wrapper">
              <div className="container-fluid admin-users-container">
                <div className="row admin-users-row">
                  <div className="table-wrapper">
                    <div className="users-title-wrapper">
                      <div className="title-wrapper">
                        <h3>All Users</h3>
                        <div
                          className={`filter-wrapper ${
                            showFilters ? "active" : ""
                          }`}
                          onClick={() => setShowFilters(!showFilters)}
                        >
                          <img src={filterIcon} className="icon" alt="" />
                          <img
                            src={filterActiveIcon}
                            className="active-icon"
                            alt=""
                          />
                          {showFilters && (
                            <div className="option-items">
                              <div className="title">Filter By Role</div>
                              <div
                                className="item"
                                onClick={e => handleFilterChange("reader")}
                              >
                                <div className="checkbox-wrapper">
                                  <input
                                    className="styled-checkbox"
                                    id="styled-checkbox-1"
                                    name="reader"
                                    checked={filters["reader"]}
                                    type="checkbox"
                                    value="value1"
                                  />
                                  <label htmlFor="styled-checkbox-1">
                                    Reader
                                  </label>
                                </div>
                              </div>
                              <div
                                className="item"
                                onClick={e => handleFilterChange("writer")}
                              >
                                <div className="checkbox-wrapper">
                                  <input
                                    className="styled-checkbox"
                                    id="styled-checkbox-1"
                                    name="writer"
                                    checked={filters["writer"]}
                                    type="checkbox"
                                    value="value1"
                                  />
                                  <label htmlFor="styled-checkbox-1">
                                    Writer
                                  </label>
                                </div>
                              </div>
                              <div
                                className="item"
                                onClick={e => handleFilterChange("editor")}
                              >
                                <div className="checkbox-wrapper">
                                  <input
                                    className="styled-checkbox"
                                    id="styled-checkbox-2"
                                    name="editor"
                                    checked={filters["editor"]}
                                    type="checkbox"
                                    value="value1"
                                  />
                                  <label htmlFor="styled-checkbox-2">
                                    Editor
                                  </label>
                                </div>
                              </div>
                              <div
                                className="item"
                                onClick={e => handleFilterChange("producer")}
                              >
                                <div className="checkbox-wrapper">
                                  <input
                                    className="styled-checkbox"
                                    id="styled-checkbox-3"
                                    name="producer"
                                    checked={filters["producer"]}
                                    type="checkbox"
                                    value="value1"
                                  />
                                  <label htmlFor="styled-checkbox-3">
                                    Producer
                                  </label>
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                      <div className="button-wrapper">
                        <Button
                          title="Add User"
                          onClick={() => setModalShow(true)}
                        />
                      </div>
                    </div>
                    <UsersTable
                      setModal={setModalShow}
                      setUpdateData={setUpdateData}
                      setShow={setShow}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <AdminModal
            update={show}
            updateData={updateData}
            setUpdateData={setUpdateData}
            modalShow={modalShow}
            setShow={setShow}
            setModalShow={setModalShow}
          />
        </>
      </AdminLayout>
    </>
  )
}

const mapStateToProps = state => ({
  userInfo: state.adminUsers.userInfo,
  requesting: state.adminUsers.requesting,
  error: state.adminUsers.error,
  currentPage: state.adminUsers.currentPage,
  concatUsers: state.adminUsers.concatUsers
})

const mapDispatchToProps = dispatch => ({
  getUserRequest: (data, search, page) =>
    dispatch(getAdminUserData(data, search, page)),
  setConcatUsers: data => dispatch(setConcatUsers(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Users)
