import {
  POST_FEEDBACK,
  POST_FEEDBACK_SUCCESS,
  POST_FEEDBACK_FAILURE
} from "./type"

export const postFeedback = (data) => ({
  type: POST_FEEDBACK,
  data
})

export const postFeedbackSuccess = data => ({
  type: POST_FEEDBACK_SUCCESS,
  data
})

export const postFeedbackFailure = data => ({
  type: POST_FEEDBACK_FAILURE,
  data
})
