import {
    WRITER_ADMIN_APPLICATION,
    WRITER_ADMIN_APPLICATION_SUCCESS,
    WRITER_ADMIN_APPLICATION_FAILURE,
    EDITOR_ADMIN_APPLICATION,
    EDITOR_ADMIN_APPLICATION_SUCCESS,
    EDITOR_ADMIN_APPLICATION_FAILURE,
    SAVE_ADMIN_APPLICATION,
    SAVE_ADMIN_APPLICATION_SUCCESS,
    SAVE_ADMIN_APPLICATION_FAILURE,
    PATCH_WRITER_ADMIN_APPLICATION,
    PATCH_WRITER_ADMIN_APPLICATION_SUCCESS,
    PATCH_WRITER_ADMIN_APPLICATION_FAILURE,
    PATCH_EDITOR_ADMIN_APPLICATION,
    PATCH_EDITOR_ADMIN_APPLICATION_SUCCESS,
    PATCH_EDITOR_ADMIN_APPLICATION_FAILURE,
    PATCH_SAVE_ADMIN_APPLICATION,
    PATCH_SAVE_ADMIN_APPLICATION_SUCCESS,
    PATCH_SAVE_ADMIN_APPLICATION_FAILURE
} from "./type"

const initialState = {
    writerAppli: false,
    writerAppliLoad: false,
    writerAppliError: false,

    editorAppli: false,
    editorAppliLoad: false,
    editorAppliError: false,

    saveAppli: false,
    saveAppliLoad: false,
    saveAppliError: false,

    patchWriter: false,
    patchWriterLoad: false,
    patchWriterError: false,

    patchEditor: false,
    patchEditorLoad: false,
    patchEditorError: false,

    patchSave: false,
    patchSaveLoad: false,
    patchSaveError: false,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case WRITER_ADMIN_APPLICATION:
            return {
                ...state,
                writerAppliLoad: true,
            }
        case WRITER_ADMIN_APPLICATION_SUCCESS:
            return {
                ...state,
                writerAppliLoad: false,
                writerAppli: action.data
            }
        case WRITER_ADMIN_APPLICATION_FAILURE:
            return {
                ...state,
                writerAppliLoad: false,
                writerAppliError: action.data
            }
            case EDITOR_ADMIN_APPLICATION:
            return {
                ...state,
                editorAppliLoad: true,
            }
        case EDITOR_ADMIN_APPLICATION_SUCCESS:
            return {
                ...state,
                editorAppliLoad: false,
                editorAppli: action.data
            }
        case EDITOR_ADMIN_APPLICATION_FAILURE:
            return {
                ...state,
                editorAppliLoad: false,
                editorAppliError: action.data
            }
            case SAVE_ADMIN_APPLICATION:
            return {
                ...state,
                saveAppliLoad: true,
            }
        case SAVE_ADMIN_APPLICATION_SUCCESS:
            return {
                ...state,
                saveAppliLoad: false,
                saveAppli: action.data
            }
        case SAVE_ADMIN_APPLICATION_FAILURE:
            return {
                ...state,
                saveAppliLoad: false,
                saveAppliError: action.data
            }
        case PATCH_WRITER_ADMIN_APPLICATION:
            return {
                ...state,
                patchWriterLoad: true,
            }
        case PATCH_WRITER_ADMIN_APPLICATION_SUCCESS:
            return {
                ...state,
                patchWriterLoad: false,
                patchWriter: action.data,
            }
        case PATCH_WRITER_ADMIN_APPLICATION_FAILURE:
            return {
                ...state,
                patchWriterLoad: false,
                patchWriterError: action.data,
            }
            case PATCH_EDITOR_ADMIN_APPLICATION:
            return {
                ...state,
                patchEditorLoad: true,
            }
        case PATCH_EDITOR_ADMIN_APPLICATION_SUCCESS:
            return {
                ...state,
                patchEditorLoad: false,
                patchEditor: action.data,
            }
        case PATCH_EDITOR_ADMIN_APPLICATION_FAILURE:
            return {
                ...state,
                patchEditorLoad: false,
                patchEditorError: action.data,
            }
            case PATCH_SAVE_ADMIN_APPLICATION:
            return {
                ...state,
                patchSaveLoad: true,
            }
        case PATCH_SAVE_ADMIN_APPLICATION_SUCCESS:
            return {
                ...state,
                patchSaveLoad: false,
                patchSave: action.data,
            }
        case PATCH_SAVE_ADMIN_APPLICATION_FAILURE:
            return {
                ...state,
                patchSaveLoad: false,
                patchSaveError: action.data,
            }
        default:
            return state
    }
}
