import { all, call, put, takeLatest } from "redux-saga/effects"
import { BASE_URL } from "../../../../config/app"
import XHR from "../../../../utils/XHR"
import {
  postBecomeWriterFailure,
  postBecomeWriterSuccess,
  getBecomeWriterDataSuccess,
  getBecomeWriterDataFailure,
  getBecomeNovelSuccess,
  getBecomeNovelFailure
} from "./action"
import {
  POST_BECOME_WRITER,
  GET_BECOME_WRITER_DATA,
  GET_BECOME_NOVEL_DATA
} from "./type"
import toast from "react-hot-toast"

const postBecomeWriterAPI = async data => {
  const URL = `${BASE_URL}api/v1/request-position/`
  const token = await localStorage.getItem("token")

  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: `Token ${token}`
    },
    method: "POST",
    data
  }
  return XHR(URL, options)
}

const getBecomeWriterAPI = async () => {
  const URL = `${BASE_URL}api/v1/request_character/`
  const token = await localStorage.getItem("token")

  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

const getBecomeNovelAPI = async () => {
  const URL = `${BASE_URL}api/v1/request_novel/`
  const token = await localStorage.getItem("token")

  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

function* getBecomeNovelData() {
  try {
    const response = yield call(getBecomeNovelAPI)
    yield put(getBecomeNovelSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(getBecomeNovelFailure(response.data))
  }
}

function* getBecomeWriterData() {
  try {
    const response = yield call(getBecomeWriterAPI)
    yield put(getBecomeWriterDataSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(getBecomeWriterDataFailure(response.data))
  }
}

function* postBecomeWriterData({ data }) {
  try {
    const response = yield call(postBecomeWriterAPI, data)
    yield put(postBecomeWriterSuccess(response.data))
    toast.success("Application Submit Successfully", {
      id: "card fields"
    })
  } catch (e) {
    const { response } = e
    yield put(postBecomeWriterFailure(response.data))
  }
}
export default all([
  takeLatest(GET_BECOME_WRITER_DATA, getBecomeWriterData),
  takeLatest(POST_BECOME_WRITER, postBecomeWriterData),
  takeLatest(GET_BECOME_NOVEL_DATA, getBecomeNovelData)
])
