import { all, call, put, takeLatest } from "redux-saga/effects"
import { BASE_URL } from "../../../../config/app"
import XHR from "../../../../utils/XHR"
import {
  putRequestPasswordSuccess,
  putRequestPasswordFailure,
  getRequestProfileSuccess,
  getRequestProfileFailure,
  putRequestAboutMeSuccess,
  putRequestAboutMeFailure
} from "./action"
import {
  PUT_REQUEST_PASSWORD,
  GET_REQUEST_PROFILE_DATA,
  PUT_REQUEST_ABOUT_ME
} from "./type"
import toast from "react-hot-toast"

const profileDataAPI = async () => {
  const URL = `${BASE_URL}api/v1/user-detail/`
  const token = await localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

const passwordChangeAPI = async data => {
  const URL = `${BASE_URL}api/v1/change-password/`
  const token = await localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "PUT",
    data
  }
  return XHR(URL, options)
}

const aboutMeAPI = async data => {
  const URL = `${BASE_URL}api/v1/bio-update/`
  const token = await localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: `Token ${token}`
    },
    method: "PATCH",
    data
  }
  return XHR(URL, options)
}

function* profileData() {
  try {
    const response = yield call(profileDataAPI)
    yield put(getRequestProfileSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(getRequestProfileFailure(response.data))
  }
}

function* passwordChangeData({ data }) {
  try {
    const response = yield call(passwordChangeAPI, data)
    yield put(putRequestPasswordSuccess(response.data))
    toast.success(response.data.message, {
      id: 'card fields',
    });
  } catch (e) {
    const { response } = e
    yield put(putRequestPasswordFailure(response.data))
  }
}

function* aboutMeData({ data }) {
  try {
    const response = yield call(aboutMeAPI, data)
    yield put(putRequestAboutMeSuccess(response.data))
    toast.success('Profile Updated', {
      id: 'card fields',
    })
  } catch (e) {
    const { response } = e
    yield put(putRequestAboutMeFailure(response.data))
  }
}

export default all([
  takeLatest(PUT_REQUEST_PASSWORD, passwordChangeData),
  takeLatest(GET_REQUEST_PROFILE_DATA, profileData),
  takeLatest(PUT_REQUEST_ABOUT_ME, aboutMeData)
])
