import {
  GET_FAVORITES,
  GET_FAVORITES_FAILURE,
  GET_FAVORITES_SUCCESS,
  PUT_FAVORITES,
  PUT_FAVORITES_SUCCESS,
  PUT_FAVORITES_FAILURE,
  PATCH_FAVORITES_NOVEL,
  PATCH_FAVORITES_NOVEL_SUCCESS,
  PATCH_FAVORITES_NOVEL_FAILURE,
  GET_ADVENTURE_LOG,
  GET_ADVENTURE_LOG_SUCCESS,
  GET_ADVENTURE_LOG_FAILURE,
  UPDATE_ADVENTURE_LOG,
  UPDATE_ADVENTURE_LOG_SUCCESS,
  UPDATE_ADVENTURE_LOG_FAILURE
} from "./type"

export const getFavorites = (name) => ({
  type: GET_FAVORITES,
  name
})

export const getFavoritesSuccess = data => ({
  type: GET_FAVORITES_SUCCESS,
  data
})

export const getFavoritesFailure = data => ({
  type: GET_FAVORITES_FAILURE,
  data
})
// PUT FAVORITS
export const putFavorites = (data) => ({
  type: PUT_FAVORITES,
  data
})

export const putFavoritesSuccess = data => ({
  type: PUT_FAVORITES_SUCCESS,
  data
})

export const putFavoritesFailure = data => ({
  type: PUT_FAVORITES_FAILURE,
  data
})
// PATCH FAVORIT NOVEL
export const patchFavoriteNovel = (data, memberID, bookID, chapterID) => ({
  type: PATCH_FAVORITES_NOVEL,
  data,
  memberID,
  bookID,
  chapterID
})

export const patchFavoriteNovelSuccess = data => ({
  type: PATCH_FAVORITES_NOVEL_SUCCESS,
  data
})

export const patchFavoriteNovelFailure = data => ({
  type: PATCH_FAVORITES_NOVEL_FAILURE,
  data
})
// ADVENTUR LOG
export const getAdventureLog = (data) => ({
  type: GET_ADVENTURE_LOG,
  data  
})

export const getAdventureLogSuccess = data => ({
  type: GET_ADVENTURE_LOG_SUCCESS,
  data
})

export const getAdventureLogFailure = data => ({
  type: GET_ADVENTURE_LOG_FAILURE,
  data
})
// ADVENTURE LOG UPDATE
export const updateAdventureLog = (data, bookID, chapterID) => ({
  type: UPDATE_ADVENTURE_LOG,
  data,
  bookID,
  chapterID
})

export const updateAdventureLogSuccess = data => ({
  type: UPDATE_ADVENTURE_LOG_SUCCESS,
  data
})

export const updateAdventureLogFailure = data => ({
  type: UPDATE_ADVENTURE_LOG_FAILURE,
  data
})