import React from "react"
import avatar from "../../../assets/images/avatar.svg"
import goldenLike from "../../../assets/images/like-golden.svg"
import goldenToken from "../../../assets/images/token-golden.svg"
import greaterWhite from "../../../assets/images/greater-white.svg"
import { history } from "../../../reduxStore/store"
import "./style.scss"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"

const TopGRTCard = props => {
  const { topLikes, isLoading } = props

  const grtData = []
  for (let i = 0; i < topLikes?.length; i++) {
    grtData.push({
      id: topLikes[i].id,
      avatarImg: avatar,
      avatarName: topLikes[i]?.awarded_to || "--",
      likeImg: goldenLike,
      tokenImg: goldenToken,
      tokenNumber: topLikes[i]?.total_token || "--"
    })
  }
  const likeSkeleton = () => (
    <div className="likes-skele-body">
      <div className="like-detail">
        <div className="skele-profile-img">
          <Skeleton
            circle={true}
            height={63.984}
            width={63.984}
            style={{ marginBottom: 24 }}
          />
        </div>
        <div className="user-details">
          <div className="name">
            <Skeleton height={30.988} width={230.848} />
          </div>
          <div className="name-sub">
            <div className="likes">
              <Skeleton height={15.988} width={63.848} />
            </div>
            <div className="good-read">
              <Skeleton height={10.988} width={80.848} />
            </div>
          </div>
        </div>
      </div>
      <div className="like-list-num">
        <Skeleton height={30.988} width={13.848} />
      </div>
    </div>
  )

  return isLoading ? (
    <div className="top-grt-card-wrapper">
      <div className="top-grt-card">
        <div className="grt-card-title">
          <h3>Top Good Read Tokens Awarded</h3>
        </div>
        <div className="grt-card-body">
          {likeSkeleton()}
          {likeSkeleton()}
        </div>
        <div className="grt-card-footer">
          <div
            className="footer-action"
            onClick={() =>
              history.push({
                pathname: `/admin/finances`,
                tab: "Good Read Tokens"
              })
            }
          >
            <p>See Full List</p>
            <img src={greaterWhite} alt="" />
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="top-grt-card-wrapper">
      <div className="top-grt-card">
        <div className="grt-card-title">
          <h3>Top Good Read Tokens Awarded</h3>
        </div>
        <div className="grt-card-body">
          {grtData?.length ? (
            grtData.map((item, index) => (
              <div className="grt-avatar-details" key={item.id}>
                <div className="left-details">
                  <div className="avatar">
                    <img src={item.avatarImg} alt="avatar" />
                  </div>
                  <div className="avatar-details">
                    <div className="name">{item.avatarName}</div>
                    <div className="action-wrapper">
                      {/* <div className="action">
                        <img src={item.likeImg} alt="likes" />
                        <p>Likes ({item.likeNumber})</p>
                      </div> */}
                      <div className="action">
                        <img src={item.tokenImg} alt="tokens" />
                        <p>Good Read Tokens ({item.tokenNumber.toFixed(2)})</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="right-details">
                  <div className="number">{index + 1}</div>
                </div>
              </div>
            ))
          ) : (
            <div className="no-data-div">
              <center>
                <span className="text-info-span">No data available</span>
              </center>
            </div>
          )}
        </div>
        <div className="grt-card-footer">
          <div
            className="footer-action"
            onClick={() =>
              history.push({
                pathname: `/admin/finances`,
                tab: "Good Read Tokens"
              })
            }
          >
            <p>See Full List</p>
            <img src={greaterWhite} alt="" />
          </div>
        </div>
      </div>
    </div>
  )
}

export default TopGRTCard
