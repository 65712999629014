import { 
    GET_NOVALS_TOP,
    GET_NOVALS_TOP_SUCCESS,
    GET_NOVALS_TOP_FAILURE, 
} from "./type";

export const getNovalsTop = () => ({
    type: GET_NOVALS_TOP
});

export const getNovalsTopSuccess = (data) => ({
    type: GET_NOVALS_TOP_SUCCESS,
    data
});

export const getNovalsTopFailure = (data) => ({
    type: GET_NOVALS_TOP_FAILURE,
    data
});