export const GET_WORK_ROOM_DATA = 'GET_WORK_ROOM_DATA';
export const GET_WORK_ROOM_DATA_SUCCESS = 'GET_WORK_ROOM_DATA_SUCCESS';
export const GET_WORK_ROOM_DATA_FAILURE = 'GET_WORK_ROOM_DATA_FAILURE';
// PATCH
export const PATCH_CHARACTER_DETAILS = 'PATCH_CHARACTER_DETAILS';
export const PATCH_CHARACTER_DETAILS_SUCCESS = 'PATCH_CHARACTER_DETAILS_SUCCESS';
export const PATCH_CHARACTER_DETAILS_FAILURE = 'PATCH_CHARACTER_DETAILS_FAILURE';
// UPCOMING GET
export const GET_UPCOMING_DATA = 'GET_UPCOMING_DATA';
export const GET_UPCOMING_DATA_SUCCESS = 'GET_UPCOMING_DATA_SUCCESS';
export const GET_UPCOMING_DATA_FAILURE = 'GET_UPCOMING_DATA_FAILURE';
// UPCOMING PATCH
export const PATCH_UPCOMING_DATA = 'PATCH_UPCOMING_DATA';
export const PATCH_UPCOMING_DATA_SUCCESS = 'PATCH_UPCOMING_DATA_SUCCESS';
export const PATCH_UPCOMING_DATA_FAILURE = 'PATCH_UPCOMING_DATA_FAILURE';