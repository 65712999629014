import React, { useEffect, useState } from "react"
import Switch from "react-switch"
import { ProSidebarProvider } from "react-pro-sidebar"
import { Sidebar, Menu, MenuItem, useProSidebar } from "react-pro-sidebar"
import storyHostLogo from "../../assets/images/story-host-logo.svg"
import questionIcon from "../../assets/images/question.svg"
import questionIconActive from "../../assets/images/question-blue.svg"
import logoutIcon from "../../assets/images/logout.svg"
import toggleIcon from "../../assets/images/toggle.svg"
import "./style.scss"
import { routes } from "../../Router"
import { history } from "../../reduxStore/store"
import { connect } from "react-redux"
import {
  logOut,
  toggleUserType,
  loginRequestSuccess,
  toggleUserTypeSuccess,
  toggleUserTypeFailure
} from "../../pages/AuthScreens/Login/redux/action"
import { useLocation } from "react-router-dom"
import WriterModeModal from "../WriterModeModal"
import { getEditWorkroomSuccess } from "../../pages/EditorHome/redux/action"
import { Images } from "../../theme/Images"

const ModeSwitch = ({ loginInfo, toggleUserType }) => {
  const [checked] = useState(loginInfo?.user?.mode == "reader" ? true : false)

  const handleChange = toggle => {
    const userId = loginInfo.user?.id
    if (toggle) {
      const data = {
        request_type: true
      }
      toggleUserType(userId, data)
    } else {
      const data = {
        request_type: false
      }
      toggleUserType(userId, data)
    }
  }
  return (
    <div className="switch-main ">
      <label className="switch-label">
        <Switch
          onChange={handleChange}
          checked={checked}
          checkedIcon={false}
          uncheckedIcon={false}
          onColor="#A2A2A2"
          offColor="#A2A2A2"
          offHandleColor="#2B292D"
          onHandleColor="#C0E5E6"
          activeBoxShadow={null}
        />
      </label>
      <span className="label">
        Reader Mode <span>{checked ? "ON" : "OFF"}</span>
      </span>
    </div>
  )
}

const SidebarLayout = props => {
  const { loginInfo, getEditWorkroomSuccess, cardFlex, setCardFlex, toggleUserType } = props
  const { collapseSidebar, toggleSidebar } = useProSidebar()
  const [activeElement, setActiveElement] = useState("")
  const location = useLocation()

  useEffect(() => {
    setActiveElement(location.pathname)
  }, [location.pathname])

  const logOut = () => {
    props.logOut()
    localStorage.clear()
    history.push("/")
    getEditWorkroomSuccess(false)
  }

  const onPush = () => {
    if (loginInfo?.user?.user_type === "admin") {
      history.push("/admin/support/")
    } else {
      history.push(`/${loginInfo?.user?.mode}/feedback/`)
    }
  }

  const feedbackPath = `/${loginInfo?.user?.mode}/feedback/`
  const supportPath = `/${loginInfo?.user?.mode}/support/`

  return (
    <>
      <Sidebar customBreakPoint="992px" className="sidebar">
        <div className="top-menu">
          <Menu>
            <MenuItem className="logo-header">
              <div className="logo-wrapper">
                <img
                  src={storyHostLogo}
                  alt=""
                  onClick={() => {
                    collapseSidebar()
                    setCardFlex(!cardFlex)
                  }}
                />
                <div className="text">Story Host</div>
                <div className="toggle">
                  <img
                    src={toggleIcon}
                    alt=""
                    onClick={() => {
                      collapseSidebar()
                      setCardFlex(!cardFlex)
                    }}
                  />
                </div>
              </div>
            </MenuItem>
            {routes.map(
              (item, index) =>
                item.isShow === loginInfo?.user?.mode && (
                  <MenuItem
                    className="menu-item"
                    onClick={() => {
                      history.push(item.layout + item.path)
                    }}
                    key={index}
                  >
                    <div
                      className={`icon-text ${item.layout + item.path === activeElement
                        ? "active"
                        : ""
                        }`}
                    >
                      <img src={item.iconSrc} className="menu-icon" alt="" />
                      <img
                        src={item.activeIconSrc}
                        className="menu-icon-active"
                        alt=""
                      />
                      <div className="text">{item.name}</div>
                    </div>
                  </MenuItem>
                )
            )}
            <MenuItem
              href="http://mystoryhost.com/"
              className="menu-item"
              target="_blank"
            >
              <div
                className='icon-text'>
                <img src={Images.helpIcon} className="menu-icon" alt="" />
                <img
                  src={Images.helpIconAtive}
                  className="menu-icon-active"
                  alt=""
                />
                <div className="text"> Help</div>
              </div>
            </MenuItem>
          </Menu>
        </div>
        <div className="bottom-menu">
          <Menu>
            {loginInfo?.user?.user_type != "admin" && (
              <MenuItem className="switch-anchor">
                {
                  <ModeSwitch
                    loginInfo={loginInfo}
                    toggleUserType={toggleUserType}
                  />
                }
              </MenuItem>
            )}
            <MenuItem className="menu-item">
              <div
                className={`icon-text ${activeElement === feedbackPath ||
                  activeElement === supportPath
                  ? "active"
                  : ""
                  }`}
                onClick={onPush}
              >
                <img src={questionIcon} className="menu-icon" alt="" />
                <img
                  src={questionIconActive}
                  className="menu-icon-active"
                  alt=""
                />
                <div className="text">Support</div>
              </div>
            </MenuItem>
            <MenuItem className="menu-item">
              <div className="icon-text" onClick={logOut}>
                <img src={logoutIcon} alt="" />
                <div className="text">Sign Out</div>
              </div>
            </MenuItem>
          </Menu>
        </div>
      </Sidebar>
      <main className="toggle-sidebar-icon">
        <img src={toggleIcon} alt="" onClick={() => toggleSidebar()} />
      </main>
    </>
  )
}

const SidebarMenu = ({
  logOut,
  loginInfo,
  cardFlex,
  setCardFlex,
  toggleUserType,
  toggleUser,
  toggleUserTypeSuccess,
  loginRequestSuccess,
  toggleUserError,
  toggleUserTypeFailure,
  getEditWorkroomSuccess
}) => {
  const [modalShow, setModalShow] = useState(false)

  useEffect(() => {
    if (toggleUser) {
      loginRequestSuccess({
        token: loginInfo.token,
        user: toggleUser
      })
      toggleUserTypeSuccess(false)
    }
  }, [toggleUser])

  useEffect(() => {
    if (toggleUserError) {
      setModalShow(true)
    }
    toggleUserTypeFailure(false)
  }, [toggleUserError])

  return (
    <ProSidebarProvider>
      {
        <SidebarLayout
          cardFlex={cardFlex}
          setCardFlex={setCardFlex}
          logOut={logOut}
          loginInfo={loginInfo}
          toggleUserType={toggleUserType}
          getEditWorkroomSuccess={getEditWorkroomSuccess}
        />
      }
      <WriterModeModal modalShow={modalShow} setModalShow={setModalShow} />
    </ProSidebarProvider>
  )
}
const mapStateToProps = state => ({
  loginInfo: state.login.loginInfo,
  toggleUser: state.login.toggleUser,
  toggleUserError: state.login.toggleUserError
})

const mapDispatchToProps = dispatch => ({
  logOut: data => dispatch(logOut(data)),
  toggleUserType: (id, data) => dispatch(toggleUserType(id, data)),
  loginRequestSuccess: data => dispatch(loginRequestSuccess(data)),
  toggleUserTypeSuccess: data => dispatch(toggleUserTypeSuccess(data)),
  toggleUserTypeFailure: data => dispatch(toggleUserTypeFailure(data)),
  getEditWorkroomSuccess: data => dispatch(getEditWorkroomSuccess(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(SidebarMenu)
