import { initializeApp } from "firebase/app"
import {
  getMessaging,
  getToken,
  isSupported,
  onMessage
} from "firebase/messaging"
import { toast } from "react-hot-toast"

// const APIKEY = "AIzaSyBQxIVQxQNlapbM88PIuDlQrJw7PSP8cnI"
// const AUTHDOMAIN = "storyhostsite.firebaseapp.com"
// const PROJECTID = "storyhostsite"
// const STORAGEBUCKET = "storyhostsite.appspot.com"
// const MESSAGINGSENDERID = "232766320230"
// const APPID = "1:232766320230:web:460117b569da0742d0c7a7"
// const MEASUREMENTID = "G-ZKGXDBRY00"

const FIREBASE_CONFIG = {
  apiKey: process.env.REACT_APP_APIKEY,
  authDomain: process.env.REACT_APP_AUTHDOMAIN,
  projectId: process.env.REACT_APP_PROJECTID,
  storageBucket: process.env.REACT_APP_STORAGEBUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
  appId: process.env.REACT_APP_APPID,
  measurementId: process.env.REACT_APP_MEASUREMENTID
}

const firebaseApp = initializeApp(FIREBASE_CONFIG)

function requestPermission() {
  Notification.requestPermission().then(permission => {
    if (permission === "granted") {
      toast.success("Notification Enabled")
    }
  })
}

const messaging = (async () => {
  requestPermission()
  try {
    const isSupportedBrowser = await isSupported()
    if (isSupportedBrowser) {
      return getMessaging(firebaseApp)
    } else {
      requestPermission()
    }
    console.log("Firebase not supported this browser")
    return null
  } catch (err) {
    console.log(err)
    return null
  }
})()

// const messaging = getMessaging(firebaseApp)

export default messaging

export const onMessageListener = () =>
  new Promise(resolve => {
    onMessage(messaging, payload => {
      resolve(payload)
    })
  })

export const fetchToken = () => {
  const new_messaging = getMessaging(firebaseApp)
  return getToken(new_messaging, {
    vapidKey:
      "BCGUunGLhBe72vg-7ehzT1DNrLcFs4nP4egIBtjz0Sny00QCzShcycHot9siaFj2cKwtj06Qeqc-eCG3FCHu18w"
  })
}
