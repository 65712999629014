import React, { useEffect, useState } from "react"
import Button from "../Button"
import Modal from "react-bootstrap/Modal"
import closeIcon from "../../assets/images/close-icon-modal.svg"
import pencilIcon from "../../assets/images/textarea-write.svg"
import Input from "../Input"
import useForm from "../../utils/useForm"
import BoardSelect from "../../components/BoardSelect"
import { connect } from "react-redux"
import { editCharactersRequest, getRoleTypeRequest } from "../../pages/CreateNovel/redux/action"

import "./style.scss"
import TextArea from "../TextArea"

const EditChracters = props => {
  const {
    modalShow,
    setModalShow,
    modalData,
    editCharactersRequest,
    GetRoleData,
    editData
  } = props
  const [userID, setUserID] = useState([])
  const [writerId, setWriterId] = useState("")

  useEffect(() => {
    let writerID1 = []
    console.log(GetRoleData);
    if (GetRoleData) {
      GetRoleData &&
        GetRoleData?.data?.map(val =>
          writerID1.push({
            value: val.id,
            label: val.name
          })
        )
      console.log(writerID1);
      setUserID(writerID1)
    }
  }, [GetRoleData])

  useEffect(() => {
    if (modalData) {
      //console.log(modalData);
      handleOnChange("CharacterName", modalData.name)
      handleOnChange("personality", modalData.personality)
      handleOnChange("about", modalData.about)
      setWriterId(modalData?.writer?.id)
    }
  }, [modalData])

  useEffect(() => {
    if (editData) {
      setModalShow(false)
    }
  }, [editData])

  const stateSchema = {
    CharacterName: {
      value: "",
      error: ""
    },
    personality: {
      value: "",
      error: ""
    },
    about: {
      value: "",
      error: ""
    }
  }

  const validationStateSchema = {
    CharacterName: {
      required: true
    },
    personality: {
      required: true
    },
    about: {
      required: true
    }
  }
  const { state, handleOnChange, disable, setState } = useForm(
    stateSchema,
    validationStateSchema
  )
  const handleEditCharacter = () => {
    editCharactersRequest({
      data: {
        name: state.CharacterName.value,
        personality: state.personality.value,
        about: state.about.value,
        writer: writerId,
      },
      id: modalData.id
    })
  }

  return (
    <>
      <Modal
        {...props}
        show={modalShow}
        size="lg"
        aria-labelledby="EditChracters-modal-title-vcenter"
        centered
        className="create-EditChracters-modal"
      >
        <Modal.Body className="EditChracters-modal-body">
          <div
            className="close-icon"
            onClick={() => {
              setModalShow(false)
            }}
          >
            <img src={closeIcon} alt="" />
          </div>
          <div className="EditChracters-wrapper">
            <h3>Edit Character</h3>
          </div>
          <div className="EditChracters-details">
            <Input
              placeholder="Create Character Name"
              showIcon={true}
              src={pencilIcon}
              //   value={modalData.}
              value={state.CharacterName.value}
              showError={true}
              onChange={val =>
                handleOnChange("CharacterName", val.target.value)
              }
            />

            <div className="row">
              <div className="col-lg-6">
                <TextArea
                  placeholder="Charater's Personality Traits"
                  value={state.personality.value}
                  showError={true}
                  onChange={val =>
                    handleOnChange("personality", val.target.value)
                  }
                />
              </div>
              <div className="col-lg-6">
                <TextArea
                  placeholder="About the Character"
                  value={state.about.value}
                  showError={true}
                  onChange={val =>
                    handleOnChange("about", val.target.value)
                  }
                />
              </div>
            </div>
            <div className="row">
              <div className="col-lg-12 select-user">
                {userID.length > 0 ?
                  <BoardSelect
                    options={userID}
                    placeholder="ID"
                    onChange={val =>
                      setWriterId(val)
                    }
                    value={writerId}
                    allowClear={true}
                    showSearch={true}
                  /> : null}
              </div>
            </div>
          </div>
          <div className="field-wrapper">{/* ))} */}</div>
          <div className="button-wrapper button-Submit mb-3">
            <Button title="Save Changes" onClick={handleEditCharacter} />
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

const mapStateToProps = state => ({
  editData: state.createNovel.editData,
  GetRoleData: state.createNovel.GetRoleData,
})

const mapDispatchToProps = dispatch => ({
  editCharactersRequest: data => dispatch(editCharactersRequest(data)),
  getRoleTypeRequest: data => dispatch(getRoleTypeRequest(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(EditChracters)
