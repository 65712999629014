import React, { useEffect, useState } from "react"
import Form from "react-bootstrap/Form"
import InputGroup from "react-bootstrap/InputGroup"
import searchIcon from "../../assets/images/search-icon.svg"
import lessIcon from "../../assets/images/less-white-icon.svg"
import favorite from "../../assets/images/favorites.svg"
import favoriteActive from "../../assets/images/filled-favorite-active.svg"
import "./style.scss"
import Button from "../Button"
import { useHistory } from "react-router-dom"
import addIcon from "../../assets/images/add.svg"


const Header = props => {
  const searchBar = props.showSearch ? props.showSearch : false
  const backIcon = props.showBack ? props.showBack : false
  const rightWrapper = props.showRightWrapper ? props.showRightWrapper : false
  const favoriteIcon = props.showFavorite ? props.showFavorite : false
  const chatButton = props.showChatButton ? props.showChatButton : false
  const showSreachAndButton = props.showSreachAndButton
  const showTitle = props.showTitle
  const className = props.className ? props.className : ""
  const awardBtn = props.showAwardBtn ? props.showAwardBtn : false
  const onChange = props.onChange
    ? props.onChange
    : false
    ? props.showSreachAndButton
    : false
  const addFav = props.addFav ? props.addFav : false
  const favOnClick = props.favOnClick ? props.favOnClick : null
  const tokenOnClick = props.tokenOnClick ? props.tokenOnClick : false
  const tokenLoader = props.tokenLoader
  const [search, setSearch] = useState("")
  const history = useHistory()

  useEffect(() => {
    setSearch("")
  }, [props.tab])

  const onChangeFun = val => {
    onChange(val)
    setSearch(val)
  }

  return (
    <section className={`header-section ${className}`}>
      <div className="main-text">
        {backIcon && (
          <div className="back" onClick={() => history.goBack()}>
            <img src={lessIcon} alt="" />
          </div>
        )}
        <div className="text">
          <div className="title" title={showTitle}>
            {props.name}
          </div>
        </div>
      </div>
      {searchBar && (
        <div className="search-wrapper">
          <InputGroup>
            <InputGroup.Text id="basic-addon1">
              <img src={searchIcon} className="img-fluid" alt="search" />
            </InputGroup.Text>
            <Form.Control
              placeholder="Search"
              aria-label="Search"
              aria-describedby="basic-addon1"
              onChange={val => onChangeFun(val.target.value)}
              value={search}
            />
          </InputGroup>
        </div>
      )}
      {showSreachAndButton && (
        <div className="right-wrapper ">
          <div className="search-wrapper showSreach ">
            <InputGroup>
              <InputGroup.Text id="basic-addon1">
                <img src={searchIcon} className="img-fluid" alt="search" />
              </InputGroup.Text>
              <Form.Control
                placeholder="Search"
                aria-label="Search"
                aria-describedby="basic-addon1"
                onChange={val => props.proOnChange(val.target.value)}
              />
            </InputGroup>
          </div>
          <div className="button-wrapper showbutton">
            <Button title="Create New Novel" onClick={props.onClick} />
          </div>
        </div>
      )}
      {rightWrapper && (
        <div className="right-wrapper">
          {awardBtn && (
            <Button
              title={props.awardBtnTitle}
              showIcon={true}
              src={props.awardBtnImg}
              onClick={tokenOnClick}
              showSpinner={tokenLoader}
            />
          )}
          <div className="icon-wrapper" onClick={favOnClick}>
            <img src={addFav ? favoriteActive : favorite} alt="" />
          </div>
        </div>
      )}
        {props.addCard &&
            (<div className="add" onClick={props.addCardOnClick}>
              <img src={addIcon} alt="add" />
            </div>)
          }
      {chatButton && (
        <div className="button-wrapper">
          <Button title="New Chat" onClick={props.onClick} />
        </div>
      )}
      {favoriteIcon && (
        <div className="right-wrapper">
          <div className="icon-wrapper" onClick={favOnClick}>
            <img src={addFav ? favoriteActive : favorite} alt="" />
          </div>
        </div>
      )}
    </section>
  )
}

export default Header
