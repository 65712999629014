import React, { useState, useEffect } from "react"
import forgotPasswordSideImage from "../../assets/images/forgot-password-img.png"
import profileIcon from "../../assets/images/profile.svg"
import lockIcon from "../../assets/images/lock-icon.svg"
import Button from "../../components/Button"
import Input from "../../components/Input"
import useForm from "../../utils/useForm"
import validator from "../../utils/validation"
import { postConfirmPassword, postConfirmPasswordFailure } from "./redux/action"
import { connect } from "react-redux"
import { useHistory } from "react-router-dom"

const ConfirmPassword = props => {
  const {
    postConfirmRequest,
    confirmInfo,
    requesting,
    error,
    postConfirmPasswordFailure
  } = props

  const stateSchema = {
    password: {
      value: "",
      error: ""
    }
  }

  const validationStateSchema = {
    password: {
      required: true,
      validator: validator.password
    }
  }
  const { state, handleOnChange, disable, setState } = useForm(
    stateSchema,
    validationStateSchema
  )
  const [token, setToken] = useState("")
  const [newPassword, setNewPassword] = useState("")
  const history = useHistory()

  const onSubmit = async () => {
    const data = {
      token: token,
      password: state.password.value
    }
    await postConfirmRequest(data, history)
  }
  useEffect(() => {
    postConfirmPasswordFailure(false)
  }, [])

  return (
    <section className="landing-page-section auth-page-section">
      <div className="container-fluid landing-content-container">
        <div className="row auth-content-row">
          <div className="col-md-6">
            <div className="landing-left-content auth-left-content">
              <div className="content-details">
                <h1>Story Host</h1>
                <h2 className="forgot-title">
                  Enter Token Number and New Password.
                </h2>
              </div>
              <div className="input-wrapper">
                <Input
                  type="text"
                  showIcon={true}
                  src={profileIcon}
                  placeholder="Enter Token Number"
                  onChange={val => setToken(val.target.value)}
                  showError={true}
                  errorMessage={error.token && error.token}
                />
                <Input
                  type="password"
                  showIcon={true}
                  src={lockIcon}
                  showHideIcon={true}
                  value={state.password.value}
                  showError={true}
                  placeholder="Enter New Password"
                  onChange={val => handleOnChange("password", val.target.value)}
                  errorMessage={state.password.error || error.password && error.password[0]}
                />
              </div>
              <div className="button-wrapper confirm-password-button-wrapper">
                <Button
                  showSpinner={requesting}
                  title="Reset Password"
                  className="reset-btn"
                  onClick={onSubmit}
                />
              </div>
            </div>
          </div>
          <div className="col-md-6">
            <div className="auth-right-content">
              <img
                src={forgotPasswordSideImage}
                className="img-fluid"
                alt="Image"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const mapStateToProps = state => ({
  confirmInfo: state.confirmPassword,
  requesting: state.confirmPassword.requesting,
  error: state.confirmPassword.error
})

const mapDispatchToProps = dispatch => ({
  postConfirmRequest: (data, history) =>
    dispatch(postConfirmPassword(data, history)),
  postConfirmPasswordFailure: data => dispatch(postConfirmPasswordFailure(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmPassword)
