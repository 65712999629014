import react from "react"
import { Switch, Redirect } from "react-router-dom"
import AuthLayout from "./layout/Auth/index"
import AdminLayout from "./layout/Admin/index"
import ReaderLayout from "./layout/Reader/index"
import WriterLayout from "./layout/Writer/index"
import EditorLayout from "./layout/Editor/index"
import ProducerLayout from "./layout/Producer/index"
import RouteGuard from "./RouterGuard/index"
import { connect } from "react-redux"
import ForegroundNotification from "./pages/ForegroundNotification"
import { Toaster } from "react-hot-toast"
import { routes } from "./Router"

function App(props) {
  const { loginInfo } = props

  return (
    <>
      <Toaster position="top-center" />
      <ForegroundNotification />
      <Switch>
        {loginInfo && loginInfo?.user?.user_type === "admin" && (
          <RouteGuard
            path="/admin"
            component={props => <AdminLayout {...props} />}
            isProtected
          />
        )}
        {loginInfo && loginInfo?.user?.mode === "reader" && (
          <RouteGuard
            path="/reader"
            component={props => <ReaderLayout {...props} />}
            isProtected
          />
        )}
        {loginInfo && loginInfo?.user?.mode === "writer" && (
          <RouteGuard
            path="/writer"
            component={props => <WriterLayout {...props} />}
            isProtected
          />
        )}
        {loginInfo && loginInfo?.user?.mode === "editor" && (
          <RouteGuard
            path="/editor"
            component={props => <EditorLayout {...props} />}
            isProtected
          />
        )}
        {loginInfo && loginInfo?.user?.mode === "producer" && (
          <RouteGuard
            path="/producer"
            component={props => <ProducerLayout {...props} />}
            isProtected
          />
        )}
        {/* always key keep this route guard at last just before fallback route don't change this order*/}
        <RouteGuard
          path={[
            ...routes
              .filter(route => route.layout === "/auth")
              .map(filteredRoute => filteredRoute.path),
            "/auth"
          ]}
          component={props => <AuthLayout {...props} />}
        />
        <Redirect to="/" />
      </Switch>
    </>
  )
}

const mapStateToProps = state => ({
  accessToken: state.login.accessToken,
  loginInfo: state.login.loginInfo
})

export default connect(mapStateToProps, null)(App)
