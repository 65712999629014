import React, { useEffect, useState } from "react"
import Modal from "react-bootstrap/Modal"
import closeIcon from "../../assets/images/close-icon-modal.svg"
import PaymentInfo from "./components/Payment"
import PersonalInfo from "./components/Personal"
import PriceCard from "./components/PriceCard"
import { connect } from "react-redux"
import { addYourInformationFailure } from "./components/Personal/redux/action"
import { loginRequestFailure } from "../../pages/AuthScreens/Login/redux/action"
import "./style.scss"

const JoinClubModal = props => {
  const {
    modalShow,
    setModalShow,
    addYourInformationFailure,
    loginRequestFailure,
    addCard,
    storePlanID,
    availgrt,
    cardNoShow
  } = props

  const [joinClubTab, setJoinClubTab] = useState("PersonalInfo")

  useEffect(() => {
    addCard && setJoinClubTab(addCard)
  }, [addCard])

  return (
    <>
      <Modal
        show={modalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={`join-club-modal ${
          joinClubTab === "PriceCard" ? "" : "personal-info-modal"
        }`}
      >
        <Modal.Header>
          <Modal.Title id="contained-modal-title-vcenter">
            <div className="head-title">
              {availgrt == 0
                ? "You have reached your monthly quota limit please wait for next month or upgrade your plan?"
                : "Join the Readers Club!"}
            </div>
            <div
              className="close-icon"
              onClick={() => {
                setModalShow(false)
                setTimeout(
                  () => setJoinClubTab(addCard ? addCard : "PersonalInfo"),
                  500
                )
                addYourInformationFailure(false)
                loginRequestFailure(false)
              }}
            >
              <img src={closeIcon} alt="" />
            </div>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Step 1 */}
          {joinClubTab === "PriceCard" ? (
            <PriceCard setJoinClubTab={setJoinClubTab} availgrt={availgrt} />
          ) : joinClubTab === "PersonalInfo" ? (
            <PersonalInfo setJoinClubTab={setJoinClubTab} />
          ) : joinClubTab === "PaymentInfo" ? (
            <PaymentInfo
              setJoinClubTab={setJoinClubTab}
              setModalShow={setModalShow}
              addCard={addCard}
              storePlanID={storePlanID}
              availgrt={availgrt}
              cardNoShow={cardNoShow}
            />
          ) : (
            setJoinClubTab("PersonalInfo")
          )}
        </Modal.Body>
        <Modal.Footer>
          {addCard == "PriceCard" && (
            <div className="steps-wrapper">
              {joinClubTab === "PriceCard" ? (
                <div className="step active"></div>
              ) : (
                <div className="step"></div>
              )}
              {joinClubTab === "PaymentInfo" ? (
                <div className="step active"></div>
              ) : (
                <div className="step"></div>
              )}
            </div>
          )}
        </Modal.Footer>
      </Modal>
    </>
  )
}

const mapDispatchToProps = dispatch => ({
  addYourInformationFailure: data => dispatch(addYourInformationFailure(data)),
  loginRequestFailure: data => dispatch(loginRequestFailure(data))
})

export default connect(null, mapDispatchToProps)(JoinClubModal)
