// Application
export const WRITER_ADMIN_APPLICATION = 'WRITER_ADMIN_APPLICATION';
export const WRITER_ADMIN_APPLICATION_SUCCESS = 'WRITER_ADMIN_APPLICATION_SUCCESS';
export const WRITER_ADMIN_APPLICATION_FAILURE = 'WRITER_ADMIN_APPLICATION_FAILURE';

export const EDITOR_ADMIN_APPLICATION = 'EDITOR_ADMIN_APPLICATION';
export const EDITOR_ADMIN_APPLICATION_SUCCESS = 'EDITOR_ADMIN_APPLICATION_SUCCESS';
export const EDITOR_ADMIN_APPLICATION_FAILURE = 'EDITOR_ADMIN_APPLICATION_FAILURE';

export const SAVE_ADMIN_APPLICATION = 'SAVE_ADMIN_APPLICATION';
export const SAVE_ADMIN_APPLICATION_SUCCESS = 'SAVE_ADMIN_APPLICATION_SUCCESS';
export const SAVE_ADMIN_APPLICATION_FAILURE = 'SAVE_ADMIN_APPLICATION_FAILURE';

export const PATCH_WRITER_ADMIN_APPLICATION = 'PATCH_WRITER_ADMIN_APPLICATION';
export const PATCH_WRITER_ADMIN_APPLICATION_SUCCESS = 'PATCH_WRITER_ADMIN_APPLICATION_SUCCESS';
export const PATCH_WRITER_ADMIN_APPLICATION_FAILURE = 'PATCH_WRITER_ADMIN_APPLICATION_FAILURE';

export const PATCH_EDITOR_ADMIN_APPLICATION = 'PATCH_EDITOR_ADMIN_APPLICATION';
export const PATCH_EDITOR_ADMIN_APPLICATION_SUCCESS = 'PATCH_EDITOR_ADMIN_APPLICATION_SUCCESS';
export const PATCH_EDITOR_ADMIN_APPLICATION_FAILURE = 'PATCH_EDITOR_ADMIN_APPLICATION_FAILURE';

export const PATCH_SAVE_ADMIN_APPLICATION = 'PATCH_SAVE_ADMIN_APPLICATION';
export const PATCH_SAVE_ADMIN_APPLICATION_SUCCESS = 'PATCH_SAVE_ADMIN_APPLICATION_SUCCESS';
export const PATCH_SAVE_ADMIN_APPLICATION_FAILURE = 'PATCH_SAVE_ADMIN_APPLICATION_FAILURE';