import React, { useState, useEffect } from "react"
import Input from "../Input"
import searchIcon from "../../assets/images/search-icon.svg"
import avatarImg from "../../assets/images/avatar.svg"
import closeIcon from "../../assets/images/close-icon-modal.svg"
import "./style.scss"
import {
  setDirectUser,
  setGroupUsers
} from "../../pages/PubNubChat/redux/action"
import { connect } from "react-redux"
import { getAllUsersData, searchListShow } from "../../pages/PubNubChat/redux/action"

const Search = props => {
  const {
    placeholder = "",
    onChange,
    value,
    alldata,
    setDirectUser,
    isGroupChat,
    setGroupUsers,
    groupUsers,
    loginInfo,
    getAllUsersData,
    disabledField,
    channelSearch,
    updateChannelUsers,
    setShowOption,
    searchListShow,
    searchListStatus
  } = props

  const [selectedAvatar, setSelectedAvatar] = useState([])
  const [allUsersData, setAllUsersData] = useState([])
  const suggestedData = []
  for (let i = 1; i < 46; i++) {
    suggestedData.push({
      id: i,
      src: avatarImg,
      suggested: "Jane Cooper"
    })
  }

  useEffect(() => {
    updateChannelUsers && searchListShow(false)
  }, [updateChannelUsers])

  useEffect(() => {
    alldata &&
      setAllUsersData(
        alldata.filter(val => val.eTag !== `${loginInfo.user.id}`)
      )
  }, [alldata])

  const handleSelectUser = item => {
    if (isGroupChat) {
      if (selectedAvatar.includes(item)) {
        const filterData = selectedAvatar.filter(val => val.eTag !== item.eTag)
        setSelectedAvatar(filterData)
        setGroupUsers(filterData)
      } else {
        setSelectedAvatar([...selectedAvatar, item])
        setGroupUsers([...groupUsers, item])
      }
    } else {
      setSelectedAvatar([item])
      setDirectUser(item)
    }
  }

  const handleSearch = e => {
    setTimeout(() => {
      getAllUsersData(e.target.value)
    }, [])
  }

  return (
    <>
      <div className="search-wrapper">
        <div className="search-field">
          <Input
            type="text"
            placeholder={placeholder}
            showIcon={true}
            src={searchIcon}
            onFocus={() => {
              !channelSearch && searchListShow(true)
              setShowOption(false)
            }}
            onChange={channelSearch ? onChange : e => handleSearch(e)}
            value={value}
            disabled={disabledField}
          />
          {searchListStatus && (
            <div className="suggested-wrapper">
              <div className="suggested-item">
                <div
                  className="close-icon"
                  style={{ display: "flex", justifyContent: "end" }}
                  onClick={() => {
                    searchListShow(false)
                  }}
                >
                  <img src={closeIcon} alt="" />
                </div>
                {allUsersData.length > 0 ? (
                  allUsersData.map(item => (
                    <div
                      style={{}}
                      className="item"
                      key={item.id}
                      onClick={() => {
                        handleSelectUser(item)
                      }}
                    >
                      <div
                        className={`avatar-img ${selectedAvatar.includes(item) ? "selected-avatar" : ""
                          }`}
                      >
                        <img
                          src={item.profileUrl ? item.profileUrl : avatarImg}
                          alt=""
                        // style={{
                        //   borderRadius: "20px",
                        //   height: "32px",
                        //   width: "32px"
                        // }}
                        />
                      </div>
                      <div className="avatar-name" title={item.name}>
                        {item.name}
                      </div>
                    </div>
                  ))
                ) : (
                  <p style={{ textAlign: "center" }}>No User</p>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        className={`selected-user-wrapper ${selectedAvatar.length > 0 ? "opacity-1" : ""
          }`}
      >
        {selectedAvatar.map(item => (
          <div className="selected">
            <div className="avatar-img selected-avatar">
              <img src={item.profileUrl ? item.profileUrl : avatarImg} alt="" />
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  alldata: state.PubNubChat.allUsersdata,
  groupUsers: state.PubNubChat.groupUsers,
  updateChannelUsers: state.PubNubChat.updateChannelUsers,
  searchListStatus: state.PubNubChat.searchListStatus,
  loginInfo: state.login.loginInfo
})

const mapDispatchToProps = dispatch => ({
  setDirectUser: data => dispatch(setDirectUser(data)),
  setGroupUsers: data => dispatch(setGroupUsers(data)),
  getAllUsersData: data => dispatch(getAllUsersData(data)),
  searchListShow: data => dispatch(searchListShow(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Search)
