import React from "react"
import Modal from "react-bootstrap/Modal"
import AddUserModal from "./components/AddUserModal"
import {connect} from 'react-redux'

import {addAdminUserFailure} from '../../../pages/AdminScreens/Users/redux/action';

const AdminModal = props => {
  const { modalShow, setModalShow, updateData,setUpdateData, update, setShow, addAdminUserFailure } = props
  const onHide = () => {
    setModalShow(false);
    setShow(false);
    addAdminUserFailure(false);
    setUpdateData({})
  }
  
  return (
    <>
      <Modal
        show={modalShow}
        onHide={onHide}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="admin-modal"
      >
        <Modal.Body>
          <AddUserModal
            updateData={updateData}
            setUpdateData={setUpdateData}
            update={update}
            setModalShow={setModalShow}
            setShow={setShow}
          />
        </Modal.Body>
      </Modal>
    </>
  )
}

const mapDispatchToProps = dispatch => ({
  addAdminUserFailure: (data) => dispatch(addAdminUserFailure(data))
})

export default connect(null, mapDispatchToProps)(AdminModal)