import {
  PRIVACY_POLICY_DATA,
  PRIVACY_POLICY_DATA_SUCCESS,
  PRIVACY_POLICY_DATA_FAILURE
} from "./type"

const initialState = {
  privacyData: [],
  error: false,
  requesting: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case PRIVACY_POLICY_DATA:
      return {
        ...state,
        requesting: true
      }
    case PRIVACY_POLICY_DATA_SUCCESS:
      return {
        ...state,
        requesting: false,
        privacyData: action.data,
        error: false
      }
    case PRIVACY_POLICY_DATA_FAILURE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }
    default:
      return state
  }
}
