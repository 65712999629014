import {
    GET_ADMIN_HOME,
    GET_ADMIN_HOME_SUCCESS,
    GET_ADMIN_HOME_FAILURE,
    GET_ADMIN_HOME_BOX,
    GET_ADMIN_HOME_BOX_SUCCESS,
    GET_ADMIN_HOME_BOX_FAILURE
} from "./type";

const initialState = {
    adminHomeInfo: false,
    requesting: false,
    error: false,

    homeBoxData: false,
    homeBoxRequesting: false,
    homeBoxError: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_ADMIN_HOME:
            return {
                ...state,
                requesting: true,
                error: false
            }
        case GET_ADMIN_HOME_SUCCESS:
            return {
                ...state,
                requesting: false,
                adminHomeInfo: action.data
            }
        case GET_ADMIN_HOME_FAILURE:
            return {
                ...state,
                requesting: false,
                error: action.data
            }
            case GET_ADMIN_HOME_BOX:
            return {
                ...state,
                homeBoxRequesting: true,
                homeBoxError: false
            }
        case GET_ADMIN_HOME_BOX_SUCCESS:
            return {
                ...state,
                homeBoxRequesting: false,
                homeBoxData: action.data
            }
        case GET_ADMIN_HOME_BOX_FAILURE:
            return {
                ...state,
                homeBoxRequesting: false,
                homeBoxError: action.data
            }
        default:
            return state
    }
}
