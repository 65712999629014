import React from "react"
import { Select } from "antd"
import "./style.scss"

const BoardSelect = ({
  defaultValue,
  options,
  onChange,
  placeholder,
  onSelect,
  value,
  onFocus,
  disabled,
  allowClear,
  showSearch
}) => {
  return (
    <div className="board-select-wrapper">
      <Select
        value={value}
        onSelect={onSelect}
        placeholder={placeholder}
        defaultValue={defaultValue}
        suffixIcon={false}
        options={options}
        onChange={onChange}
        onFocus={onFocus}
        disabled={disabled}
        allowClear={allowClear}
        showSearch={showSearch}
        filterOption={
          (input, option) => (option?.label ?? '').includes(input)
        }
      />
    </div>
  )
}

export default BoardSelect
