import {
    GET_ADMIN_FEEDBACK,
    GET_ADMIN_FEEDBACK_SUCCESS,
    GET_ADMIN_FEEDBACK_FAILURE,
    GET_FEEDBACK_BY_ID,
    GET_FEEDBACK_BY_ID_SUCCESS,
    GET_FEEDBACK_BY_ID_FAILURE,
    PUT_ADMIN_FEEDBACK,
    PUT_ADMIN_FEEDBACK_SUCCESS,
    PUT_ADMIN_FEEDBACK_FAILURE
   } from "./type"

const initialState = {
    data: false,
    requesting: false,
    error: false,
    feedbackData: false,
    feedbackRequesting: false,
    feedbackError: false,
    putFeedback: false,
    putRequesting: false,
    putError: false,
}

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_ADMIN_FEEDBACK:
            return {
                ...state,
                requesting: true,
            }
        case GET_ADMIN_FEEDBACK_SUCCESS:
            return {
                ...state,
                requesting: false,
                data: action.data
            }
        case GET_ADMIN_FEEDBACK_FAILURE:
            return {
                ...state,
                requesting: false,
                error: action.data
            }
            case GET_FEEDBACK_BY_ID:
            return {
                ...state,
                feedbackRequesting: true,
            }
        case GET_FEEDBACK_BY_ID_SUCCESS:
            return {
                ...state,
                feedbackRequesting: false,
                feedbackData: action.data
            }
        case GET_FEEDBACK_BY_ID_FAILURE:
            return {
                ...state,
                feedbackRequesting: false,
                feedbackError: action.data
            }
            case PUT_ADMIN_FEEDBACK:
            return {
                ...state,
                putRequesting: true,
            }
        case PUT_ADMIN_FEEDBACK_SUCCESS:
            return {
                ...state,
                putRequesting: false,
                putFeedback: action.data
            }
        case PUT_ADMIN_FEEDBACK_FAILURE:
            return {
                ...state,
                putRequesting: false,
                putError: action.data
            }
        default:
            return state
    }
}
