import React, { useState } from "react"
import Button from "../Button"
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem
} from "reactstrap"
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import "./style.scss"

const CharacterCard = ({
  cardId,
  bookName,
  characterName,
  setWriterDetail,
  activeCard,
  setSelectCard,
  about,
  toptitle,
  paraTitle,
  novalDetails,
  isLoaded
}) => {
  const [open, setOpen] = useState("0")
  const toggle = id => {
    if (open === id) {
      setOpen("0")
    } else {
      setOpen(id)
    }
  }

  return (
    <div className="book-detail-card-wrapper character-card-wrapper">
      <div className={`card-details  ${activeCard}`}>
        {/* active-card */}
        <div className="card-description">
          <h3 title={paraTitle}>{isLoaded ? <Skeleton width={100} borderRadius={20} /> : bookName}</h3>
          <p className="para-title" title={toptitle}>
            {isLoaded ? <Skeleton width={150} borderRadius={20} /> : characterName}
          </p>
          <div className="accordion-wrapper">
            <Accordion open={open} toggle={toggle}>
              <AccordionItem>
                <AccordionHeader targetId="1" className="info">
                  {isLoaded ? <Skeleton highlightColor='#ddd' width={200} borderRadius={20} /> : "More Information"}
                </AccordionHeader>
                <AccordionBody accordionId="1">
                  <div className="novalDetails-about">
                    {novalDetails && <h5>Personality:</h5>}
                    <p>{novalDetails || ""}</p>
                  </div>
                  <div className="novalDetails-about">
                    {about && <h5>About:</h5>}
                    <p>{about || ""}</p>
                    <div className="button-wrapper">
                      <Button
                        title="Apply for Position"
                        className="full-btn"
                        onClick={() => {
                          setOpen()
                          setSelectCard(cardId)
                          setWriterDetail(1)
                         
                        }}
                      />
                    </div>
                  </div>
                </AccordionBody>
              </AccordionItem>
            </Accordion>
          </div>
        </div>
      </div>
    </div>
  )
}

export default CharacterCard
