// GET FAVORITES
export const GET_FAVORITES = 'GET_FAVORITES';
export const GET_FAVORITES_SUCCESS = 'GET_FAVORITES_SUCCESS';
export const GET_FAVORITES_FAILURE = 'GET_FAVORITES_FAILURE';
// PUT FAVORITES AUTHOR
export const PUT_FAVORITES = 'PUT_FAVORITES';
export const PUT_FAVORITES_SUCCESS = 'PUT_FAVORITES_SUCCESS';
export const PUT_FAVORITES_FAILURE = 'PUT_FAVORITES_FAILURE';
// PATCH FAVORIT NOVELS
export const PATCH_FAVORITES_NOVEL = 'PATCH_FAVORITES_NOVEL';
export const PATCH_FAVORITES_NOVEL_SUCCESS = 'PATCH_FAVORITES_NOVEL_SUCCESS';
export const PATCH_FAVORITES_NOVEL_FAILURE = 'PATCH_FAVORITES_NOVEL_FAILURE';
// Adventure Log
export const GET_ADVENTURE_LOG = 'GET_ADVENTURE_LOG';
export const GET_ADVENTURE_LOG_SUCCESS = 'GET_ADVENTURE_LOG_SUCCESS';
export const GET_ADVENTURE_LOG_FAILURE = 'GET_ADVENTURE_LOG_FAILURE';
// ADVENTURE LOG UPDATE
export const UPDATE_ADVENTURE_LOG = 'UPDATE_ADVENTURE_LOG';
export const UPDATE_ADVENTURE_LOG_SUCCESS = 'UPDATE_ADVENTURE_LOG_SUCCESS';
export const UPDATE_ADVENTURE_LOG_FAILURE = 'UPDATE_ADVENTURE_LOG_FAILURE';