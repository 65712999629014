import {
  GET_CARD_DATA,
  GET_CARD_DATA_SUCCESS,
  GET_CARD_DATA_FAILURE,
  GET_ACTIVE_CARD_DATA,
  GET_ACTIVE_CARD_DATA_SUCCESS,
  GET_ACTIVE_CARD_DATA_FAILURE,
  POST_CARD_DATA,
  POST_CARD_DATA_SUCCESS,
  POST_CARD_DATA_FAILURE,
  PATCH_ACTIVE_CARD_DATA,
  PATCH_ACTIVE_CARD_DATA_SUCCESS,
  PATCH_ACTIVE_CARD_DATA_FAILURE,
  DELETE_CARD_DATA,
  DELETE_CARD_DATA_SUCCESS,
  DELETE_CARD_DATA_FAILURE,
  POST_NEW_CARD_DATA,
  POST_NEW_CARD_DATA_SUCCESS,
  POST_NEW_CARD_DATA_FAILURE
} from "./type"

const initialState = {
  data: false,
  requesting: false,
  error: false,
  activeCardData: false,
  activeCardError: false,

  postData: false,
  postRequest: false,
  postError: false,

  postNewData: false,
  postNewRequest: false,
  postNewError: false,

  patchInfo: false,
  patchRequest: false,
  patchError: false,

  deteleInfo: false,
  deleteRequesting: false,
  deleteError: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_CARD_DATA:
      return {
        ...state,
        requesting: true
      }
    case GET_CARD_DATA_SUCCESS:
      return {
        ...state,
        requesting: false,
        data: action.data
      }
    case GET_CARD_DATA_FAILURE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }
    case GET_ACTIVE_CARD_DATA:
      return {
        ...state,
        requesting: true
      }
    case GET_ACTIVE_CARD_DATA_SUCCESS:
      return {
        ...state,
        requesting: false,
        activeCardData: action.data
      }
    case GET_ACTIVE_CARD_DATA_FAILURE:
      return {
        ...state,
        requesting: false,
        activeCardError: action.data
      }
    case POST_CARD_DATA:
      return {
        ...state,
        postRequest: true
      }
    case POST_CARD_DATA_SUCCESS:
      return {
        ...state,
        postRequest: false,
        postData: action.data
      }
    case POST_CARD_DATA_FAILURE:
      return {
        ...state,
        postRequest: false,
        patchError: action.data
      }
    case PATCH_ACTIVE_CARD_DATA:
      return {
        ...state,
        patchRequest: true
      }
    case PATCH_ACTIVE_CARD_DATA_SUCCESS:
      return {
        ...state,
        patchRequest: false,
        patchInfo: action.data
      }
    case PATCH_ACTIVE_CARD_DATA_FAILURE:
      return {
        ...state,
        patchRequest: false,
        postError: action.data
      }
      case DELETE_CARD_DATA:
      return {
        ...state,
        deleteRequesting: true
      }
    case DELETE_CARD_DATA_SUCCESS:
      return {
        ...state,
        deleteRequesting: false,
        deleteInfo: action.data
      }
    case DELETE_CARD_DATA_FAILURE:
      return {
        ...state,
        deleteRequesting: false,
        deleteError: action.data
      }
    case POST_NEW_CARD_DATA:
      return {
        ...state,
        postNewRequest: true
      }
    case POST_NEW_CARD_DATA_SUCCESS:
      return {
        ...state,
        postNewRequest: false,
        postNewData: action.data
      }
    case POST_NEW_CARD_DATA_FAILURE:
      return {
        ...state,
        postNewRequest: false,
        postNewData: action.data
      }
    default:
      return state
  }
}
