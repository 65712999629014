import { all, call, put, takeLatest } from "redux-saga/effects"
import { BASE_URL } from "../../../../config/app"
import XHR from "../../../../utils/XHR"
import {
  getFavoritesFailure,
  getFavoritesSuccess,
  patchFavoriteNovelSuccess,
  patchFavoriteNovelFailure,
  putFavoritesSuccess,
  putFavoritesFailure,
  getAdventureLogSuccess,
  getAdventureLogFailure,
  updateAdventureLogSuccess,
  updateAdventureLogFailure
} from "./action"
import { getAuthorProfile, getBookData, getNovelChapter } from "../../ReaderHome/redux/action"
import {
  GET_FAVORITES,
  PUT_FAVORITES,
  PATCH_FAVORITES_NOVEL,
  GET_ADVENTURE_LOG,
  UPDATE_ADVENTURE_LOG
} from "./type"

const favoritesDataAPI = async (name) => {
  const URL = `${BASE_URL}api/v1/fav-author/?name=${name}`
  const token = await localStorage.getItem("token")

  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

const adventureLogAPI = async (name) => {
  const URL = `${BASE_URL}api/v1/adventure_logs/get/?name=${name}`
  const token = await localStorage.getItem("token")

  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

const favoritesPutDataAPI = async data => {
  const URL = `${BASE_URL}api/v1/fav-author/edit/`
  const token = await localStorage.getItem("token")

  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "PUT",
    data
  }
  return XHR(URL, options)
}

const updateAdventureLogAPI = async data => {
  const URL = `${BASE_URL}api/v1/adventure_logs/edit/`
  const token = await localStorage.getItem("token")

  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "PATCH",
    data
  }
  return XHR(URL, options)
}

const favoriteNovelAPI = async data => {
  const URL = `${BASE_URL}api/v1/fav-novels/edit/`
  const token = await localStorage.getItem("token")

  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "PATCH",
    data
  }
  return XHR(URL, options)
}

function* patchFavoriteNovel({ data, memberID, bookID, chapterID }) {
  try {
    const response = yield call(favoriteNovelAPI, data)
    yield put(patchFavoriteNovelSuccess(response.data))
    if (memberID) {
      yield put(getAuthorProfile(memberID))
    } else if (bookID) {
      yield put(getBookData(bookID))
    } else if (chapterID) {
      yield put(getNovelChapter(chapterID))
    }
  } catch (e) {
    const { response } = e
    yield put(patchFavoriteNovelFailure(response.data))
  }
}

function* favoritesData({ name }) {
  try {
    const response = yield call(favoritesDataAPI, name)
    yield put(getFavoritesSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(getFavoritesFailure(response.data))
  }
}

function* adventureLogData({data}) {
  try {
    const response = yield call(adventureLogAPI, data)
    yield put(getAdventureLogSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(getAdventureLogFailure(response.data))
  }
}

function* putFavoritesData({ data }) {
  try {
    const response = yield call(favoritesPutDataAPI, data)
    yield put(putFavoritesSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(putFavoritesFailure(response.data))
  }
}

function* updateAdventureLog({ data, bookID, chapterID }) {
  try {
    const response = yield call(updateAdventureLogAPI, data)
    yield put(updateAdventureLogSuccess(response.data))
    if (bookID) {
      yield put(getBookData(bookID))
    } else if (chapterID) {
      yield put(getNovelChapter(chapterID))
    }
  } catch (e) {
    const { response } = e
    yield put(updateAdventureLogFailure(response.data))
  }
}

export default all([
  takeLatest(GET_FAVORITES, favoritesData),
  takeLatest(PUT_FAVORITES, putFavoritesData),
  takeLatest(PATCH_FAVORITES_NOVEL, patchFavoriteNovel),
  takeLatest(GET_ADVENTURE_LOG, adventureLogData),
  takeLatest(UPDATE_ADVENTURE_LOG, updateAdventureLog)
])
