import {
  POST_REQUEST_LOGIN,
  POST_REQUEST_LOGIN_SUCCESS,
  POST_REQUEST_LOGIN_FAILURE,
  SET_ACCESS_TOKEN,
  LOGOUT,
  SET_FIREBASE_TOKEN,
  SET_MEMBER_ID,
  SET_USER_ID,
  SET_CHAPTER_ID,
  SET_BOOK_ID,
  SET_WORKROOM_ID,
  SET_APPLICATION_DATA,
  TOGGLE_USER_TYPE,
  TOGGLE_USER_TYPE_SUCCESS,
  TOGGLE_USER_TYPE_FAILURE,
  SET_CHARACTER_ID,
  SET_NEXT_CHAPTER_ID,
  SET_UPDATE_NOVEL_ID
} from "./type"

const initialState = {
  loginInfo: false,
  error: false,
  requesting: false,
  accessToken: false,
  firebaseToken: false,
  memberID: false,
  usersID: false,
  chapterID: false,
  bookID: false,
  workroomID: false,
  characterID: false,
  setApplicateData: false,
  nextChapterID: false,
  getNovelID: false,

  toggleUser: false,
  toggleUserRequesting: false,
  toggleUserError: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case POST_REQUEST_LOGIN:
      return {
        ...state,
        requesting: true
      }

    case POST_REQUEST_LOGIN_SUCCESS:
      return {
        ...state,
        requesting: false,
        loginInfo: action.data
      }
    case POST_REQUEST_LOGIN_FAILURE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }
    case SET_ACCESS_TOKEN:
      return {
        ...state,
        accessToken: action.accessToken
      }
    case SET_FIREBASE_TOKEN:
      return {
        ...state,
        firebaseToken: action.data
      }
      case SET_MEMBER_ID:
      return {
        ...state,
        memberID: action.data
      }
      case SET_USER_ID:
      return {
        ...state,
        usersID: action.data
      }
      case SET_CHAPTER_ID:
      return {
        ...state,
        chapterID: action.data
      }
      case SET_BOOK_ID:
      return {
        ...state,
        bookID: action.data
      }
      case SET_WORKROOM_ID:
      return {
        ...state,
        workroomID: action.data
      }
      case SET_CHARACTER_ID:
        return {
          ...state,
          characterID: action.data
        }
      case SET_APPLICATION_DATA:
      return {
        ...state,
        setApplicateData: action.data
      }
      case SET_NEXT_CHAPTER_ID:
      return {
        ...state,
        nextChapterID: action.data
      }
      case TOGGLE_USER_TYPE:
      return {
        ...state,
        toggleUserRequesting: true
      }

    case TOGGLE_USER_TYPE_SUCCESS:
      return {
        ...state,
        toggleUserRequesting: false,
        toggleUser: action.data
      }
    case TOGGLE_USER_TYPE_FAILURE:
      return {
        ...state,
        toggleUserRequesting: false,
        toggleUserError: action.data
      }
      case SET_UPDATE_NOVEL_ID:
      return {
        ...state,
        getNovelID: action.data
      }
    case LOGOUT:
      return {
        ...state,
        requesting: false,
        accessToken: false,
        firebaseToken: false,
        memberID: false,
        usersID: false,
        chapterID: false,
        bookID: false,
        workroomID: false,
        setApplicateData: false,
        characterID: false,
        nextChapterID: false,
        getNovelID: false,
        loginInfo: false
        }
    default:
      return state
  }
}
