import { all, call, put, takeLatest } from "redux-saga/effects"
import { BASE_URL } from "../../../config/app"
import XHR from "../../../utils/XHR"
import { GET_PRODUCER_HOME, GET_NOVEL_ID, PATCH_NOVEL_REQUEST } from "./type"
import {
  producerHomeFailure,
  producerHomeSuccess,
  getNovelDataSuccess,
  getNovelDataFailure,
  patchNovelDataSuccess,
  patchNovelDataFailure
} from "./action"
import toast from "react-hot-toast"


const producerHomeDataAPI = async (name = "") => {
  const URL = `${BASE_URL}api/v1/producer_home/?name=${name}`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

async function getNovelAPI(data) {
  const URL = `${BASE_URL}api/v1/novels/${data}/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

async function patchNovelAPI(id, data) {
  const URL = `${BASE_URL}api/v1/novels/${id}/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: `Token ${token}`
    },
    method: "PATCH",
    data
  }
  return XHR(URL, options)
}

function* producerHomeData({ data }) {
  try {
    const response = yield call(producerHomeDataAPI, data)
    yield put(producerHomeSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(producerHomeFailure(response.data))
  }
}

function* getNovelData({ data }) {
  try {
    const response = yield call(getNovelAPI, data)
    yield put(getNovelDataSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(getNovelDataFailure(response.data))
  }
}

function* patchNovelData({id, data }) {
  try {
    const response = yield call(patchNovelAPI, id, data)
    yield put(patchNovelDataSuccess(response.data))
    toast.success("Novel updated successfully", {
      id: "cards"
    })
  } catch (e) {
    const { response } = e
    yield put(patchNovelDataFailure(response.data))
  }
}

export default all([
  takeLatest(GET_PRODUCER_HOME, producerHomeData),
  takeLatest(GET_NOVEL_ID, getNovelData),
  takeLatest(PATCH_NOVEL_REQUEST, patchNovelData)
])
