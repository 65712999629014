import React from "react"
import emoji from "../../../assets/images/emoji.svg"
import galleryIcon from "../../../assets/images/gallery.svg"
import micIcon from "../../../assets/images/mic.svg"
import attachmentIcon from "../../../assets/images/attachments.svg"
import sendIcon from "../../../assets/images/send.svg"
import EmojiPicker from "emoji-picker-react"
import { Spinner } from "reactstrap"
import playIcon from "../../../assets/images/play-icon-blue.svg"
import stopIcon from "../../../assets/images/stop-icon-blue.svg"
import "./style.scss"

const ChatInputWrapper = props => {
  const {
    setFileUpload,
    onSend,
    onEmojiClick,
    setInputMessage,
    inputMessage,
    setShowEmojiPicker,
    showEmojiPicker,
    onMicClick,
    isRecording,
    status,
    startRecording,
    stopRecording,
    sendingLoading,
    handleEnterKey,
    disabledField,
    onClick,
    onFocus,
    onWrapperClick,
    selectedFiles,
    handleRemoveFile,
    checkMultiFile,
    setImagesUpload,
    setMultiFile
  } = props

  return (
    <div
      className="container-fluid chat-input-section"
      onClick={onWrapperClick}
    >
      <div className="row chat-input-wrapper">
        <div className="col-8">
          <div className="left-details">
            <div
              className={`emoji ${disabledField ? "disabled" : ""}`}
              onClick={() => setShowEmojiPicker(!showEmojiPicker)}
            >
              <img src={emoji} alt="emoji" />
            </div>
            {showEmojiPicker && (
              <div className="emoji-picker-wrapper">
                <EmojiPicker onEmojiClick={onEmojiClick} />
              </div>
            )}
            <div className="input-wrapper">
              {checkMultiFile.length > 0 ? (
                <div className="chat-selected-file">
                  {checkMultiFile &&
                    checkMultiFile.map((item, i) => (
                      <div className="file">
                        <div
                          className="remove-file"
                          onClick={() => handleRemoveFile(i)}
                        >
                          <span>x</span>
                        </div>
                        <p>{item.name}</p>
                      </div>
                    ))}
                </div>
              ) : (
                <input
                  value={inputMessage}
                  onChange={val => {
                    setInputMessage(val.target.value)
                  }}
                  // value={
                  //   checkMultiFile.length > 0 ? checkMultiFile : inputMessage
                  // }
                  // onChange={val => {
                  //   setInputMessage(val.target.value)
                  //   setMultiFile([])
                  // }}
                  onKeyDown={handleEnterKey}
                  style={{ width: "inherit" }}
                  placeholder="Type a message..."
                  disabled={disabledField}
                  className={`message-input-field ${
                    disabledField ? "disabled" : ""
                  }`}
                  onClick={onClick}
                  onFocus={onFocus}
                />
              )}
            </div>
          </div>
        </div>
        <div className="col-4">
          <div className="right-details">
            <div className="action-wrapper">
              <label for="image">
                <img
                  src={galleryIcon}
                  className={`send-action ${disabledField ? "disabled" : ""}`}
                  alt="gallery"
                />
              </label>
              <input
                type="file"
                style={{ display: "none" }}
                id="image"
                onChange={setImagesUpload}
                disabled={disabledField}
                className={`send-action ${disabledField ? "disabled" : ""}`}
              />
              <div className="mic-icon-wrapper">
                {isRecording && (
                  <div className="mic-options">
                    <p>{status}</p>
                    <div style={{ display: "flex", flexDirection: "row" }}>
                      {status === "recording" ? (
                        <button
                          onClick={() => {
                            stopRecording()
                            onMicClick(!isRecording)
                          }}
                        >
                          <img src={stopIcon} alt="" />
                        </button>
                      ) : (
                        <>
                          <button onClick={startRecording}>
                            <img src={playIcon} alt="" />
                          </button>
                          <button
                            onClick={() => {
                              stopRecording()
                              onMicClick(!isRecording)
                            }}
                          >
                            <img src={stopIcon} alt="" />
                          </button>
                        </>
                      )}
                    </div>
                  </div>
                )}
                <img
                  src={micIcon}
                  alt="gallery"
                  onClick={() => onMicClick(!isRecording)}
                  className={`send-action ${disabledField ? "disabled" : ""}`}
                />
              </div>
              <label for="file">
                <img
                  src={attachmentIcon}
                  className={`send-action ${disabledField ? "disabled" : ""}`}
                  alt="gallery"
                />
              </label>
              <input
                type="file"
                style={{ display: "none" }}
                id="file"
                onChange={setFileUpload}
                disabled={disabledField}
              />
              {sendingLoading ? (
                <div className="send-spinner">
                  <Spinner size="sm" color="black" />
                </div>
              ) : (
                <div
                  onClick={onSend}
                  className={`last-action ${disabledField ? "disabled" : ""}`}
                >
                  <img src={sendIcon} alt="gallery" />
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ChatInputWrapper
