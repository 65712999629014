import {
  GET_AUTHOR_PROFILE,
  GET_AUTHOR_PROFILE_SUCCESS,
  GET_AUTHOR_PROFILE_FAILURE,
  GET_NOVEL_DATA,
  GET_NOVEL_DATA_SUCCESS,
  GET_NOVEL_DATA_FAILURE,
  GET_BOOK_DATA,
  GET_BOOK_DATA_SUCCESS,
  GET_BOOK_DATA_FAILURE,
  GET_CHAPTER_DATA,
  GET_CHAPTER_DATA_SUCCESS,
  GET_CHAPTER_DATA_FAILURE,
  UPLOADED_DATA_CONCAT,
  GET_READER_UPLOADED,
  GET_READER_UPLOADED_SUCCESS,
  GET_READER_UPLOADED_FAILURE,
  GET_READER_FAVORITES,
  GET_READER_FAVORITES_SUCCESS,
  GET_READER_FAVORITES_FAILURE,
  GET_READER_TOP_STORIES,
  GET_READER_TOP_STORIES_SUCCESS,
  GET_READER_TOP_STORIES_FAILURE,
  GET_READER_TOP_WRITERS,
  GET_READER_TOP_WRITERS_SUCCESS,
  GET_READER_TOP_WRITERS_FAILURE,
  GET_READER_TOP_CHARACTERS,
  GET_READER_TOP_CHARACTERS_SUCCESS,
  GET_READER_TOP_CHARACTERS_FAILURE,
  GET_READER_TOP_CHAPTERS,
  GET_READER_TOP_CHAPTERS_SUCCESS,
  GET_READER_TOP_CHAPTERS_FAILURE
} from "./type"

const initialState = {
  authorProfileData: false,
  novelData: false,
  novelLoading: false,
  novelError: false,
  bookData: false,
  bookLoading: false,
  bookError: false,
  chapterData: false,
  chapterLoading: false,
  chapterError: false,
  uploadDataConcat: false,

  readerUploaded: false,
  readerUploadedLoad: false,
  readerUploadedError: false,

  readerFavorites: false,
  readerFavoritesLoad: false,
  readerFavoritesError: false,

  readerStories: false,
  readerStoriesLoad: false,
  readerStoriesError: false,

  readerWriters: false,
  readerWritersLoad: false,
  readerWritersError: false,

  readerCharacters: false,
  readerCharactersLoad: false,
  readerCharactersError: false,

  readerChapters: false,
  readerChaptersLoad: false,
  readerChaptersError: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_AUTHOR_PROFILE:
      return {
        ...state,
        requesting: true
      }
    case GET_AUTHOR_PROFILE_SUCCESS:
      return {
        ...state,
        requesting: false,
        authorProfileData: action.data
      }
    case GET_AUTHOR_PROFILE_FAILURE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }
    case GET_NOVEL_DATA:
      return {
        ...state,
        novelLoading: true
      }
    case GET_NOVEL_DATA_SUCCESS:
      return {
        ...state,
        novelLoading: false,
        novelData: action.data
      }
    case GET_NOVEL_DATA_FAILURE:
      return {
        ...state,
        novelLoading: false,
        novelError: action.data
      }
    case GET_BOOK_DATA:
      return {
        ...state,
        bookLoading: true
      }
    case GET_BOOK_DATA_SUCCESS:
      return {
        ...state,
        bookLoading: false,
        bookData: action.data
      }
    case GET_BOOK_DATA_FAILURE:
      return {
        ...state,
        bookLoading: false,
        bookError: action.data
      }
    case GET_CHAPTER_DATA:
      return {
        ...state,
        chapterLoading: true
      }
    case GET_CHAPTER_DATA_SUCCESS:
      return {
        ...state,
        chapterLoading: false,
        chapterData: action.data
      }
    case GET_CHAPTER_DATA_FAILURE:
      return {
        ...state,
        chapterLoading: false,
        chapterError: action.data
      }
    case UPLOADED_DATA_CONCAT:
      return {
        ...state,
        uploadDataConcat: action.data,
        requesting: false
      }
    case GET_READER_UPLOADED:
      return {
        ...state,
        readerUploadedLoad: true
      }
    case GET_READER_UPLOADED_SUCCESS:
      return {
        ...state,
        readerUploadedLoad: false,
        readerUploaded: action.data
      }
    case GET_READER_UPLOADED_FAILURE:
      return {
        ...state,
        readerUploadedLoad: false,
        readerUploadedError: action.data
      }
    case GET_READER_FAVORITES:
      return {
        ...state,
        readerFavoritesLoad: true
      }
    case GET_READER_FAVORITES_SUCCESS:
      return {
        ...state,
        readerFavoritesLoad: false,
        readerFavorites: action.data
      }
    case GET_READER_FAVORITES_FAILURE:
      return {
        ...state,
        readerFavoritesLoad: false,
        readerFavoritesError: action.data
      }
    case GET_READER_TOP_STORIES:
      return {
        ...state,
        readerStoriesLoad: true
      }
    case GET_READER_TOP_STORIES_SUCCESS:
      return {
        ...state,
        readerStoriesLoad: false,
        readerStories: action.data
      }
    case GET_READER_TOP_STORIES_FAILURE:
      return {
        ...state,
        readerStoriesLoad: false,
        readerStoriesError: action.data
      }
    case GET_READER_TOP_WRITERS:
      return {
        ...state,
        readerWritersLoad: true
      }
    case GET_READER_TOP_WRITERS_SUCCESS:
      return {
        ...state,
        readerWritersLoad: false,
        readerWriters: action.data
      }
    case GET_READER_TOP_WRITERS_FAILURE:
      return {
        ...state,
        readerWritersLoad: false,
        readerWritersError: action.data
      }
    case GET_READER_TOP_CHARACTERS:
      return {
        ...state,
        readerCharactersLoad: true
      }
    case GET_READER_TOP_CHARACTERS_SUCCESS:
      return {
        ...state,
        readerCharactersLoad: false,
        readerCharacters: action.data
      }
    case GET_READER_TOP_CHARACTERS_FAILURE:
      return {
        ...state,
        readerCharactersLoad: false,
        readerCharactersError: action.data
      }
    case GET_READER_TOP_CHAPTERS:
      return {
        ...state,
        readerChaptersLoad: true
      }
    case GET_READER_TOP_CHAPTERS_SUCCESS:
      return {
        ...state,
        readerChaptersLoad: false,
        readerChapters: action.data
      }
    case GET_READER_TOP_CHAPTERS_FAILURE:
      return {
        ...state,
        readerChaptersLoad: false,
        readerChaptersError: action.data
      }
    default:
      return state
  }
}
