import {
  POST_REQUEST_LOGIN,
  POST_REQUEST_LOGIN_SUCCESS,
  POST_REQUEST_LOGIN_FAILURE,
  SET_ACCESS_TOKEN,
  LOGOUT,
  SET_FIREBASE_TOKEN,
  SET_MEMBER_ID,
  SET_USER_ID,
  SET_CHAPTER_ID,
  SET_BOOK_ID,
  SET_WORKROOM_ID,
  SET_APPLICATION_DATA,
  TOGGLE_USER_TYPE,
  TOGGLE_USER_TYPE_SUCCESS,
  TOGGLE_USER_TYPE_FAILURE,
  SET_CHARACTER_ID,
  SET_NEXT_CHAPTER_ID,
  SET_UPDATE_NOVEL_ID
} from "./type"

// POST_PAYMENT_INFORMATION
export const loginRequest = data => ({
  type: POST_REQUEST_LOGIN,
  data
})

export const setAccessToken = accessToken => ({
  type: SET_ACCESS_TOKEN,
  accessToken
})

export const setFirebaseToken = data => ({
  type: SET_FIREBASE_TOKEN,
  data
})

export const loginRequestSuccess = data => ({
  type: POST_REQUEST_LOGIN_SUCCESS,
  data
})

export const loginRequestFailure = data => ({
  type: POST_REQUEST_LOGIN_FAILURE,
  data
})

export const logOut = () => ({
  type: LOGOUT
})
// SAVE IDS
export const saveMemberID = (data) => ({
  type: SET_MEMBER_ID,
  data
})

export const saveUserID = (data) => ({
  type: SET_USER_ID,
  data
})

export const saveChapterID = (data) => ({
  type: SET_CHAPTER_ID,
  data
})

export const saveBookID = (data) => ({
  type: SET_BOOK_ID,
  data
})

export const saveCharacterID = (data) => ({
  type: SET_CHARACTER_ID,
  data
})

export const saveWorkroomID = (data) => ({
  type: SET_WORKROOM_ID,
  data
})

export const setApplicationData = (data) => ({
  type: SET_APPLICATION_DATA,
  data
})

export const setNextChapterID = (data) => ({
  type: SET_NEXT_CHAPTER_ID,
  data
})
// User Type
export const toggleUserType = (id, data) => ({
  type: TOGGLE_USER_TYPE,
  id,
  data,
})

export const toggleUserTypeSuccess = (data) => ({
  type: TOGGLE_USER_TYPE_SUCCESS,
  data
})

export const toggleUserTypeFailure = (data) => ({
  type: TOGGLE_USER_TYPE_FAILURE,
  data
})

export const setUpdateNovelID = (data) => ({
  type: SET_UPDATE_NOVEL_ID,
  data
})
