import { all, call, put, takeLatest } from "redux-saga/effects"
// import AsyncStorage from '@react-native-community/async-storage';
// config
import { BASE_URL } from "../../../../../config/app"
// utils
import XHR from "../../../../../utils/XHR"
// types
import { 
    ADD_YOUR_INFORMATION,
    } from "./type";
// actions
import {
  addYourInformationSuccess,
  addYourInformationFailure,
} from "./action"
import toast from 'react-hot-toast';


async function postPersonalAPI(data) {
  const URL = `${BASE_URL}api/v1/signup/`

  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "POST",
    data
  }
  return XHR(URL, options)
}

function* postPersonalInfo({data, modalState}) {
  try {
    const response = yield call(postPersonalAPI, data)
    localStorage.setItem('token', response.data.token)
    yield put(addYourInformationSuccess(response.data))
    toast.success('User Created Successfully', {
      id: 'card fields',
    })
  } catch (e) {
    const { response } = e

    yield put(addYourInformationFailure(response?.data))
  }
}

export default all([
    takeLatest(ADD_YOUR_INFORMATION, postPersonalInfo),
])