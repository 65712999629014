import {
    POST_PAYMENT_INFORMATION,
    POST_PAYMENT_INFORMATION_SUCCESS,
    POST_PAYMENT_INFORMATION_FAILURE,
   } from "./type";

   const initialState = {
     paymentResponse: false,
     requesting: false,
     error: false,
  };

  export default (state = initialState, action) => {
    switch (action.type) {
      case POST_PAYMENT_INFORMATION:
        return {
          ...state,
          requesting: true,
        };
      case POST_PAYMENT_INFORMATION_SUCCESS:
        return {
          ...state,
          requesting: false,
          paymentResponse: action.data
        };
      case POST_PAYMENT_INFORMATION_FAILURE:
        return {
          ...state,
          requesting: false,
          error: action.data,
        };
      default:
        return state;
    }
  };
  
  
  
  
  
  
  
  