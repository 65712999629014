import {
    GET_ADMIN_SUBSCRIPTION,
    GET_ADMIN_SUBSCRIPTION_SUCCESS,
    GET_ADMIN_SUBSCRIPTION_FAILURE,
    POST_ADMIN_SUBSCRIPTION,
    POST_ADMIN_SUBSCRIPTION_SUCCESS,
    POST_ADMIN_SUBSCRIPTION_FAILURE,
    POST_EDIT_PLANS,
    POST_EDIT_PLANS_SUCCESS,
    POST_EDIT_PLANS_FAILURE,
    GET_SUBSCRIBED_USER,
    GET_SUBSCRIBED_USER_SUCCESS,
    GET_SUBSCRIBED_USER_FAILURE,
    SET_SUB_USER_CONCAT,
    GET_FINANCES_TOP_BOX,
    GET_FINANCES_TOP_BOX_SUCCESS,
    GET_FINANCES_TOP_BOX_FAILURE,
    GET_GRT_DATA,
    GET_GRT_DATA_SUCCESS,
    GET_GRT_DATA_FAILURE,
    GRT_DATA_CONCAT
} from "./type";

const initialState = {
    subData: false,
    subRequesting: false,
    subError: false,

    postData: false,
    postRequesting: false,
    postError: false,

    updatePlan: false,
    updateRequesting: false,
    updateError: false,

    subUsersData: false,
    subUserRequesting: false,
    subUserError: false,

    subConcat: false,

    financeBoxData: false,
    financeBoxRequesting: false,
    financeBoxError: false,

    grtData: false,
    grtRequesting: false,
    grtError: false,
    grtConcat: false
}

export default (state = initialState, action) => {
    switch (action.type) {
        case GET_ADMIN_SUBSCRIPTION:
            return {
                ...state,
                subRequesting: true,
                subError: false
            }
        case GET_ADMIN_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                subRequesting: false,
                subData: action.data
            }
        case GET_ADMIN_SUBSCRIPTION_FAILURE:
            return {
                ...state,
                subRequesting: false,
                subError: action.data
            }
            case POST_ADMIN_SUBSCRIPTION:
            return {
                ...state,
                postRequesting: true,
                postError: false
            }
        case POST_ADMIN_SUBSCRIPTION_SUCCESS:
            return {
                ...state,
                postRequesting: false,
                postData: action.data
            }
        case POST_ADMIN_SUBSCRIPTION_FAILURE:
            return {
                ...state,
                postRequesting: false,
                postError: action.data
            }
            case POST_EDIT_PLANS:
            return {
                ...state,
                updateRequesting: true,
                updateError: false
            }
        case POST_EDIT_PLANS_SUCCESS:
            return {
                ...state,
                updateRequesting: false,
                updatePlan: action.data
            }
        case POST_EDIT_PLANS_FAILURE:
            return {
                ...state,
                updateRequesting: false,
                updateError: action.data
            }
            case GET_SUBSCRIBED_USER:
            return {
                ...state,
                subUserRequesting: true,
            }
        case GET_SUBSCRIBED_USER_SUCCESS:
            return {
                ...state,
                subUserRequesting: false,
                subUsersData: action.data
            }
        case GET_SUBSCRIBED_USER_FAILURE:
            return {
                ...state,
                subUserRequesting: false,
                subUserError: action.data
            }
            case SET_SUB_USER_CONCAT:
            return {
                ...state,
                subConcat: action.data
            }
            case GET_FINANCES_TOP_BOX:
            return {
                ...state,
                financeBoxRequesting: true,
            }
        case GET_FINANCES_TOP_BOX_SUCCESS:
            return {
                ...state,
                financeBoxRequesting: false,
                financeBoxData: action.data
            }
        case GET_FINANCES_TOP_BOX_FAILURE:
            return {
                ...state,
                financeBoxRequesting: false,
                financeBoxError: action.data
            }
            case GET_GRT_DATA:
            return {
                ...state,
                grtRequesting: true,
            }
        case GET_GRT_DATA_SUCCESS:
            return {
                ...state,
                grtRequesting: false,
                grtData: action.data
            }
        case GET_GRT_DATA_FAILURE:
            return {
                ...state,
                grtRequesting: false,
                grtError: action.data
            }
            case GRT_DATA_CONCAT:
            return {
                ...state,
                grtConcat: action.data
            }
        default:
            return state
    }
}
