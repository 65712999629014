import React, { useEffect,useState } from "react"
import Input from "../../../../Input"
import personalInfoImg from "../../../../../assets/images/personal-info-img.svg"
import inputMailIcon from "../../../../../assets/images/input-mail-icon.svg"
import inputProfileIcon from "../../../../../assets/images/input-profile-icon.svg"
import flagIcon from "../../../../../assets/images/flag-black.svg"
import flagIconActive from "../../../../../assets/images/flag-blue.svg"
import Button from "../../../../Button"
import BoardSelect from "../../../../BoardSelect"
import useForm from "../../../../../utils/useForm"
import validator from "../../../../../utils/validation"
import { Toaster } from "react-hot-toast"
import { connect } from "react-redux"
import {
  addAdminUser,
  updateAdminUser,
  addAdminUserFailure
} from "../../../../../pages/AdminScreens/Users/redux/action"
import { BASE_URL } from "../../../../../config/app"
import BoardPhoneInput from "../../../../PhoneInput"
import axios from 'axios';
const AddUserModal = props => {
  const {
    setModalShow,
    setShow,
    updateData,
    setUpdateData,
    postAdminUser,
    addAdminUserFailure,
    updateAdminUser,
    getEditorData,
    addUserRequesting,
    addUserError,
    updateError,
    update
  } = props

  const token = localStorage.getItem("token")

  const onCloseModal = () => {
    setModalShow(false)
    setShow(false)
    setUpdateData({})
    addAdminUserFailure(false)
  }

  const onSubmit = () => {
    addAdminUserFailure(false)
    if (update) {
      const data = {
        email: state.email.value,
        name: state.fullName.value,
        user_type: state.role.value,
        reffering_member: state.memberId.value,
        phone_number: `+${state.phoneNumber.value}`,
      }
      updateAdminUser(data, updateData?.id, setModalShow, setShow, setUpdateData)
    } else {
      const payload = {
        email: state.email.value,
        name: state.fullName.value,
        user_type: state.role.value,
        flags: state.flag.value,
        reffering_member: state.memberId.value,
        phone_number: `+${state.phoneNumber.value}`,
      }
      postAdminUser(payload, setModalShow)
    }
  }

  const stateSchema = {
    email: {
      value: "",
      error: ""
    },
    fullName: {
      value: "",
      error: ""
    },
    role: {
      value: "",
      error: ""
    },
    flag: {
      value: false,
      error: ""
    },
    memberId: {
      value: "",
      error: ""
    },
    phoneNumber: {
      value: "",
      error: ""
    },
  }

  const validationStateSchema = {
    email: {
      required: false,
    },
    fullName: {
      required: false // validator: validator.userName
    },
    role: {
      required: false
    },
    flag: {
      required: true // validator: validator.
    },
    memberId: {
      required: false
    },
    phoneNumber: {
      required: true
    },
  }
  const [editorUserID, setEditorUserID] = useState([])
  const [producerUserID, setProducerUserID] = useState([])

  const { state, setState, handleOnChange, disable } = useForm(
    stateSchema,
    validationStateSchema
  )


  useEffect(() => {
    getProducer()
    getEditor()
  }, [])

  const getProducer = () => {
    const URL = `${BASE_URL}api/v1/novel_user/?user_type=producer`
    const options = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      }
    }
    axios.get(URL,options)
    .then(function (response) {
      if (response.data.length > 0) {
        let editorID = []
        response.data.map(val =>
            editorID.push({
              value: val.id,
              label: val.name
            })
          )
        setProducerUserID(editorID)
      }
    })
    .catch(function (error) {
      // Handle any errors that occurred during the request
      console.error('Error:', error);
    });
  }

  const getEditor = () => {
    const URL = `${BASE_URL}api/v1/novel_user/?user_type=editor`
    const options = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      }
    }
    axios.get(URL,options)
    .then(function (response) {
      if (response.data.length > 0) {
        let editorID = []
        response.data.map(val =>
            editorID.push({
              value: val.id,
              label: val.name
            })
          )
        setEditorUserID(editorID)
      }
    })
    .catch(function (error) {
      // Handle any errors that occurred during the request
      console.error('Error:', error);
    });
  }
  
  return (
    <section className="add-user-section">
      <div className="add-user-header">
        <img src={personalInfoImg} alt="" />
        <h3>Edit Chapter</h3>
      </div>
      <div className="add-user-input-wrapper mt-2">
        <BoardSelect
          onSelect={(val) => handleOnChange('role', val)}
          options={editorUserID}
          placeholder={"Select Editor"}
          defaultValue={state.updateData?.user_type ? 'Select Editor' : updateData?.user_type}
        />
      </div>
      <div className="add-user-input-wrapper mt-4">
        <BoardSelect
          onSelect={(val) => handleOnChange('role', val)}
          options={producerUserID}
          placeholder={"Select Producer"}
          defaultValue={state.updateData?.user_type ? 'Select Producer' : updateData?.user_type}
        />
      </div>
      <div className={`button-wrapper ${update && 'mt-5'}`}>
        <Button
          title="Cancel"
          onClick={onCloseModal}
          className="transparent-btn"
        />
        <Button
          title={'Edit Chapter'}
          disabled={addUserRequesting}
          showSpinner={addUserRequesting}
          onClick={onSubmit}
        />
      </div>
    </section>
  )
}

const mapStateToProps = state => ({
  addUserInfo: state.adminUsers.addUserInfo,
  addUserRequesting: state.adminUsers.addUserRequesting,
  addUserError: state.adminUsers.addUserError,
  updateError: state.adminUsers.updateError,
})

const mapDispatchToProps = dispatch => ({
  postAdminUser: (data, setModalShow) => dispatch(addAdminUser(data, setModalShow)),
  addAdminUserFailure: data => dispatch(addAdminUserFailure(data)),
  updateAdminUser: (data, id, setModalShow, setShow, setUpdateData) => dispatch(updateAdminUser(data, id, setModalShow, setShow, setUpdateData))
})

export default connect(mapStateToProps, mapDispatchToProps)(AddUserModal)
