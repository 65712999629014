// READER Application
export const GET_READER_APPLICATION = 'GET_READER_APPLICATION';
export const GET_READER_APPLICATION_SUCCESS = 'GET_READER_APPLICATION_SUCCESS';
export const GET_READER_APPLICATION_FAILURE = 'GET_READER_APPLICATION_FAILURE';
// WRITER APPLICATION
export const GET_WRITER_APPLICATION = 'GET_WRITER_APPLICATION';
export const GET_WRITER_APPLICATION_SUCCESS = 'GET_WRITER_APPLICATION_SUCCESS';
export const GET_WRITER_APPLICATION_FAILURE = 'GET_WRITER_APPLICATION_FAILURE';
// EDITOR APPLICATION
export const GET_EDITORS_APPLICATION = 'GET_EDITORS_APPLICATION';
export const GET_EDITORS_APPLICATION_SUCCESS = 'GET_EDITORS_APPLICATION_SUCCESS';
export const GET_EDITORS_APPLICATION_FAILURE = 'GET_EDITORS_APPLICATION_FAILURE';
// SAVE APPLICATION
export const GET_SAVE_APPLICATION = 'GET_SAVE_APPLICATION';
export const GET_SAVE_APPLICATION_SUCCESS = 'GET_SAVE_APPLICATION_SUCCESS';
export const GET_SAVE_APPLICATION_FAILURE = 'GET_SAVE_APPLICATION_FAILURE';
// PATCH WRITER
export const PATCH_WRITER_DATA = 'PATCH_WRITER_DATA';
export const PATCH_WRITER_DATA_SUCCESS = 'PATCH_WRITER_DATA_SUCCESS';
export const PATCH_WRITER_DATA_FAILURE = 'PATCH_WRITER_DATA_FAILURE';

// PATCH EDITOR
export const PATCH_EDITOR_DATA = 'PATCH_EDITOR_DATA';
export const PATCH_EDITOR_DATA_SUCCESS = 'PATCH_EDITOR_DATA_SUCCESS';
export const PATCH_EDITOR_DATA_FAILURE = 'PATCH_EDITOR_DATA_FAILURE';

// PATCH PRODUCER
export const PATCH_PRODUCER_DATA = 'PATCH_PRODUCER_DATA';
export const PATCH_PRODUCER_DATA_SUCCESS = 'PATCH_PRODUCER_DATA_SUCCESS';
export const PATCH_PRODUCER_DATA_FAILURE = 'PATCH_PRODUCER_DATA_FAILURE';

// PATCH SAVE
export const PATCH_SAVE_DATA = 'PATCH_SAVE_DATA';
export const PATCH_SAVE_DATA_SUCCESS = 'PATCH_SAVE_DATA_SUCCESS';
export const PATCH_SAVE_DATA_FAILURE = 'PATCH_SAVE_DATA_FAILURE';
