// Bank Link Card
export const GET_CARD_DATA = 'GET_CARD_DATA';
export const GET_CARD_DATA_SUCCESS = 'GET_CARD_DATA_SUCCESS';
export const GET_CARD_DATA_FAILURE = 'GET_CARD_DATA_FAILURE';
// Bank Active Card
export const GET_ACTIVE_CARD_DATA = 'GET_ACTIVE_CARD_DATA';
export const GET_ACTIVE_CARD_DATA_SUCCESS = 'GET_ACTIVE_CARD_DATA_SUCCESS';
export const GET_ACTIVE_CARD_DATA_FAILURE = 'GET_ACTIVE_CARD_DATA_FAILURE';
// POST REQUEST
export const POST_CARD_DATA = 'POST_CARD_DATA';
export const POST_CARD_DATA_SUCCESS = 'POST_CARD_DATA_SUCCESS';
export const POST_CARD_DATA_FAILURE = 'POST_CARD_DATA_FAILURE';
// PATCH
export const PATCH_ACTIVE_CARD_DATA = 'PATCH_ACTIVE_CARD_DATA';
export const PATCH_ACTIVE_CARD_DATA_SUCCESS = 'PATCH_ACTIVE_CARD_DATA_SUCCESS';
export const PATCH_ACTIVE_CARD_DATA_FAILURE = 'PATCH_ACTIVE_CARD_DATA_FAILURE';
// DELETE
export const DELETE_CARD_DATA = 'DELETE_CARD_DATA';
export const DELETE_CARD_DATA_SUCCESS = 'DELETE_CARD_DATA_SUCCESS';
export const DELETE_CARD_DATA_FAILURE = 'DELETE_CARD_DATA_FAILURE';

// add new card
export const POST_NEW_CARD_DATA = 'POST_NEW_CARD_DATA';
export const POST_NEW_CARD_DATA_SUCCESS = 'POST_NEW_CARD_DATA_SUCCESS';
export const POST_NEW_CARD_DATA_FAILURE = 'POST_NEW_CARD_DATA_FAILURE';