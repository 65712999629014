import { all, call, put, takeLatest } from "redux-saga/effects"
import { BASE_URL } from "../../../config/app"
import XHR from "../../../utils/XHR"
import {
  CREATE_NEW_CHAPTER,
  CREATE_NEW_SUBMISSION,
  POST_CREATE_NEW_SUBMISSION,
  SUBMISSION_UPLOAD_FILE,
  PATCH_NOVEL_CHAPTER,
  DELETE_MP3_FILE
} from "./type"
import {
  createNewChapterSuccess,
  createNewChapterFailure,
  createNewSubmissionSuccess,
  createNewSubmissionFailure,
  postCreateNewSubmissionSuccess,
  postCreateNewSubmissionFailure,
  submissionFileUploadSuccess,
  submissionFileUploadFailure,
  patchNovelChapterSuccess,
  patchNovelChapterFailure,
  deleteMp3FileSuccess,
  deleteMp3FileFailure
} from "./action"
import toast from "react-hot-toast"

async function createChapterAPI(data) {
  const URL = `${BASE_URL}api/v1/novel_chapter/`
  const token = localStorage.getItem("token")

  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: `Token ${token}`
    },
    method: "POST",
    data
  }
  return XHR(URL, options)
}

async function patchChapterAPI(id, data) {
  const URL = `${BASE_URL}api/v1/novel_chapter/${id}/`
  const token = localStorage.getItem("token")

  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: `Token ${token}`
    },
    method: "PATCH",
    data
  }
  return XHR(URL, options)
}

async function deleteFileAPI(id) {
  const URL = `${BASE_URL}api/v1/upload_mp3_file/${id}/`
  const token = localStorage.getItem("token")

  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "DELETE"
  }
  return XHR(URL, options)
}

function* deleteFile({ id }) {
  try {
    const response = yield call(deleteFileAPI, id)
    yield put(deleteMp3FileSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(deleteMp3FileFailure(response.data))
  }
}

function* patchNewChapter({ id, data }) {
  try {
    const response = yield call(patchChapterAPI, id, data)
    yield put(patchNovelChapterSuccess(response.data))
    toast.success("Update chapter successfully", {
      id: "card fields"
    })
  } catch (e) {
    const { response } = e
    yield put(patchNovelChapterFailure(response.data))
  }
}

function* createNewChapter({ data }) {
  try {
    const response = yield call(createChapterAPI, data)
    yield put(createNewChapterSuccess(response.data))
    toast.success("Create chapter successful", {
      id: "card fields"
    })
  } catch (e) {
    const { response } = e
    yield put(createNewChapterFailure(response.data))
  }
}
async function createSubmissionAPI(data) {
  const URL = `${BASE_URL}api/v1/submission/`
  const token = localStorage.getItem("token")

  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET",
    data
  }
  return XHR(URL, options)
}

function* createNewSubmission({ data }) {
  try {
    const response = yield call(createSubmissionAPI, data)
    yield put(createNewSubmissionSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(createNewSubmissionFailure(response.data))
  }
}
async function postCreateNewSubmissionAPI(data) {
  const URL = `${BASE_URL}api/v1/submission/`
  const token = localStorage.getItem("token")

  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "POST",
    data
  }
  return XHR(URL, options)
}

function* postCreateNewSubmission({ data }) {
  try {
    const response = yield call(postCreateNewSubmissionAPI, data)
    yield put(postCreateNewSubmissionSuccess(response.data))
    toast.success("Submission Successful", {
      id: "card fields"
    })
  } catch (e) {
    const { response } = e
    yield put(postCreateNewSubmissionFailure(response.data))
  }
}

async function postSubmissionFileAPI(data) {
  const URL = `${BASE_URL}api/v1/submission_file/`
  const token = localStorage.getItem("token")

  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "POST",
    data
  }
  return XHR(URL, options)
}

function* postSubmissionFile({ data }) {
  try {
    const response = yield call(postSubmissionFileAPI, data)
    yield put(submissionFileUploadSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(submissionFileUploadFailure(response.data))
  }
}

export default all([
  takeLatest(CREATE_NEW_CHAPTER, createNewChapter),
  takeLatest(CREATE_NEW_SUBMISSION, createNewSubmission),
  takeLatest(POST_CREATE_NEW_SUBMISSION, postCreateNewSubmission),
  takeLatest(SUBMISSION_UPLOAD_FILE, postSubmissionFile),
  takeLatest(PATCH_NOVEL_CHAPTER, patchNewChapter),
  takeLatest(DELETE_MP3_FILE, deleteFile)
])
