import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import ApplicationCard from "../../components/ApplicationCard"
import HomeModal from "../../components/HomeModal"
import HomeLayout from "../../layout/HomeLayout"
import "./style.scss"
import {
  getReaderApplication,
  getWriterApplication,
  getEditorApplication,
  getSaveApplication,
  patchWriterData,
  patchEditorData,
  patchProducerData,
  patchSaveData,
  patchWriterDataSuccess,
  patchEditorDataSuccess,
  patchProducerDataSuccess,
  patchSaveDataSuccess
} from "./redux/action"

const OpenApplications = props => {
  const {
    getReaderApplication,
    getWriterApplication,
    getEditorApplication,
    getSaveApplication,
    patchWriterData,
    patchEditorData,
    patchProducerData,
    patchSaveData,
    patchWriterDataSuccess,
    patchEditorDataSuccess,
    patchProducerDataSuccess,
    patchSaveDataSuccess,
    readerApp,
    readerAppLoad,
    writerApp,
    writerAppLoad,
    editorApp,
    editorAppLoad,
    saveApp,
    saveAppLoad,
    patchWriter,
    patchWriterLoad,
    patchEditor,
    patchEditorLoad,
    patchProducer,
    patchProducerLoad,
    patchSave,
    patchSaveLoad
  } = props
  const [active, setActive] = useState("Writers")
  const toggleItems = ["Writers", "Editors", "Producers", "Saved"]

  useEffect(() => {
    if (active == "Writers") {
      getReaderApplication()
    } else if (active == "Editors") {
      getWriterApplication()
    } else if (active == "Producers") {
      getEditorApplication()
    } else if (active == "Saved") {
      getSaveApplication()
    }
  }, [active])

  useEffect(() => {
    if (patchWriter.status) {
      getReaderApplication()
      patchWriterDataSuccess(false)
    } else if (patchEditor.status) {
      getWriterApplication()
      patchEditorDataSuccess(false)
    } else if (patchProducer.status) {
      getEditorApplication()
      patchProducerDataSuccess(false)
    } else if (patchSave.status) {
      getSaveApplication()
      patchSaveDataSuccess(false)
    }
  }, [patchWriter, patchEditor, patchProducer, patchSave])

  const patchRequest = (id, status) => {
    const data = {
      status: status
    }
    if (active == "Writers") {
      patchWriterData(id, data)
    } else if (active == "Editors") {
      patchEditorData(id, data)
    } else if (active == "Producers") {
      patchProducerData(id, data)
    } else if (active == "Saved") {
      patchSaveData(id, data)
    }
  }

  return (
    <HomeLayout headerName={"Applications"} searchBarWrapper={true}>
      <section className="content-section open-application-section">
        <div className="home-card-wrapper open-application-wrapper">
          <div className="container-fluid open-application-container">
            <div className="toggle-select">
              {toggleItems &&
                toggleItems.map(item => (
                  <>
                    <div
                      key={item.id}
                      className={`title ${active == item ? "active" : ""}`}
                      onClick={() => setActive(item)}
                    >
                      {item}
                    </div>
                  </>
                ))}
            </div>
            {active == "Writers" ? (
              <div className="row">
                {!readerApp && readerAppLoad ? (
                  [1, 2].map(item => <ApplicationCard isLoader={true} />)
                ) : readerApp?.length > 0 ? (
                  readerApp?.map(item => (
                    <>
                      <h3 className="application-heading">{item?.character_name}</h3>
                      {item?.application.map((item, index) => (
                        <ApplicationCard
                          patchRequesting={patchWriterLoad}
                          // usertype={item.user.user_type}
                          id={item.id}
                          name={item?.character?.novel?.name || ""}
                          about={
                            item?.content || ""
                          }
                          writerName={
                            item?.user?.name || ""
                          }
                          patchRequest={patchRequest}
                          item={item}
                        />
                      ))}
                    </>
                  ))
                ) : (
                  <div className="no-data">No Data</div>
                )}
              </div>
            ) : active == "Editors" ? (
              <div className="row">
                {!writerApp && writerAppLoad ? (
                  [1, 2].map(item => <ApplicationCard isLoader={true} />)
                ) : writerApp?.length > 0 ? (
                  writerApp?.map(item => (
                    <>
                      <h3 className="application-heading">{item?.novel_name}</h3>
                      {item?.application.map((item, index) => (
                        <ApplicationCard
                          patchRequesting={patchEditorLoad}
                          // usertype={item.user.user_type}
                          id={item.id}
                          name={item?.novels?.name || ""}
                          about={item?.content || ""}
                          writerName={
                            item?.user?.name || ""
                          }
                          patchRequest={patchRequest}
                          item={item}
                        />
                      ))}
                    </>
                  ))
                ) : (
                  <div className="no-data">No Data</div>
                )}
              </div>
            ) : active == "Producers" ? (
              <div className="row">
                {!editorApp && editorAppLoad ? (
                  [1, 2].map(item => <ApplicationCard isLoader={true} />)
                ) : editorApp?.length > 0 ? (
                  editorApp?.map(item => (
                    <>
                      <h3 className="application-heading">{item?.novel_name}</h3>
                      {item?.application.map((item, index) => (
                        <ApplicationCard
                          patchRequesting={patchProducerLoad}
                          // usertype={item.user.user_type}
                          id={item.id}
                          name={item?.novels?.name || ""}
                          about={item?.content || ""}
                          writerName={
                            item?.user?.name || ""
                          }
                          patchRequest={patchRequest}
                          item={item}
                        />
                      ))}
                    </>
                  ))
                ) : (
                  <div className="no-data">No Data</div>
                )}
              </div>
            ) : (
              active == "Saved" && (
                <div className="row">
                  {!saveApp && saveAppLoad ? (
                    [1, 2].map(item => <ApplicationCard isLoader={true} />)
                  ) : saveApp?.length > 0 ? (
                    saveApp?.map(item => (
                      <>
                        {item?.character_name && (
                          <>
                            <h3 className="application-heading">{item?.character_name}</h3>
                            {item?.character_application.map(item => (
                              <ApplicationCard
                                patchRequesting={patchSaveLoad}
                                // usertype={item.user.user_type}
                                id={item.id}
                                name={item?.character?.novel?.name || ""}
                                about={
                                  item?.content || ""
                                }
                                writerName={
                                  item?.user?.name || ""
                                }
                                patchRequest={patchRequest}
                                item={item}
                              />
                            ))}
                          </>
                        )}
                        {item?.novel_name && (
                          <>
                            <h3 className="application-heading">{item?.novel_name}</h3>
                            {item?.novel_application.map((item, index) => (
                              <ApplicationCard
                                patchRequesting={patchSaveLoad}
                                // usertype={item.user.user_type}
                                id={item.id}
                                name={item?.novels?.name || ""}
                                about={item?.content || ""}
                                writerName={
                                  item?.user?.name || ""
                                }
                                patchRequest={patchRequest}
                                item={item}
                              />
                            ))}
                          </>
                        )}
                      </>
                    ))
                  ) : (
                    <div className="no-data">No Data</div>
                  )}
                </div>
              )
            )}
          </div>
        </div>
      </section>
      <HomeModal controls={true} />
    </HomeLayout>
  )
}

const mapStateToProps = state => ({
  readerApp: state.openApplications.readerApp,
  readerAppLoad: state.openApplications.readerAppLoad,
  writerApp: state.openApplications.writerApp,
  writerAppLoad: state.openApplications.writerAppLoad,
  editorApp: state.openApplications.editorApp,
  editorAppLoad: state.openApplications.editorAppLoad,
  saveApp: state.openApplications.saveApp,
  saveAppLoad: state.openApplications.saveAppLoad,
  patchWriter: state.openApplications.patchWriter,
  patchWriterLoad: state.openApplications.patchWriterLoad,
  patchEditor: state.openApplications.patchEditor,
  patchEditorLoad: state.openApplications.patchEditorLoad,
  patchProducer: state.openApplications.patchProducer,
  patchProducerLoad: state.openApplications.patchProducerLoad,
  patchSave: state.openApplications.patchSave,
  patchSaveLoad: state.openApplications.patchSaveLoad
})

const mapDispatchToProps = dispatch => ({
  getReaderApplication: data => dispatch(getReaderApplication(data)),
  getWriterApplication: data => dispatch(getWriterApplication(data)),
  getEditorApplication: data => dispatch(getEditorApplication(data)),
  getSaveApplication: data => dispatch(getSaveApplication(data)),
  patchWriterData: (id, status) => dispatch(patchWriterData(id, status)),
  patchEditorData: (id, status) => dispatch(patchEditorData(id, status)),
  patchProducerData: (id, status) => dispatch(patchProducerData(id, status)),
  patchSaveData: (id, status) => dispatch(patchSaveData(id, status)),
  patchWriterDataSuccess: data => dispatch(patchWriterDataSuccess(data)),
  patchEditorDataSuccess: data => dispatch(patchEditorDataSuccess(data)),
  patchProducerDataSuccess: data => dispatch(patchProducerDataSuccess(data)),
  patchSaveDataSuccess: data => dispatch(patchSaveDataSuccess(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(OpenApplications)
