import React from "react"
import closeIcon from "../../../../assets/images/close-icon-modal.svg"
import greaterWhiteIcon from "../../../../assets/images/greater-white.svg"
import { Spinner } from "reactstrap"
import { saveBookID } from "../../../../pages/AuthScreens/Login/redux/action"
import { connect } from "react-redux"
import { saveMemberID } from "../../../../pages/AuthScreens/Login/redux/action"

const CharacterSummary = ({ saveBookID, saveMemberID, setModalShow, history, loginInfo, novalData, setCurrentTab }) => {
  
  const onTab = () => {
    setCurrentTab(1)
    setModalShow(false)
  }

  const setBookID = () => {
    saveBookID(novalData?.novelId)
    history.push({pathname: `/${loginInfo.user.mode}/reading_experience/`})
  }

  const onSaveData = (id, authName) => {
    if(id){
      saveMemberID(id)
      history.push({pathname:`/${loginInfo.user.mode}/member_profile/${authName.toLocaleLowerCase().replace(/\s+/g, '_')}`})
    }
}

return (
    <div className="modal-body-right">
      {novalData?.novelLoading ? (
        <div className="spinner-style">
          <Spinner color="primary" />
        </div>
      ) : (
          <>
            <div className="modal-right-header">
              <h3>Genre</h3>
              <div className="novel">{novalData?.novelCheptor?.chapter?.name}</div>
              <div className="author-details">
                <p className="author" onClick={() => onSaveData(novalData?.novelCheptor?.character?.novel?.author?.id, novalData?.novelCheptor?.character?.novel?.author?.name)}>{novalData?.novelCheptor?.character?.novel?.author?.name || ""}</p>
                <p className="writer">{`Written By ${novalData?.novelCheptor?.character?.writer?.name || ""}`}</p>
              </div>
              <div className="close-icon">
                <img
                  src={closeIcon}
                  alt=""
                  onClick={onTab}
                />
              </div>
            </div>
            <div className="modal-right-body">
              <div className="body-text-wrapper">
                <div className="text-wrapper">
                  <h3>Chapter Summary</h3>
                  <p>{novalData?.novelCheptor?.chapter?.summary || ""}</p>
                </div>
              </div>
            </div>
            <div
              className="modal-right-footer"
              onClick={setBookID}
            >
              <div className="text">Read Chapter 1</div>
              <div className="image-wrapper">
                <img src={greaterWhiteIcon} alt="" />
              </div>
            </div>
          </>)}
    </div>
  )
}

const mapStateToProps = state => ({
  loginInfo: state.login.loginInfo
})

const mapDispatchToProps = dispatch => ({
  saveBookID: data => dispatch(saveBookID(data)),
  saveMemberID: data => dispatch(saveMemberID(data)) 
})

export default connect(mapStateToProps, mapDispatchToProps)(CharacterSummary)
