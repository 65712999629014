import React, { useState, useEffect } from "react"
import Input from "../Input"
import searchIcon from "../../assets/images/search-icon.svg"
import avatarImg from "../../assets/images/avatar.svg"
import closeIcon from "../../assets/images/close-icon-modal.svg"
import "./style.scss"
import { connect } from "react-redux"
import { getAdminUserData } from "../../pages/AdminScreens/Users/redux/action"

const AdminSearch = props => {
  const {
    placeholder = "",
    onChange,
    value,
    userInfo,
    isGroupChat,
    getAdminUserData,
    requesting,
    setSelect,
    setGroupList,
    usersList
  } = props

  const [showSearch, setShowSearch] = useState(false)
  const [selectedAvatar, setSelectedAvatar] = useState([])
  const [selectedId, setSelectedId] = useState(null)

  useEffect(() => {
    getAdminUserData("", "", 1, "all")
  }, [])

  const filterBySearch = value => {
    setTimeout(() => {
      getAdminUserData("", value, 1, "all")
    }, [])
  }

  const handleSelectUser = item => {
    if (isGroupChat) {
      if (selectedAvatar.includes(item)) {
        const filterData = selectedAvatar.filter(val => val.id !== item.id)
        setSelectedAvatar(filterData)
        setSelect(filterData)
      } else {
        setSelectedAvatar([...selectedAvatar, item])
        setGroupList([...usersList, item.id])
      }
    } else {
      setSelectedAvatar([item])
      setSelect(item.id)
      if (selectedId === item.id) {
        setSelectedId(null)
        setSelectedAvatar([])
        setSelect(null)
      } else {
        setSelectedId(item.id)
        setSelectedAvatar([item])
        setSelect(item.id)
      }
    }
  }

  return (
    <>
      <div className="search-wrapper admin-search-wrapper">
        <div className="search-field">
          <Input
            type="text"
            placeholder={placeholder}
            showIcon={true}
            src={searchIcon}
            onFocus={() => setShowSearch(true)}
            onChange={val => filterBySearch(val.target.value)}
          />
          {showSearch && (
            <div className="suggested-wrapper">
              <div className="suggested-item">
                <div
                  className="close-icon"
                  onClick={() => {
                    setShowSearch(false)
                  }}
                >
                  <img src={closeIcon} alt="" />
                </div>
                {userInfo.length > 0 ? (
                  userInfo.map(item => (
                    <div
                      className="item"
                      key={item.id}
                      onClick={() => {
                        handleSelectUser(item)
                      }}
                    >
                      <div
                        className={`avatar-img 
                        ${isGroupChat
                            ? selectedAvatar.includes(item) && "selected-avatar"
                            : selectedId === item.id && "selected-avatar"
                          }
                        `}
                      >
                        <img
                          src={item.profileUrl ? item.profileUrl : avatarImg}
                          alt=""
                        // style={{ borderRadius: "20px" }}
                        />
                      </div>
                      <div className="avatar-name" title={item.name}>
                        {item.name}
                      </div>
                    </div>
                  ))
                ) : (
                  <p style={{ textAlign: "center" }}>No User</p>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        className={`selected-user-wrapper ${selectedAvatar.length > 0 ? "opacity-1" : ""
          }`}
      >
        {selectedAvatar.map(item => (
          <div className="selected">
            <div className="avatar-img selected-avatar">
              <img src={item.profileUrl ? item.profileUrl : avatarImg} alt="" />
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  userInfo: state.adminUsers.userInfo,
  requesting: state.adminUsers.requesting
})

const mapDispatchToProps = dispatch => ({
  getAdminUserData: (data, search, page, isType) =>
    dispatch(getAdminUserData(data, search, page, isType))
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminSearch)
