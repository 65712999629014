import {
  PRIVACY_POLICY_DATA,
  PRIVACY_POLICY_DATA_SUCCESS,
  PRIVACY_POLICY_DATA_FAILURE
} from "./type"

// POST_PAYMENT_INFORMATION
export const getPrivacyRequest = data => ({
  type: PRIVACY_POLICY_DATA,
  data
})

export const getPrivacySuccess = data => ({
  type: PRIVACY_POLICY_DATA_SUCCESS,
  data
})

export const getPrivacyFailure = data => ({
  type: PRIVACY_POLICY_DATA_FAILURE,
  data
})
