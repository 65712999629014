import React, { useState } from "react"
import Modal from "react-bootstrap/Modal"
import imageIcon from "../../assets/images/image-icon.svg"
import greaterWhiteIcon from "../../assets/images/greater-white.svg"
import lessWhiteIcon from "../../assets/images/less-white-icon.svg"
import { useHistory } from "react-router-dom"
import "./style.scss"
import NovelDetails from "./components/NovelDetails"
import CharacterDetails from "./components/CharacterDetails"
import CharacterSummary from "./components/CharacterSummary"

const HomeModal = ({ modalShow, setModalShow, controls, readerHomeData }) => {
  const history = useHistory()
  const [currentTab, setCurrentTab] = useState(1)
  const [novalData, setNovalData] = useState(false)

  const modalTab = [
    {
      id: 1,
      component: (
        <NovelDetails
          setCurrentTab={setCurrentTab}
          setModalShow={setModalShow}
          readerHomeData={readerHomeData}
        />
      )
    },
    {
      id: 2,
      component: (
        <CharacterDetails
          setCurrentTab={setCurrentTab}
          setModalShow={setModalShow}
          novelId={readerHomeData?.id}
          setNovalData={setNovalData}
        />
      )
    },
    {
      id: 3,
      component: (
        <CharacterSummary
          history={history}
          setModalShow={setModalShow}
          novalData={novalData}
          setCurrentTab={setCurrentTab}
        />
      )
    }
  ]

  return (
    <>
      <Modal
        show={modalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="home-genre-modal"
      >
        <Modal.Body>
          {controls && (
            <div className="controls-group">
              <div className="control left-control">
                <img src={lessWhiteIcon} alt="left" />
              </div>
              <div className="control right-control">
                <img src={greaterWhiteIcon} alt="right" />
              </div>
            </div>
          )}
          <div className="modal-body-left">
            <div className={`image-wrapper ${readerHomeData?.thumbnail ? '' : 'no-img-wrapper'}`}>
              <img src={readerHomeData?.thumbnail || imageIcon} alt="" />
            </div>
          </div>
          {modalTab.map(item => currentTab === item.id && item.component)}
        </Modal.Body>
      </Modal>
    </>
  )
}

export default HomeModal
