import { all, call, put, takeLatest } from "redux-saga/effects"
import { BASE_URL } from "../../../../config/app"
import XHR from "../../../../utils/XHR"
import {
  GET_ADMIN_USER,
  ADMIN_USER_DELETE,
  FLAGS_UPDATE,
  ADD_ADMIN_USER,
  UPDATE_ADMIN_USER
} from "./type"
import {
  getAdminUserData,
  getAdminUserSuccess,
  getAdminUserFailure,
  adminUserDeleteSuccess,
  adminUserDeleteFailure,
  flagsUpdateSuccess,
  flagsUpdateFailure,
  addAdminUserSuccess,
  addAdminUserFailure,
  updateAdminUserSuccess,
  updateAdminUserFailure,
  setCurrentPages,
} from "./action"
import toast from "react-hot-toast"

async function getAdminUserAPI(data = "", search = "", page = 1) {
  const URL = `${BASE_URL}api/v1/admin-user-list/?name=${search}&user_type=${data}&page=${page}`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    }
  }
  return XHR(URL, options)
}

async function deleteAdminUserAPI(id) {
  const URL = `${BASE_URL}api/v1/admin-user-list/${id}/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "DELETE"
  }
  return XHR(URL, options)
}

async function addAdminUserAPI(data) {
  const URL = `${BASE_URL}api/v1/admin_add_user/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "POST",
    data
  }
  return XHR(URL, options)
}

async function updateAdminUserAPI(data, id) {
    const URL = `${BASE_URL}api/v1/admin-user-list/${id}/`
    const token = localStorage.getItem("token")
    const options = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      },
      method: "PATCH",
      data
    }
    return XHR(URL, options)
  }

  async function flagUserAPI(data, id) {
    const URL = `${BASE_URL}api/v1/admin-user-list/${id}/`
    const token = localStorage.getItem("token")
    const options = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      },
      method: "PATCH",
      data
    }
    return XHR(URL, options)
  }

  function* updateAdminUser({ data, id, setModalShow, setShow, setUpdateData }) {
    try {
      const response = yield call(updateAdminUserAPI, data, id)
      yield put(updateAdminUserSuccess(response.data))
      toast.success("Updated Successfully", {
        id: 'card fields',
      })
      setModalShow(false)
       setShow(false)
        setUpdateData({})
      yield put(getAdminUserData())
    } catch (e) {
      const { response } = e
      yield put(updateAdminUserFailure(response?.data))
    }
  }

function* addAdminUser({ data, setModalShow }) {
  try {
    const response = yield call(addAdminUserAPI, data)
    yield put(addAdminUserSuccess(response.data))
    toast.success("User Added Successfully", {
      id: 'card fields',
    })
    yield put(getAdminUserData())
    setModalShow(false)
  } catch (e) {
    const { response } = e
    yield put(addAdminUserFailure(response?.data))
  }
}

function* getAdminUser({ data, search, page}) {
  try {
    const response = yield call(getAdminUserAPI, data, search, page)
    yield put(getAdminUserSuccess(response?.data.results))
    yield put(setCurrentPages(response?.data))
  } catch (e) {
    const { response } = e
    yield put(getAdminUserFailure(response?.data))
  }
}
function* deleteAdminUser({ data }) {
  try {
    const response = yield call(deleteAdminUserAPI, data)
    yield put(adminUserDeleteSuccess(response.data))
    toast.success("Delete Successful", {
      id: 'card fields',
    })
    yield put(getAdminUserData())
  } catch (e) {
    const { response } = e
    yield put(adminUserDeleteFailure(response?.data))
    toast.error("Something Went Wrong", {
      id: 'card fields',
    })
  }
}

function* flagsUserUpdate({ data, id }) {
  try {
    const response = yield call(flagUserAPI, data, id)
    yield put(flagsUpdateSuccess(response.data))
    toast.success("Flags Update Successful", {
      id: 'card fields',
    })
    yield put(getAdminUserData())
  } catch (e) {
    const { response } = e
    yield put(flagsUpdateFailure(response?.data))
    toast.error("Something Went Wrong", {
      id: 'card fields',
    })
  }
}

export default all([
  takeLatest(GET_ADMIN_USER, getAdminUser),
  takeLatest(ADMIN_USER_DELETE, deleteAdminUser),
  takeLatest(FLAGS_UPDATE, flagsUserUpdate),
  takeLatest(ADD_ADMIN_USER, addAdminUser),
  takeLatest(UPDATE_ADMIN_USER, updateAdminUser),
])
