import React, { useState } from "react"
import notificationMsg from "../../../../../assets/images/notification-msg.svg"
import Button from "../../../../Button"
import { Toaster } from "react-hot-toast"
import AdminSearch from "../../../../AdminSearch"
import BoardRangePicker from "../../../../BoardRangePicker"
import BoardTimePicker from "../../../../BoardTimePicker"
import Input from "../../../../Input"
import TextArea from "../../../../TextArea"
import pencilIcon from "../../../../../assets/images/textarea-write.svg"
import clockIcon from "../../../../../assets/images/clock.svg"
import { connect } from "react-redux"
import {
  postUserPushNotification,
  postUserPushNotificationFailure,
} from "../../../../../pages/AdminScreens/PushNotifications/redux/action"


const CreatePushNotification = props => {
  const {
    postPushNotification,
    PushNotificationFailure,
    userRequesting,
    userError,
    setShowModal,
    setModalShow,
  } = props
  const [titleValue, setTitleValue] = useState("")
  const [messageValue, setMessageValue] = useState("")
  const [select, setSelect] = useState(null)
  const [checkBtn, setCheckBtn] = useState(false)
  const [rangPic, setRangPic] = useState({})
  const [showTimePicker, setShowTimePicker] = useState(false)
  const [value, onChange] = useState([])
  const [timeRangPic, setTimeRangPic] = useState([])

  const onChanges = (value, dateString) => {
    const first = dateString[0]
    const second = dateString[1]
    setRangPic({ start: first, end: second })
  };

  const onSubmit = (nType) => {
    setCheckBtn(nType)
    const to_time = value[0]
    const from_time = value[1]
    const data = {
      title: titleValue,
      message: messageValue,
      notification_type: nType,
      selected_type: "user",
      user: select,
      to_time: to_time,
      from_time: from_time,
      start_date: rangPic.start,
      end_date: rangPic.end,
      local_timezone: Intl.DateTimeFormat().resolvedOptions().timeZone
    }
    postPushNotification(data, setShowModal, setModalShow, nType)
  }

  const onTimeSubmit = () => {
    setShowTimePicker(false)
    setTimeRangPic(value)
  }

  return (
    <section className="add-user-section create-push-notification-section">
      <div className="add-user-header">
        <div>
          <Toaster position="top-center" />
        </div>
        <img src={notificationMsg} alt="" />
        <h3>New Push Notification</h3>
      </div>
      <div className="notification-creation-wrapper">
        <Input
          type="text"
          placeholder="Title"
          showIcon={true}
          src={pencilIcon}
          value={titleValue}
          onChange={e => setTitleValue(e.target.value)}
          showError={true}
          errorMessage={userError.title && userError.title}
          onFocus={() => PushNotificationFailure(false)}
        />
        <TextArea
          placeholder="Message"
          value={messageValue}
          onChange={e => setMessageValue(e.target.value)}
          showError={true}
          errorMessage={userError.message && userError.message[0]}
          onFocus={() => PushNotificationFailure(false)}
        />
        <AdminSearch placeholder="Select Users" setSelect={setSelect} />

        <h3>Set Schedule</h3>
        <div className="field-row">
          <div className="field">
            <BoardRangePicker onChange={onChanges} />
            <div className={`error-message ${userError.msg ? 'opacity-1' : ''}`}>{userError.msg && userError.msg}</div>
          </div>
          <div className="field">
            {showTimePicker &&
              <BoardTimePicker onChange={onChange} value={value} onClick={onTimeSubmit} />
            }
            <Input
              placeholder="Select Time"
              type="text"
              showIcon={true}
              src={clockIcon}
              value={timeRangPic.join("   To   ")}
              onClick={() => setShowTimePicker(!showTimePicker)}
            />
          </div>
        </div>
      </div>
      <div className="button-wrapper">
        <Button
          title="Set Notification"
          className="transparent-btn"
          onClick={() => onSubmit("scheduled")}
          showSpinner={checkBtn === "scheduled" && userRequesting}
          disabled={titleValue.trim() === '' || messageValue.trim() === '' || select === null ? true : false}
        />
        <Button
          title="Send Immediately"
          onClick={() => onSubmit("send_now")}
          showSpinner={checkBtn === "send_now" && userRequesting}
        />
      </div>
    </section>
  )
}

const mapStateToProps = state => ({
  userPush: state.pushNotifications.userPush,
  userRequesting: state.pushNotifications.userRequesting,
  userError: state.pushNotifications.userError
})

const mapDispatchToProps = dispatch => ({
  postPushNotification: (data, setShowModal, setModalShow, nType) => dispatch(postUserPushNotification(data, setShowModal, setModalShow, nType)),
  PushNotificationFailure: data => dispatch(postUserPushNotificationFailure(data)),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreatePushNotification)
