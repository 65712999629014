import Header from '../../components/Header';
import React from 'react';
import SidebarMenu from '../../components/SidebarMenu';

import './style.scss';

const AdminLayout = (props) => {
    return (
        <section className='homepage-section admin-section'>
            <SidebarMenu />
            <div className="main-section">
                <Header
                    className={props.className}
                    onChange={props.onChange}
                    name={props.headerName}
                    showSearch={props.searchBarWrapper}
                    showBack={props.showBack}
                    showRightWrapper={props.showRightWrapper}
                    showFavorite={props.showFavorite}
                    showChatButton={props.showChatButton}
                    tab={props.tab}
                />
                {props.children}
            </div>
        </section>
    )
}

export default AdminLayout