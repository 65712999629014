import {
  PUT_REQUEST_PASSWORD,
  PUT_REQUEST_PASSWORD_SUCCESS,
  PUT_REQUEST_PASSWORD_FAILURE,
  GET_REQUEST_PROFILE_DATA,
  GET_REQUEST_PROFILE_DATA_SUCCESS,
  GET_REQUEST_PROFILE_DATA_FAILURE,
  PUT_REQUEST_ABOUT_ME,
  PUT_REQUEST_ABOUT_ME_SUCCESS,
  PUT_REQUEST_ABOUT_ME_FAILURE
} from "./type"

export const putRequestPassword = (data) => ({
  type: PUT_REQUEST_PASSWORD,
  data
})

export const putRequestPasswordSuccess = data => ({
  type: PUT_REQUEST_PASSWORD_SUCCESS,
  data
})

export const putRequestPasswordFailure = data => ({
  type: PUT_REQUEST_PASSWORD_FAILURE,
  data
})
// GET REQUEST PROFILE DATA
export const getRequestProfileData = () => ({
  type: GET_REQUEST_PROFILE_DATA,
})

export const getRequestProfileSuccess = data => ({
  type: GET_REQUEST_PROFILE_DATA_SUCCESS,
  data
})

export const getRequestProfileFailure = data => ({
  type: GET_REQUEST_PROFILE_DATA_FAILURE,
  data
})
// PUT_REQUEST_ABOUT_ME

export const putRequestAboutMe = data => ({
  type: PUT_REQUEST_ABOUT_ME,
  data
})

export const putRequestAboutMeSuccess = data => ({
  type: PUT_REQUEST_ABOUT_ME_SUCCESS,
  data
})

export const putRequestAboutMeFailure = data => ({
  type: PUT_REQUEST_ABOUT_ME_FAILURE,
  data
})