import { all, call, put, takeLatest } from "redux-saga/effects"
import { BASE_URL } from "../../../config/app"
import XHR from "../../../utils/XHR"
import {
  postApplyPositionSuccess,
  postApplyPositionFailure,
  getWriterAdvertsSuccess,
  getWriterAdvertsFailure,
  getWriterCurrentSuccess,
  getWriterCurrentFailure,
  getWriterWorkroomSuccess,
  getWriterWorkroomFailure,
} from "./action"
import { APPLY_FOR_POSITION, GET_WRITER_ADVERTS, GET_WRITER_CURRENT, GET_WRITER_WORKROOMS } from "./type"
import toast from "react-hot-toast"

const writerAdvertsAPI = async (name="", page=1 ) => {
  const URL = `${BASE_URL}api/v1/request_novel/?name=${name}&page=${page}`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

const writerCurrentAPI = async () => {
  const URL = `${BASE_URL}api/v1/current_applications/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

const writerWarkroomAPI = async () => {
  const URL = `${BASE_URL}api/v1/writer_available_workroom/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

const applyPositionAPI = async data => {
  const URL = `${BASE_URL}api/v1/request-position/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "POST",
    data
  }
  return XHR(URL, options)
}

function* applyPositionData({ data }) {
  try {
    const response = yield call(applyPositionAPI, data)
    yield put(postApplyPositionSuccess(response.data))
    toast.success("Position applied successfully", {
      id: "card fields"
    })
  } catch (e) {
    const { response } = e
    yield put(postApplyPositionFailure(response.data))
  }
}

function* writerAdverts({data, page}) {
  try {
    const response = yield call(writerAdvertsAPI, data, page)
    yield put(getWriterAdvertsSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(getWriterAdvertsFailure(response.data))
  }
}

function* writerCurrent() {
  try {
    const response = yield call(writerCurrentAPI)
    yield put(getWriterCurrentSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(getWriterCurrentFailure(response.data))
  }
}

function* writerWorkroom() {
  try {
    const response = yield call(writerWarkroomAPI)
    yield put(getWriterWorkroomSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(getWriterWorkroomFailure(response.data))
  }
}

export default all([
  takeLatest(GET_WRITER_ADVERTS, writerAdverts),
  takeLatest(GET_WRITER_CURRENT, writerCurrent),
  takeLatest(GET_WRITER_WORKROOMS, writerWorkroom),
  takeLatest(APPLY_FOR_POSITION, applyPositionData),
])
