// FEEDBACK GET
export const GET_ADMIN_FEEDBACK = 'GET_ADMIN_FEEDBACK';
export const GET_ADMIN_FEEDBACK_SUCCESS = 'GET_ADMIN_FEEDBACK_SUCCESS';
export const GET_ADMIN_FEEDBACK_FAILURE = 'GET_ADMIN_FEEDBACK_FAILURE';
// FEEDBACK GET ONE
export const GET_FEEDBACK_BY_ID = 'GET_FEEDBACK_BY_ID';
export const GET_FEEDBACK_BY_ID_SUCCESS = 'GET_FEEDBACK_BY_ID_SUCCESS';
export const GET_FEEDBACK_BY_ID_FAILURE = 'GET_FEEDBACK_BY_ID_FAILURE';
// FEEDBACK PUT
export const PUT_ADMIN_FEEDBACK = 'PUT_ADMIN_FEEDBACK';
export const PUT_ADMIN_FEEDBACK_SUCCESS = 'PUT_ADMIN_FEEDBACK_SUCCESS';
export const PUT_ADMIN_FEEDBACK_FAILURE = 'PUT_ADMIN_FEEDBACK_FAILURE';
