import React, { useRef, useState, useEffect } from "react"
import Input from "../../../components/Input"
import HomeLayout from "../../../layout/HomeLayout"
import mailIcon from "../../../assets/images/mail-icon.svg"
import profileIcon from "../../../assets/images/profile.svg"
import uploadIcon from "../../../assets/images/upload.svg"
import "./style.scss"
import Button from "../../../components/Button"
import CharacterCard from "../../../components/CharacterCard"
import validator from "../../../utils/validation"
import useForm from "../../../utils/useForm"
import {
  postBecomeWriter,
  getBecomeWriterData,
  postBecomeWriterFailure,
  getBecomeNovelData
} from "./redux/action"
import { connect } from "react-redux"
import { Toaster } from "react-hot-toast"
import TextArea from "../../../components/TextArea"

const BecomeWriter = props => {
  const {
    getBecomeWriterData,
    getBecomeNovelData,
    postBecomeWriter,
    becomeWriterData,
    becomeNovel,
    requesting,
    data,
    datarequesting,
    error,
    postBecomeWriterFailure,
    loginInfo
  } = props

  const [writerDetails, setWriterDetails] = useState(false)
  const [selectCard, setSelectCard] = useState(false)
  const [selectedFile, setSelectedFile] = useState({})
  const [isFilePicked, setIsFilePicked] = useState(false)
  const [fileError, setFileError] = useState(false)

  const fileRef = useRef(null)

  useEffect(() => {
    if (loginInfo.user.mode == "reader") {
      getBecomeWriterData()
    } else {
      getBecomeNovelData()
    }
    postBecomeWriterFailure(false)
  }, [])

  useEffect(() => {
    if (data.id) {
      setState(stateSchema)
      // setSelectedFile({})
    }
  }, [data.id])

  const handleChange = e => {
    setFileError(false)
    const selectedFile = e.target.files[0]
    const fileSize = selectedFile.size
    const fileMb = parseFloat(fileSize / (1024 * 1024)).toFixed(50)
    postBecomeWriterFailure(false)
    if (fileMb > 50) {
      setFileError("The maximum file size that can be uploaded is 50MB")
      setSelectedFile({})
    } else {
      setSelectedFile(selectedFile)
      setIsFilePicked(true)
    }
  }

  const onCancel = () => {
    setWriterDetails(false)
    // setSelectedFile({})
    postBecomeWriterFailure(false)
    setFileError(false)
    setState(stateSchema)
  }

  const onSubmit = () => {
    setFileError(false)
    const formData = new FormData()
    formData.append("title", state.userName.value)
    formData.append("content", state.content.value)
    if (loginInfo.user.mode == "reader") {
      formData.append("character", selectCard)
    } else {
      formData.append("novels", selectCard)
    }
    postBecomeWriter(formData)
  }

  const handleUploadClick = () => {
    fileRef.current?.click()
    return false
  }

  const stateSchema = {
    userName: {
      value: "",
      error: ""
    },
    content: {
      value: "",
      error: ""
    },
  }
  const validationStateSchema = {
    userName: {
      required: true,
      validator: ""
    },
    content: {
      required: true,
      validator: ""
    }
  }
  const { state, handleOnChange, setState, disable } = useForm(
    stateSchema,
    validationStateSchema
  )

  return (
    <HomeLayout
      headerName={`Become a ${loginInfo.user.mode == "writer"
          ? "Editor"
          : loginInfo.user.mode == "editor"
            ? "Producer"
            : "Writer"
        }`}
    >
      <Toaster position="top-center" />
      <section className="content-section writer-section">
        <div className="open-character-section">
          <div className="open-character-details-wrapper">
            <div className="home-card-wrapper open-character-wrapper">
              <div className="container-fluid home-card-container">
                <div className="section-description">
                  <p>
                    We're excited to connect you with producers who are looking for writers to bring their characters to life. Here, you'll find a dedicated section for producers who are seeking writers to write as a character in their story. This section lists all of the current openings for writers on the platform, making it easy for you to find the perfect character to write for.<br /><br />

                    To learn more about the available characters, simply click on the listing to read more about the project and the type of writer that the producer is looking for. Once you find a character that interests you, simply click to apply for the position.<br /><br />

                    Story Host is designed to bring fascinating character perspectives together into one world that holds a fascinating story. With this easy-to-use platform, you can apply to become a writer and bring your character, and their unique perspective, to life. So what are you waiting for? Sign up today to become a writer and start exploring the opportunities available!<br /><br />
                  </p>
                </div>
                <h2 className="title">
                  {loginInfo.user.mode == "writer" ||
                    loginInfo.user.mode == "editor"
                    ? "Open Novels"
                    : "Open Characters"}
                </h2>
                <div className="row home-card-row character-card-row">
                  {requesting
                    ? [1, 2, 3, 4, 5]?.map((item, i) => (
                      <CharacterCard isLoaded={true} />
                    ))
                    : loginInfo.user.mode == "writer" ||
                      loginInfo.user.mode == "editor"
                      ? becomeNovel &&
                      becomeNovel?.results.map(item => (
                        <CharacterCard
                          cardId={item?.id}
                          activeCard={
                            selectCard === item.id ? "active-card" : ""
                          }
                          bookName={item?.genre ? item?.genre : "---"}
                          characterName={item?.name}
                          details={item?.opening_scene}
                          about={item?.about}
                          setWriterDetail={setWriterDetails}
                          setSelectCard={setSelectCard}
                          toptitle={item?.name}
                          novalDetails={item?.personality}
                        />
                      ))
                      : becomeWriterData &&
                      becomeWriterData?.map((item, i) => (
                        <CharacterCard
                          cardId={item?.id}
                          activeCard={
                            selectCard === item.id ? "active-card" : ""
                          }
                          bookName={
                            item?.novel?.name ? item?.novel?.name : "---"
                          }
                          characterName={item?.name}
                          details={item?.personality}
                          about={item?.about}
                          setWriterDetail={setWriterDetails}
                          setSelectCard={setSelectCard}
                          toptitle={item?.name}
                          paraTitle={item?.novel?.name}
                          novalDetails={item?.personality}
                        />
                      ))}
                </div>
              </div>
            </div>
          </div>
        </div>

        {writerDetails === 1 ? (
          <div className="writer-details-section">
            <div className="container-fluid information-wrapper">
              <h3>Input your information:</h3>

              <div className="input-row">
                <Input
                  showIcon={true}
                  src={profileIcon}
                  placeholder="Title"
                  type="text"
                  value={state.userName.value}
                  showError={true}
                  onChange={e => handleOnChange("userName", e.target.value)}
                  errorMessage={error?.name && error?.name}
                />
                {/* <Input
                  showIcon={true}
                  src={mailIcon}
                  placeholder="Enter Email Address"
                  type="email"
                  value={state.email.value}
                  showError={true}
                  onChange={e => handleOnChange("email", e.target.value)}
                  errorMessage={error?.email && error?.email}
                /> */}
              </div>
              {/* <div className="upload-file-wrapper" onClick={handleUploadClick}>
                <div className="upload-file">
                  <div className="icon-wrapper">
                    <img src={uploadIcon} alt="upload" />
                    <div className="sample">Upload Sample Work</div>
                    <input
                      type="file"
                      name="file"
                      ref={fileRef}
                      onChange={e => handleChange(e)}
                      hidden
                      value=""
                    />
                    {isFilePicked && (
                      <div>
                        <p className="selected-file-name">
                          {selectedFile?.name}
                        </p>
                      </div>
                    )}
                  </div>
                </div>
              </div> */}
              <TextArea placeholder="Content" value={state.content.value} onChange={(e) => handleOnChange("content", e.target.value)} />
              <div
                className={`error-message mt-2 ${(error && error.sample) || fileError ? "opacity-1" : ""
                  }`}
              >
                {error ? error.sample : fileError && fileError}
              </div>
              <div className="button-wrapper">
                <Button
                  title="Submit Application"
                  showSpinner={datarequesting}
                  onClick={onSubmit}
                />
                <Button
                  title="Cancel"
                  className="transparent-btn"
                  onClick={onCancel}
                />
              </div>
            </div>
          </div>
        ) : null}
      </section>
    </HomeLayout>
  )
}

const mapStateToProps = state => ({
  becomeWriterData: state.becomeWriter.becomeWriterData,
  data: state.becomeWriter.data,
  requesting: state.becomeWriter.requesting,
  datarequesting: state.becomeWriter.datarequesting,
  error: state.becomeWriter.error,
  becomeNovel: state.becomeWriter.becomeNovel,
  loginInfo: state.login.loginInfo
})

const mapDispatchToProps = dispatch => ({
  postBecomeWriter: data => dispatch(postBecomeWriter(data)),
  getBecomeWriterData: data => dispatch(getBecomeWriterData(data)),
  postBecomeWriterFailure: data => dispatch(postBecomeWriterFailure(data)),
  getBecomeNovelData: data => dispatch(getBecomeNovelData(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(BecomeWriter)
