import {
    GET_ADMIN_HOME,
    GET_ADMIN_HOME_SUCCESS,
    GET_ADMIN_HOME_FAILURE,
    GET_ADMIN_HOME_BOX,
    GET_ADMIN_HOME_BOX_SUCCESS,
    GET_ADMIN_HOME_BOX_FAILURE
} from "./type";

export const getAdminHome = (data) => ({
    type: GET_ADMIN_HOME,
    data,
});

export const getAdminHomeSuccess = (data) => ({
    type: GET_ADMIN_HOME_SUCCESS,
    data
});

export const getAdminHomeFailure = (data) => ({
    type: GET_ADMIN_HOME_FAILURE,
    data
});
// ADMIN BOX
export const getAdminHomeBox = (data) => ({
    type: GET_ADMIN_HOME_BOX,
    data,
});

export const getAdminHomeBoxSuccess = (data) => ({
    type: GET_ADMIN_HOME_BOX_SUCCESS,
    data
});

export const getAdminHomeBoxFailure = (data) => ({
    type: GET_ADMIN_HOME_BOX_FAILURE,
    data
});