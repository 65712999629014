import React, { useState, useEffect } from "react"
import liskCheckIcon from "../../../assets/images/list-check.svg"
import pencilIcon from "../../../assets/images/textarea-write.svg"
import Input from "../../Input"
import Button from "../../Button"
import {
  getAdminSubscription,
  postAdminSubscription,
  postEditPlan
} from "../../../pages/AdminScreens/AdminFinances/redux/action"
import "./style.scss"
import ToggleSwitch from "../../ToggleSwitch"
import BoardSelect from "../../BoardSelect"
import { connect } from "react-redux"
import { Spinner } from "reactstrap"
import useForm from "../../../utils/useForm"
import { Toaster } from "react-hot-toast"
import Switch from "react-switch"
import Skeleton from "react-loading-skeleton"

const EditSubscribePlanCard = props => {
  const {
    postAdminSubscription,
    getAdminSubscription,
    subData,
    subRequesting,
    postEditPlan,
    updateRequesting,
    filterValue,
    isLoading
  } = props

  const [editCard, setEditCard] = useState(false)
  const [updateData, setUpdateData] = useState({})
  const [updateIndex, setUpdateIndex] = useState(0)
  const [showSpinner, setShowSpinner] = useState(null)
  const [filteredList, setFilteredList] = useState([])
  const [input, setInput] = useState("")

  useEffect(() => {
    if (!subData) {
      getAdminSubscription()
    }
  }, [])

  useEffect(() => {
    subData && setFilteredList(subData)
  }, [subData])

  useEffect(() => {
    subData && setFilteredList(subData)
  }, [filterValue == ""])

  useEffect(() => {
    filterValue && filterBySearch(filterValue)
  }, [filterValue])

  const handleChange = (id, active) => {
    if (active === true) {
      const data = {
        product_id: id,
        on_off: false
      }
      postAdminSubscription(data)
    } else if (active === false) {
      const data = {
        product_id: id,
        on_off: true
      }
      postAdminSubscription(data)
    }
  }

  const filterBySearch = value => {
    if (value[0] == "") {
      setInput("")
    } else {
      setInput(value.trim())
    }
    const updatedList = subData.filter(item =>
      item.product.name.toLowerCase().includes(input.toLowerCase())
    )
    setFilteredList(updatedList)
  }

  const stateSchema = {
    tital: {
      value: "",
      error: ""
    },
    pricing: {
      value: "",
      error: ""
    },
    billed: {
      value: "",
      error: ""
    },
    perspectives: {
      value: "",
      error: ""
    },
    GRToken: {
      value: "",
      error: ""
    }
  }

  const validationStateSchema = {
    tital: {
      required: false
    },
    pricing: {
      required: false
    },
    billed: {
      required: false
    },
    perspectives: {
      required: false
    },
    GRToken: {
      required: false
    }
  }

  const { state, handleOnChange, disable } = useForm(
    stateSchema,
    validationStateSchema
  )

  useEffect(() => {
    updateData &&
      handleOnChange(
        "tital",
        updateData?.product?.name ? updateData?.product?.name : ""
      )
    updateData &&
      handleOnChange(
        "pricing",
        updateData?.amount_decimal ? updateData?.amount_decimal : ""
      )
    updateData &&
      handleOnChange("billed", updateData?.interval ? updateData?.interval : "")
    updateData &&
      handleOnChange(
        "perspectives",
        updateData?.product?.metadata?.characters_perspective
          ? updateData?.product?.metadata?.characters_perspective
          : ""
      )
    updateData &&
      handleOnChange(
        "GRToken",
        updateData?.product?.metadata?.good_read_tokens
          ? updateData?.product?.metadata?.good_read_tokens
          : ""
      )
  }, [updateData])

  const onUpdate = (data, check, index) => {
    setUpdateIndex(index)
    setEditCard(check)
    setUpdateData(data)
  }

  const updateSubmit = id => {
    const data = {
      product_id: id,
      title: state.tital.value,
      pricing: state.pricing.value,
      billed: state.billed.value,
      perspective: state.perspectives.value,
      token: state.GRToken.value
    }
    postEditPlan(data, setEditCard)
  }

  return (
    <>
      {isLoading ? (
        <section className="edit-subscribe-section">
          <div className="home-card-wrapper edit-subscribe-section-wrapper">
            <div className="edit-subscribe-card-section">
              <div className="container-fluid edit-subscribe-card-container">
                <div className="row edit-subscribe-row">
                  {[1, 2, 3, 4].map((item, index) => (
                    <div
                      className={`col-sm-6 col-lg-4 col-xl-3 edit-subscribe-card-wrapper `}
                      key={index}
                    >
                      <div className="edit-subscribe-card">
                        <div className="edit">Edit</div>
                        <Skeleton height={30.988} width={200.848} />

                        <div className="img-wrapper text-center">
                          {" "}
                          <div className="img-skeleton">
                            <Skeleton height={70.988} width={63.848} />
                          </div>
                        </div>
                        <div className="price">
                          {" "}
                          <Skeleton height={30.988} width={200.848} />
                        </div>
                        <div className="list-wrapper">
                          <ul>
                            <li>
                              {" "}
                              <Skeleton height={45.988} width={121.848} />
                            </li>
                            <li>
                              {" "}
                              <Skeleton height={45.988} width={110.848} />
                            </li>
                          </ul>
                        </div>
                        <div className="subscription-toggle">
                          <div className="text">Turn Subscription</div>

                          <Switch
                            checkedIcon={false}
                            uncheckedIcon={false}
                            onColor="#A2A2A2"
                            offColor="#A2A2A2"
                            offHandleColor="#2B292D"
                            onHandleColor="#C0E5E6"
                            activeBoxShadow={null}
                            disabled
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      ) : (
        <section className="edit-subscribe-section">
          <div className="home-card-wrapper edit-subscribe-section-wrapper">
            <div className="edit-subscribe-card-section">
              <div className="container-fluid edit-subscribe-card-container">
                <div className="row edit-subscribe-row">
                  {filteredList &&
                    filteredList.map((item, index) => (
                      <div
                        className={`col-sm-6 col-lg-4 col-xl-3 edit-subscribe-card-wrapper ${
                          item?.product?.active === false && "disabled-wrapper"
                        }`}
                        key={item.id}
                      >
                        {updateIndex === index + 1 && editCard ? (
                          <div className="edit-info-card">
                            <div className="active-subscription">Edit</div>
                            <h3>Change Subscription Plan Details</h3>
                            <div className="edit-input-field-wrapper">
                              <Input
                                placeholder="Title"
                                type="text"
                                showIcon={true}
                                src={pencilIcon}
                                value={state.tital.value}
                                onChange={val =>
                                  handleOnChange("tital", val.target.value)
                                }
                              />
                              <Input
                                placeholder="Pricing"
                                type="number"
                                showIcon={true}
                                src={pencilIcon}
                                value={state.pricing.value}
                                onChange={val =>
                                  handleOnChange("pricing", val.target.value)
                                }
                              />
                              <BoardSelect
                                value={state.billed.value}
                                options={[
                                  { value: "month", label: "Month" },
                                  { value: "year", label: "Year" }
                                ]}
                                placeholder={"Billed"}
                                onSelect={val => handleOnChange("billed", val)}
                              />
                              <Input
                                placeholder="How many perspectives?"
                                type="number"
                                showIcon={true}
                                src={pencilIcon}
                                value={state.perspectives.value}
                                onChange={val =>
                                  handleOnChange(
                                    "perspectives",
                                    val.target.value
                                  )
                                }
                              />
                              <Input
                                placeholder="How many GR Tokens?"
                                type="number"
                                showIcon={true}
                                src={pencilIcon}
                                value={state.GRToken.value}
                                onChange={val =>
                                  handleOnChange("GRToken", val.target.value)
                                }
                              />
                            </div>
                            <div className="button-wrapper">
                              <Button
                                title="Cancel"
                                className="transparent-btn"
                                onClick={() => setEditCard(false)}
                              />
                              <Button
                                title="Save"
                                onClick={() => updateSubmit(item.product.id)}
                                showSpinner={updateRequesting}
                              />
                            </div>
                          </div>
                        ) : (
                          <div className="edit-subscribe-card">
                            <div
                              className="edit"
                              onClick={() => onUpdate(item, true, index + 1)}
                            >
                              Edit
                            </div>
                            <h3>{item?.product?.name}</h3>
                            <div className="img-wrapper text-center">
                              <img
                                src={item?.product?.images}
                                className="img-fluid book-img"
                                alt=""
                              />
                            </div>
                            <div className="price">
                              ${item?.amount_decimal}
                              <span className="month">/{item?.interval}</span>
                            </div>
                            <div className="list-wrapper">
                              <ul>
                                <li>
                                  <span className="icon">
                                    <img src={liskCheckIcon} alt="" />
                                  </span>
                                  <span className="text">
                                    <span style={{ color: "#6F8BDF" }}>
                                      Follow Story from
                                    </span>
                                    {` ${item?.product?.metadata?.characters_perspective}`}
                                    <br /> Characters Perspective
                                  </span>
                                </li>
                                <li>
                                  <span className="icon">
                                    <img src={liskCheckIcon} alt="" />
                                  </span>
                                  <span className="text">
                                    <span style={{ color: "#6F8BDF" }}>
                                      Receive
                                    </span>
                                    {` ${item?.product?.metadata?.good_read_tokens} `}
                                    Good Read <br /> Tokens
                                  </span>
                                </li>
                              </ul>
                            </div>
                            <div className="subscription-toggle">
                              <div className="text">
                                Turn Subscription
                                {item?.product?.active === false
                                  ? " Off"
                                  : " On"}
                              </div>
                              {subRequesting && showSpinner === index ? (
                                <Spinner size={"sm"} />
                              ) : (
                                <ToggleSwitch
                                  key={index}
                                  onChange={() => {
                                    handleChange(
                                      item?.product.id,
                                      item?.product?.active,
                                      index + 1
                                    )
                                    setShowSpinner(index)
                                  }}
                                  checked={item?.product?.active === false}
                                />
                              )}
                            </div>
                          </div>
                        )}
                      </div>
                    ))}
                </div>
              </div>
            </div>
          </div>
        </section>
      )}

      {/* )} */}
    </>
  )
}

const mapStateToProps = state => ({
  subData: state.adminFinances.subData.plans,
  subRequesting: state.adminFinances.subRequesting,
  subError: state.adminFinances.subError,
  postRequesting: state.adminFinances.postRequesting,
  updateRequesting: state.adminFinances.updateRequesting
})

const mapDispatchToProps = dispatch => ({
  getAdminSubscription: data => dispatch(getAdminSubscription(data)),
  postAdminSubscription: data => dispatch(postAdminSubscription(data)),
  postEditPlan: (data, setEditCard) => dispatch(postEditPlan(data, setEditCard))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(EditSubscribePlanCard)
