import { all, call, put, takeLatest } from "redux-saga/effects"
import { BASE_URL } from "../../../../config/app"
import XHR from "../../../../utils/XHR"
import {
  writerAdminAppli,
  writerAdminAppliSuccess,
  writerAdminAppliFailure,
  editorAdminAppli,
  editorAdminAppliSuccess,
  editorAdminAppliFailure,
  saveAdminAppli,
  saveAdminAppliSuccess,
  saveAdminAppliFailure,
  patchWriterApplicationSuccess,
  patchWriterApplicationFailure,
  patchEditorApplicationSuccess,
  patchEditorApplicationFailure,
  patchSaveApplicationSuccess,
  patchSaveApplicationFailure
} from "./action"
import {
  WRITER_ADMIN_APPLICATION,
  EDITOR_ADMIN_APPLICATION,
  SAVE_ADMIN_APPLICATION,
  PATCH_WRITER_ADMIN_APPLICATION,
  PATCH_EDITOR_ADMIN_APPLICATION,
  PATCH_SAVE_ADMIN_APPLICATION
} from "./type"
import toast from "react-hot-toast"

const writerAdminApplicationAPI = async () => {
  const URL = `${BASE_URL}api/v1/admin_writer_application/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    }
  }
  return XHR(URL, options)
}

const editorAdminApplicationAPI = async () => {
    const URL = `${BASE_URL}api/v1/admin_editor_application/`
    const token = localStorage.getItem("token")
    const options = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      }
    }
    return XHR(URL, options)
  }

  const saveAdminApplicationAPI = async () => {
    const URL = `${BASE_URL}api/v1/admin_saved_application/`
    const token = localStorage.getItem("token")
    const options = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      }
    }
    return XHR(URL, options)
  }

function* writerAdminApplication() {
  try {
    const response = yield call(writerAdminApplicationAPI)
    yield put(writerAdminAppliSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(writerAdminAppliFailure(response.data))
  }
}

function* editorAdminApplication() {
    try {
      const response = yield call(editorAdminApplicationAPI)
      yield put(editorAdminAppliSuccess(response.data))
    } catch (e) {
      const { response } = e
      yield put(editorAdminAppliFailure(response.data))
    }
  }

  function* saveAdminApplication() {
    try {
      const response = yield call(saveAdminApplicationAPI)
      yield put(saveAdminAppliSuccess(response.data))
    } catch (e) {
      const { response } = e
      yield put(saveAdminAppliFailure(response.data))
    }
  }

const patchWriterAPI = async (id, data) => {
  const URL = `${BASE_URL}api/v1/admin_writer_application/${id}/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "PATCH",
    data
  }
  return XHR(URL, options)
}

const patchEditorAPI = async (id, data) => {
  const URL = `${BASE_URL}api/v1/admin_editor_application/${id}/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "PATCH",
    data
  }
  return XHR(URL, options)
}

const patchSaveAPI = async (id, data) => {
  const URL = `${BASE_URL}api/v1/admin_saved_application/${id}/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "PATCH",
    data
  }
  return XHR(URL, options)
}

function* patchWriterData({ id, status }) {
  try {
    const response = yield call(patchWriterAPI, id, status)
    yield put(patchWriterApplicationSuccess(response.data))
    yield put(writerAdminAppli())
    toast.success("Status Changed", {
      id: "card fields"
    })
  } catch (e) {
    const { response } = e
    yield put(patchWriterApplicationFailure(response.data))
    toast.error("Something Went Wrong", {
      id: "card fields"
    })
  }
}

function* patchEditorData({ id, status }) {
  try {
    const response = yield call(patchEditorAPI, id, status)
    yield put(patchEditorApplicationSuccess(response.data))
    yield put(editorAdminAppli())
    toast.success("Status Changed", {
      id: "card fields"
    })
  } catch (e) {
    const { response } = e
    yield put(patchEditorApplicationFailure(response.data))
    toast.error("Something Went Wrong", {
      id: "card fields"
    })
  }
}

function* patchSaveData({ id, status }) {
  try {
    const response = yield call(patchSaveAPI, id, status)
    yield put(patchSaveApplicationSuccess(response.data))
    yield put(saveAdminAppli())
    toast.success("Status Changed", {
      id: "card fields"
    })
  } catch (e) {
    const { response } = e
    yield put(patchSaveApplicationFailure(response.data))
    toast.error("Something Went Wrong", {
      id: "card fields"
    })
  }
}

export default all([
  takeLatest(WRITER_ADMIN_APPLICATION, writerAdminApplication),
  takeLatest(EDITOR_ADMIN_APPLICATION, editorAdminApplication),
  takeLatest(SAVE_ADMIN_APPLICATION, saveAdminApplication),
  takeLatest(PATCH_WRITER_ADMIN_APPLICATION, patchWriterData),
  takeLatest(PATCH_EDITOR_ADMIN_APPLICATION, patchEditorData),
  takeLatest(PATCH_SAVE_ADMIN_APPLICATION, patchSaveData),

])
