import {
  GET_NOVALS_TOP,
  GET_NOVALS_TOP_SUCCESS,
  GET_NOVALS_TOP_FAILURE
} from "./type"

const initialState = {
  novalsTopData: false,
  requesting: false,
  error: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_NOVALS_TOP:
      return {
        ...state,
        requesting: true
      }
    case GET_NOVALS_TOP_SUCCESS:
      return {
        ...state,
        requesting: false,
        novalsTopData: action.data
      }
    case GET_NOVALS_TOP_FAILURE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }
    default:
     return state;
  }
}
