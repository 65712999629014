import React, { useEffect, useState } from "react"
import { Toaster } from "react-hot-toast"
import Input from "../../components/Input"
import HomeLayout from "../../layout/HomeLayout"
import pencilIcon from "../../assets/images/textarea-write.svg"
import "./style.scss"
import TextArea from "../../components/TextArea"
import Button from "../../components/Button"
import UploadFile from "../../components/UploadFile"
import CreateSubmissionModal from "../../components/CreateSubmissionModal"
import { connect } from "react-redux"
import { createNewChapter, createNewChapterFailure, createNewSubmission } from "./redux/action"
import useForm from "../../utils/useForm"

const CreateChapter = props => {

  const {
    postNewChapter,
    newChapterInfo,
    requesting,
    error,
    createNewChapterFailure,
    createNewSubmission,
    newSubmissionInfo,
    subRequesting,
    subError
  } = props

  const [thumbnail, setThumbnail] = useState(false)
  const [uploadMp3, setUploadMp3] = useState(false)
  const [modalShow, setModalShow] = useState(false);
  const [fileImgError, setFileImgError] = useState(false)
  const [fileMp3Error, setFileMp3Error] = useState(false)
  const [selectedCard, setSelectedCard] = useState([])

  useEffect(() => {
    createNewSubmission()
  }, [])

  useEffect(() => {
    createNewChapterFailure(false)
    setState(stateSchema)
    setUploadMp3({})
    setThumbnail({})
    setSelectedCard([])
  }, [newChapterInfo])

  const onSubmitData = () => {
    const formData = new FormData()
    formData.append("name", state.ChapterTitle.value)
    formData.append("opening_scene", state.opening_scene.value)
    formData.append("thumbnail", thumbnail)
    formData.append("upload_mp3", uploadMp3)
    formData.append("submission", selectedCard)
    postNewChapter(formData)
  }

  const validationStateSchema = {
    ChapterTitle: {
      required: true
    },
    opening_scene: {
      required: true
    }
  }

  const stateSchema = {
    ChapterTitle: {
      value: "",
      error: ""
    },
    opening_scene: {
      value: "",
      error: ""
    }
  }

  const { state, handleOnChange, disable, setState } = useForm(
    stateSchema,
    validationStateSchema
  )

  const uploadImg = value => {
    setFileImgError(false)
    const file = value.target.files[0]
    const fileType = file.type
    createNewChapterFailure(false)
    if (
      fileType == "image/jpeg" ||
      fileType == "image/jpg" ||
      fileType == "image/png"
    ) {
      setThumbnail(file)
    } else {
      setFileImgError("Upload (.jpeg .jpg .png) file.")
      setThumbnail({})
    }
  }

  const uploadmp3 = value => {
    setFileMp3Error(false)
    const file = value.target.files[0]
    const fileType = file.type
    createNewChapterFailure(false)
    if (fileType == "audio/mpeg") {
      setUploadMp3(file)
    } else {
      setFileMp3Error("upload (.mp3) file.")
      setUploadMp3({})
    }
  }

  const onSelect = (id) => {
    if (selectedCard.includes(id)) {
      setSelectedCard(prv => prv.filter(item => item !== id))
    } else {
      setSelectedCard(prv => [...prv, id])
    }
  }

  return (
    <HomeLayout
      headerName={"Create New Chapter - Chapter x out of 13"}
      className="chapter-header"
    >
      <Toaster position="top-center" />
      <section className="content-section new-chapter-section">
        <div className="fill-box-section">
          <h2>Fill the boxes below:</h2>
          <div className="container-fluid fill-box-container">
            <div className="row">
              <div className="col-lg-6 fill-box-left">
                <div className="left-details">
                  <Input
                    placeholder="Create Chapter Title"
                    showIcon={true}
                    src={pencilIcon}
                    value={state.ChapterTitle.value}
                    showError={true}
                    errorMessage={state.ChapterTitle.error}
                    onChange={val =>
                      handleOnChange("ChapterTitle", val.target.value)
                    }
                  />
                  <TextArea
                    placeholder="Create Opening Scene"
                    value={state.opening_scene.value}
                    showError={true}
                    errorMessage={state.opening_scene.error}
                    onChange={val =>
                      handleOnChange("opening_scene", val.target.value)
                    }
                  />
                </div>
              </div>
              <div className="col-lg-6 fill-box-right">
                <div className="right-details">
                  <div className="box-upload-wrapper">
                    <UploadFile
                      title="Upload Image"
                      onChange={val => uploadImg(val)}
                      selectedFile={thumbnail.name}
                      value={thumbnail}
                    />
                    <p
                      className={`error-message ${error?.thumbnail || fileImgError ? "opacity-1" : ""
                        }`}
                    >
                      {error?.thumbnail ? error?.thumbnail : fileImgError}
                    </p>
                  </div>

                  <div className="box-upload-wrapper">
                    <UploadFile
                      title="Upload MP3 File"
                      onChange={val => uploadmp3(val)}
                      selectedFile={uploadMp3.name}
                    />
                    <p
                      className={`error-message ${error?.upload_mp3 || fileMp3Error ? "opacity-1" : ""
                        }`}
                    >
                      {error?.upload_mp3 ? error?.upload_mp3 : fileMp3Error}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="character-submission-section">
          <h2>Characters</h2>
          <div className="container-fluid character-submission-container">
            <div className="row character-submission-row">
              {newSubmissionInfo && newSubmissionInfo[0]?.character.map(item => (
                <div
                  className="character-submission-card-wrapper"
                  key={item?.id}
                >
                  <div
                    className={`character-submission-card ${selectedCard.includes(item?.id) ? 'selected-submission' : ''}`}
                    onClick={() => onSelect(item?.id)}
                  >
                    <h3 title={item?.name}>{item?.name}</h3>
                    <Button
                      title="Create Submission"
                      onClick={() => {
                        setModalShow(true)
                      }}
                      className={`${selectedCard.includes(item?.id) ? 'white-btn' : ''}`}
                    />
                  </div>
                </div>
              ))}
            </div>
          </div>
          <div className="button-wrapper">
            <Button
              title="Save & Review"
              showSpinner={requesting}
              onClick={onSubmitData}
            />
            <Button title="Publish Chapter" className="transparent-btn" />
          </div>
        </div>
      </section>
      <CreateSubmissionModal
        modalShow={modalShow}
        setModalShow={setModalShow}
        selectedCardID={selectedCard}
      />
    </HomeLayout>
  )
}

const mapStateToProps = state => ({
  newChapterInfo: state.createChapter.newChapterInfo,
  requesting: state.createChapter.requesting,
  error: state.createChapter.error,
  newSubmissionInfo: state.createChapter.newSubmissionInfo,
  subRequesting: state.createChapter.subRequesting,
  subError: state.createChapter.subError,
})

const mapDispatchToProps = dispatch => ({
  postNewChapter: data => dispatch(createNewChapter(data)),
  createNewChapterFailure: data => dispatch(createNewChapterFailure(data)),
  createNewSubmission: data => dispatch(createNewSubmission(data)),
})

export default connect(mapStateToProps, mapDispatchToProps)(CreateChapter)
