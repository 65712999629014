import {
  GET_READER_APPLICATION,
  GET_READER_APPLICATION_SUCCESS,
  GET_READER_APPLICATION_FAILURE,
  GET_WRITER_APPLICATION,
  GET_WRITER_APPLICATION_SUCCESS,
  GET_WRITER_APPLICATION_FAILURE,
  GET_EDITORS_APPLICATION,
  GET_EDITORS_APPLICATION_SUCCESS,
  GET_EDITORS_APPLICATION_FAILURE,
  GET_SAVE_APPLICATION,
  GET_SAVE_APPLICATION_SUCCESS,
  GET_SAVE_APPLICATION_FAILURE,
  PATCH_WRITER_DATA,
  PATCH_WRITER_DATA_SUCCESS,
  PATCH_WRITER_DATA_FAILURE,
  PATCH_EDITOR_DATA,
  PATCH_EDITOR_DATA_SUCCESS,
  PATCH_EDITOR_DATA_FAILURE,
  PATCH_PRODUCER_DATA,
  PATCH_PRODUCER_DATA_SUCCESS,
  PATCH_PRODUCER_DATA_FAILURE,
  PATCH_SAVE_DATA,
  PATCH_SAVE_DATA_SUCCESS,
  PATCH_SAVE_DATA_FAILURE
} from "./type"

const initialState = {
  readerApp: false,
  readerAppLoad: false,
  readerAppError: false,

  writerApp: false,
  writerAppLoad: false,
  writerAppError: false,

  editorApp: false,
  editorAppLoad: false,
  editorAppError: false,

  saveApp: false,
  saveAppLoad: false,
  saveAppError: false,

  patchWriter: false,
  patchWriterLoad: false,
  patchWriterError: false,

  patchEditor: false,
  patchEditorLoad: false,
  patchEditorError: false,

  patchProducer: false,
  patchProducerLoad: false,
  patchProducerError: false,

  patchSave: false,
  patchSaveLoad: false,
  patchSaveError: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_READER_APPLICATION:
      return {
        ...state,
        readerAppLoad: true
      }
    case GET_READER_APPLICATION_SUCCESS:
      return {
        ...state,
        readerAppLoad: false,
        readerApp: action.data
      }
    case GET_READER_APPLICATION_FAILURE:
      return {
        ...state,
        readerAppLoad: false,
        readerAppError: action.data
      }
      case GET_WRITER_APPLICATION:
      return {
        ...state,
        writerAppLoad: true
      }
    case GET_WRITER_APPLICATION_SUCCESS:
      return {
        ...state,
        writerAppLoad: false,
        writerApp: action.data
      }
    case GET_WRITER_APPLICATION_FAILURE:
      return {
        ...state,
        writerAppLoad: false,
        writerAppError: action.data
      }
      case GET_EDITORS_APPLICATION:
      return {
        ...state,
        editorAppLoad: true
      }
    case GET_EDITORS_APPLICATION_SUCCESS:
      return {
        ...state,
        editorAppLoad: false,
        editorApp: action.data
      }
    case GET_EDITORS_APPLICATION_FAILURE:
      return {
        ...state,
        editorAppLoad: false,
        editorAppError: action.data
      }
      case GET_SAVE_APPLICATION:
      return {
        ...state,
        saveAppLoad: true
      }
    case GET_SAVE_APPLICATION_SUCCESS:
      return {
        ...state,
        saveAppLoad: false,
        saveApp: action.data
      }
    case GET_SAVE_APPLICATION_FAILURE:
      return {
        ...state,
        saveAppLoad: false,
        saveAppError: action.data
      }
      case PATCH_WRITER_DATA:
      return {
        ...state,
        patchWriterLoad: true
      }
    case PATCH_WRITER_DATA_SUCCESS:
      return {
        ...state,
        patchWriterLoad: false,
        patchWriter: action.data
      }
      case PATCH_WRITER_DATA_FAILURE:
        return {
          ...state,
          patchWriterLoad: false,
          writerAppError: action.data
        }
        case PATCH_EDITOR_DATA:
      return {
        ...state,
        patchEditorLoad: true
      }
    case PATCH_EDITOR_DATA_SUCCESS:
      return {
        ...state,
        patchEditorLoad: false,
        patchEditor: action.data
      }
      case PATCH_EDITOR_DATA_FAILURE:
        return {
          ...state,
          patchEditorLoad: false,
          patchEditorError: action.data
        }
        case PATCH_PRODUCER_DATA:
      return {
        ...state,
        patchProducerLoad: true
      }
    case PATCH_PRODUCER_DATA_SUCCESS:
      return {
        ...state,
        patchProducerLoad: false,
        patchProducer: action.data
      }
      case PATCH_PRODUCER_DATA_FAILURE:
        return {
          ...state,
          patchProducerLoad: false,
          patchProducerError: action.data
        }
        case PATCH_SAVE_DATA:
      return {
        ...state,
        patchSaveLoad: true
      }
    case PATCH_SAVE_DATA_SUCCESS:
      return {
        ...state,
        patchSaveLoad: false,
        patchSave: action.data
      }
      case PATCH_SAVE_DATA_FAILURE:
        return {
          ...state,
          patchSaveLoad: false,
          patchSaveError: action.data
        }
    default:
      return state
  }
}
