import {
  GET_ADMIN_USER,
  GET_ADMIN_USER_SUCCESS,
  GET_ADMIN_USER_FAILURE,
  ADMIN_USER_DELETE,
  ADMIN_USER_DELETE_SUCCESS,
  ADMIN_USER_DELETE_FAILURE,
  FLAGS_UPDATE,
  FLAGS_UPDATE_SUCCESS,
  FLAGS_UPDATE_FAILURE,
  ADD_ADMIN_USER,
  ADD_ADMIN_USER_SUCCESS,
  ADD_ADMIN_USER_FAILURE,
  UPDATE_ADMIN_USER,
  UPDATE_ADMIN_USER_SUCCESS,
  UPDATE_ADMIN_USER_FAILURE,
  SET_CURRENT_PAGE,
  SET_CONCAT_USER_DATA
} from "./type"

export const getAdminUserData = (data, search, page, isType) => ({
  type: GET_ADMIN_USER,
  data,
  search,
  page,
  isType
})

export const getAdminUserSuccess = data => ({
  type: GET_ADMIN_USER_SUCCESS,
  data
})

export const getAdminUserFailure = data => ({
  type: GET_ADMIN_USER_FAILURE,
  data
})
// DELETE
export const adminUserDelete = (data) => ({
  type: ADMIN_USER_DELETE,
  data
})

export const adminUserDeleteSuccess = data => ({
  type: ADMIN_USER_DELETE_SUCCESS,
  data
})

export const adminUserDeleteFailure = data => ({
  type: ADMIN_USER_DELETE_FAILURE,
  data
})
// FLAGS
export const flagsUpdate = (data, id) => ({
  type: FLAGS_UPDATE,
  data,
  id,
})

export const flagsUpdateSuccess = data => ({
  type: FLAGS_UPDATE_SUCCESS,
  data
})

export const flagsUpdateFailure = data => ({
  type: FLAGS_UPDATE_FAILURE,
  data
})
// ADD USER
export const addAdminUser = (data,setModalShow) => ({
  type: ADD_ADMIN_USER,
  data,
  setModalShow
})

export const addAdminUserSuccess = data => ({
  type: ADD_ADMIN_USER_SUCCESS,
  data
})

export const addAdminUserFailure = data => ({
  type: ADD_ADMIN_USER_FAILURE,
  data
})
// EDIT USER
export const updateAdminUser = (data, id, setModalShow, setShow, setUpdateData) => ({
  type: UPDATE_ADMIN_USER,
  data,
  id,
  setModalShow, 
  setShow, 
  setUpdateData
})

export const updateAdminUserSuccess = data => ({
  type: UPDATE_ADMIN_USER_SUCCESS,
  data
})

export const updateAdminUserFailure = data => ({
  type: UPDATE_ADMIN_USER_FAILURE,
  data
})
// SET CURRENT PAGE
export const setCurrentPages = data => ({
  type: SET_CURRENT_PAGE,
  data
})
// CONCAT
export const setConcatUsers = data => ({
  type: SET_CONCAT_USER_DATA,
  data
})
