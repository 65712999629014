import {
  GET_ADMIN_USER,
  GET_ADMIN_USER_SUCCESS,
  GET_ADMIN_USER_FAILURE,
  ADMIN_USER_DELETE,
  ADMIN_USER_DELETE_SUCCESS,
  ADMIN_USER_DELETE_FAILURE,
  FLAGS_UPDATE,
  FLAGS_UPDATE_SUCCESS,
  FLAGS_UPDATE_FAILURE,
  ADD_ADMIN_USER,
  ADD_ADMIN_USER_SUCCESS,
  ADD_ADMIN_USER_FAILURE,
  UPDATE_ADMIN_USER,
  UPDATE_ADMIN_USER_SUCCESS,
  UPDATE_ADMIN_USER_FAILURE,
  SET_CURRENT_PAGE,
  SET_CONCAT_USER_DATA
} from "./type"

const initialState = {
  userInfo: false,
  concatUsers: false,
  requesting: false,
  error: false,
  addUserInfo: false,
  addUserRequesting: false,
  addUserError: false,
  updateUserInfo: false,
  updateRequesting: false,
  updateError: false,

  currentPage: false,
  flagInfo: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_ADMIN_USER:
      return {
        ...state,
        requesting: true,
        error: false,
        userInfo: []
      }
    case GET_ADMIN_USER_SUCCESS:
      return {
        ...state,
        requesting: false,
        userInfo: action.data
      }
    case GET_ADMIN_USER_FAILURE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }
    case ADMIN_USER_DELETE:
      return {
        ...state,
        requesting: true,
        error: false
      }
    case ADMIN_USER_DELETE_SUCCESS:
      return {
        ...state,
        requesting: false
      }
    case ADMIN_USER_DELETE_FAILURE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }
    case FLAGS_UPDATE:
      return {
        ...state,
        requesting: true,
        error: false
      }
    case FLAGS_UPDATE_SUCCESS:
      return {
        ...state,
        requesting: false,
        flagInfo: action.data
      }
    case FLAGS_UPDATE_FAILURE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }
    case ADD_ADMIN_USER:
      return {
        ...state,
        addUserRequesting: true
      }
    case ADD_ADMIN_USER_SUCCESS:
      return {
        ...state,
        addUserRequesting: false,
        addUserInfo: action.data
      }
    case ADD_ADMIN_USER_FAILURE:
      return {
        ...state,
        addUserRequesting: false,
        addUserError: action.data
      }
    case UPDATE_ADMIN_USER:
      return {
        ...state,
        updateRequesting: true
      }
    case UPDATE_ADMIN_USER_SUCCESS:
      return {
        ...state,
        updateRequesting: false,
        updateUserInfo: action.data
      }
    case UPDATE_ADMIN_USER_FAILURE:
      return {
        ...state,
        updateRequesting: false,
        updateError: action.data
      }
    case SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: action.data
      }
    case SET_CONCAT_USER_DATA:
      return {
        ...state,
        requesting: false,
        concatUsers: action.data
      }
    default:
      return state
  }
}
