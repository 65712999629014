import React, { useEffect, useState } from "react"
import { Space, Table, Popover } from "antd"
import Button from "../../Button"
import optionIcon from "../../../assets/images/options-white.svg"
import pencilIcon from "../../../assets/images/writer.svg"
import pencilActiveIcon from "../../../assets/images/writer-blue.svg"
import flagActiveIcon from "../../../assets/images/flag-blue.svg"
import flagIcon from "../../../assets/images/flag.svg"
import starIcon from "../../../assets/images/star.svg"
import deleteIcon from "../../../assets/images/delete.svg"
import deleteActiveIcon from "../../../assets/images/delete-blue.svg"
import "./style.scss"
import {
  adminUserDelete,
  flagsUpdate,
  getAdminUserData,
  setConcatUsers
} from "../../../pages/AdminScreens/Users/redux/action"
import { connect } from "react-redux"
import { useVT } from "virtualizedtableforantd4"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"

const UsersTable = props => {
  const {
    flagsUpdate,
    userInfo,
    setShow,
    setUpdateData,
    setModal,
    adminUserDelete,
    currentPage,
    concatData,
    getAdminUserData,
    isLoading,
    setConcatUsers,
    requesting
  } = props

  const [tooltip, setTooltip] = useState(false)
  const [activeClass, setActiveClass] = useState(false)
  const [concatUser, setConcatUser] = useState([])

  const [tableParams, setTableParams] = useState({
    pagination: {
      current: 1,
      pageSize: 10,
    },
  });

  const [userType, setUserType] = useState('')

  useEffect(() => {
    getAdminUserData("", "", 1)
  }, [])

  useEffect(() => {
    console.log(requesting);
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        current:currentPage?.current_page,
        total: currentPage?.total_objects,
        // 200 is mock data, you should read it from server
        // total: data.totalCount,
      },
    });
    if(currentPage?.next == null){
      if(currentPage?.previous != undefined){
        const params = new URLSearchParams(currentPage?.previous.split('?')[1]);
        let match = params.get('user_type')
        if(match == null){
          setUserType('')
        }else if(match == 'reader'){
          setUserType('reader')
        }else if(match == 'writer'){
          setUserType('writer')
        }else if(match == 'editor'){
          setUserType('editor')
        }else if(match == 'producer'){
          setUserType('producer')
        }
      }
    }else{
      if(currentPage?.next != undefined){
        const params = new URLSearchParams(currentPage?.next.split('?')[1]);
        let match = params.get('user_type')
        if(match == null){
          setUserType('')
        }else if(match == 'reader'){
          setUserType('reader')
        }else if(match == 'writer'){
          setUserType('writer')
        }else if(match == 'editor'){
          setUserType('editor')
        }else if(match == 'producer'){
          setUserType('producer')
        }
      }
      
      
    }
    setConcatUser(userInfo)
  }, [currentPage])

  const onDelete = id => {
    adminUserDelete(id)
  }

  const onFlagsUpdate = (id, flag) => {
    if (flag) {
      const data = {
        flags: false
      }
      flagsUpdate(data, id)
    } else {
      const data = {
        flags: true
      }
      flagsUpdate(data, id)
    }
  }

  const onEdit = val => {
    setUpdateData(val)
    setShow(true)
    setModal(true)
  }

  const onAction = id => {
    setActiveClass(id)
    setTooltip(id)
  }

  const text = <span></span>

  const popoverContent = value => {
    return (
      tooltip === value.id && (
        <div className="option-items">
          <div className="item" onClick={() => onEdit(value)}>
            <img src={pencilIcon} className="img" alt="" />
            <img src={pencilActiveIcon} className="active-img" alt="" />
            <div className="text">Edit User</div>
          </div>
          <div
            className={`item ${value.flags && "active-flag"}`}
            onClick={() => onFlagsUpdate(value.id, value.flags)}
          >
            <img src={flagActiveIcon} className="active-img" alt="" />
            <img src={flagIcon} className="img" alt="" />
            <div className="text">{"Flag User"}</div>
          </div>
          {value?.user_type !== "admin" && (
            <div className="item" onClick={() => onDelete(value.id)}>
              <img src={deleteActiveIcon} className="active-img" alt="" />
              <img src={deleteIcon} className="img" alt="" />
              <div className="text">Delete User</div>
            </div>
          )}
        </div>
      )
    )
  }

  const columns = [
    {
      title: "ID",
      dataIndex: "id",
      key: "id",
      render: text => <a>ID{text}</a>
    },
    {
      title: "Name (A-Z)",
      dataIndex: "name",
      key: "name",
      render: (_, name) => (
        <Space size="middle">
          <img src={name.flags ? flagActiveIcon : starIcon} alt="" />
          <p className="flag-name mb-0" title={name.name}>
            {name.name ? name.name : "---"}
          </p>
        </Space>
      )
    },
    {
      title: "Role",
      dataIndex: "user_type",
      key: "user_type",
      render: role => <Button title={role} className="radius-btn no-cursor" />
    },
    {
      title: "Email",
      key: "email",
      dataIndex: "email",
      render: (_, email) => (
        <p className="email mb-0" title={email.email}>
          {email.email ? email.email : "---"}
        </p>
      )
    },
    {
      title: "Phone",
      key: "phone_number",
      render: (_, phone) => (
        <p className="mb-0">
          {phone.phone_number ? phone.phone_number : "---"}
        </p>
      )
    },
    {
      title: "Earned",
      key: "token_earn",
      render: (_, token_earn) => (
        <p
          className="phone mb-0"
          title={token_earn.token_earn ? token_earn.token_earn.token_earn.total_token__sum?.toFixed(2) : "0"}
        >
          {token_earn.token_earn.token_earn.total_token__sum ? token_earn.token_earn.token_earn.total_token__sum?.toFixed(2) : "0"}
        </p>
      )
    },{
      title: "Current balance",
      key: "available_grt",
      render: (_, available_grt) => (
        <p
          className="phone mb-0"
          title={available_grt.available_grt ? available_grt.available_grt?.toFixed(2) : "0"}
        >
          {available_grt.available_grt? available_grt.available_grt?.toFixed(2) : "0"}
        </p>
      )
    },
    {
      title: " ",
      render: (_, value) => (
        <Space
          size="middle"
          className="phone-details user-phone-details"
          key={value.id}
        >
          <Popover
            placement="left"
            title={text}
            content={popoverContent(value)}
            trigger="click"
            getPopupContainer={trigger => trigger.parentNode}
          >
            <div
              className={`options ${tooltip === value.id && activeClass === value.id ? "active" : ""
                }`}
              onClick={() => {
                onAction(value.id)
              }}
            >
              <img src={optionIcon} alt="" key={value.id} />
            </div>
          </Popover>
        </Space>
      )
    }
  ]


  useEffect(() => {
    //
    //
  }, [JSON.stringify(tableParams)]);
  const handleTableChange = (pagination, filters, sorter) => {
    setTableParams({
      pagination,
      filters,
      ...sorter,
    });
    console.log(pagination);
    // `dataSource` is useless since `pageSize` changed
    if (pagination.current !== tableParams?.pagination?.current) {
      setConcatUsers(true)
      
      getAdminUserData(userType, "", pagination.current)
    }
  };

  if (requesting || !userInfo?.length) {
    return (
      <>
        <SkeletonTheme baseColor="#6f8bdf40" highlightColor="#6F8BDF">
          <Table
            rowKey="key"
            pagination={false}
            dataSource={[...Array(10)].map((_, index) => ({
              key: `key${index}`
            }))}
            columns={columns.map(column => {
              return {
                ...column,
                render: function renderPlaceholder() {
                  return <Skeleton width="40%" height='10px' />
                }
              }
            })}
          />

        </SkeletonTheme>
      </>
    )
  } else {
    return (
      <Table
        scroll={{
          scrollToFirstRowOnChange: false,
          y: 600,
          x: 1000
        }}
        columns={columns}
        dataSource={concatUser}
        pagination={tableParams.pagination}
        loading={requesting}
        onChange={handleTableChange}
      />
    )
  }
}
const mapStateToProps = state => ({
  currentPage: state.adminUsers.currentPage,
  userInfo: state.adminUsers.userInfo,
  flagInfo: state.adminUsers.flagInfo,
  concatData: state.adminUsers.concatUsers,
  requesting: state.adminUsers.requesting
})

const mapDispatchToProps = dispatch => ({
  adminUserDelete: data => dispatch(adminUserDelete(data)),
  flagsUpdate: (data, id) => dispatch(flagsUpdate(data, id)),
  getAdminUserData: (data, search, page) =>
    dispatch(getAdminUserData(data, search, page)),
  setConcatUsers: data => dispatch(setConcatUsers(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(UsersTable)
