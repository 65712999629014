export const GET_ADMIN_PUSH_NOTIFICATION = 'GET_ADMIN_PUSH_NOTIFICATION';
export const GET_ADMIN_PUSH_NOTIFICATION_SUCCESS = 'GET_ADMIN_PUSH_NOTIFICATION_SUCCESS';
export const GET_ADMIN_PUSH_NOTIFICATION_FAILURE = 'GET_ADMIN_PUSH_NOTIFICATION_FAILURE';
// POST SELECT USER
export const POST_USER_PUSH_NOTIFICATION = 'POST_USER_PUSH_NOTIFICATION';
export const POST_USER_PUSH_NOTIFICATION_SUCCESS = 'POST_USER_PUSH_NOTIFICATION_SUCCESS';
export const POST_USER_PUSH_NOTIFICATION_FAILURE = 'POST_USER_PUSH_NOTIFICATION_FAILURE';
// GET GROUP
export const GET_ADMIN_GROUP = 'GET_ADMIN_GROUP';
export const GET_ADMIN_GROUP_SUCCESS = 'GET_ADMIN_GROUP_SUCCESS';
export const GET_ADMIN_GROUP_FAILURE = 'GET_ADMIN_GROUP_FAILURE';
// POST GROUP
export const POST_ADMIN_GROUP = 'POST_ADMIN_GROUP';
export const POST_ADMIN_GROUP_SUCCESS = 'POST_ADMIN_GROUP_SUCCESS';
export const POST_ADMIN_GROUP_FAILURE = 'POST_ADMIN_GROUP_FAILURE';
// PUT REAQUEST FOR ACTIVE INACTIVE
export const PUT_FOR_ACTIVE = 'PUT_FOR_ACTIVE';
export const PUT_FOR_ACTIVE_SUCCESS = 'PUT_FOR_ACTIVE_SUCCESS';
export const PUT_FOR_ACTIVE_FAILURE = 'PUT_FOR_ACTIVE_FAILURE';
// DELETE NOTIFICATION
export const DELETE_ADMIN_NOTIFICATION = 'DELETE_ADMIN_NOTIFICATION';
export const DELETE_ADMIN_NOTIFICATION_SUCCESS = 'DELETE_ADMIN_NOTIFICATION_SUCCESS';
export const DELETE_ADMIN_NOTIFICATION_FAILURE = 'DELETE_ADMIN_NOTIFICATION_FAILURE';

// concat table
export const CONCAT_ADMIN_NOTIFICATION = 'CONCAT_ADMIN_NOTIFICATION';