import { Space, Table } from "antd"
import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import starIcon from "../../../assets/images/star.svg"
import goldenToken from "../../../assets/images/token-golden.svg"
import Button from "../../Button"
import { getGRTRequest } from "../../../pages/AdminScreens/AdminFinances/redux/action"
import { useVT } from "virtualizedtableforantd4"
import { grtDataConcat } from "../../../pages/AdminScreens/AdminFinances/redux/action"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"

const GRTTable = props => {
  const {
    getGRTRequest,
    grtData,
    grtDataConcat,
    grtConcat,
    grtRequesting,
    isLoading
  } = props
  const [concatUser, setConcatUser] = useState([])

  useEffect(() => {
    getGRTRequest("", 1)
  }, [])

  useEffect(() => {
    if (grtData?.results && grtData?.current_page == 1) {
      setConcatUser(grtData?.results)
    } else if (grtConcat) {
      setConcatUser([...concatUser, ...grtData?.results])
    } else {
      setConcatUser(grtData?.results)
    }
  }, [grtData?.results])

  const columns = [
    {
      title: "ID",
      key: "id",
      render: text => (
        <div className="grt-main">
          <div className="grt-table-row">{`ID${text.id ? text.id : "0"}`}</div>
        </div>
      )
    },
    {
      title: "Receiver",
      key: "awarded_to",
      render: (_, receiver) => (
        <div className="grt-main">
          <div className="grt-table-row">
            <Space size="middle">
              <img src={starIcon} alt="" />
              <p className="mb-0">{receiver?.awarded_to}</p>
            </Space>
          </div>
        </div>
      )
    },
    {
      title: "Role",
      key: "role",
      render: text => (
        <div className="grt-main">
          <div className="grt-table-row">
            <Button title={text?.role} className="radius-btn no-cursor" />
          </div>
        </div>
      )
    },
    {
      title: "Awarded by",
      key: "awarded_by",
      render: text => (
        <div className="grt-main">
          <div className="grt-table-row">{text?.awarded_by}</div>
        </div>
      )
    },
    {
      title: "Chapter",
      key: "Chapter",
      render: (text) => (
        <div className="grt-main">
          <div className="grt-table-row">{text?.chapter_name}</div>
        </div>
      )
    },
    {
      title: "Tokens Received",
      key: "received_token",
      render: (_, receiver) => (
        <div className="grt-main">
          <div className="grt-table-row">
            <Space size="middle">
              <img src={goldenToken} alt="" />
              <p className="mb-0">
                {receiver.received_token
                  ? receiver.received_token.toFixed(2)
                  : "0"}
              </p>
            </Space>
          </div>
        </div>
      )
    },
    
    {
      title: "Total Tokens",
      key: "total_token",
      render: (_, receiver) => (
        <div className="grt-main">
          <div className="grt-table-row">
            <Space size="middle">
              <img src={goldenToken} alt="" />
              <p className="mb-0">
                {receiver?.total_token ? receiver?.total_token.toFixed(2) : "0"}
              </p>
            </Space>
          </div>
        </div>
      )
    }
  ]

  const [vt] = useVT(
    () => ({
      onScroll: ({ top, isEnd }) => {
        if (isEnd) {
          const currentNo = grtData.current_page + 1
          if (currentNo <= grtData.total_page) {
            grtDataConcat(true)
            getGRTRequest("", currentNo)
          }
        }
      },
      scroll: {
        y: 600,
        x: 1000
      },
      debug: false
    }),
    [concatUser]
  )

  return (
    <>
      {isLoading || grtRequesting ? (
         <SkeletonTheme baseColor="#6f8bdf40" highlightColor="#6F8BDF">
        <Table
          rowKey="key"
          pagination={false}
          dataSource={[...Array(5)].map((_, index) => ({
            key: `key${index}`
          }))}
          columns={columns.map(column => {
            return {
              ...column,
              render: function renderPlaceholder() {
                return (
                  <div style={{ padding: 16 }}>
                    <Skeleton width='40%' height='10px'/>
                  </div>
                )
              }
            }
          })}
        />
        </SkeletonTheme>
      ) : (
        <Table
          components={vt}
          columns={columns}
          dataSource={concatUser}
          pagination={false}
          loading={false}
          scroll={{
            scrollToFirstRowOnChange: false,
            y: 600,
            x: 1000
          }}
        />
      )}
    </>
  )
}

const mapStateToProps = state => ({
  grtData: state.adminFinances.grtData,
  grtRequesting: state.adminFinances.grtRequesting,
  grtConcat: state.adminFinances.grtConcat
})

const mapDispatchToProps = dispatch => ({
  getGRTRequest: (data, page) => dispatch(getGRTRequest(data, page)),
  grtDataConcat: data => dispatch(grtDataConcat(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(GRTTable)
