import { all, call, put, takeLatest } from "redux-saga/effects"
import { BASE_URL } from "../../../config/app"
import XHR from "../../../utils/XHR"
import {
  getReaderApplicationSuccess,
  getReaderApplicationFailure,
  getWriterApplicationSuccess,
  getWriterApplicationFailure,
  getEditorApplicationSuccess,
  getEditorApplicationFailure,
  getSaveApplicationSuccess,
  getSaveApplicationFailure,
  patchWriterDataSuccess,
  patchWriterDataFailure,
  patchEditorDataSuccess,
  patchEditorDataFailure,
  patchProducerDataSuccess,
  patchProducerDataFailure,
  patchSaveDataSuccess,
  patchSaveDataFailure
} from "./action"
import {
  GET_READER_APPLICATION,
  GET_WRITER_APPLICATION,
  GET_EDITORS_APPLICATION,
  GET_SAVE_APPLICATION,
  PATCH_WRITER_DATA,
  PATCH_EDITOR_DATA,
  PATCH_PRODUCER_DATA,
  PATCH_SAVE_DATA
} from "./type"

const readerApplicationAPI = async () => {
  const URL = `${BASE_URL}api/v1/prod_reader_application/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

const writerApplicationAPI = async () => {
  const URL = `${BASE_URL}api/v1/prod_writer_application/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

const editorApplicationAPI = async () => {
  const URL = `${BASE_URL}api/v1/prod_editor_application/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

const saveApplicationAPI = async () => {
  const URL = `${BASE_URL}api/v1/prod_saved_application/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

const writerPatchAPI = async (id, status) => {
  const URL = `${BASE_URL}api/v1/prod_reader_application/${id}/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "PATCH",
    data: status
  }
  return XHR(URL, options)
}

const editorPatchAPI = async (id, status) => {
  const URL = `${BASE_URL}api/v1/prod_writer_application/${id}/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "PATCH",
    data: status
  }
  return XHR(URL, options)
}

const producerPatchAPI = async (id, status) => {
  const URL = `${BASE_URL}api/v1/prod_editor_application/${id}/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "PATCH",
    data: status
  }
  return XHR(URL, options)
}

const savePatchAPI = async (id, status) => {
  const URL = `${BASE_URL}api/v1/prod_saved_application/${id}/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "PATCH",
    data: status
  }
  return XHR(URL, options)
}

function* writerPatchData({ id, status }) {
  try {
    const response = yield call(writerPatchAPI, id, status)
    yield put(patchWriterDataSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(patchWriterDataFailure(response.data))
  }
}

function* editorPatchData({ id, status }) {
  try {
    const response = yield call(editorPatchAPI, id, status)
    yield put(patchEditorDataSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(patchEditorDataFailure(response.data))
  }
}

function* producerPatchData({ id, status }) {
  try {
    const response = yield call(producerPatchAPI, id, status)
    yield put(patchProducerDataSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(patchProducerDataFailure(response.data))
  }
}

function* savePatchData({ id, status }) {
  try {
    const response = yield call(savePatchAPI, id, status)
    yield put(patchSaveDataSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(patchSaveDataFailure(response.data))
  }
}

function* readerApplicationData() {
  try {
    const response = yield call(readerApplicationAPI)
    yield put(getReaderApplicationSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(getReaderApplicationFailure(response.data))
  }
}

function* writerApplicationData() {
  try {
    const response = yield call(writerApplicationAPI)
    yield put(getWriterApplicationSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(getWriterApplicationFailure(response.data))
  }
}

function* editorApplicationData() {
  try {
    const response = yield call(editorApplicationAPI)
    yield put(getEditorApplicationSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(getEditorApplicationFailure(response.data))
  }
}

function* saveApplicationData() {
  try {
    const response = yield call(saveApplicationAPI)
    yield put(getSaveApplicationSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(getSaveApplicationFailure(response.data))
  }
}

export default all([
  takeLatest(GET_READER_APPLICATION, readerApplicationData),
  takeLatest(GET_WRITER_APPLICATION, writerApplicationData),
  takeLatest(GET_EDITORS_APPLICATION, editorApplicationData),
  takeLatest(GET_SAVE_APPLICATION, saveApplicationData),
  takeLatest(PATCH_WRITER_DATA,writerPatchData),
  takeLatest(PATCH_EDITOR_DATA,editorPatchData),
  takeLatest(PATCH_PRODUCER_DATA,producerPatchData),
  takeLatest(PATCH_SAVE_DATA,savePatchData)
])
