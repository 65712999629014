import React, { useEffect } from "react"
import { connect } from "react-redux"
import backIcon from "../../../assets/images/less-white-icon.svg"
import { useHistory } from "react-router-dom"

import { getTermsConditionsRequest } from "./redux/action"
import { Spinner } from "reactstrap"

const TermsConditions = props => {
  const { getTermsConditionsRequest, termsCondtionData, requesting } = props
  const history = useHistory()

  useEffect(() => {
    getTermsConditionsRequest()
  }, [])

  return (
    <>
      <section className="landing-page-section policy-page-section">
        <div className="container-fluid landing-content-container">
          <div className="row landing-content-row policy-content-row">
            <div className="col-md-12">
              <div className="policy-wrapper terms-wrapper">
                <div className="header">
                  <div className="back-icon" onClick={() => history.goBack()}>
                    <img src={backIcon} alt="" />
                  </div>
                  <h2>Story Host</h2>
                  <h3>Terms & Conditions</h3>
                  <h4>STORYHOST.COM TERMS OF USE</h4>
                  Last updated August 12, 2020 <br />
                  <br /> IMPORTANT - PLEASE READ CAREFULLY: These Terms of Use
                  (&quot;<b>Terms</b>&quot;) are a legal agreement between you
                  and StoryHost.Com (&ldquo;<b>Story Host</b>&rdquo;) and
                  governs how you may read and vote for stories using the Good
                  Reads Subscription service, and possibly upgrade your access
                  to contribute to the writing, editing, and production of
                  stories (each a &ldquo;<b>Story Project</b>&rdquo; and
                  collectively the &quot;<b>Story Projects</b>&quot;). By using
                  the Story Host website(s) (&ldquo;<b>Sites</b>&rdquo;), you
                  agree to these Terms. If you do not agree to these Terms,
                  please do not use the Sites. Accessing the Sites, buying a
                  Good Reads Subscription, and participating in a Story Project
                  are collectively the &ldquo;<b>Services.&rdquo;</b> 
                  <p>
                    <b> <br />
                      BY USING THE SERVICES, YOU ACKNOWLEDGE THAT YOU HAVE READ
                      THESE TERMS AND AGREE TO BE BOUND BY THESE TERMS AND
                      CONDITIONS.
                    </b>
                  </p>
                  <p>
                    PLEASE REVIEW STORY HOST&rsquo;S PRIVACY POLICY, WHICH IS
                    INCORPORATED INTO THESE TERMS BY REFERENCE.
                  </p>
                  <b>1. GRANT OF LICENSE.</b>
                  <br />
                  Subject to these Terms, Story Host grants you a worldwide,
                  non-exclusive, limited, revocable, non- transferable license
                  to use the Services for your personal use. This license allows
                  you to create an Account (see section 3 below) and use the
                  Services as described in this Agreement.
                  <br />
                  <p>
                    <b>   <br /> 2. LICENSE RESTRICTIONS.</b>
                    <br /> You may not: (a) modify, translate, reverse-engineer,
                    reverse-compile or decompile, disassemble, create derivative
                    works of, or use data gathering or extraction tools in
                    connection with the Sites; (b) reproduce, duplicate, copy,
                    sell, resell, or otherwise exploit the Sites, in whole or in
                    part, for any purpose without Story Host&rsquo;s express
                    written consent; (c) frame or utilize framing techniques to
                    enclose any trademark, logo, or other proprietary
                    information (including images, text, page layout, or form)
                    without the express written consent of the respective owner;
                    (d) use any data mining tools or automation tools such as
                    spiders, crawlers, scripts, bots, or any automated method of
                    recording information; (e) use any meta-tags or any other
                    &quot;hidden text&quot; utilizing &ldquo;Story Host,&rdquo;
                    its affiliates, partners or artists without Story
                    Host&rsquo;s express written permission; or (f) engage in
                    any conduct in connection with the Services that may be
                    considered illegal or tortious including, without
                    limitation, &ldquo;hacking&rdquo; the Services, or
                    infringing the intellectual property or other proprietary
                    rights of Story Host or third-parties. Additionally, where
                    the Services are available as downloadable files, Story Host
                    grants you a non- exclusive, non-transferable, revocable,
                    limited license to use the Services for your personal use on
                    authorized devices, unless otherwise specified. The Services
                    may not be copied, resold, or sublicensed. These Terms apply
                    to all versions and updates to the Services. The Services
                    may include measures to control access to the Services
                    (including age controls), prevent unauthorized copies, or
                    otherwise attempt to prevent anyone from exceeding the
                    limited rights and licenses granted under these Terms. You
                    may not take any action to circumvent or defeat such
                    security features. If you disable or otherwise tamper with
                    the technical protection measures, your license to use the
                    Services will be considered automatically revoked and we may
                    also close your Story Host Account (described below).
                  </p>
                  <p>
                    <b>3. MEMBERSHIP LEVELS.</b>
                    <br /> Reader: Readers may purchase Story Host&rsquo;s
                    monthly Good Read Subscription, read stories posted on the
                    Sites, and use the tokens (&ldquo;<b>Good Read Tokens</b>
                    &rdquo;) you obtain through your subscription to award Good
                    Story Tokens to stories you enjoy reading. Writer: Writers
                    have all Reader rights plus you may write and submit posts
                    and redeem Good Read Tokens awarded to you by other Members.
                    Editor: Editors have all Writer rights plus you may edit and
                    approve the posting of a Writer submitted posts on the
                    Sites. Producer: Producers have all Editor rights plus you
                    may create new story messages boards.
                  </p>
                  <p>
                    <b>4. THIRD-PARTY PRODUCTS, SERVICES, AND LINKS.</b>
                    <br />
                    Story Host may offer third-party products through the
                    Services, such as paying for or redeeming Good Story Tokens
                    through a third-party payment service provider. You assume
                    all risks and liabilities associated with the use of any
                    such third-party products. Additionally, your rights and
                    obligations regarding such products or services will be
                    governed by the separate terms of use and privacy policies
                    of the applicable third-party provider.
                  </p>{" "}
                  <p>
                    <b>5. ACCESS TO THE SERVICES.</b>
                    <br /> By using the Services, you affirm that you are at
                    least 14 years old or that you have reviewed these Terms
                    with your parent or guardian and they approved these Terms
                    on your behalf and takes full responsibility for your
                    compliance with the Terms. If you are under the age of 14,
                    you must not attempt to use our Services, register or use a
                    Story Host Account, and/or submit any Personal Information
                    (defined in the Privacy Policy) to us. If it comes to our
                    attention that Personal Information has been collected from
                    a person under the age of 14, we will delete this
                    information as quickly as possible. If you believe that we
                    may have Personal Information from or about a person under
                    the age of 14, please contact us at hostadmin@StoryHost.com.
                    If we learn that a minor is using the Services, we will
                    require verified parental or guardian consent, in accordance
                    with the Children's Online Privacy Protection Act of 1998
                    (&quot;<b>COPPA</b>&quot;).
                  </p>
                  <p>
                    You may be required to register an account before using
                    certain Services (&quot;<b>Account</b>&quot;). You may also
                    be required or have the option to access the Services
                    through a social networking service (&quot;<b>SNS</b>
                    &quot;). Your Account is personal to you, and you may not
                    rent, lease, sell, trade, gift, bequeath, or otherwise
                    transfer it to anyone else. You will provide true and
                    complete information about yourself when you register your
                    Account and you will keep the account information up to
                    date. You may not have more than one Account or access the
                    Services through more than one SNS at any time. You are
                    solely responsible for maintaining the confidentiality of
                    your password and username and for any activities that occur
                    under your Account. Story Host does not authorize automated
                    methods to be used to sign up for an Account. Story Host
                    reserves the right to immediately terminate your access to
                    the Services or your Account if you breach these Terms or
                    otherwise violate any applicable law, rule, or regulation.
                  </p>
                  <p>
                    By using the Services and providing us with your contact
                    information, you explicitly consent to receive
                    communications from us. If you do not want to receive such
                    communications, please review Story Host&rsquo;s Privacy
                    Policy and opt-out procedures.
                  </p>
                  <p>
                    <b>6. GOOD READ TOKENS BASICS.</b>
                    <br />
                    The monthly Good Read Subscription provides you with Good
                    Read Tokens you may use to reward other Members for their
                    participation in a Story Project. Good Read Tokens are
                    provided to you under a limited, personal, revocable,
                    non-sublicensable license to use within the Services. If you
                    are a Writer, you have no property interest, right, or title
                    in or to any Good Read Tokens. No Member may resell Good
                    Read Tokens in any manner, including, without limitation, by
                    means of any direct sale or auction service. If you are a
                    Writer, Editor, or Producer, you may redeem Good Read Tokens
                    according to Story Host&rsquo;s Token Redemption Process.
                  </p>
                  <p>
                    You agree that your purchases of the monthly Good Read
                    Subscription and any related Good Read Tokens are final. No
                    refunds will be given, except in Story Host&rsquo;s sole
                    discretion. Story Host has no liability for hacking or loss
                    of your Good Read Tokens or any benefits you receive by
                    subscribing to the Good Reads Subscription and using the
                    Services. Story Host has no obligation to, and will not,
                    reimburse you for any Good Read Tokens. Story Host reserves
                    the right, in its sole discretion and without prior
                    notification, to change or limit the price, availability, or
                    order quantity of any Good Read Tokens and to refuse to
                    provide you with any Good Read Tokens.
                  </p>
                  <p>
                    Good Read Tokens may have an expiration date. If your Good
                    Read Tokens expire and you still have an active Account,
                    Story Host may offer conversion of such Good Read Tokens in
                    Story Host&rsquo;s sole discretion; however Story Host is
                    under no obligation to do so. Good Read Tokens may be
                    forfeited if: (a) your Account or access to the Services is
                    terminated or suspended for any reason; (b) you breach these
                    Terms; (c) Story Host discontinues availability of the
                    Service; or (d) you stop using the Services.
                  </p>
                  <p>
                    <b>7. ONLINE CONTENT AND CONDUCT.</b>
                    <br />
                    The Services include opportunities, through your Account or
                    SNS, to review and assign Good Read Tokens to story content
                    created by other users (&ldquo;<b>Story Content</b>&rdquo;),
                    earn Good Read Tokens by providing feedback, editing, and
                    producing Story Content, chat or participate in blogs,
                    message boards, online forums and other functions that
                    provide you with the opportunity to create, submit, post,
                    display, transmit, perform, publish, or distribute to the
                    world (&quot;<b>Forum(s)</b>&quot;) links, writings, photos,
                    graphics, or other material (&quot;<b>Forum Content</b>
                    &quot;). All Forum Content posted by you to any Forum is
                    your sole responsibility.
                  </p>
                  <p>
                    Story Host has the right, but not the responsibility, to
                    monitor or remove Forum Content that Story Host believes to
                    be harmful, offensive, or otherwise violates these Terms,
                    without liability to you. Story Host, in its sole
                    discretion, reserves the right to restrict or disable your
                    use of the Forums.
                  </p>
                  <p>
                    By submitting Forum Content to any Forum, you warrant that
                    you have all necessary rights to submit the Forum Content
                    without any restrictions of any kind, and grant to Story
                    Host a fully-paid, royalty-free, non-exclusive, irrevocable,
                    worldwide, unconditional, perpetual, right and license to
                    use, reproduce, modify, publicly display and perform, make
                    derivatives works of, sublicense, and distribute all such
                    Forum Content in all media now known or later developed,
                    and/or to incorporate such materials in other works in any
                    form, media, or technology now known or later developed.
                  </p>
                  <p>
                    Any opinions, advice, statements, services, offers, or other
                    information or content expressed or made available by
                    third-parties in the Forums, are those of the respective
                    authors and not of Story Host or its affiliates, and Story
                    Host is not responsible for the accuracy or reliability of
                    same. Story Host makes no representations or warranties
                    regarding the Forums.
                  </p>
                  <p>When using the Services or the Forums, you may not:</p>
                  a) Use the Services or Forums in any manner which violates
                  applicable laws or regulations;
                  <br />
                  b) Post or transmit any Forum Content that: (i) violates a
                  third party's rights or dignity, including any contract,
                  privacy, publicity or intellectual property rights; (ii) is
                  deceptive, untrue, harassing, libelous, defamatory, abusive,
                  tortious, threatening, harmful, obscene, indecent, hateful,
                  bigoted, racist or pornographic; (iii) would constitute or
                  encourage a criminal offense; (iv) contains any viruses, worms
                  or similar software; or (v) includes personal information
                  about another person without that person's consent; <br />
                  c) Impersonate or falsely state or represent your identity;
                  <br />
                  d) Engage in or facilitate the transmission of unsolicited
                  mass mailings, or &quot;spamming&quot;;
                  <br />
                  e) Distribute or otherwise publish any material containing any
                  solicitation, promotion, or advertising for goods or Services;
                  <br />
                  f) Collect personal data about others for unlawful,
                  commercial, or any other unauthorized purposes;
                  <br />
                  g) Access, or attempt to gain access to, another user&rsquo;s
                  account without authorization;
                  <br />
                  h) Promote, encourage, or take part in any activity involving
                  hacking, phishing, taking advantage of exploits or cheats
                  and/or distribution of counterfeit software and/or counterfeit
                  virtual currency/items;
                  <br />
                  i) Use any content hacking/altering/cheating software or
                  tools;
                  <br />
                  j) Upload files that contain a virus, worm, spyware, time
                  bombs, corrupted data or other computer programs that may
                  damage, or otherwise interfere with the Services; or <br />
                  k) Reverse engineer, decompile, or modify, or attempt to
                  modify, any part of the Services.
                  <p>
                    You understand that you may be exposed to Forum Content and
                    actions from other users that are indecent, explicit,
                    offensive, or which otherwise violates these Terms (&ldquo;
                    <b>Inappropriate Activity</b>&rdquo;). You can report actual
                    or suspected Inappropriate Activity to
                    hostadmin@StoryHost.com. Story Host reserves the right, in
                    its sole discretion, to take any action against
                    Inappropriate Activity that Story Host believes to be
                    harmful, offensive, or otherwise violates these Terms. Story
                    Host will not be liable to you or any third-party in any way
                    for Forum Content, Inappropriate Activity, or for any damage
                    or loss therefrom, including service interruption or system
                    failure.
                  </p>
                  <p>
                    <b>8. TITLE.</b>
                    <br />
                    Unless otherwise specified in these Terms, any and all
                    title, ownership, rights, and intellectual property rights
                    in and to the Services, except content created or provided
                    by Service users, (collectively the &ldquo;
                    <b>Story Host Content</b>&rdquo;) will remain in Story Host
                    and/or its suppliers, and is protected by the copyright laws
                    of the United States and other jurisdictions around the
                    world via international copyright treaties. The names and
                    logos, and other graphics, icons, and service names
                    associated with the Story Host Content are trademarks,
                    registered trademarks or trade dress of Story Host or its
                    licensors. You may not use, copy, transmit, modify,
                    distribute, or create any derivative works from the Services
                    or the Story Host Content without prior written consent from
                    Story Host.
                  </p>
                  <p>
                    The Services may allow you to create content (&ldquo;
                    <b>Your Content</b>&rdquo;) that incorporates the Story Host
                    Content. Story Host retains all rights, title, and interest
                    in and to the Story Host Content. You retain all rights to
                    Your Content; provided, however, that Story Host will have
                    an exclusive, perpetual, irrevocable, fully transferable and
                    sub-licensable worldwide right and license to Your Content
                    for any purpose, including, but not limited to, the rights
                    to reproduce, copy, adapt, modify, perform, display,
                    publish, broadcast, transmit, or otherwise communicate to
                    the public by any means whether now known or unknown and
                    distribute Your Content without any further notice or
                    compensation to you. Except where prohibited by law, you
                    waive any moral rights of paternity, publication,
                    reputation, or attribution with respect to Story Host's use
                    of Your Content.
                  </p>
                  <p>
                    <b>9. DISCLAIMER OF WARRANTY AND LIMIT OF LIABILITY.</b>
                    <br /> STORY HOST MAKES NO REPRESENTATION, WARRANTY, AND/OR
                    GUARANTEE OF THE SUITABILITY OF THE SERVICES FOR YOUR
                    PURPOSES, OR THAT THE USE OF THE SAME WILL BE SECURE,
                    UNINTERRUPTED, ERROR-FREE, FREE OF VIRUSES OR OTHER HARMFUL
                    COMPONENTS, OR FUNCTION PROPERLY IN COMBINATION WITH ANY
                    THIRD-PARTY TECHNOLOGY, HARDWARE, SOFTWARE, SYSTEMS, OR
                    DATA.
                  </p>
                  <p>
                    THE SERVICES ARE PROVIDED &ldquo;AS IS&quot; AND ALL
                    CONDITIONS, REPRESENTATIONS, AND WARRANTIES, WHETHER
                    EXPRESS, IMPLIED, STATUTORY OR OTHERWISE, INCLUDING, WITHOUT
                    LIMITATION, ANY IMPLIED WARRANTY OF MERCHANTABILITY, TITLE,
                    FITNESS FOR A PARTICULAR PURPOSE, OR NON-INFRINGEMENT ARE
                    HEREBY DISCLAIMED TO THE MAXIMUM EXTENT PERMITTED BY
                    APPLICABLE LAW. FURTHER, STORY HOST MAKES NO REPRESENTATIONS
                    OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE
                    OPERATION OF THE SAME, OR TO THE INFORMATION, CONTENT,
                    COMMUNICATIONS, MATERIALS, OR PRODUCTS AND SERVICES
                    CONTAINED THEREIN. YOUR USE OF THE SERVICES IS AT YOUR SOLE
                    RISK.
                  </p>
                  <p>
                    STORY HOST WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND
                    ARISING FROM YOUR USE OF THE SERVICES, INCLUDING, WITHOUT
                    LIMITATION, DIRECT, INDIRECT, INCIDENTAL, PUNITIVE AND
                    CONSEQUENTIAL DAMAGES. CERTAIN REGIONAL LAWS DO NOT ALLOW
                    LIMITATIONS ON IMPLIED WARRANTIES OR THE EXCLUSION OR
                    LIMITATION OF CERTAIN DAMAGES. IF THESE LAWS APPLY TO YOU,
                    SOME OR ALL OF THE ABOVE DISCLAIMERS, EXCLUSIONS, OR
                    LIMITATIONS MAY NOT APPLY TO YOU, AND YOU MAY HAVE
                    ADDITIONAL RIGHTS.
                  </p>
                  <p>
                    YOU FURTHER ACKNOWLEDGE THAT STORY HOST IS NOT LIABLE, AND
                    YOU AGREE NOT TO HOLD STORY HOST LIABLE, FOR THE CONDUCT OF
                    THIRD-PARTIES, INCLUDING OTHER USERS OF THE SERVICES AND
                    OTHER THIRD-PARTY SITES, AND THAT THE RISK OF USING OR
                    ACCESSING THE SERVICES, AND OF INJURY FROM THE FOREGOING,
                    RESTS ENTIRELY WITH YOU.
                  </p>
                  <p>
                    <b>10. INDEMNIFICATION.</b>
                    <br />
                    You will hold harmless, indemnify and defend Story Host, its
                    licensors, affiliates and suppliers and their respective
                    officers, directors and employees, from and against any
                    losses, damages, fines and expenses (including
                    attorneys&rsquo; fees and costs) due to, arising out of, or
                    relating to any information or item, including, without
                    limitation, Your Content, Forum Content, and/or
                    Inappropriate Content you may submit, post, transmit or make
                    available through the Forums or Services, your use of the
                    Forums and Services, your connection to the Forums and
                    Services, your violation of these Terms, or your violation
                    of any law, regulation or third-party right. Without
                    limiting your indemnification obligations described above,
                    Story Host reserves the right, at its own expense, to assume
                    the exclusive defense and control of any matter subject to
                    indemnification by you.
                  </p>
                  <p>
                    <b>11. TERMINATION.</b> <br />
                    These Terms will automatically terminate if you fail to
                    comply with any term. You and Story Host have the right to
                    terminate or cancel your Account, at any time for any reason
                    and without notice. Upon termination, you will immediately
                    discontinue use of the Services. Your obligations under
                    Sections 5, 6, 11, 12,13, 16, and 17 will survive any
                    termination.
                  </p>
                  <p>
                    <b>12. NO ASSIGNMENT.</b>
                    <br /> These Terms and the licenses granted to you are
                    personal to you and may not be assigned prior to obtaining
                    Story Host's express written consent.
                  </p>
                  <p>
                    <b>13. GOVERNING LAW.</b>
                    <br /> This Agreement is governed by the laws of Washington
                    State. If there is a dispute between you and Story Host
                    regarding these Terms of Use, you agree to use reasonable
                    efforts to resolve such dispute with Story Host informally
                    and to consult and negotiate with Story Host in good faith
                    to reach a fair and equitable solution. If the parties
                    cannot resolve the dispute, the parties irrevocably submit
                    and consent to the to the jurisdiction and venue of the
                    state and federal courts in Seattle, Washington. The parties
                    hereby irrevocably waive any and all objections to the
                    laying of venue of any such suit, action or proceeding
                    brought in any such federal or state court in the State of
                    Washington.
                  </p>
                  <p>
                    <b>14. ATTORNEY&rsquo;S FEES.</b>
                    <br />
                    If a suit in law or in equity is necessary to enforce the
                    terms of this Agreement, the prevailing party will be
                    entitled to reasonable fees of attorneys, accountants, and
                    other professionals, and costs and expenses in addition to
                    any other relief to which such prevailing party may be
                    entitled.
                  </p>
                  <p>
                    <b>15. WAIVER OF CLASS-WIDE PROCEEDINGS.</b>
                    <br /> You agree that, by entering into these Terms, you and
                    we are each waiving the right to participate in a class
                    action. Each party to these Terms agrees and covenants that
                    it will not initiate any class-wide proceedings, including
                    class actions, against another party, and will not act as a
                    class representative or class member. This provision
                    constitutes an agreement that any dispute will be resolved
                    exclusively on a bilateral basis between the parties, with
                    each party acting in his/her/its individual capacity.
                  </p>
                  <p>
                    <b>16. INTERSTATE NATURE OF COMMUNICATIONS.</b> You
                    acknowledge that in using Story Host Services you will be
                    causing communications to be sent through interstate
                    telecommunications networks, which are governed by federal
                    law pursuant to the interstate commerce clause of the US
                    Constitution. Even communications that seem to be intrastate
                    in nature can result in the transmission of interstate
                    communications regardless of where you are physically
                    located at the time of transmission. You acknowledge that
                    use of the Services results in interstate data
                    transmissions.
                  </p>
                  <p>
                    <b>17. INFRINGEMENT NOTIFICATION.</b>
                    <br /> Any claims related to the Services should be
                    addressed to:
                  </p>
                  <p>
                    Legal Counsel - Story Host
                    <br />
                    Hughes Media Law Group PC
                    <br />
                    2003 Western Avenue, Suite 460
                    <br />
                    Seattle, WA 98121
                  </p>
                  <p>
                    Host&rsquo;s designated agent for notice of claims of
                    copyright infringement is as follows:
                  </p>{" "}
                  Legal Counsel - Story Host
                  <br />
                  Hughes Media Law Group PC
                  <br />
                  2003 Western Avenue, Suite 460 <br />
                  Seattle, WA 98121
                  <br />
                  Telephone: 206.774.0879|Fax: 206.260.9300
                  <br />
                  Email: hostadmin@Story Host.com
                  <p>
                    <b>18. FEEDBACK.</b>
                    <br /> Story Host does not accept unsolicited feedback,
                    suggestions, or other information about the Services or
                    other aspects of its business. If you provide Story Host
                    with feedback or other ideas, you agree that Story Host is
                    free to use any feedback provided by you for any purpose
                    (without compensation to you), including, without
                    limitation, improving its products and Services and creating
                    derivative products.
                  </p>
                  <p>
                    <b>19. GENERAL.</b>
                    <br />
                    These Terms, along with the Privacy Policy, and any other
                    privacy policy applicable to the specific Services you are
                    using, constitute the complete and final agreement between
                    Story Host and you with respect to your use of the Services
                    and may not be modified by you except in a writing duly
                    signed by you and an authorized representative of Story
                    Host. Your breach of these Terms will cause Story Host
                    immediate and irreparable harm and Story Host will be
                    entitled to seek injunctive relief without the necessity of
                    posting bond. Story Host and you are independent parties and
                    nothing in these Terms creates an employment or agent
                    relationship. If any provision in these Terms is held to be
                    unenforceable, such provision will be reformed in that
                    matter only to the extent necessary to make it enforceable,
                    and will not affect the enforceability of the remaining
                    provisions. The failure of either party to enforce any right
                    or provision in these Terms will not constitute a waiver of
                    such right or provision.
                  </p>
                  <p>
                    Story Host may make changes to the Services, these Terms, or
                    its Privacy Policy from time to time. All such changes will
                    become effective immediately upon notice and/or posting. If
                    any of these changes are deemed invalid, void, or for any
                    reason unenforceable, that change will be deemed severable
                    and will not affect the validity and enforceability of any
                    unchanged portions of the Services, these Terms, or the
                    Privacy Policy. Story Host may transfer or assign the
                    Services, these Terms, or the Privacy Policy, in whole or in
                    part, to any third-party of Story Host&rsquo;s choosing.
                    These Terms will inure to the benefit of and be binding upon
                    Story Host&rsquo;s respective successors and assigns.
                  </p>
                  <p>
                    CONTACT US. If you have questions regarding these Terms,
                    please contact us at:
                  </p>
                  Story Host
                  <br />
                  Attn: Terms of Use
                  <br />
                  E-mail: hostadmin@StoryHost.com
                  <br />
                  Phone Number:
                </div>
                <div className="policy-content">
                  <div className="policy-details"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

const mapStateToProps = state => ({
  termsCondtionData: state.TermsConditions.termsCondtionData,
  requesting: state.TermsConditions.requesting,
  error: state.TermsConditions.error
})

const mapDispatchToProps = dispatch => ({
  getTermsConditionsRequest: () => dispatch(getTermsConditionsRequest())
})

export default connect(mapStateToProps, mapDispatchToProps)(TermsConditions)
