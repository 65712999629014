import React from "react"
import Modal from "react-bootstrap/Modal"
import imageIcon from "../../../assets/images/image-icon.svg"
import greaterWhiteIcon from "../../../assets/images/greater-white.svg"
import lessWhiteIcon from "../../../assets/images/less-white-icon.svg"
import "./style.scss"
import NovelDetails from "./NovelDetails"
import { connect } from "react-redux"

import { setCurrentNovelIndex } from "../../../pages/HomeScreens/CurrentNovels/redux/action"

const NovelsModal = ({
  modalShow,
  setModalShow,
  novelIndex,
  currentNoval,
  setCurrentNovelIndex
}) => {
  
  const handleBackControl = () => {
    if (novelIndex > 0) {
      setCurrentNovelIndex(novelIndex - 1)
    }
  }

  const handleNextControl = () => {
    if (novelIndex < currentNoval.length - 1) {
      setCurrentNovelIndex(novelIndex + 1)
    }
  }

  return (
    <>
      <Modal
        show={modalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="home-genre-modal"
      >
        <Modal.Body>
          <div className="controls-group">
            <div
              className="control left-control"
              onClick={handleBackControl}
            >
              <img src={lessWhiteIcon} alt="left" />
            </div>
            <div
              className="control right-control"
              onClick={handleNextControl}
            >
              <img src={greaterWhiteIcon} alt="right" />
            </div>
          </div>
          <div className="modal-body-left">
            <div
              className={`image-wrapper ${
                !currentNoval[novelIndex]?.novel?.thumbnail && "no-img-wrapper"
              }`}
            >
              <img
                src={currentNoval[novelIndex]?.novel?.thumbnail || imageIcon}
                alt=""
              />
            </div>
          </div>
          <NovelDetails
            setModalShow={setModalShow}
            NovelsData={currentNoval}
            controlIndex={novelIndex}
          />
        </Modal.Body>
      </Modal>
    </>
  )
}

const mapStateToProps = state => ({
  novelIndex: state.CurrentNovels.novelIndex
})

const mapDispatchToProps = dispatch => ({
  setCurrentNovelIndex: data => dispatch(setCurrentNovelIndex(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(NovelsModal)
