import {
  TERM_AND_CONDITION_DATA,
  TERM_AND_CONDITION_DATA_SUCCESS,
  TERM_AND_CONDITION_DATA_FAILURE
} from "./type"

const initialState = {
  termsCondtionData: [],
  error: false,
  requesting: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case TERM_AND_CONDITION_DATA:
      return {
        ...state,
        requesting: true
      }
    case TERM_AND_CONDITION_DATA_SUCCESS:
      return {
        ...state,
        requesting: false,
        termsCondtionData: action.data,
        error: false
      }
    case TERM_AND_CONDITION_DATA_FAILURE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }
    default:
      return state
  }
}
