import React from "react"
import downIcon from "../../../assets/images/down-icon.svg"
import {
  BarChart,
  Bar,
  Cell,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer
} from "recharts"

import "./style.scss"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"

const YearSubscriptionGraph = ({ data, isLoading }) => {
  const mockData = [
    {
      name: "Jan",
      uv: "8"
    },
    {
      name: "Feb",
      uv: "5"
    },
    {
      name: "Mar",
      uv: "9"
    },
    {
      name: "Apr",
      uv: "3"
    },
    {
      name: "May",
      uv: "7"
    },
    {
      name: "Jun",
      uv: "8"
    },
    {
      name: "Jul",
      uv: "9"
    },
    {
      name: "Aug",
      uv: "6"
    },
    {
      name: "Sep",
      uv: "4"
    },
    {
      name: "Oct",
      uv: "9"
    },
    {
      name: "Nov",
      uv: "8"
    },
    {
      name: "Dec",
      uv: "6"
    }
  ]
  return (
    <div className="subscription-graph-bg">
      {isLoading ? (
        <>
          <div className="graph-header">
            <div className="title">Yearly Subsctription Graph</div>
          </div>
          <div className="admin-chart-bar-wrapper">
            <ResponsiveContainer aspect={2.5}>
              <BarChart
                className="admin-bar-chart"
                width={488}
                height={220}
                data={mockData}
                margin={{ top: 0, left: -30, right: 0, bottom: 0 }}
              >
                <XAxis
                  dataKey="name"
                  axisLine={false}
                  tickLine={false}
                  fontSize="9px"
                  color="#2B292D"
                  fontWeight={500}
                  tickMargin={10}
                />
                <YAxis
                  axisLine={false}
                  tickLine={false}
                  fontSize="10px"
                  color="#2B292D"
                  fontWeight={600}
                  tickMargin={5}
                />
                <Bar
                  dataKey="uv"
                  barSize={10}
                  fill="grey"
                  radius={100}
                  color="grey"
                  spacing={30}
                  background={{ fill: "white", radius: 10 }}
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </>
      ) : (
        <>
          <div className="graph-header">
            <div className="title">Yearly Subsctription Graph</div>
            {/* <div className="duration">
               <span>Month</span>
               <img src={downIcon} alt="" />
             </div> */}
          </div>
          <div className="admin-chart-bar-wrapper">
            <ResponsiveContainer aspect={2.5}>
              <BarChart
                className="admin-bar-chart"
                width={488}
                height={220}
                data={data}
                margin={{ top: 0, left: -30, right: 0, bottom: 0 }}
              >
                <XAxis
                  dataKey="name"
                  axisLine={false}
                  tickLine={false}
                  fontSize="9px"
                  color="#2B292D"
                  fontWeight={500}
                  tickMargin={10}
                />
                <YAxis
                  axisLine={false}
                  tickLine={false}
                  fontSize="10px"
                  color="#2B292D"
                  fontWeight={600}
                  tickMargin={5}
                />
                <Bar
                  dataKey="uv"
                  barSize={10}
                  fill="#6F8BDF"
                  radius={100}
                  color="#fff"
                  spacing={30}
                  background={{ fill: "#fff", radius: 10 }}
                />
              </BarChart>
            </ResponsiveContainer>
          </div>
        </>
      )}
    </div>
  )
}

export default YearSubscriptionGraph
