import React, { useState } from "react"
import Modal from "react-bootstrap/Modal"
import greaterWhiteIcon from "../../../assets/images/greater-white.svg"
import lessWhiteIcon from "../../../assets/images/less-white-icon.svg"
import "./style.scss"
import ApplyPositionModal from "../Apply/index"
import OpenAdverts from "./components/OpenAdverts"
import CurrentApplications from "./components/CurrentApplications"
import { useEffect } from "react"
import { connect } from "react-redux"
import { setOpenAdvertIndex } from "../../../pages/WriterHome/redux/action"

const PositionPopUp = ({
  modalShow,
  setModalShow,
  modalData,
  controls,
  currentApp,
  checkModal,
  setOpenAdvertIndex,
  openAdvertIndex
}) => {
  const [changeTab, setCruntTab] = useState("Advert")
   
  useEffect(() => {
    if (checkModal) {
      checkModal && setCruntTab("Curent")
    } else {
      setCruntTab("Advert")
    }
  }, [checkModal, checkModal])

  const handleBackControl = () => {
    if (openAdvertIndex > 0) {
      setOpenAdvertIndex(openAdvertIndex - 1)
    }
  }

  const handleNextControl = () => {
    if (openAdvertIndex < currentApp?.length - 1 || openAdvertIndex < modalData?.length - 1) {
      setOpenAdvertIndex(openAdvertIndex + 1)
    }
  }

  return (
    <>
      <Modal
        show={modalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className={`${
          changeTab === "Apply" ? "" : "home-genre-modal position-modal"
        } personal-info-modal apply-position-modal`}
      >
        <Modal.Body>
          {!controls && (
            <div
              className={`controls-group ${
                changeTab === "Apply" ? "apply-control-group" : ""
              }`}
            >
              <div className="control left-control" onClick={handleBackControl}>
                <img src={lessWhiteIcon} alt="left" />
              </div>
              <div
                className="control right-control"
                onClick={handleNextControl}
              >
                <img src={greaterWhiteIcon} alt="right" />
              </div>
            </div>
          )}
          {}
          {changeTab === "Advert" && (
            <OpenAdverts
              novelName={modalData && modalData[openAdvertIndex]?.name}
              novelPic={modalData && modalData[openAdvertIndex]?.thumbnail}
              Paragraph={modalData && modalData[openAdvertIndex]?.about_novel}
              genre={modalData && modalData[openAdvertIndex]?.genre}
              authorName={(modalData && modalData[openAdvertIndex]?.author?.name) || '---'}
              authorId={modalData && modalData[openAdvertIndex]?.author?.id}
              writerName={
                (modalData &&
                  modalData[openAdvertIndex]?.character &&
                  modalData[openAdvertIndex]?.character[0]?.writer?.name) ||
                "---"
              }
              setModalShow={setModalShow}
              setCruntTab={setCruntTab}
            />
          )}
          {changeTab === "Curent" && (
            <CurrentApplications
              setModalShow={setModalShow}
              currentAppData={currentApp}
              openAdvertIndex={openAdvertIndex}
            />
          )}
          {changeTab === "Apply" && (
            <ApplyPositionModal
              setModalShow={setModalShow}
              setCruntTab={setCruntTab}
              novelID={modalData[openAdvertIndex]?.id}
            />
          )}
        </Modal.Body>
      </Modal>
    </>
  )
}

const mapStateToProps = state => ({
  openAdvertIndex: state.writerHome.openAdvertIndex
})

const mapDispatchToProps = dispatch => ({
  setOpenAdvertIndex: data => dispatch(setOpenAdvertIndex(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(PositionPopUp)
