// GET LOGIN
export const POST_REQUEST_LOGIN = 'POST_REQUEST_LOGIN';
export const POST_REQUEST_LOGIN_SUCCESS = 'POST_REQUEST_LOGIN_SUCCESS';
export const POST_REQUEST_LOGIN_FAILURE = 'POST_REQUEST_LOGIN_FAILURE';
export const SET_ACCESS_TOKEN = 'SET_ACCESS_TOKEN';
export const SET_FIREBASE_TOKEN = 'SET_FIREBASE_TOKEN';
export const LOGOUT = 'LOGOUT';
export const SET_MEMBER_ID = 'SET_MEMBER_ID';
export const SET_USER_ID = 'SET_USER_ID';
export const SET_CHAPTER_ID = 'SET_CHAPTER_ID';
export const SET_BOOK_ID = 'SET_BOOK_ID';
export const SET_WORKROOM_ID = 'SET_WORKROOM_ID';
export const SET_APPLICATION_DATA = 'SET_APPLICATION_DATA';
export const SET_NEXT_CHAPTER_ID = 'SET_NEXT_CHAPTER_ID';
// TOGGLE_USER_TYPE
export const TOGGLE_USER_TYPE = 'TOGGLE_USER_TYPE';
export const TOGGLE_USER_TYPE_SUCCESS = 'TOGGLE_USER_TYPE_SUCCESS';
export const TOGGLE_USER_TYPE_FAILURE = 'TOGGLE_USER_TYPE_FAILURE';
export const SET_CHARACTER_ID = 'SET_CHARACTER_ID';
export const SET_UPDATE_NOVEL_ID = "SET_UPDATE_NOVEL_ID";

