import React, { useEffect, useState } from "react"
import awardIcon from "../../../assets/images/awarded.svg"
import favoriteIcon from "../../../assets/images/favorites-white.svg"
import globeIcon from "../../../assets/images/globe.svg"
import profileIcon from "../../../assets/images/profile-white.svg"
import tokenIcon from "../../../assets/images/token-white.svg"
import AnalyticsDetailCard from "../../../components/AdminComponents/AnalyticsDetailCard"
import TopGRTCard from "../../../components/AdminComponents/TopGRTCard"
import YearSubscriptionGraph from "../../../components/AdminComponents/YearSubscriptionGraph"
import AdminLayout from "../../../layout/AdminLayout"
import { connect } from "react-redux"
import { getAdminHome } from "./redux/action"
import { Spinner } from "reactstrap"
import { getAdminHomeBox } from "./redux/action"
import { SkeletonTheme } from "react-loading-skeleton"

const AdminHome = props => {
  const {
    adminHomeInfo,
    requesting,
    getAdminHomeData,
    getAdminHomeBox,
    homeBoxData,
    homeBoxRequesting
  } = props
  const [graphData, setGraphData] = useState([])

  useEffect(() => {
    getAdminHomeData()
    getAdminHomeBox()
  }, [])

  useEffect(() => {
    if (adminHomeInfo) {
      let graphValue = adminHomeInfo?.activity.map(item => ({
        name: item.month,
        uv: item.count
      }))
      setGraphData(graphValue)
    }
  }, [adminHomeInfo])

  return (
    <AdminLayout headerName={"Home"} searchBarWrapper={true}>
      {homeBoxRequesting ? (
        <SkeletonTheme baseColor="#6f8bdf40" highlightColor="#6F8BDF">
          <div className="content-section admin-content-section">
            <div className="admin-home-wrapper">
              <div className="container-fluid admin-home-container">
              <SkeletonTheme baseColor="#fff" highlightColor="#6F8BDF">
                <div className="row admin-row-wrapper">
                  <AnalyticsDetailCard
                    isLoading={homeBoxRequesting}
                    subtitle={"Total Subscriptions"}
                    iconImg={profileIcon}
                  />
                  <AnalyticsDetailCard
                    isLoading={homeBoxRequesting}
                    subtitle={"Token Escrow"}
                    iconImg={tokenIcon}
                  />
                  <AnalyticsDetailCard
                    isLoading={homeBoxRequesting}
                    subtitle={"Referrals"}
                    iconImg={favoriteIcon}
                  />
                  <AnalyticsDetailCard
                    isLoading={homeBoxRequesting}
                    subtitle={"Awarded to Members"}
                    iconImg={awardIcon}
                  />
                  <AnalyticsDetailCard
                    isLoading={homeBoxRequesting}
                    subtitle={"Website"}
                    iconImg={globeIcon}
                  />
                </div>
                </SkeletonTheme>
                <div className="row">
                  <div className="col-xl-6 subscription-section-wrapper">
                    <YearSubscriptionGraph isLoading={homeBoxRequesting} />
                  </div>
                  <div className="col-xl-6 top-grt-section-wrapper">
                    <TopGRTCard isLoading={homeBoxRequesting} />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </SkeletonTheme>
      ) : (
        <div className="content-section admin-content-section">
          <div className="admin-home-wrapper">
            <div className="container-fluid admin-home-container">
              <div className="row admin-row-wrapper">
                {homeBoxData &&
                  homeBoxData?.map(item => (
                    <>
                      <AnalyticsDetailCard
                        iconImg={profileIcon}
                        title={item?.total_subscriptions}
                        subtitle={"Total Subscriptions"}
                        hoverTitle={"Total Number of subscriber."}
                      />
                      <AnalyticsDetailCard
                        iconImg={tokenIcon}
                        title={`$${item?.token_escrow.toFixed(2)}`}
                        subtitle={"Token Escrow"}
                        hoverTitle={"This amount is 95% of unawarded token for all users in app."}
                      />
                      <AnalyticsDetailCard
                        iconImg={favoriteIcon}
                        title={`$${item?.referrals.toFixed(2)}`}
                        subtitle={"Referrals"}
                        hoverTitle={"This amount is 5% of unawarded token for referrals."}
                      />
                      <AnalyticsDetailCard
                        iconImg={awardIcon}
                        title={`$${item?.awarded_to_member.toFixed(2)}`}
                        subtitle={"Awarded to Members"}
                        hoverTitle={"This amount is 70% of awarded tokens for members."}
                      />
                      <AnalyticsDetailCard
                        iconImg={globeIcon}
                        title={`$${item?.website?.toFixed(2)}`}
                        subtitle={"Website"}
                        hoverTitle={"This amount is 25% of awarded tokens for site admin."}
                      />
                    </>
                  ))}
              </div>
              <div className="row">
                <div className="col-xl-6 subscription-section-wrapper">
                  <YearSubscriptionGraph data={graphData} />
                </div>
                <div className="col-xl-6 top-grt-section-wrapper">
                  <TopGRTCard topLikes={adminHomeInfo?.top_likes} />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </AdminLayout>
  )
}

const mapStateToProps = state => ({
  adminHomeInfo: state.adminHome.adminHomeInfo,
  requesting: state.adminHome.requesting,
  error: state.adminHome.error,
  homeBoxData: state.adminHome.homeBoxData,
  homeBoxRequesting: state.adminHome.homeBoxRequesting
})

const mapDispatchToProps = dispatch => ({
  getAdminHomeData: data => dispatch(getAdminHome(data)),
  getAdminHomeBox: data => dispatch(getAdminHomeBox(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminHome)
