import {
     GET_READER_CLUB_DATA, 
     GET_READER_CLUB_SUCCESS,
     GET_READER_CLUB_FAILURE,
     POST_READER_CLUB_DATA,
     POST_READER_CLUB_SUCCESS,
     POST_READER_CLUB_FAILURE,
     SAVE_PLAN_ID,
    } from "./type";

// GET_READER_CLUB_DATA
export const getReaderClubData = () => ({
    type: GET_READER_CLUB_DATA
})

export const readerClubDataSuccess = (data) => ({
    type: GET_READER_CLUB_SUCCESS,
    data
})

export const readerClubDataFailure = (data) => ({
    type: GET_READER_CLUB_FAILURE,
    data
})
// POST_READER_CLUB_DATA
export const postReaderClubData = (history,data) => ({
    type: POST_READER_CLUB_DATA,
    history,
    data
})

export const postReaderClubDataSuccess = (data) => ({
    type: POST_READER_CLUB_SUCCESS,
    data
})

export const postReaderClubDataFailure = (data) => ({
    type: POST_READER_CLUB_FAILURE,
    data
})

export const savePlanId = (planId) => ({
    type: SAVE_PLAN_ID,
    planId
})