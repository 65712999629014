import { all, call, put, takeLatest } from "redux-saga/effects"
// import AsyncStorage from '@react-native-community/async-storage';
// config
import { BASE_URL } from "../../../../config/app"
// utils
import XHR from "../../../../utils/XHR"
// types
import { TERM_AND_CONDITION_DATA } from "./type"
// actions
import { getTermsConditionsSuccess, getTermsConditionsFailure } from "./action"
import toast from "react-hot-toast"

async function getTermsConditionDataAPI(data) {
  const token = await localStorage.getItem("token")
  const URL = `${BASE_URL}modules/terms-and-conditions/`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "GET"
    // data
  }
  return XHR(URL, options)
}

function* getTermsConditionData({ data }) {
  try {
    const response = yield call(getTermsConditionDataAPI, data)
    yield put(getTermsConditionsSuccess(response?.data))
  } catch (e) {
    const { response } = e
    toast.error("Something Went Wrong", {
      id: 'card fields',
    })
    yield put(getTermsConditionsFailure(response.data))
  }
}

export default all([takeLatest(TERM_AND_CONDITION_DATA, getTermsConditionData)])
