import React, { useEffect, useState } from "react"
import Button from "../../components/Button"
import HomeLayout from "../../layout/HomeLayout"
import greaterIcon from "../../assets/images/greater-blue-icon.svg"
import uploadWhite from "../../assets/images/uploadWhite.svg"
import writerIcon from "../../assets/images/writer-white.svg"
import "./style.scss"
import {
  getWorkRoom,
  patchWorkRoomRequest,
  patchWorkRoomSuccess,
  getUpcomingData,
  patchUpcomingData,
  patchUpcomingDataSuccess
} from "./redux/action"
import { getNovelChapterSuccess } from "../HomeScreens/ReaderHome/redux/action"
import { connect } from "react-redux"
import { Spinner } from "reactstrap"
import moment from "moment"
import { useHistory } from "react-router-dom"
import {
  saveChapterID,
  saveCharacterID
} from "../AuthScreens/Login/redux/action"
import TextArea from "../../components/TextArea"

const WorkRoom = props => {
  const {
    getWorkRoomData,
    workroomID,
    requesting,
    workRoomData,
    loginInfo,
    saveChapterID,
    saveCharacterID,
    patchWorkRoomRequest,
    patchRequesting,
    patchData,
    patchWorkRoomSuccess,
    patchUpcomingDataSuccess,
    getUpcomingData,
    upcomingData,
    patchUpcomingData,
    patchUpcomLoad,
    patchUpcoming,
    upcomingLoad,
    getNovelChapterSuccess
  } = props

  const [checkEdit, setCheckEdit] = useState(false)
  const [upcomingEdit, setUpcomingEdit] = useState(false)
  const [characterPersonality, setCharacterPersonality] = useState("")
  const [characterAbouts, setCharacterAbouts] = useState("")
  const [leftOff, setLeftOff] = useState("")
  const [startPoint, setStartPoint] = useState("")
  const [synopsisStory, setSynopsisStory] = useState("")

  useEffect(() => {
    if (workroomID) {
      getWorkRoomData(workroomID)
      getUpcomingData(workroomID)
      getNovelChapterSuccess(false)
    }
  }, [workroomID])

  useEffect(() => {
    if (patchUpcoming?.id) {
      setLeftOff("")
      setStartPoint("")
      setSynopsisStory("")
      setUpcomingEdit(false)
      getUpcomingData(workroomID)
      patchUpcomingDataSuccess(false)
    }
  }, [patchUpcoming?.id])

  useEffect(() => {
    if (patchData.id) {
      setCheckEdit(false)
      getWorkRoomData(workroomID)
    }
    patchWorkRoomSuccess(false)
  }, [patchData.id])

  useEffect(() => {
    if (checkEdit) {
      setCharacterPersonality(workRoomData?.character?.personality)
      setCharacterAbouts(workRoomData?.character?.about)
    }
  }, [checkEdit])

  useEffect(() => {
    if (upcomingEdit) {
      setLeftOff(upcomingData?.left_of)
      setStartPoint(upcomingData?.starting_point)
      setSynopsisStory(upcomingData?.synopsis)
    }
  }, [upcomingEdit])

  const history = useHistory()

  const readChapter = id => {
    saveChapterID(id)
    history.push({
      pathname: `/${loginInfo.user.mode}/chapter/${id}`
    })
  }

  const addChapter = () => {
    console.log(">>>>>>>>>>");
    if (workRoomData?.character?.id) {
      saveChapterID(false)
      saveCharacterID(workRoomData?.character?.id)
      history.push({ pathname: `/${loginInfo.user.mode}/chapter/new`, permission: workRoomData?.permissions })
    }
  }

  const onChangeTextarea = (type, val) => {
    if (type == "personality") {
      setCharacterPersonality(val.target.value)
    } else if (type == "about") {
      setCharacterAbouts(val.target.value)
    }
  }

  const onPatchRequest = () => {
    if (
      characterPersonality == workRoomData?.character?.personality &&
      characterAbouts == workRoomData?.character?.about
    ) {
      setCheckEdit(false)
    } else {
      const data = {
        personality: characterPersonality,
        about: characterAbouts
      }
      patchWorkRoomRequest(workRoomData?.character?.id, data)
    }
  }

  const onUpcomingSubmit = () => {
    if (
      leftOff == upcomingData?.left_of &&
      startPoint == upcomingData?.starting_point &&
      synopsisStory == upcomingData?.synopsis
    ) {
      setUpcomingEdit(false)
    } else {
      const data = {
        left_of: leftOff,
        starting_point: startPoint,
        synopsis: synopsisStory
      }
      patchUpcomingData(workroomID, data)
    }
  }

  return (
    <HomeLayout headerName={workRoomData?.workroom?.name} showBack={true}>
      {requesting ? (
        <div className="spinner-style">
          <Spinner color="primary" className="table-spinner" size="lg" />
        </div>
      ) : (
        <>
          <section className="reading-section workroom-section">
            <div className="container-fluid reading-section-container workroom-section-container">
              {/* <div className="row"> */}
              {(
                <div
                  className="reading-card Workroom-card"
                  key={workRoomData?.character?.id}
                >
                  <div className="reading-card-header Workroom-card-header">
                    <div className="character-title workroom-character-title">
                      <div className="title-text">
                        {workRoomData?.character?.name}
                      </div>
                      {/* <div className="author">Played & Written by Jon Doe</div> */}
                      {workRoomData?.permissions && <div className="pro-edit-btn">
                        {checkEdit ? (
                          <Button
                            title="Save Changes"
                            showSpinner={patchRequesting}
                            onClick={onPatchRequest}
                          />
                        ) : (
                          <div
                            className="edit-btn"
                            onClick={() => setCheckEdit(true)}
                          >
                            <img src={writerIcon} />
                          </div>
                        )}
                      </div>}

                    </div>
                  </div>
                  <div className="row reading-description-row workroom-decription-row">
                    <div className="col-md-6 reading-description workroom-description">
                      <div className="title">Personality Traits</div>
                      <div className="text-description">
                        {checkEdit ? (
                          <TextArea
                            value={characterPersonality}
                            onChange={val =>
                              onChangeTextarea("personality", val)
                            }
                          />
                        ) : (
                          workRoomData?.character?.personality
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 reading-description workroom-description">
                      <div className="title">About Character</div>
                      <div className="text-description">
                        {checkEdit ? (
                          <TextArea
                            value={characterAbouts}
                            onChange={val => onChangeTextarea("about", val)}
                          />
                        ) : (
                          workRoomData?.character?.about
                        )}
                      </div>
                    </div>
                    {/* <div className="divider"></div> */}
                  </div>
                </div>
              ) || (
                  <div className="reading-card Workroom-card no-data">
                    No Data
                  </div>
                )}

              <div className="reading-card workroom-card ">
                <div className="row chapter-details-row">
                  <div className="card-title">Completed Chapters</div>
                  {workRoomData?.complete_chapter &&
                    workRoomData?.complete_chapter.length > 0 ? (
                    workRoomData?.complete_chapter?.map(item => (
                      <div className="col-lg-3 col-md-6" key={item.id}>
                        <div className="chapter-details">
                          <div className="date">
                            {moment(item.created_at).format("DD MMM YY")}
                          </div>
                          <div className="chapter-description">
                            {/* <div className="chapter-number">{item.name}</div> */}
                            <div
                              className="button read-chapter"
                              onClick={() => readChapter(item.id)}
                            >
                              {item.name}
                              <img src={greaterIcon} alt="" />
                            </div>
                          </div>
                        </div>
                      </div>
                    ))
                  ) : (
                    <div className="no-data">No Data</div>
                  )}
                </div>
              </div>
              {!upcomingData && upcomingLoad ? (
                <div className="spinner-style">
                  <Spinner
                    color="primary"
                    className="table-spinner"
                    size="lg"
                  />
                </div>
              ) : (
                <div className="reading-card Workroom-card">
                  <div className="card-title-wrapper d-flex justify-content-between">
                    <div className="card-title">Upcoming Chapter</div>
                    {workRoomData?.permissions && <div className="pro-edit-btn">
                      {upcomingEdit ? (
                        <Button
                          title="Save Changes"
                          showSpinner={patchUpcomLoad}
                          onClick={onUpcomingSubmit}
                        />
                      ) : (
                        <div
                          className="edit-btn"
                          onClick={() => setUpcomingEdit(true)}
                        >
                          <img src={writerIcon} />
                        </div>
                      )}
                    </div>}

                  </div>
                  <div className="row reading-description-row workroom-decription-row">
                    <div className="col-md-6 reading-description workroom-description">
                      <div className="title">Where we left off:</div>
                      <div className="text-description">
                        {upcomingEdit ? (
                          <TextArea
                            value={leftOff}
                            onChange={val => setLeftOff(val.target.value)}
                          />
                        ) : (
                          (upcomingData?.left_of && upcomingData?.left_of) ||
                          "No Data"
                        )}
                      </div>
                      <div className="title mt-4 ">
                        Synopsis of this week's Story:
                      </div>
                      <div className="text-description">
                        {upcomingEdit ? (
                          <TextArea
                            value={synopsisStory}
                            onChange={val => setSynopsisStory(val.target.value)}
                          />
                        ) : (
                          (upcomingData?.synopsis && upcomingData?.synopsis) ||
                          "No Data"
                        )}
                      </div>
                    </div>
                    <div className="col-md-6 reading-description workroom-description">
                      <div className="title ">Starting point:</div>

                      <div className="text-description">
                        {upcomingEdit ? (
                          <TextArea
                            value={startPoint}
                            onChange={val => setStartPoint(val.target.value)}
                          />
                        ) : (
                          (upcomingData?.starting_point &&
                            upcomingData?.starting_point) ||
                          "No Data"
                        )}
                      </div>
                      <div className="button-wrapper">
                        {workRoomData?.permissions && <><Button
                          title="Add a New Chapter"
                          showIcon={true}
                          src={uploadWhite}
                          onClick={addChapter} />
                          <Button
                            title="Group Chat"
                            className="transparent-btn"
                            onClick={() => history.push(`/${loginInfo.user.mode}/chat`)} /></>}

                      </div>
                    </div>
                  </div>
                </div>
              )}
              {/* </div> */}
            </div>
          </section>
        </>
      )}
    </HomeLayout>
  )
}

const mapStateToProps = state => ({
  workRoomData: state.workRoom.data,
  requesting: state.workRoom.requesting,
  workroomID: state.login.workroomID,
  loginInfo: state.login.loginInfo,
  patchRequesting: state.workRoom.patchRequesting,
  patchData: state.workRoom.patchData,
  upcomingData: state.workRoom.upcomingData,
  patchUpcomLoad: state.workRoom.patchUpcomLoad,
  patchUpcoming: state.workRoom.patchUpcoming,
  upcomingLoad: state.workRoom.upcomingLoad
})

const mapDispatchToProps = dispatch => ({
  getWorkRoomData: data => dispatch(getWorkRoom(data)),
  saveChapterID: data => dispatch(saveChapterID(data)),
  saveCharacterID: data => dispatch(saveCharacterID(data)),
  patchWorkRoomRequest: (id, data) => dispatch(patchWorkRoomRequest(id, data)),
  patchWorkRoomSuccess: data => dispatch(patchWorkRoomSuccess(data)),
  getUpcomingData: data => dispatch(getUpcomingData(data)),
  patchUpcomingData: (id, data) => dispatch(patchUpcomingData(id, data)),
  patchUpcomingDataSuccess: data => dispatch(patchUpcomingDataSuccess(data)),
  getNovelChapterSuccess: data => dispatch(getNovelChapterSuccess(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(WorkRoom)
