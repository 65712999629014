import React from "react";
import { Spinner } from "react-bootstrap";

import './style.scss';

const Button = ({
  title = "",
  className='',
  loading = "",
  showIcon,
  showSpinner,
  src,
  onClick,
  type,
  disabled,
  ref,
  hidden,
  value,
  ...props
}) => {
  const iconSrc = showIcon ? showIcon : false
  const spinner = showSpinner ? showSpinner : false
  return (
    <button
      className={`board-btn ${className}`}
      onClick={onClick}
      disabled={disabled || loading}
      type={type}
      ref={ref}
      hidden={hidden}
      value={value}
    >
      {iconSrc && (
        <span className="icon">
          <img src={src} alt="icon" />
        </span>
      )}
      {title}
      {spinner && (
        <Spinner as="span" animation="border" size="sm" className="ms-2" />
      )}
    </button>
  )
}
export default Button
