import {
  POST_AWARD_GRT,
  POST_AWARD_GRT_SUCCESS,
  POST_AWARD_GRT_FAILURE,
} from "./type"

// AWARD GRT
export const postAwardGRT = (data) => ({
  type: POST_AWARD_GRT,
  data
})

export const postAwardGRTSuccess = data => ({
  type: POST_AWARD_GRT_SUCCESS,
  data
})

export const postAwardGRTFailure = data => ({
  type: POST_AWARD_GRT_FAILURE,
  data
})
