import { all, call, put, takeLatest } from "redux-saga/effects"
import { BASE_URL } from "../../../../config/app"
import XHR from "../../../../utils/XHR"
import {
  getTokenStoreFailure,
  getTokenStoreSuccess,
  cancelTokenStoreSuccess,
  cancelTokenStoreFailure,
  upgradeTokenStoreSuccess,
  upgradeTokenStoreFailure,
} from "./action"
import {
  GET_TOKEN_STORE,
  CANCEL_TOKEN_STORE,
  UPGRADE_TOKEN_STORE
} from "./type"
import toast from "react-hot-toast"


const getTokenDataAPI = async () => {
  const URL = `${BASE_URL}api/v1/active-subscription/`
  const token = await localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

const cancelTokenDataAPI = async data => {
  const URL = `${BASE_URL}api/v1/subcription/cancel/`
  const token = await localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "DELETE",
    data
  }
  return XHR(URL, options)
}

const upgradeTokenDataAPI = async (data) => {
  const URL = `${BASE_URL}api/v1/subcription/`
  const token = await localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "POST",
    data
  }
  return XHR(URL, options)
}

function* upgradeTokenData({ data }) {
  try {
    const response = yield call(upgradeTokenDataAPI, data)
    yield put(upgradeTokenStoreSuccess(response.data))
    toast.success('Subscription Successful', {
      id: 'card fields',
    })
  } catch (e) {
    const { response } = e
    yield put(upgradeTokenStoreFailure(response.data))
    toast.error('Something went wrong', {
      id: 'card fields',
    })
  }
}

function* cancelTokenData({ data }) {
  try {
    const response = yield call(cancelTokenDataAPI, data)
    yield put(cancelTokenStoreSuccess(response.data))
    toast.success('Unsubscription Successful', {
      id: 'card fields',
    })
  } catch (e) {
    const { response } = e
    yield put(cancelTokenStoreFailure(response.data))
    toast.error('Something went wrong', {
      id: 'card fields',
    })
  }
}

function* getTokenData() {
  try {
    const response = yield call(getTokenDataAPI)
    yield put(getTokenStoreSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(getTokenStoreFailure(response.data))
  }
}
export default all([
  takeLatest(GET_TOKEN_STORE, getTokenData),
  takeLatest(CANCEL_TOKEN_STORE, cancelTokenData),
  takeLatest(UPGRADE_TOKEN_STORE, upgradeTokenData),
])
