import { all, call, put, takeLatest } from "redux-saga/effects"
import { BASE_URL } from "../../../config/app"
import XHR from "../../../utils/XHR"
import { POST_FORGOT_PASSWORD } from "./type";
import {
  postForgotRequestSuccess,
  postForgotRequestFailure,
} from "./action"
import toast from 'react-hot-toast';


async function postForgotAPI(data) {
  const URL = `${BASE_URL}api/v1/password/reset/`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    method: "POST",
    data
  }
  return XHR(URL, options)
}

function* postForgotInfo({data, history}) {
  try {
    const response = yield call(postForgotAPI, data)
    yield put(postForgotRequestSuccess(response.data))
    history.push("/auth/confirm_password")
  } catch (e) {
    const { response } = e
    yield put(postForgotRequestFailure(response.data))
  }
}

export default all([
    takeLatest(POST_FORGOT_PASSWORD, postForgotInfo),
])