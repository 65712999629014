// GET GRT
export const GET_TOKEN_STORE = 'GET_TOKEN_STORE';
export const GET_TOKEN_STORE_SUCCESS = 'GET_TOKEN_STORE_SUCCESS';
export const GET_TOKEN_STORE_FAILURE = 'GET_TOKEN_STORE_FAILURE';
// CANCEL
export const CANCEL_TOKEN_STORE = 'CANCEL_TOKEN_STORE';
export const CANCEL_TOKEN_STORE_SUCCESS = 'CANCEL_TOKEN_STORE_SUCCESS';
export const CANCEL_TOKEN_STORE_FAILURE = 'CANCEL_TOKEN_STORE_FAILURE';
// UPGRADE
export const UPGRADE_TOKEN_STORE = 'UPGRADE_TOKEN_STORE';
export const UPGRADE_TOKEN_STORE_SUCCESS = 'UPGRADE_TOKEN_STORE_SUCCESS';
export const UPGRADE_TOKEN_STORE_FAILURE = 'UPGRADE_TOKEN_STORE_FAILURE';
