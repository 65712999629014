import React, { useState, useEffect } from "react"
import { connect } from "react-redux"
import EditSubscribePlanCard from "../../../components/AdminComponents/EditSubscribePlanCard"
import GRTTable from "../../../components/AdminComponents/GRTTable"
import SubscribedUsersTable from "../../../components/AdminComponents/SubscribedUsersTable"
import AdminLayout from "../../../layout/AdminLayout"
import { getSubscribedUsers } from "../../../pages/AdminScreens/AdminFinances/redux/action"
import "./style.scss"
import {
  getFinanceData,
  setSubscribedConcat,
  getGRTRequest,
  grtDataConcat
} from "../../../pages/AdminScreens/AdminFinances/redux/action"
import { Spinner } from "reactstrap"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"

const AdminFinances = props => {
  const {
    getSubscribedUsers,
    getFinanceData,
    financeBoxData,
    financeBoxRequesting,
    setSubscribedConcat,
    getGRTRequest,
    grtDataConcat
  } = props
  const { tab } = props.location
  const [active, setActive] = useState(tab ? tab : "Subscription")
  const toggleItems = ["Subscription", "Users", "Good Read Tokens"]
  const [filterValue, setFilterValue] = useState("")

  useEffect(() => {
    getFinanceData()
  }, [])

  const OnSearch = value => {
    if (active == "Users") {
      getSubscribedUsers(1, value)
      setSubscribedConcat(false)
    } else if (active == "Good Read Tokens") {
      getGRTRequest(value, 1)
      grtDataConcat(false)
    } else {
      setFilterValue(value)
    }
  }
  console.log('financeBoxData', financeBoxData)

  return (
    <AdminLayout
      onChange={OnSearch}
      headerName={"Finances"}
      searchBarWrapper={true}
      tab={active}
    >
      {financeBoxRequesting ? (
        <SkeletonTheme baseColor="#6f8bdf40" highlightColor="#6F8BDF">
          <section className="content-section finances-section">
            <div className="home-card-wrapper finances-wrapper">
              <div className="container-fluid finances-container">
                <SkeletonTheme baseColor="#fff" highlightColor="#6F8BDF">
                  <div className="row finances-card-row">
                    <div className="finances-details-card-wrapper">
                      <div className="finances-details-card">
                        <h3>Number of subscribers on each level</h3>
                        <div className="detail-items">
                          <div className="item-wrapper">
                            <div className="item">
                              <h4>Casual</h4>
                              <div className="number">
                                <Skeleton height={10} width={33.848} />
                              </div>
                            </div>
                            <div className="item">
                              <h4>Excited</h4>
                              <div className="number">
                                {" "}
                                <Skeleton height={10} width={33.848} />
                              </div>
                            </div>
                          </div>
                          <div className="item-wrapper">
                            <div className="item">
                              <h4>Active</h4>
                              <div className="number">
                                {" "}
                                <Skeleton height={10} width={33.848} />
                              </div>
                            </div>
                            <div className="item">
                              <h4>Binge</h4>
                              <div className="number">
                                {" "}
                                <Skeleton height={10} width={33.848} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="finances-details-card-wrapper">
                      <div className="finances-details-card">
                        <h3>This Month</h3>
                        <div className="detail-items">
                          <div className="item-wrapper">
                            <div className="item">
                              <h4>Sold Tokens</h4>
                              <div className="number">
                                {" "}
                                <Skeleton height={10} width={100.848} />
                              </div>
                            </div>
                            <div className="item">
                              <h4>Awarded Tokens</h4>
                              <div className="number">
                                {" "}
                                <Skeleton height={10} width={33.848} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="finances-details-card-wrapper">
                      <div className="finances-details-card">
                        <h3>Unawarded Tokens</h3>
                        <div className="detail-items">
                          <div className="item-wrapper">
                            <div className="item">
                              <h4>Number of Tokens</h4>
                              <div className="number">
                                {" "}
                                <Skeleton height={10} width={80.848} />
                              </div>
                            </div>
                            <div className="item">
                              <h4>Value</h4>
                              <div className="number">
                                {" "}
                                <Skeleton height={10} width={100.848} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="finances-details-card-wrapper">
                      <div className="finances-details-card">
                        <h3>Total Awarded Tokens</h3>
                        <div className="detail-items">
                          <div className="item-wrapper">
                            <div className="item">
                              <h4>Number of Tokens</h4>
                              <div className="number">
                                {" "}
                                <Skeleton height={10} width={80.848} />
                              </div>
                            </div>
                            <div className="item">
                              <h4>Value</h4>
                              <div className="number">
                                {" "}
                                <Skeleton height={10} width={100.848} />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </SkeletonTheme>

                <div className="toggle-select">
                  {toggleItems.map(item => (
                    <div
                      key={item.id}
                      className={`title ${active == item ? "active" : ""}`}
                      onClick={() => setActive(item)}
                    >
                      {item}
                    </div>
                  ))}
                </div>
                {active == "Subscription" && (
                  <div className="edit-subscribe-wrapper">
                    <EditSubscribePlanCard
                      filterValue={filterValue}
                      isLoading={financeBoxRequesting}
                    />
                  </div>
                )}
                {active == "Users" && (
                  <div className="subscribed-user-table-wrapper">
                    <h3 className="heading">Subscribed Users</h3>
                    <SubscribedUsersTable isLoading={financeBoxRequesting} />
                  </div>
                )}
                {active == "Good Read Tokens" && (
                  <div className="grt-table-wrapper">
                    <h3 className="heading">Good Read Tokens Awarded</h3>
                    <GRTTable isLoading={financeBoxRequesting} />
                  </div>
                )}
              </div>
            </div>
          </section>
        </SkeletonTheme>
      ) : (
        <section className="content-section finances-section">
          <div className="home-card-wrapper finances-wrapper">
            <div className="container-fluid finances-container">
              <div className="row finances-card-row">
                <div className="finances-details-card-wrapper">
                  <div className="finances-details-card">
                    <h3>Number of subscribers on each level</h3>
                    <div className="detail-items">
                      <div className="item-wrapper">
                        <div className="item">
                          <h4>Casual</h4>
                          <div
                            className="number"
                            title={"Number of subscriber for plan 'Casual'."}
                          >
                            {
                              financeBoxData[0]?.no_of_subscribers_on_each_level
                                ?.casual
                            }
                          </div>
                        </div>
                        <div className="item">
                          <h4>Excited</h4>
                          <div
                            className="number"
                            title={"Number of subscriber for plan 'Excited'."}
                          >
                            {
                              financeBoxData[0]?.no_of_subscribers_on_each_level
                                ?.excited
                            }
                          </div>
                        </div>
                      </div>
                      <div className="item-wrapper">
                        <div className="item">
                          <h4>Active</h4>
                          <div
                            className="number"
                            title={"Number of subscriber 'Active'."}
                          >
                            {
                              financeBoxData[0]?.no_of_subscribers_on_each_level
                                ?.active
                            }
                          </div>
                        </div>
                        <div
                          className="item"
                          title={"Number of subscriber for plan 'Binge'."}
                        >
                          <h4>Binge</h4>
                          <div className="number">
                            {
                              financeBoxData[0]?.no_of_subscribers_on_each_level
                                ?.binge
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="finances-details-card-wrapper">
                  <div className="finances-details-card">
                    <h3>This Month</h3>
                    <div className="detail-items">
                      <div className="item-wrapper">
                        <div className="item">
                          <h4>Sold Tokens</h4>
                          <div
                            className="number"
                            title={"Total number of awarded token of this month."}
                          >
                            
                            {financeBoxData[0]?.total_month_subscribers_list_plan?.sold_token.toFixed(
                              2
                            )}
                          </div>
                        </div>
                        <div className="item">
                          <h4>Value</h4>
                          <div
                            className="number"
                            title={"This is the amount of awareded token of this month."}
                          >
                            ${
                              financeBoxData[0]
                                ?.total_month_subscribers_list_plan
                                ?.awarded_token
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="finances-details-card-wrapper">
                  <div className="finances-details-card">
                    <h3>Unawarded Tokens</h3>
                    <div className="detail-items">
                      <div className="item-wrapper">
                        <div className="item">
                          <h4>Number of Tokens</h4>
                          <div
                            className="number"
                            title={"Total number of unawarded tokens for all users."}
                          >
                            {financeBoxData[0]?.unawarded_token?.token.toFixed(
                              2
                            )}
                          </div>
                        </div>
                        <div className="item">
                          <h4>Value</h4>
                          <div
                            className="number"
                            title={"This is the amount of unawarded tokens for all users.."}
                          >
                            $
                            {financeBoxData[0]?.unawarded_token?.value.toFixed(
                              2
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="finances-details-card-wrapper">
                  <div className="finances-details-card">
                    <h3>Total Awarded Tokens</h3>
                    <div className="detail-items">
                      <div className="item-wrapper">
                        <div className="item">
                          <h4>Number of Tokens</h4>
                          <div
                            className="number"
                            title={
                              "Total number of awarded tokens."
                            }
                          >
                            {
                              financeBoxData[0]?.total_awarded_token
                                ?.no_of_token
                            }
                          </div>
                        </div>
                        <div className="item">
                          <h4>Value</h4>
                          <div
                            className="number"
                            title={"This is the amount for total awareded tokens."}
                          >
                            $
                            {financeBoxData[0]?.total_awarded_token?.value.toFixed(
                              2
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="toggle-select">
                {toggleItems.map(item => (
                  <div
                    key={item.id}
                    className={`title ${active == item ? "active" : ""}`}
                    onClick={() => setActive(item)}
                  >
                    {item}
                  </div>
                ))}
              </div>
              {active == "Subscription" && (
                <div className="edit-subscribe-wrapper">
                  <EditSubscribePlanCard filterValue={filterValue} />
                </div>
              )}
              {active == "Users" && (
                <div className="subscribed-user-table-wrapper">
                  <h3 className="heading">Subscribed Users</h3>
                  <SubscribedUsersTable />
                </div>
              )}
              {active == "Good Read Tokens" && (
                <div className="grt-table-wrapper">
                  <h3 className="heading">Good Read Tokens Awarded</h3>
                  <GRTTable />
                </div>
              )}
            </div>
          </div>
        </section>
      )
      }
    </AdminLayout >
  )
}

const mapStateToProps = state => ({
  financeBoxData: state.adminFinances.financeBoxData,
  financeBoxRequesting: state.adminFinances.financeBoxRequesting
})

const mapDispatchToProps = dispatch => ({
  getSubscribedUsers: (data, name) => dispatch(getSubscribedUsers(data, name)),
  getFinanceData: data => dispatch(getFinanceData(data)),
  setSubscribedConcat: data => dispatch(setSubscribedConcat(data)),
  getGRTRequest: (data, page) => dispatch(getGRTRequest(data, page)),
  grtDataConcat: data => dispatch(grtDataConcat(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminFinances)
