import React, { useEffect, useState } from "react"
import AdminLayout from "../../../layout/AdminLayout"
import avatar from "../../../assets/images/avatar.svg"
import Button from "../../../components/Button"
import "./style.scss"
import TextArea from "../../../components/TextArea"
import { connect } from "react-redux"
import { getFeedbackById, putAdminFeedback } from "../AdminFeedback/redux/action"
import { Spinner } from "reactstrap"
import { Toaster } from "react-hot-toast"

const AdminFeebackDetails = props => {
  const { getFeedbackById, putAdminFeedback, feedbackData, requesting, usersID, putRequesting } = props

  const [showReplyFeedback, setShowReplyFeedback] = useState(false);
  const [replyFeedback, setReplyFeedback] = useState('');
  const [disabledBtn, setDisabledBtn] = useState(false);

  useEffect(() => {
    usersID && getFeedbackById(usersID)
  }, [usersID])

  const onSubmit = () => {
    if (replyFeedback.trim() != "") {
      const data = {
        reply: replyFeedback
      }
      putAdminFeedback(usersID, data, setShowReplyFeedback, setReplyFeedback)
    }
  }

  return (
    <AdminLayout
      headerName={"Feedback Details"}
      showBack={true}
      searchBarWrapper={true}
      className="feedback-header"
    >
      {requesting ? (
        <div className="spinner-style">
          <Spinner color="primary" />
        </div>
      ) : (
        <div className="content-section admin-feedback-section">
          <div>
            <Toaster position="top-center" />
          </div>
          <div className="admin-feedback-wrapper">
            <div className="container-fluid admin-feedback-container">
              <div className="row admin-feedback-row">
                <div className="feedback-message-wrapper">
                  <div className="feedback-avatar-details">
                    <div className="avatar">
                      <img src={avatar} alt="" />
                      <div className="details">
                        <div className="id">ID{feedbackData?.id}</div>
                        <div className="name">{feedbackData?.user}</div>
                        <div className="email">{feedbackData?.email}</div>
                      </div>
                    </div>
                  </div>
                  <div className="feedback-details">
                    <h3>Feedback</h3>
                    <p>{feedbackData?.message}</p>
                  </div>
                  <div className="button-wrapper">
                    <Button
                      title="Reply"
                      onClick={() => setShowReplyFeedback(!showReplyFeedback)}
                      className={`${showReplyFeedback ? 'active' : ''}`}
                    />
                  </div>
                </div>
                {/* {showReplyFeedback && ( */}
                <div className={`reply-feedback-wrapper ${showReplyFeedback ? 'opacity-1' : ''}`}>
                  <TextArea placeholder="Write Reply" value={replyFeedback} onChange={(val) => setReplyFeedback(val.target.value)} />
                  <div className="button-wrapper">
                    <Button
                      title="Send"
                      showSpinner={putRequesting}
                      onClick={onSubmit}
                      className={`board-btn ${replyFeedback.trim() == "" ? 'disabled-btn' : ''}`}
                    />
                  </div>
                </div>
                {/* )} */}
              </div>
            </div>
          </div>
        </div>
      )}
    </AdminLayout>
  )
}

const mapStateToProps = state => ({
  feedbackData: state.adminFeeback.feedbackData,
  requesting: state.adminFeeback.feedbackRequesting,
  error: state.adminFeeback.feedbackError,
  putRequesting: state.adminFeeback.putRequesting,
  usersID: state.login.usersID
})

const mapDispatchToProps = dispatch => ({
  getFeedbackById: id => dispatch(getFeedbackById(id)),
  putAdminFeedback: (id, data, setShowReplyFeedback, setReplyFeedback) => dispatch(putAdminFeedback(id, data, setShowReplyFeedback, setReplyFeedback))
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminFeebackDetails)
