import React, { useEffect, useState } from "react"
import dummyPic from "../../../assets/images/dummyPic.png"
import ImageCard from "../../../components/ImageCard"
import HomeLayout from "../../../layout/HomeLayout"
import BookDetailCard from "../../../components/BookDetailCard"
import HomeModal from "../../../components/HomeModal"
import ProfileCard from "../../../components/ProfileCard"
import { connect } from "react-redux"
import {
  uploadedDataConcat,
  getReaderUploaded,
  getReaderFavorites,
  getReaderStories,
  getReaderWriters,
  getReaderCharacters,
  getReaderChapters
} from "./redux/action"
import { Spinner } from "reactstrap"
import ChatMessageModal from "../../../components/ChatModals"
import seeIcon from "../../../assets/images/greater-white.svg"

const ReaderHome = props => {
  const [chatModalShow, setChatModalShow] = useState(false)
  const [modalShow, setModalShow] = useState(false)
  const [readerHomeData, setReaderHomeData] = useState(false)
  const [activeCardFlex, setActiveCardFlex] = useState(false)
  const [checkPage, setCheckPage] = useState(true)
  const [concatUser, setConcatUser] = useState([])

  const {
    setUploadedDataConcat,
    uploadDataConcat,
    getReaderUploaded,
    getReaderFavorites,
    getReaderStories,
    getReaderWriters,
    getReaderCharacters,
    getReaderChapters,
    readerUploaded,
    readerUploadedLoad,
    readerFavorites,
    readerFavoritesLoad,
    readerStories,
    readerStoriesLoad,
    readerWriters,
    readerWritersLoad,
    readerCharacters,
    readerCharactersLoad,
    readerChapters,
    readerChaptersLoad
  } = props

  useEffect(() => {
    getReaderUploaded()
    getReaderFavorites()
    getReaderStories()
    getReaderWriters()
    getReaderCharacters()
    getReaderChapters()
  }, [])

  useEffect(() => {
    const uploadedData = readerUploaded?.results
    if (uploadedData && readerUploaded?.current_page == 1) {
      setConcatUser(uploadedData)
    } else if (uploadDataConcat) {
      setConcatUser([...concatUser, ...uploadedData])
    } else {
      setConcatUser(uploadedData)
    }
    setCheckPage(true)
  }, [readerUploaded?.results])

  useEffect(() => {
    const data = readerUploaded
    if (data?.current_page == data?.total_page) {
      setCheckPage(false)
    }
  }, [readerUploaded])

  const handelImageClick = item => {
    setModalShow(true)
    setReaderHomeData(item)
  }

  const filterBySearch = value => {
    getReaderUploaded(value)
  }

  const onPagination = () => {
    const pagination = readerUploaded?.current_page
    const currentPage = pagination + 1
    if (pagination <= readerUploaded?.total_page) {
      setUploadedDataConcat(true)
      getReaderUploaded("", currentPage)
    }
  }

  return (
    <HomeLayout
      headerName={"Home"}
      searchBarWrapper={true}
      cardFlex={activeCardFlex}
      setCardFlex={setActiveCardFlex}
      onChange={filterBySearch}
    >
      <section className="content-section">
        <div className="home-card-wrapper upload-wrapper">
          <div className="container-fluid home-card-container">
            <div className="section-description">
              <p>
                Welcome to an interactive reading experience like you've never seen before! Are you looking for a fresh perspective on your favorite novels? Story Host offers an exciting and engaging way to immerse yourself in your most beloved fictional worlds.<br /><br />

                To get started, simply choose a novel and a character to read from their point of view. Once you've made your selection, be sure to read the instructions carefully to ensure you get the most out of your reading experience.<br /><br />

                Story Host offers a unique and immersive way to experience your favorite stories. By reading from a character's point of view, you'll be able to see the story unfold through their eyes, experiencing their thoughts, feelings, and motivations in real time. You'll gain a deeper understanding of the character and their place in the story, and you'll be able to engage with the plot in a more meaningful way.<br /><br />

                So what are you waiting for? Choose your novel, select your character, and start reading! Story Host is user-friendly and easy to navigate, and we're confident that you'll find the interactive reading experience both enjoyable and enlightening. Don't forget to read the instructions carefully, as they will help you get the most out of your experience. Get ready to see your favorite stories in a whole new light!<br /><br />

              </p>
            </div>
            <h2 className="title">Uploaded</h2>
            <div className="row home-card-row">
              {readerUploadedLoad ? (
                [1, 2, 3, 4, 5, 6, 7]?.map(item => (
                  <div
                    className={`card-data ${activeCardFlex ? "card-toggle-flex" : ""
                      }`}
                  >
                    <ImageCard isLoader={true} />
                  </div>
                ))
              ) : concatUser && concatUser?.length > 0 ? (
                concatUser?.map(item => (
                  <div
                    className={`card-data ${false ? "card-toggle-flex" : ""}`}
                    key={item?.id}
                  >
                    <ImageCard
                      onClick={() => handelImageClick(item)}
                      src={item?.thumbnail ? item.thumbnail : dummyPic}
                      hoverName={item?.name}
                    />
                  </div>
                ))
              ) : (
                <p className="no-data text-center">No data</p>
              )}
              {concatUser && checkPage && (
                <div className="see-more" onClick={onPagination}>
                  {readerUploadedLoad ? (
                    <Spinner size={"sm"} />
                  ) : (
                    <img src={seeIcon} alt="" />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="home-card-wrapper favorite-wrapper">
          <div className="container-fluid home-card-container">
            <h2 className="title">My Favorites</h2>
            <div className="row home-card-row">
              {readerFavoritesLoad ? (
                [1, 2, 3, 4, 5, 6, 7]?.map(item => (
                  <div
                    className={`card-data ${activeCardFlex ? "card-toggle-flex" : ""
                      }`}
                  >
                    <ImageCard isLoader={true} />
                  </div>
                ))
              ) : readerFavorites && readerFavorites?.my_fav?.novels.length > 0 ? (
                readerFavorites?.my_fav?.novels?.map(item => (
                  <div
                    className={`card-data ${activeCardFlex ? "card-toggle-flex" : ""
                      }`}
                    key={item?.id}
                  >
                    <ImageCard
                      onClick={() => handelImageClick(item)}
                      src={item?.thumbnail ? item.thumbnail : dummyPic}
                      hoverName={item?.name}
                    />
                  </div>
                ))
              ) : (
                <p className="no-data text-center">No data</p>
              )}
            </div>
          </div>
        </div>
        <div className="home-card-wrapper stories-wrapper">
          <div className="container-fluid home-card-container">
            <h2 className="title">Top 10 Stories</h2>
            <div className="row home-card-row">
              {readerStoriesLoad ? (
                [1, 2, 3, 4, 5, 6, 7]?.map(item => (
                  <div
                    className={`card-data ${activeCardFlex ? "card-toggle-flex" : ""
                      }`}
                  >
                    <ImageCard isLoader={true} />
                  </div>
                ))
              ) : readerStories && readerStories?.length ? (
                readerStories?.map(item => (
                  <div
                    className={`card-data ${activeCardFlex ? "card-toggle-flex" : ""
                      }`}
                    key={item?.id}
                  >
                    <ImageCard
                      onClick={() => handelImageClick(item)}
                      src={item?.thumbnail || dummyPic}
                      hoverName={item?.name}
                    />
                  </div>
                ))
              ) : (
                <p className="no-data text-center">No data</p>
              )}
            </div>
          </div>
        </div>
        <div className="home-card-wrapper writer-wrapper">
          <div className="container-fluid home-card-container">
            <h2 className="title">Top 10 Writers</h2>
            <div className="row home-card-row">
              {readerWritersLoad ? (
                [1, 2, 3, 4, 5, 6, 7].map(item => (
                  <ProfileCard isLoader={true} />
                ))
              ) : readerWriters && readerWriters?.length ? (
                readerWriters?.map(item => (
                  <ProfileCard
                    src={
                      item?.profile_picture ? item?.profile_picture : dummyPic
                    }
                    author={item?.name}
                    key={item?.id}
                    data={item?.id}
                  />
                ))
              ) : (
                <p className="no-data text-center">No data</p>
              )}
            </div>
          </div>
        </div>
        <div className="home-card-wrapper characters-wrapper">
          <div className="container-fluid home-card-container">
            {/* <h2 className="title" onClick={() => setChatModalShow(true)}>
              Top 10 Characters
            </h2> */}
            <h2 className="title">
              Top 10 Characters
            </h2>
            <div className="row home-card-row">
              {readerCharactersLoad ? (
                [1, 2, 3, 4].map(item => <BookDetailCard isLoader={true} />)
              ) : readerCharacters && readerCharacters?.length ? (
                readerCharacters?.map(item => (
                  <BookDetailCard
                    key={item?.id}
                    bookName={item?.novel_name ? item?.novel_name : '---'}
                    characterName={item?.character_name ? item?.character_name : '---'}
                    // writer={
                    //   (item?.novel?.character &&
                    //     item?.novel?.character[0]?.writer?.name) ||
                    //   ""
                    // }
                    author={item?.name}
                    writer={item?.writer_name ? item?.writer_name : '---'}
                    headTitle={item?.novel_name}
                    paraTitle={item?.novel_name}
                    data={item?.writer_id}
                    className={activeCardFlex ? "detail-card-toggle-flex" : ""}
                    chapter='Played &'
                  />
                ))
              ) : (
                <p className="no-data text-center">No data</p>
              )}
            </div>
          </div>
        </div>
        <div className="home-card-wrapper chapter-wrapper">
          <div className="container-fluid home-card-container">
            <h2 className="title">Top 10 Intro Chapters</h2>
            <div className="row home-card-row">
              {readerChaptersLoad ? (
                [1, 2, 3, 4].map(item => <BookDetailCard isLoader={true} />)
              ) : readerChapters && readerChapters?.length ? (
                readerChapters?.map(item => (
                  <BookDetailCard
                  data={item?.writer_id}
                    bookName={item?.novel_name? item.novel_name : '---'}
                    characterName={item?.chapter_name? item.chapter_name : '---'}
                    // writer={
                    //   (item?.character && item?.character[0]?.writer?.name) ||
                    //   ""
                    // }
                    writer={
                      item?.writer_name? item.writer_name : '---'
                    }
                    headTitle={item?.novel_name}
                    paraTitle={item?.novel_name}
                    className={activeCardFlex ? "detail-card-toggle-flex" : ""}
                    chapter=''
                  />
                ))
              ) : (
                <p className="no-data text-center">No data</p>
              )}
            </div>
          </div>
        </div>
      </section>
      <ChatMessageModal
        chatModalShow={chatModalShow}
        setChatModalShow={setChatModalShow}
      />
      <HomeModal
        modalShow={modalShow}
        setModalShow={setModalShow}
        readerHomeData={readerHomeData}
      />
    </HomeLayout>
  )
}

const mapStateToProps = state => ({
  uploadDataConcat: state.home.uploadDataConcat,
  readerUploaded: state.home.readerUploaded,
  readerUploadedLoad: state.home.readerUploadedLoad,
  readerFavorites: state.home.readerFavorites,
  readerFavoritesLoad: state.home.readerFavoritesLoad,
  readerStories: state.home.readerStories,
  readerStoriesLoad: state.home.readerStoriesLoad,
  readerWriters: state.home.readerWriters,
  readerWritersLoad: state.home.readerWritersLoad,
  readerCharacters: state.home.readerCharacters,
  readerCharactersLoad: state.home.readerCharactersLoad,
  readerChapters: state.home.readerChapters,
  readerChaptersLoad: state.home.readerChaptersLoad
})

const mapDispatchToProps = dispatch => ({
  setUploadedDataConcat: data => dispatch(uploadedDataConcat(data)),
  getReaderUploaded: (data, page) => dispatch(getReaderUploaded(data, page)),
  getReaderFavorites: data => dispatch(getReaderFavorites(data)),
  getReaderStories: data => dispatch(getReaderStories(data)),
  getReaderWriters: data => dispatch(getReaderWriters(data)),
  getReaderCharacters: data => dispatch(getReaderCharacters(data)),
  getReaderChapters: data => dispatch(getReaderChapters(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(ReaderHome)
