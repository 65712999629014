// PUT_REQUEST_PASSWORD
export const PUT_REQUEST_PASSWORD = 'PUT_REQUEST_PASSWORD';
export const PUT_REQUEST_PASSWORD_SUCCESS = 'PUT_REQUEST_PASSWORD_SUCCESS';
export const PUT_REQUEST_PASSWORD_FAILURE = 'PUT_REQUEST_PASSWORD_FAILURE';
// GET_REQUEST_PROFILE_DATA
export const GET_REQUEST_PROFILE_DATA = 'GET_REQUEST_PROFILE_DATA';
export const GET_REQUEST_PROFILE_DATA_SUCCESS = 'GET_REQUEST_PROFILE_DATA_SUCCESS';
export const GET_REQUEST_PROFILE_DATA_FAILURE = 'GET_REQUEST_PROFILE_DATA_FAILURE';
// PUT_REQUEST_ABOUT_ME
export const PUT_REQUEST_ABOUT_ME = 'PUT_REQUEST_ABOUT_ME';
export const PUT_REQUEST_ABOUT_ME_SUCCESS = 'PUT_REQUEST_ABOUT_ME_SUCCESS';
export const PUT_REQUEST_ABOUT_ME_FAILURE = 'PUT_REQUEST_ABOUT_ME_FAILURE';

