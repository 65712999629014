import React from 'react';
import PhoneInput from 'react-phone-input-2';
import profileIcon from '../../assets/images/profile.svg';

import './style.scss';

const BoardPhoneInput = ({
    onChange,
    value,
    placeholder,
    errorMessage = "",
    showError,
    BackendErrorMessage,
    onFocus,
    width = '49%'
}) => {

    const showErrorMessage = showError ? showError : false

    return (
        <div className="phone-input-wrapper" style={{width:width}}>
            <PhoneInput
                country={'us'}
                value={value}
                placeholder={placeholder}
                specialLabel={false}
                onChange={onChange}
                masks={{ us: '... ...-....' }}
                enableAreaCodes={true}
                onFocus={onFocus}
                isValid={(value, country) => {
                    if (value.match(value)) {
                        return 'Invalid value: ' + value + ', ' + country.name;
                    } else if (value.match(value)) {
                        return false
                    } else {
                        return true;
                    }
                }}
            />
            <label
                className={`error-message ${showErrorMessage ? "opacity-1" : ""}`}
            >
                {BackendErrorMessage ? BackendErrorMessage : errorMessage}
            </label>
        </div>
    )
}

export default BoardPhoneInput