import React from 'react';
import './style.scss';
import { history } from '../../reduxStore/store';
import { saveMemberID } from '../../pages/AuthScreens/Login/redux/action'
import { connect } from 'react-redux';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'

const ProfileCard = ({
    saveMemberID,
    src = '',
    author = '',
    key,
    data,
    loginInfo,
    isLoader,
}) => {
    const onSaveData = () => {
        if(data){
            saveMemberID(data)
            history.push({pathname:`/${loginInfo.user.mode}/member_profile/${author.toLocaleLowerCase().replace(/\s+/g, '_')}`})
        }
    }
    return (
        <div className="profile-card-wrapper">
            <div className="profile-card" key={key} onClick={onSaveData}>
                {isLoader ? <Skeleton circle width={150} height={150} /> : <img src={src} alt="image" />}
            </div>
            <div className="text text-center">{isLoader ? <Skeleton width={100} /> : author}</div>
        </div>
    )
}

const mapStateToProps = (state) => ({
    loginInfo: state.login.loginInfo
})

const mapDispatchToProps = dispatch => ({
    saveMemberID: data => dispatch(saveMemberID(data)) 
})

export default connect(mapStateToProps, mapDispatchToProps)(ProfileCard)