import React from "react"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
import "./style.scss"
import { Tag } from 'antd';

const WorkRoomCard = ({
  keyValue,
  src,
  novelName,
  nextText,
  iconSrc,
  onClick,
  className,
  isLoader,
  userPermission
}) => {
  return (
    <div className="workroom-card-wrapper" key={keyValue}>
      <div className="workroom-card">
        <div className="workroom-card-left">
          <div className={`workroom-card-img ${className}`}>
            {isLoader ? (
              <Skeleton
                highlightColor="#ddd"
                width={217}
                height={247}
                borderRadius={20}
              />
            ) : (
              <img src={src} alt="" />
            )}
          </div>
        </div>
        <div className="workroom-card-right">
          <div className="text-details">
            <div className="top">
              {isLoader ? (
                <Skeleton
                  highlightColor="#ddd"
                  width={100}
                  height={36}
                  borderRadius={10}
                />
              ) : (
                novelName
              )}
            </div>
            {userPermission && <Tag color="#6F8BDF">{userPermission}</Tag>}
            <div className="bottom">
              <div className="text" onClick={onClick}>
                {isLoader ? (
                  <Skeleton
                    highlightColor="#ddd"
                    width={150}
                    height={25}
                    borderRadius={10}
                  />
                ) : (
                  nextText
                )}
              </div>
              <div className="image-wrapper">
                <img src={iconSrc} alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}

export default WorkRoomCard
