import { CREATE_NEW_CHAPTER,
    CREATE_NEW_CHAPTER_SUCCESS,
    CREATE_NEW_CHAPTER_FAILURE,
    CREATE_NEW_SUBMISSION,
    CREATE_NEW_SUBMISSION_SUCCESS,
    CREATE_NEW_SUBMISSION_FAILURE,
    POST_CREATE_NEW_SUBMISSION,
    POST_CREATE_NEW_SUBMISSION_SUCCESS,
    POST_CREATE_NEW_SUBMISSION_FAILURE,
    SUBMISSION_UPLOAD_FILE,
    SUBMISSION_UPLOAD_FILE_SUCCESS,
    SUBMISSION_UPLOAD_FILE_FAILURE,
    PATCH_NOVEL_CHAPTER,
    PATCH_NOVEL_CHAPTER_SUCCESS,
    PATCH_NOVEL_CHAPTER_FAILURE,
    DELETE_MP3_FILE,
    DELETE_MP3_FILE_SUCCESS,
    DELETE_MP3_FILE_FAILURE
} from "./type";

// CREATE NEW CHAPTER
export const createNewChapter = (data) => ({
    type: CREATE_NEW_CHAPTER,
    data
})

export const createNewChapterSuccess = (data) => ({
    type: CREATE_NEW_CHAPTER_SUCCESS,
    data
})

export const createNewChapterFailure = (data) => ({
    type: CREATE_NEW_CHAPTER_FAILURE,
    data
})
// CREATE NEW SUBMISSION
export const createNewSubmission = () => ({
    type: CREATE_NEW_SUBMISSION
})

export const createNewSubmissionSuccess = (data) => ({
    type: CREATE_NEW_SUBMISSION_SUCCESS,
    data
})

export const createNewSubmissionFailure = (data) => ({
    type: CREATE_NEW_SUBMISSION_FAILURE,
    data
})
// POST SUBMISSION
export const postCreateNewSubmission = (data) => ({
    type: POST_CREATE_NEW_SUBMISSION,
    data
})

export const postCreateNewSubmissionSuccess = (data) => ({
    type: POST_CREATE_NEW_SUBMISSION_SUCCESS,
    data
})

export const postCreateNewSubmissionFailure = (data) => ({
    type: POST_CREATE_NEW_SUBMISSION_FAILURE,
    data
})
// UPLOAD FILE
export const submissionFileUpload = (data) => ({
    type: SUBMISSION_UPLOAD_FILE,
    data
})

export const submissionFileUploadSuccess = (data) => ({
    type: SUBMISSION_UPLOAD_FILE_SUCCESS,
    data
})

export const submissionFileUploadFailure = (data) => ({
    type: SUBMISSION_UPLOAD_FILE_FAILURE,
    data
})
// PATCH NOVEL CHARACTER
export const patchNovelChapter = (id,data) => ({
    type: PATCH_NOVEL_CHAPTER,
    id,
    data
})
export const patchNovelChapterSuccess = (data) => ({
    type: PATCH_NOVEL_CHAPTER_SUCCESS,
    data
})
export const patchNovelChapterFailure = (data) => ({
    type: PATCH_NOVEL_CHAPTER_FAILURE,
    data
})
// DELETE FILE
export const deleteMp3File = (id) => ({
    type: DELETE_MP3_FILE,
    id
})
export const deleteMp3FileSuccess = (data) => ({
    type: DELETE_MP3_FILE_SUCCESS,
    data
})
export const deleteMp3FileFailure = (data) => ({
    type: DELETE_MP3_FILE_FAILURE,
    data
})