import { all } from "redux-saga/effects"
import priceCard from "../components/JoinClub/components/PriceCard/redux/saga"
import personalInfo from "../components/JoinClub/components/Personal/redux/saga"
import paymentInfo from "../components/JoinClub/components/Payment/redux/saga"
import login from "../pages/AuthScreens/Login/redux/saga"
import home from "../pages/HomeScreens/ReaderHome/redux/saga"
import landing from "../pages/AuthScreens/Landing/redux/saga"
import forgotPassword from "../pages/ForgotPassword/redux/saga"
import confirmPassword from "../pages/ConfirmPassword/redux/saga"
import tokenStore from "../pages/HomeScreens/TokenStore/redux/saga"
import myProfile from "../pages/HomeScreens/MyProfile/redux/saga"
import CurrentNovels from "../pages/HomeScreens/CurrentNovels/redux/saga"
import Favorites from "../pages/HomeScreens/Favorites/redux/saga"
import PrivacyPolicy from "../pages/AuthScreens/PrivacyPolicy/redux/saga"
import becomeWriter from "../pages/HomeScreens/BecomeWriter/redux/saga"
import TermsConditions from "../pages/AuthScreens/TermsConditions/redux/saga"
import writerHome from "../pages/WriterHome/redux/saga"
import editorHome from "../pages/EditorHome/redux/saga"
import workRoom from "../pages/Workroompage/redux/saga"
import selectAccount from "../pages/HomeScreens/SelectAccount/redux/saga"
import feedback from "../pages/HomeScreens/Feedback/redux/saga"
import producerHome from "../pages/ProducerHome/redux/saga"
import openApplications from "../pages/OpenApplications/redux/saga"
import PubNubChat from "../pages/PubNubChat/redux/saga"
import createNovel from "../pages/CreateNovel/redux/saga"
import createChapter from "../pages/CreateChapter/redux/saga"
import adminUser from "../pages/AdminScreens/Users/redux/saga";
import adminChapter from "../pages/AdminScreens/Chapters/redux/saga";
import adminHome from "../pages/AdminScreens/AdminHome/redux/saga";
import adminApplications from "../pages/AdminScreens/AdminApplications/redux/saga";
import adminFeeback from "../pages/AdminScreens/AdminFeedback/redux/saga"
import adminFinances from "../pages/AdminScreens/AdminFinances/redux/saga"
import pushNotifications from '../pages/AdminScreens/PushNotifications/redux/saga'
import readingPerspective from '../pages/HomeScreens/CharacterPerspective/redux/saga'
import NewChapterModal from '../components/NewChapterModal/redux/saga'

export function* rootSaga() {
  yield all([
    priceCard,
    personalInfo,
    paymentInfo,
    login,
    home,
    landing,
    forgotPassword,
    confirmPassword,
    tokenStore,
    CurrentNovels,
    myProfile,
    Favorites,
    PrivacyPolicy,
    becomeWriter,
    TermsConditions,
    writerHome,
    editorHome,
    workRoom,
    selectAccount,
    feedback,
    producerHome,
    openApplications,
    PubNubChat,
    createNovel,
    createChapter,
    adminUser,
    adminChapter,
    adminHome,
    adminApplications,
    adminFeeback,
    adminFinances,
    pushNotifications,
    readingPerspective,
    NewChapterModal,
  ])
}
