import React, { useState, useEffect, useRef } from "react"
import Input from "../../Input"
import Button from "../../Button"
import inputMailIcon from "../../../assets/images/input-mail-icon.svg"
import closeIcon from "../../../assets/images/close-icon-modal.svg"
import applyModal from "../../../assets/images/applyModal.svg"
import inputProfileIcon from "../../../assets/images/input-profile-icon.svg"
import uploadIcon from "../../../assets/images/upload.svg"
import "./style.scss"
import { connect } from "react-redux"
import { getRequestProfileData } from "../../../pages/HomeScreens/MyProfile/redux/action"
import {
  postApplyPosition,
  postApplyPositionFailure,
  postApplyPositionSuccess
} from "../../../pages/WriterHome/redux/action"
import { Toaster } from "react-hot-toast"
import TextArea from "../../TextArea"

const ApplyPositionModal = props => {
  const {
    getRequestProfileData,
    errorMsg,
    loading,
    postApplyPosition,
    setModalShow,
    setCruntTab,
    postApplyPositionFailure,
    postApplyPositionSuccess,
    applySuccess,
    novelID
  } = props
  const [selectedFile, setSelectedFile] = useState({})
  const [isFilePicked, setIsFilePicked] = useState(false);
  const [fileError, setFileError] = useState(false)
  const [name, setName] = useState('')
  const [content, setContent] = useState('')

  const fileRef = useRef()

  const handleChange = e => {
    setFileError(false)
    const file = e.target.files[0]
    const fileSize = file.size;
    const fileMb = parseFloat(fileSize / (1024 * 1024)).toFixed(50);
    postApplyPositionFailure(false)
    if (fileMb > 50) {
      setFileError("The maximum file size that can be uploaded is 50MB")
      setSelectedFile({})
    }else{
    setSelectedFile(file)
    setIsFilePicked(true)
    }
  }
  useEffect(() => {
    getRequestProfileData()
    postApplyPositionFailure(false)
  }, [])

  useEffect(() => {
    if(applySuccess){
      setModalShow(false);
      postApplyPositionSuccess(false);
      setTimeout(() => {
      setCruntTab('Advert')
      },500)
    }
  }, [applySuccess])

  const onSubmit = () => {
    setFileError(false)
    const formData = new FormData()
    formData.append("title", name)
    // formData.append("email", email)
    formData.append("content", content)
    formData.append("novels", novelID)
    postApplyPosition(formData)
  }

  const handleUploadClick = () => {
    fileRef.current?.click()
    return false
  }

  return (
    <div className="apply-wrapper">
      <div>
        <Toaster position="top-center" />
      </div>
      <div
        className="close-modal"
        onClick={() => {
          setModalShow(false)
          setTimeout(() => {
          setCruntTab('Advert')
      },500)
        }}
      >
        <img src={closeIcon} alt="" />
      </div>
      <div className="apply-section">
        <img src={applyModal} alt="" />
      </div>
      <h3>Apply for Position</h3>
      <div className="apply-wrapper-input-wrapper">
        <Input
          type="text"
          placeholder="Title"
          showIcon={true}
          src={inputProfileIcon}
          onChange={(val) => setName(val.target.value)}
          value={name}
          onFocus={() => postApplyPositionFailure(false)}
          showError={true}
          errorMessage={errorMsg?.name && errorMsg?.name}
        />
        {/* <Input
          type="email"
          placeholder="Enter Email Address"
          showIcon={true}
          src={inputMailIcon}
          onChange={(val) => setEmail(val.target.value)}
          value={email}
          showError={true}
          errorMessage={errorMsg?.email && errorMsg?.email}
        /> */}
      </div>
      {/* <div className="upload-file-wrapper" onClick={handleUploadClick}>
        <div className="upload-file">
          <div className="icon-wrapper" >
            <img src={uploadIcon} alt="upload" />
            <div className="sample">Uplaod Sample Work</div>
            <input
              type="file"
              name="file"
              ref={fileRef}
              onChange={handleChange}
              hidden
              value=""
            />
            {isFilePicked ? (
              <div>
                <p className="selected-file-name">
                  {selectedFile.name}
                </p>
              </div>
            ) : (
              ""
            )}
          </div>
        </div>
      </div> */}
      <TextArea placeholder="Content" value={content}  onChange={(e) => setContent(e.target.value)} />
      <div>
        <p className={`error-message mb-0 ${errorMsg?.sample || fileError ? 'opacity-1' : ''}`}>
        { errorMsg
                    ? errorMsg?.sample
                    : fileError && fileError}
        </p>
      </div>

      <div className="button-wrapper">
        <Button title="Apply" onClick={onSubmit} showSpinner={loading} />
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  loading: state.writerHome.loading,
  errorMsg: state.writerHome.errorMsg,
  applySuccess:  state.writerHome.applySuccess,
})

const mapDispatchToProps = dispatch => ({
  getRequestProfileData: data => dispatch(getRequestProfileData(data)),
  postApplyPosition: (data) =>
  dispatch(postApplyPosition(data)),
  postApplyPositionFailure: data => dispatch(postApplyPositionFailure(data)),
  postApplyPositionSuccess: data => dispatch(postApplyPositionSuccess(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(ApplyPositionModal)
