import {
  GET_ADMIN_CHAPTERS,
  GET_ADMIN_CHAPTERS_SUCCESS,
  GET_ADMIN_CHAPTERS_FAILURE,
  ADMIN_CHAPTERS_DELETE,
  ADMIN_CHAPTERS_DELETE_SUCCESS,
  ADMIN_CHAPTERS_DELETE_FAILURE,
  FLAGS_UPDATE,
  FLAGS_UPDATE_SUCCESS,
  FLAGS_UPDATE_FAILURE,
  ADD_ADMIN_CHAPTERS,
  ADD_ADMIN_CHAPTERS_SUCCESS,
  ADD_ADMIN_CHAPTERS_FAILURE,
  UPDATE_ADMIN_CHAPTERS,
  UPDATE_ADMIN_CHAPTERS_SUCCESS,
  UPDATE_ADMIN_CHAPTERS_FAILURE,
  SET_CURRENT_PAGE,
  SET_CONCAT_CHAPTERS_DATA
} from "./type"

export const getAdminChaptersData = (data, search, page, isType) => ({
  type: GET_ADMIN_CHAPTERS,
  data,
  search,
  page,
  isType
})

export const getAdminChaptersSuccess = data => ({
  type: GET_ADMIN_CHAPTERS_SUCCESS,
  data
})

export const getAdminChaptersFailure = data => ({
  type: GET_ADMIN_CHAPTERS_FAILURE,
  data
})
// DELETE
export const adminChaptersDelete = (data) => ({
  type: ADMIN_CHAPTERS_DELETE,
  data
})

export const adminChaptersDeleteSuccess = data => ({
  type: ADMIN_CHAPTERS_DELETE_SUCCESS,
  data
})

export const adminChaptersDeleteFailure = data => ({
  type: ADMIN_CHAPTERS_DELETE_FAILURE,
  data
})
// FLAGS
export const flagsUpdate = (data, id) => ({
  type: FLAGS_UPDATE,
  data,
  id,
})

export const flagsUpdateSuccess = data => ({
  type: FLAGS_UPDATE_SUCCESS,
  data
})

export const flagsUpdateFailure = data => ({
  type: FLAGS_UPDATE_FAILURE,
  data
})
// ADD CHAPTERS
export const addAdminChapters = (data,setModalShow) => ({
  type: ADD_ADMIN_CHAPTERS,
  data,
  setModalShow
})

export const addAdminChaptersSuccess = data => ({
  type: ADD_ADMIN_CHAPTERS_SUCCESS,
  data
})

export const addAdminChaptersFailure = data => ({
  type: ADD_ADMIN_CHAPTERS_FAILURE,
  data
})
// EDIT CHAPTERS
export const updateAdminChapters = (data, id, setModalShow, setShow, setUpdateData) => ({
  type: UPDATE_ADMIN_CHAPTERS,
  data,
  id,
  setModalShow, 
  setShow, 
  setUpdateData
})

export const updateAdminChaptersSuccess = data => ({
  type: UPDATE_ADMIN_CHAPTERS_SUCCESS,
  data
})

export const updateAdminChaptersFailure = data => ({
  type: UPDATE_ADMIN_CHAPTERS_FAILURE,
  data
})
// SET CURRENT PAGE
export const setCurrentPages = data => ({
  type: SET_CURRENT_PAGE,
  data
})
// CONCAT
export const setConcatChapterss = data => ({
  type: SET_CONCAT_CHAPTERS_DATA,
  data
})
