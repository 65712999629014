import React, { useState, useRef, useEffect } from "react"
import Button from "../Button"
import Modal from "react-bootstrap/Modal"
import topImg from "../../assets/images/personal-info-img.svg"
import closeIcon from "../../assets/images/close-icon-modal.svg"
import pencilIcon from "../../assets/images/textarea-write.svg"
import docIcon from "../../assets/images/document.svg"
import Input from "../Input"
import {
  postCreateNewSubmission,
  submissionFileUpload,
  submissionFileUploadFailure,
  submissionFileUploadSuccess
} from "../../pages/CreateChapter/redux/action"
import { connect } from "react-redux"

import "./style.scss"

const CreateSubmissionModal = props => {

  const {
    modalShow,
    setModalShow,
    postCreateNewSubmission,
    uploadFileRequesting,
    uploadFileInfo,
    submissionFileUpload,
    postSubmissionInfo,
    postSubRequesting,
    uploadFileError,
    submissionFileUploadFailure,
    submissionFileUploadSuccess,
    selectedCardID
  } = props

  const [submissionName, setSubmissionName] = useState("")

  useEffect(() => {
    if (postSubmissionInfo.msg === "Submission Successfully") {
      setModalShow(false)
      setSubmissionName("")
    }
  }, [postSubmissionInfo])

  const fileRef = useRef()

  const handleUploadClick = () => {
    fileRef.current?.click()
    return false
  }

  const onSubmit = () => {
    const formData = new FormData()
    formData.append("name", submissionName)
    formData.append("character", selectedCardID ? selectedCardID[0] : '')
    formData.append("file_submission_id", uploadFileInfo?.id)
    postCreateNewSubmission(formData)
  }

  const handleUploadChange = value => {
    submissionFileUploadFailure(false)
    const file = value.target.files[0]
    const formData = new FormData()
    formData.append("upload_submission", file)
    submissionFileUpload(formData)
  }

  const closeModal = () => {
    setModalShow(false)
    setSubmissionName("")
    submissionFileUploadFailure(false)
    submissionFileUploadSuccess(false)
  }

  return (
    <>
      <Modal
        {...props}
        show={modalShow}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="create-submission-modal"
      >
        <Modal.Body className="submission-modal-body">
          <div className="close-icon" onClick={closeModal}>
            <img src={closeIcon} alt="" />
          </div>
          <div className="submission-wrapper">
            <img className="top-img" src={topImg} alt="" />
            <h3>Create Submission</h3>
            <div className="submission-process-details">
              <Input
                placeholder="Create Submission Name"
                showIcon={true}
                src={pencilIcon}
                onChange={e => setSubmissionName(e.target.value)}
                value={submissionName}
              />
              <div className="button-wrapper">
                <Button
                  title="Upload Adventure Log From Writer"
                  className="full-btn"
                  onClick={handleUploadClick}
                  showSpinner={uploadFileRequesting}
                />
                <input
                  type="file"
                  name="file"
                  ref={fileRef}
                  onChange={handleUploadChange}
                  hidden
                  value=""
                />
              </div>
              <div className={`error-message ${uploadFileError?.msg ? "opacity-1" : ""}`}> {uploadFileError?.msg && uploadFileError?.msg} </div>
              <div className="upload-document-wrapper">
                <h4>Uploaded Document</h4>
                <div className="upload-document">
                  <div className="uploaded-content">
                    <img src={docIcon} alt="Document" />
                    <p className="mb-0">{uploadFileInfo?.data}</p>
                  </div>
                </div>
              </div>
              <div className="button-wrapper bottom-button-wrapper">
                <Button
                  title="Submit Submission"
                  onClick={onSubmit}
                  showSpinner={postSubRequesting}
                />
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  )
}

const mapStateToProps = state => ({
  postSubmissionInfo: state.createChapter.postSubmissionInfo,
  postSubRequesting: state.createChapter.postSubRequesting,
  posSubError: state.createChapter.posSubError,
  uploadFileInfo: state.createChapter.uploadFileInfo,
  uploadFileRequesting: state.createChapter.uploadFileRequesting,
  uploadFileError: state.createChapter.uploadFileError
})

const mapDispatchToProps = dispatch => ({
  postCreateNewSubmission: data => dispatch(postCreateNewSubmission(data)),
  submissionFileUpload: data => dispatch(submissionFileUpload(data)),
  submissionFileUploadFailure: data => dispatch(submissionFileUploadFailure(data)),
  submissionFileUploadSuccess: data => dispatch(submissionFileUploadSuccess(data))
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateSubmissionModal)
