import React, { useState } from "react"
import Button from "../Button"
import "./style.scss"
import { connect } from "react-redux"
import {
  patchWriterApplication,
  patchEditorApplication,
  patchSaveApplication
} from "../../pages/AdminScreens/AdminApplications/redux/action"

const AdminApplicaticationCard = props => {
  const {
    id,
    usertype,
    name,
    title,
    content,
    item,
    patchRequesting,
    active,
    patchWriterApplication,
    patchEditorApplication,
    patchSaveApplication
  } = props

  const [checkID, setCheckID] = useState(0)
  const [checkStatus, setCheckStatus] = useState("")

  const onSubmit = status => {
    setCheckID(id)
    setCheckStatus(status)
    const data = {
      status: status
    }
    if(active == "Editors"){
      patchWriterApplication(id, data)
    }else if(active == "Producers"){
      patchEditorApplication(id, data)
    }else if(active == "Saved"){
      patchSaveApplication(id, data)
    }
  }

  return (
    <div className="col-md-6 application-card-wrapper">
      <div className="application-card-details">
        <div className="application-card-head">
          <div className="writer-details">
            <div className="info">
              <p>{usertype || ""}</p>
            </div>
            <h3>{name || ""}</h3>
          </div>
        </div>
        <div className="application-card-body">
          <h3>{title || ""}</h3>
          <p>{content || ""}</p>
        </div>
        <div className="application-card-footer">
          <div className="button-wrapper">
            {item?.status !== "saved" && (
              <Button
                title="Save Candidate"
                onClick={() => onSubmit("saved")}
                showSpinner={
                  checkID == id && checkStatus == "saved" && patchRequesting
                }
              />
            )}
            <Button
              title="Accept"
              className="orange-btn" // ms-0 add this class in case of saved card
              onClick={() => onSubmit("accepted")}
              showSpinner={
                checkID == id && checkStatus == "accepted" && patchRequesting
              }
            />
            <Button
              title="Reject"
              className="black-btn"
              onClick={() => onSubmit("rejected")}
              showSpinner={
                checkID == id && checkStatus == "rejected" && patchRequesting
              }
            />
          </div>
        </div>
      </div>
    </div>
  )
}

const mapDispatchToProps = dispatch => ({
  patchWriterApplication: (id, status) =>
    dispatch(patchWriterApplication(id, status)),
    patchEditorApplication: (id, status) =>
    dispatch(patchEditorApplication(id, status)),
    patchSaveApplication: (id, status) =>
    dispatch(patchSaveApplication(id, status)),
})

export default connect(
  null,
  mapDispatchToProps
)(AdminApplicaticationCard)
