export const CREATE_NEW_NOVEL = "CREATE_NEW_NOVEL"
export const CREATE_NEW_NOVEL_SUCCESS = "CREATE_NEW_NOVEL_SUCCESS"
export const CREATE_NEW_NOVEL_FAILURE = "CREATE_NEW_NOVEL_FAILURE"
// Post
export const POST_ROLE_TYPE_REQUEST = "POST_ROLE_TYPE_REQUEST"
export const POST_ROLE_TYPE_SUCCESS = "POST_ROLE_TYPE_SUCCESS"
export const POST_ROLE_TYPE_FAILURE = "POST_ROLE_TYPE_FAILURE"

// Get
export const GET_ROLE_TYPE_REQUEST = "GET_ROLE_TYPE_REQUEST"
export const GET_ROLE_TYPE_SUCCESS = "GET_ROLE_TYPE_SUCCESS"
export const GET_EDITOR_TYPE_SUCCESS = "GET_EDITOR_TYPE_SUCCESS"
export const GET_ROLE_TYPE_FAILURE = "GET_ROLE_TYPE_FAILURE"

// get character list
export const GET_CHARACTER_REQUEST = "GET_CHARACTER_REQUEST"
export const GET_CHARACTER_REQUEST_SUCCESS = "GET_CHARACTER_REQUEST_SUCCESS"
export const GET_CHARACTER_REQUEST_FAILURE = "GET_CHARACTER_REQUEST_FAILURE"

// del
export const DELETE_REQUEST = "DELETE_REQUEST"
export const DELETE_SUCCESS = "DELETE_SUCCESS"
export const DELETE_FAILURE = "DELETE_FAILURE"
// export const UPDATE_TEAM_MEMBERS ="UPDATE_TEAM_MEMBERS"

export const EDIT_CHARACTER_REQUEST = "EDIT_CHARACTER_REQUEST"
export const EDIT_CHARACTER_SUCCESS = "EDIT_CHARACTER_SUCCESS"
export const EDIT_CHARACTER_FAILURE = "EDIT_CHARACTER_FAILURE"

// del novel
export const DELETE_NOVEL_REQUEST = "DELETE_NOVEL_REQUEST"
export const DELETE_NOVEL_SUCCESS = "DELETE_NOVEL_SUCCESS"
export const DELETE_NOVEL_FAILURE = "DELETE_NOVEL_FAILURE"