import React, { useEffect } from "react"
import { connect } from "react-redux"
import closeIcon from "../../../../assets/images/close-icon-modal.svg"
import greaterWhiteIcon from "../../../../assets/images/greater-white.svg"
import { getNovelData } from "../../../../pages/HomeScreens/ReaderHome/redux/action"
import { Spinner } from "reactstrap"
import { Toaster } from "react-hot-toast"

const CharacterDetails = props => {
  const {
    getNovelData,
    novelData,
    novelLoading,
    setModalShow,
    setCurrentTab,
    novelId,
    setNovalData
  } = props

  useEffect(() => {
    novelId && getNovelData(novelId)
  }, [novelId])

  const forNext = () => {
    setCurrentTab(3)
    setNovalData({
      novelCheptor: novelData,
      novelId: novelId,
      novelLoading: novelLoading
    })
  }
  const onTab = () => {
    setCurrentTab(1)
    setModalShow(false)
  }

  return (
    <div className="modal-body-right">
      <div>
        <Toaster position="top-center" />
      </div>
      {novelLoading ? (
        <div className="spinner-style">
          <Spinner color="primary" />
        </div>
      ) : (
        <>
          <div className="modal-right-header">
            <h3>{novelData?.character?.novel?.genre}</h3>
            <div className="novel">{novelData?.character?.name}</div>
            <div className="author-details">
              <p className="author">{`Played & Written By ${novelData?.character?.writer?.name || ""}`}</p>
            </div>
            <div className="close-icon">
              <img src={closeIcon} alt="" onClick={onTab} />
            </div>
          </div>
          <div className="modal-right-body">
            <div className="body-text-wrapper">
              <div className="text-wrapper">
                <h3>Personality Traits</h3>
                <p>{novelData?.character?.personality}</p>
              </div>
              <div className="text-wrapper">
                <h3>About Character</h3>
                <p>{novelData?.character?.about}</p>
              </div>
            </div>
          </div>
          <div className="modal-right-footer" onClick={forNext}>
            <div className="text">Read from {novelData?.character?.name} Perspective</div>
            <div className="image-wrapper">
              <img src={greaterWhiteIcon} alt="" />
            </div>
          </div>
        </>
      )}
    </div>
  )
}

const mapStateToProps = state => ({
  novelData: state.home.novelData,
  novelLoading: state.home.novelLoading,
  novelError: state.home.novelError
})

const mapDispatchToProps = dispatch => ({
  getNovelData: data => dispatch(getNovelData(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(CharacterDetails)
