import React, { useEffect, useState } from "react"
import HomeLayout from "../../../layout/HomeLayout"
import dummyPic from "../../../assets/images/dummyPic.png"
import ImageCard from "../../../components/ImageCard"
import "./style.scss"
import { connect } from "react-redux"
import { getCurrentNovel, setCurrentNovelIndex } from "./redux/action"
import NovelsModal from "../../../components/HomeModal/CurrentNovelModal"

const CurrentNovels = props => {
  const { getCurrentNovel, currentNoval, requesting, setCurrentNovelIndex } =
    props
  const [modalShow, setModalShow] = useState(false)

  useEffect(() => {
    getCurrentNovel()
  }, [])

  const openModal = index => {
    setCurrentNovelIndex(index)
    setModalShow(true)
  }

  const filterBySearch = value => {
    getCurrentNovel(value)
  }

  return (
    <HomeLayout
      headerName="Current Novels"
      searchBarWrapper={true}
      onChange={filterBySearch}
    >
      <section className="content-section current-novels-section">
        <div className="home-card-wrapper current-novels-wrapper">
          <div className="container-fluid home-card-container">
            <div className="row home-card-row current-novels-card-row">
              {requesting ? (
                [1, 2, 3, 4, 5, 6, 7].map((item, index) => (
                  <div className="card-data" key={item?.id}>
                    <ImageCard isLoader={true} />
                  </div>
                ))
              ) : currentNoval && currentNoval.length > 0 ? (
                currentNoval?.map((item, index) => (
                  <div className="card-data" key={item?.id}>
                    <ImageCard
                      onClick={() => openModal(index)}
                      src={
                        item?.novel?.thumbnail
                          ? item?.novel?.thumbnail
                          : dummyPic
                      }
                      hoverName={item?.novel?.name}
                    />
                  </div>
                ))
              ) : (
                <h4 className="no-data" style={{ textAlign: "center" }}>
                  No Data
                </h4>
              )}
            </div>
          </div>
        </div>
      </section>
      <NovelsModal
        modalShow={modalShow}
        setModalShow={setModalShow}
        currentNoval={currentNoval}
      />
    </HomeLayout>
  )
}

const mapStateToProps = state => ({
  currentNoval: state.CurrentNovels.data,
  requesting: state.CurrentNovels.requesting,
  error: state.CurrentNovels.error
})

const mapDispatchToProps = dispatch => ({
  getCurrentNovel: value => dispatch(getCurrentNovel(value)),
  setCurrentNovelIndex: data => dispatch(setCurrentNovelIndex(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(CurrentNovels)
