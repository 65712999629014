import React from 'react'
import Button from '../Button';
import Modal from 'react-bootstrap/Modal';
import warningSign from '../../assets/images/warning-sign.svg';
import closeIcon from '../../assets/images/close-icon-modal.svg';
import { history } from '../../reduxStore/store';
import './style.scss';


const WriterModeModal = ({modalShow, setModalShow}) => {

    return (
        <>
            <Modal
                show={modalShow}
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                className="warning-modal"
            >
                <Modal.Body className='warning-modal-body'>
                    <div className="close-icon" onClick={() => setModalShow(false)}>
                        <img src={closeIcon} alt="" />
                    </div>
                    <div className="warning-wrapper">
                        <img src={warningSign} alt="" />
                        <h3>Writer mode NOT active</h3>
                        <div className="button-wrapper">
                            <Button
                                title="Become a Writer"
                                onClick={() => history.push('/reader/become_writer')}
                            />
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}

export default WriterModeModal