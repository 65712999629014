import { all, call, put, takeLatest } from "redux-saga/effects"
import { BASE_URL } from "../../../config/app"
import XHR from "../../../utils/XHR"
import { postNewChapterSuccess, postNewChapterFailure } from "./action"
import { POST_NEW_CHAPTER_REQUEST } from "./type"
import toast from "react-hot-toast"

const postNewChapterAPI = async data => {
  const URL = `${BASE_URL}api/v1/novel_chapter/`
  const token = localStorage.getItem("token")

  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "POST",
    data
  }
  return XHR(URL, options)
}

function* postNewChapterData({ data }) {
  try {
    const response = yield call(postNewChapterAPI, data)
    yield put(postNewChapterSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(postNewChapterFailure(response.data))
  }
}
export default all([takeLatest(POST_NEW_CHAPTER_REQUEST, postNewChapterData)])
