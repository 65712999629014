import { all, call, put, takeLatest } from "redux-saga/effects";
import { BASE_URL } from "../../../../config/app";
import XHR from "../../../../utils/XHR";
import { GET_NOVALS_TOP } from "./type";
import { getNovalsTopSuccess, getNovalsTopFailure } from "./action";

const getNovalsTopAPI = async () => {
      const URL = `${BASE_URL}api/v1/novels/top/`

      const options = {
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
        method: "GET"
      }
      return XHR(URL, options)
    }
    
    function* getNovalsTop () {
      try {
        const response = yield call(getNovalsTopAPI)
        yield put(getNovalsTopSuccess(response.data))
      } catch (e) {
        const { response } = e
        yield put(getNovalsTopFailure(response.data))
      }
    }
    export default all([
        takeLatest(GET_NOVALS_TOP, getNovalsTop),
    ])