import Login from "./pages/AuthScreens/Login"
import WriterHome from "./pages/WriterHome"
import EditorHome from "./pages/EditorHome"
import ProducerHome from "./pages/ProducerHome"
import ForgotPassword from "./pages/ForgotPassword"
import Home from "./pages/HomeScreens/ReaderHome"
import ReadingExperience from "./pages/HomeScreens/ReadingExperience"
import ReadingPerspective from "./pages/HomeScreens/CharacterPerspective"
import Landing from "./pages/AuthScreens/Landing"
import { Images } from "./theme/Images"
import ConfirmPassword from "./pages/ConfirmPassword"
import MemberProfile from "./pages/HomeScreens/MemberProfile"
import Favorites from "./pages/HomeScreens/Favorites"
import CurrentNovels from "./pages/HomeScreens/CurrentNovels"
import TokenStore from "./pages/HomeScreens/TokenStore"
import PrivacyPolicy from "./pages/AuthScreens/PrivacyPolicy"
import TermsConditions from "./pages/AuthScreens/TermsConditions"
import MyProfile from "./pages/HomeScreens/MyProfile"
import BecomeWriter from "./pages/HomeScreens/BecomeWriter"
import Feedback from "./pages/HomeScreens/Feedback"
import SelectAccount from "./pages/HomeScreens/SelectAccount"
import Workroompage from "./pages/Workroompage"
import CreateNovel from "./pages/CreateNovel"
import Applications from "./pages/Applications"
import CreateChapter from "./pages/CreateChapter"
import OpenApplications from "./pages/OpenApplications"
import ChatApp from "./pages/PubNubChat/index.js"
import AdminHome from "./pages/AdminScreens/AdminHome"
import PushNotifications from "./pages/AdminScreens/PushNotifications"
import AdminFinances from "./pages/AdminScreens/AdminFinances"
import AdminLogin from "./pages/AuthScreens/AdminLogin"
import Users from "./pages/AdminScreens/Users"
import Chapters from "./pages/AdminScreens/Chapters"

import AdminApplications from "./pages/AdminScreens/AdminApplications"
import AdminFeeback from "./pages/AdminScreens/AdminFeedback"
import AdminFeebackDetails from "./pages/AdminScreens/AdminFeedbackDetails"
import ReviewResponse from "./pages/ReviewResponses"
import QuillEditor from "./pages/QuillEditor"

export const routes = [
  // Admin

  // Reader
  {
    name: "Home",
    iconSrc: Images.homeIcon,
    activeIconSrc: Images.homeIconActive,
    component: Home,
    path: "/home",
    layout: "/reader",
    isShow: "reader"
  },
  {
    name: "Profile",
    iconSrc: Images.profileIcon,
    activeIconSrc: Images.profileIconActive,
    component: MyProfile,
    path: "/profile",
    layout: "/reader",
    isShow: "reader"
  },
  {
    name: "Current Novels",
    iconSrc: Images.bookIcon,
    activeIconSrc: Images.bookIconActive,
    component: CurrentNovels,
    path: "/current_novels",
    layout: "/reader",
    isShow: "reader"
  },
  {
    name: "Become a Writer",
    iconSrc: Images.writerIcon,
    activeIconSrc: Images.writerIconActive,
    component: BecomeWriter,
    path: "/become_writer",
    layout: "/reader",
    isShow: "reader"
  },
  {
    name: "Good Read Token Store",
    iconSrc: Images.tokensIcon,
    activeIconSrc: Images.tokensIconActive,
    component: TokenStore,
    path: "/token_store",
    layout: "/reader",
    isShow: "reader"
  },
  {
    name: "Favorites",
    iconSrc: Images.favoriteIcon,
    activeIconSrc: Images.favoriteIconActive,
    component: Favorites,
    path: "/favorites",
    layout: "/reader",
    isShow: "reader"
  },
  {
    name: "Reading Experience",
    iconSrc: Images.bookIcon,
    activeIconSrc: Images.bookIconActive,
    component: ReadingExperience,
    path: "/reading_experience",
    layout: "/reader"
  },
  {
    name: "Character Perspective",
    iconSrc: Images.bookIcon,
    activeIconSrc: Images.bookIconActive,
    component: ReadingPerspective,
    path: "/chapter",
    layout: "/reader"
  },
  {
    name: "Member Profile",
    iconSrc: Images.bookIcon,
    activeIconSrc: Images.bookIconActive,
    component: MemberProfile,
    path: "/member_profile",
    layout: "/reader"
  },
  {
    name: "Send Feedback",
    component: Feedback,
    path: "/feedback",
    layout: "/reader"
  },
  {
    name: "Messages",
    iconSrc: Images.message,
    activeIconSrc: Images.messageActive,
    component: ChatApp,
    path: "/chat",
    layout: "/reader",
    isShow: false
  },
  {
    name: "Select Account",
    component: SelectAccount,
    path: "/account",
    layout: "/reader"
  },
  {
    name: "Privacy Policy",
    iconSrc: Images.application,
    activeIconSrc: Images.activeApp,
    component: PrivacyPolicy,
    path: "/privacy",
    layout: "/reader"
  },
  {
    name: "Terms & Conditions",
    iconSrc: Images.editor,
    activeIconSrc: Images.edotorActive,
    component: TermsConditions,
    path: "/terms_conditions",
    layout: "/reader"
  },
  // Writer
  {
    name: "Home",
    iconSrc: Images.homeIcon,
    activeIconSrc: Images.homeIconActive,
    component: WriterHome,
    path: "/home",
    layout: "/writer",
    isShow: "writer"
  },
  {
    name: "Profile",
    iconSrc: Images.profileIcon,
    activeIconSrc: Images.profileIconActive,
    component: MyProfile,
    path: "/profile",
    layout: "/writer",
    isShow: "writer"
  },
  // {
  //   name: "Current Novels",
  //   iconSrc: Images.bookIcon,
  //   activeIconSrc: Images.bookIconActive,
  //   component: CurrentNovels,
  //   path: "/current_novels",
  //   layout: "/writer",
  //   isShow: "writer"
  // },
  {
    name: "Member Profile",
    iconSrc: Images.bookIcon,
    activeIconSrc: Images.bookIconActive,
    component: MemberProfile,
    path: "/member_profile",
    layout: "/writer"
  },
  {
    name: "Reading Experience",
    iconSrc: Images.bookIcon,
    activeIconSrc: Images.bookIconActive,
    component: ReadingExperience,
    path: "/reading_experience",
    layout: "/writer"
  },
  {
    name: "Become an Editor",
    iconSrc: Images.editor,
    activeIconSrc: Images.edotorActive,
    component: BecomeWriter,
    path: "/become_editor",
    layout: "/writer",
    isShow: "writer"
  },
  // {
  //   name: "Open Applications",
  //   iconSrc: Images.application,
  //   activeIconSrc: Images.activeApp,
  //   component: OpenApplications,
  //   path: "/writer_applications",
  //   layout: "/writer",
  //   isShow: "writer"
  // },
  {
    name: "Favorites",
    iconSrc: Images.favoriteIcon,
    activeIconSrc: Images.favoriteIconActive,
    component: Favorites,
    path: "/favorites",
    layout: "/writer",
    isShow: "writer"
  },
  {
    name: "Messages",
    iconSrc: Images.message,
    activeIconSrc: Images.messageActive,
    component: ChatApp,
    path: "/chat",
    layout: "/writer",
    isShow: "writer"
  },
  {
    name: "WorkRoom",
    component: Workroompage,
    path: "/workroom",
    layout: "/writer"
  },
  {
    name: "QuillEditor",
    component: QuillEditor,
    path: "/chapter",
    layout: "/writer"
  },
  {
    name: "Select Account",
    component: SelectAccount,
    path: "/account",
    layout: "/writer"
  },
  {
    name: "Privacy Policy",
    iconSrc: Images.application,
    activeIconSrc: Images.activeApp,
    component: PrivacyPolicy,
    path: "/privacy",
    layout: "/writer"
  },
  {
    name: "Terms & Conditions",
    iconSrc: Images.editor,
    activeIconSrc: Images.edotorActive,
    component: TermsConditions,
    path: "/terms_conditions",
    layout: "/writer"
  },
  {
    name: "Send Feedback",
    component: Feedback,
    path: "/feedback",
    layout: "/writer"
  },
  // Editor
  {
    name: "Home",
    iconSrc: Images.homeIcon,
    activeIconSrc: Images.homeIconActive,
    component: EditorHome,
    path: "/home",
    layout: "/editor",
    isShow: "editor"
  },
  {
    name: "Profile",
    iconSrc: Images.profileIcon,
    activeIconSrc: Images.profileIconActive,
    component: MyProfile,
    path: "/profile",
    layout: "/editor",
    isShow: "editor"
  },
  // {
  //   name: "Current Novels",
  //   iconSrc: Images.bookIcon,
  //   activeIconSrc: Images.bookIconActive,
  //   component: CurrentNovels,
  //   path: "/current_novels",
  //   layout: "/editor",
  //   isShow: "editor"
  // },
  {
    name: "Reading Experience",
    iconSrc: Images.bookIcon,
    activeIconSrc: Images.bookIconActive,
    component: ReadingExperience,
    path: "/reading_experience",
    layout: "/editor"
  },
  {
    name: "WorkRoom",
    component: Workroompage,
    path: "/workroom",
    layout: "/editor"
  },
  {
    name: "QuillEditor",
    component: QuillEditor,
    path: "/chapter",
    layout: "/editor"
  },
  {
    name: "Become a Producer",
    iconSrc: Images.producer,
    activeIconSrc: Images.produceActive,
    component: BecomeWriter,
    path: "/become_producer",
    layout: "/editor",
    isShow: "editor"
  },
  // {
  //   name: "Open Applications",
  //   iconSrc: Images.application,
  //   activeIconSrc: Images.activeApp,
  //   component: OpenApplications,
  //   path: "/editor_applications",
  //   layout: "/editor",
  //   isShow: "editor"
  // },
  {
    name: "Member Profile",
    iconSrc: Images.bookIcon,
    activeIconSrc: Images.bookIconActive,
    component: MemberProfile,
    path: "/member_profile",
    layout: "/editor"
  },
  {
    name: "Favorites",
    iconSrc: Images.favoriteIcon,
    activeIconSrc: Images.favoriteIconActive,
    component: Favorites,
    path: "/favorites",
    layout: "/editor",
    isShow: "editor"
  },
  {
    name: "Messages",
    iconSrc: Images.message,
    activeIconSrc: Images.messageActive,
    component: ChatApp,
    path: "/chat",
    layout: "/editor",
    isShow: "editor"
  },
  {
    name: "Select Account",
    component: SelectAccount,
    path: "/account",
    layout: "/editor"
  },
  {
    name: "Privacy Policy",
    iconSrc: Images.application,
    activeIconSrc: Images.activeApp,
    component: PrivacyPolicy,
    path: "/privacy",
    layout: "/editor"
  },
  {
    name: "Terms & Conditions",
    iconSrc: Images.editor,
    activeIconSrc: Images.edotorActive,
    component: TermsConditions,
    path: "/terms_conditions",
    layout: "/editor"
  },
  {
    name: "Send Feedback",
    component: Feedback,
    path: "/feedback",
    layout: "/editor"
  },
  // Producer
  {
    name: "Home",
    iconSrc: Images.homeIcon,
    activeIconSrc: Images.homeIconActive,
    component: ProducerHome,
    path: "/home",
    layout: "/producer",
    isShow: "producer"
  },
  {
    name: "Profile",
    iconSrc: Images.profileIcon,
    activeIconSrc: Images.profileIconActive,
    component: MyProfile,
    path: "/profile",
    layout: "/producer",
    isShow: "producer"
  },
  // {
  //   name: "Review Responses",
  //   iconSrc: Images.review,
  //   activeIconSrc: Images.reviewActive,
  //   component: ReviewResponse,
  //   path: "/review_responses",
  //   layout: "/producer",
  //   isShow: "producer"
  // },
  {
    name: "Open Applications",
    iconSrc: Images.application,
    activeIconSrc: Images.activeApp,
    component: OpenApplications,
    path: "/producer_applications",
    layout: "/producer",
    isShow: "producer"
  },
  {
    name: "Messages",
    iconSrc: Images.message,
    activeIconSrc: Images.messageActive,
    component: ChatApp,
    path: "/chat",
    layout: "/producer",
    isShow: "producer"
  },
  {
    name: "Member Profile",
    iconSrc: Images.bookIcon,
    activeIconSrc: Images.bookIconActive,
    component: MemberProfile,
    path: "/member_profile",
    layout: "/producer"
  },
  {
    name: "Favorites",
    iconSrc: Images.favoriteIcon,
    activeIconSrc: Images.favoriteIconActive,
    component: Favorites,
    path: "/favorites",
    layout: "/producer",
    isShow: "producer"
  },
  {
    name: "Select Account",
    component: SelectAccount,
    path: "/account",
    layout: "/producer"
  },
  {
    component: CreateNovel,
    path: "/create_novel",
    layout: "/producer"
  },
  {
    component: CreateChapter,
    path: "/create_chapter",
    layout: "/producer"
  },
  {
    name: "WorkRoom",
    component: Workroompage,
    path: "/workroom",
    layout: "/producer"
  },
  {
    name: "QuillEditor",
    component: QuillEditor,
    path: "/chapter",
    layout: "/producer"
  },
  {
    name: "Reading Experience",
    iconSrc: Images.bookIcon,
    activeIconSrc: Images.bookIconActive,
    component: ReadingExperience,
    path: "/reading_experience",
    layout: "/producer"
  },
  {
    name: "Privacy Policy",
    iconSrc: Images.application,
    activeIconSrc: Images.activeApp,
    component: PrivacyPolicy,
    path: "/privacy",
    layout: "/producer"
  },
  {
    name: "Terms & Conditions",
    iconSrc: Images.editor,
    activeIconSrc: Images.edotorActive,
    component: TermsConditions,
    path: "/terms_conditions",
    layout: "/producer"
  },
  {
    name: "Send Feedback",
    component: Feedback,
    path: "/feedback",
    layout: "/producer"
  },
  {
    component: Applications,
    path: "/application",
    layout: "/producer"
  },
  // Admin
  {
    name: "Home",
    iconSrc: Images.homeIcon,
    activeIconSrc: Images.homeIconActive,
    component: AdminHome,
    path: "/home",
    layout: "/admin",
    isShow: "admin"
  },
  {
    name: "Profile",
    iconSrc: Images.profileIcon,
    activeIconSrc: Images.profileIconActive,
    component: MyProfile,
    path: "/profile",
    layout: "/admin",
    isShow: "admin"
  },
  {
    name: "Users",
    iconSrc: Images.adminUsers,
    activeIconSrc: Images.adminUsersActive,
    component: Users,
    path: "/users",
    layout: "/admin",
    isShow: "admin"
  },
  {
    name: "Applications",
    iconSrc: Images.application,
    activeIconSrc: Images.activeApp,
    component: AdminApplications,
    path: "/applications",
    layout: "/admin",
    isShow: "admin"
  },
  {
    name: "Push Notifications",
    iconSrc: Images.pushNotifications,
    activeIconSrc: Images.pushNotificationsActive,
    component: PushNotifications,
    path: "/push_notifications",
    layout: "/admin",
    isShow: "admin"
  },
  {
    name: "Finances",
    iconSrc: Images.finances,
    activeIconSrc: Images.financesActive,
    component: AdminFinances,
    path: "/finances",
    layout: "/admin",
    isShow: "admin"
  },
  {
    name: "Story Chapters",
    iconSrc: Images.application,
    activeIconSrc: Images.activeApp,
    component: Chapters,
    path: "/chapters",
    layout: "/admin",
    isShow: "admin"
  },
  {
    component: AdminFeeback,
    path: "/support",
    layout: "/admin"
  },
  {
    component: AdminFeebackDetails,
    path: "/feedback_details",
    layout: "/admin"
  },
  {
    component: CreateNovel,
    path: "/update_novel",
    layout: "/admin"
  },
  {
    component: AdminLogin,
    path: "/admin_login",
    layout: "/auth"
  },
  // auth Screen
  {
    component: Login,
    path: "/login",
    layout: "/auth"
  },
  {
    component: ForgotPassword,
    path: "/forgot_password",
    layout: "/auth"
  },
  {
    component: ConfirmPassword,
    path: "/confirm_password",
    layout: "/auth"
  },
  {
    name: "Privacy Policy",
    iconSrc: Images.application,
    activeIconSrc: Images.activeApp,
    component: PrivacyPolicy,
    path: "/privacy",
    layout: "/auth"
  },
  {
    component: TermsConditions,
    path: "/terms_conditions",
    layout: "/auth"
  },
  // This landing object must be in last item of the to work routing correctly
  {
    component: Landing,
    path: "/",
    layout: "/auth"
  }
]
