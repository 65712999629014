import {
  GET_WORK_ROOM_DATA,
  GET_WORK_ROOM_DATA_SUCCESS,
  GET_WORK_ROOM_DATA_FAILURE,
  PATCH_CHARACTER_DETAILS,
  PATCH_CHARACTER_DETAILS_SUCCESS,
  PATCH_CHARACTER_DETAILS_FAILURE,
  GET_UPCOMING_DATA,
  GET_UPCOMING_DATA_SUCCESS,
  GET_UPCOMING_DATA_FAILURE,
  PATCH_UPCOMING_DATA,
  PATCH_UPCOMING_DATA_SUCCESS,
  PATCH_UPCOMING_DATA_FAILURE
} from "./type"

const initialState = {
  data: false,
  requesting: false,
  error: false,

  patchData: false,
  patchRequesting: false,
  patchError: false,

  upcomingData: false,
  upcomingLoad: false, 
  upcomingError: false,

  patchUpcoming: false,
  patchUpcomLoad: false,
  patchUpcomError: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_WORK_ROOM_DATA:
      return {
        ...state,
        requesting: true
      }
    case GET_WORK_ROOM_DATA_SUCCESS:
      return {
        ...state,
        requesting: false,
        data: action.data
      }
    case GET_WORK_ROOM_DATA_FAILURE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }
      case PATCH_CHARACTER_DETAILS:
      return {
        ...state,
        patchRequesting: true
      }
    case PATCH_CHARACTER_DETAILS_SUCCESS:
      return {
        ...state,
        patchRequesting: false,
        patchData: action.data
      }
    case PATCH_CHARACTER_DETAILS_FAILURE:
      return {
        ...state,
        patchRequesting: false,
        patchError: action.data
      }
      case GET_UPCOMING_DATA:
      return {
        ...state,
        upcomingLoad: true
      }
    case GET_UPCOMING_DATA_SUCCESS:
      return {
        ...state,
        upcomingLoad: false,
        upcomingData: action.data
      }
    case GET_UPCOMING_DATA_FAILURE:
      return {
        ...state,
        upcomingLoad: false,
        upcomingError: action.data
      }
      case PATCH_UPCOMING_DATA:
      return {
        ...state,
        patchUpcomLoad: true
      }
    case PATCH_UPCOMING_DATA_SUCCESS:
      return {
        ...state,
        patchUpcomLoad: false,
        patchUpcoming: action.data
      }
    case PATCH_UPCOMING_DATA_FAILURE:
      return {
        ...state,
        patchUpcomLoad: false,
        patchUpcomError: action.data
      }
    default:
      return state
  }
}
