import { all, call, put, takeLatest } from "redux-saga/effects"
import { BASE_URL } from "../../../config/app"
import XHR from "../../../utils/XHR"
import {
  CREATE_NEW_NOVEL,
  GET_ROLE_TYPE_REQUEST,
  POST_ROLE_TYPE_REQUEST,
  DELETE_REQUEST,
  EDIT_CHARACTER_REQUEST,
  GET_CHARACTER_REQUEST,
  DELETE_NOVEL_REQUEST
} from "./type"
import {
  createNewNovelSuccess,
  createNewNovelFailure,
  getRoleTypelSuccess,
  getRoleTypeFailure,
  postRoleTypeSuccess,
  postRoleTypeFailure,
  deleteFailure,
  deleteSuccess,
  getCharacterRequest,
  editCharactersSuccess,
  editCharactersFailure,
  getCharacterSuccess,
  getCharacterFailure,
  deleteNovelSuccess,
  deleteNovelFailure,
  getEditorSuccess
} from "./action"
import toast from "react-hot-toast"
import { history } from "../../../reduxStore/store"
import { getNovelDataSuccess, getProducerHome } from "../../ProducerHome/redux/action"
import { setUpdateNovelID } from "../../AuthScreens/Login/redux/action"

async function createNovelAPI(data) {
  const URL = `${BASE_URL}api/v1/novels/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "multipart/form-data",
      Authorization: `Token ${token}`
    },
    method: "POST",
    data
  }
  return XHR(URL, options)
}

function* createNewNovel({ data }) {
  try {
    const response = yield call(createNovelAPI, data)
    yield put(createNewNovelSuccess(response.data))
    toast.success("Novel created successfully", {
      id: "card fields"
    })
    history.push("/home")
    //  (history.goBack())
  } catch (e) {
    const { response } = e
    yield put(createNewNovelFailure(response.data))
  }
}

async function RoleTypeAPI(data) {
  const userType = data ? `?user_type=${data}` : ""
  const URL = `${BASE_URL}api/v1/novel_user/${userType}`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

function* RoleTypeData({ data }) {
  try {
    const response = yield call(RoleTypeAPI, data)
    if (data == "writer") {
      yield put(getRoleTypelSuccess({ data: response.data, type: data }))
    }
    else if (data == "editor") {
      yield put(getEditorSuccess({ data: response.data, type: data }))
    }
    else {
      yield put(getRoleTypelSuccess({ data: response.data }))
    }
  } catch (e) {
    const { response } = e
    yield put(getRoleTypeFailure(response.data))
  }
}

async function postRoleTypeAPI(data) {
  const URL = `${BASE_URL}api/v1/character_create/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "POST",
    data
  }
  return XHR(URL, options)
}

async function getCharacterAPI() {
  const URL = `${BASE_URL}api/v1/character_create/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

function* getCharacterData() {
  try {
    const response = yield call(getCharacterAPI)
    yield put(getCharacterSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(getCharacterFailure(response.data))
  }
}

function* postRoleTypeData({ data }) {
  try {
    const response = yield call(postRoleTypeAPI, data)
    yield put(postRoleTypeSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(postRoleTypeFailure(response.data))
  }
}

const DeleteTeamMemebrAPI = async data => {
  const URL = `${BASE_URL}api/v1/character_create/${data}/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "DELETE"
  }
  return XHR(URL, options)
}

function* MemeberDelete({ data }) {
  try {
    const response = yield call(DeleteTeamMemebrAPI, data)
    yield put(deleteSuccess(response.data))
    yield put(getCharacterRequest())
    toast.success("successfully remove", {
      id: "ds"
    })
  } catch (e) {
    const { response } = e
    yield put(deleteFailure(response.data))
  }
}

const editCharacterAPI = async data => {
  const URL = `${BASE_URL}api/v1/admin_novel_character/${data.id}/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "PATCH",
    data: data.data
  }
  return XHR(URL, options)
}

function* editCharacter({ data }) {
  try {
    const response = yield call(editCharacterAPI, data)
    yield put(editCharactersSuccess(response.data))
    yield put(getCharacterRequest())

    toast.success("Updated successfully", {
      id: "ds"
    })
  } catch (e) {
    const { response } = e
    yield put(editCharactersFailure(response.data))
  }
}
const DeleteNovelAPI = async data => {
  const URL = `${BASE_URL}api/v1/novels/${data}/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "DELETE"
  }
  return XHR(URL, options)
}

function* DeleteNovel({ data }) {
  try {
    const response = yield call(DeleteNovelAPI, data)
    yield put(deleteNovelSuccess(response.data))


    // yield put (history.push(`/producer/home/`))
    yield put(getProducerHome())

  } catch (e) {
    const { response } = e
    yield put(deleteNovelFailure(response.data))
  }
}
export default all([
  takeLatest(CREATE_NEW_NOVEL, createNewNovel),
  takeLatest(GET_ROLE_TYPE_REQUEST, RoleTypeData),
  takeLatest(POST_ROLE_TYPE_REQUEST, postRoleTypeData),
  takeLatest(DELETE_REQUEST, MemeberDelete),
  takeLatest(EDIT_CHARACTER_REQUEST, editCharacter),
  takeLatest(GET_CHARACTER_REQUEST, getCharacterData),
  takeLatest(DELETE_NOVEL_REQUEST, DeleteNovel)
])
