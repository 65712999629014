import {
  
  POST_NEW_CHAPTER_REQUEST,
  POST_NEW_CHAPTER_SUCCESS,
  POST_NEW_CHAPTER_FAILURE,
  POST_NEW_CHAPTER_RESET,
} from "./type"


const initialState = {
  data: false,
  requesting: false,
  error: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case  POST_NEW_CHAPTER_REQUEST:
      return {
        ...state,
        requesting: true
      }
    case  POST_NEW_CHAPTER_SUCCESS:
      return {
        ...state,
        requesting: false,
        data: action.data
      }
    case  POST_NEW_CHAPTER_FAILURE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }
    case POST_NEW_CHAPTER_RESET:
      return {
        ...state,
        requesting: false,
        data: false,
      }
    default:
      return state
  }
}
