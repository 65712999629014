import React, { useState } from "react";
import Input from "../../../../../components/Input";
import pencilIcon from "../../../../../assets/images/textarea-write.svg";
import multipleChatImg from '../../../../../assets/images/multiple-chat.svg';
import Button from "../../../../Button";
import AdminSearch from "../../../../AdminSearch";
import { postAdminGroup } from "../../../../../pages/AdminScreens/PushNotifications/redux/action";
import './style.scss';
import { connect } from "react-redux";
import { Toaster } from "react-hot-toast"


const PushGroupChat = (props) => {
    const { postAdminGroup, requesting, error, setShowModal } = props
    const [groupName, setGroupName] = useState('')
    const [usersList, setUsersList] = useState([])


    const onSubmit = () => {
        const data = {
            name: groupName,
            users: usersList,
        }
        postAdminGroup(data, setShowModal)
    }

    return (
        <div className="modal-body-details-wrapper">
            <section className="group-chat-section">
                <div>
                    <Toaster position="top-center" />
                </div>
                <div className="group-chat-header">
                    <div className="top-img">
                        <img src={multipleChatImg} alt="" />
                    </div>
                    <div className="head-title">
                        New Chat
                    </div>
                </div>
                <div className="group-creation-wrapper">
                    <div className="group-input-wrapper">
                        <Input
                            type="text"
                            placeholder="Create Group Name"
                            showIcon={true}
                            src={pencilIcon}
                            onChange={val => setGroupName(val.target.value)}
                            value={groupName}
                        />
                    </div>
                </div>
                <AdminSearch placeholder="Select User" isGroupChat={true} setGroupList={setUsersList} usersList={usersList} />
                <div className="button-wrapper chat-button">
                    <Button
                        title="Cancel"
                        className="transparent-btn"
                        onClick={() => setShowModal('selectGroup')}
                    />
                    <Button
                        title="Create Group"
                        onClick={onSubmit}
                        showSpinner={requesting}
                    />
                </div>
            </section>
        </div>
    )
}

const mapStateToProps = (state) => ({
    requesting: state.pushNotifications.postGroupRequesting,
    error: state.pushNotifications.postGroupError,
})

const mapDispatchToProps = (dispatch) => ({
    postAdminGroup: (data, setShowModal) => dispatch(postAdminGroup(data, setShowModal))
})

export default connect(mapStateToProps, mapDispatchToProps)(PushGroupChat)