import {
  CREATE_NEW_CHAPTER,
  CREATE_NEW_CHAPTER_SUCCESS,
  CREATE_NEW_CHAPTER_FAILURE,
  CREATE_NEW_SUBMISSION,
  CREATE_NEW_SUBMISSION_SUCCESS,
  CREATE_NEW_SUBMISSION_FAILURE,
  POST_CREATE_NEW_SUBMISSION,
  POST_CREATE_NEW_SUBMISSION_SUCCESS,
  POST_CREATE_NEW_SUBMISSION_FAILURE,
  SUBMISSION_UPLOAD_FILE,
  SUBMISSION_UPLOAD_FILE_SUCCESS,
  SUBMISSION_UPLOAD_FILE_FAILURE,
  PATCH_NOVEL_CHAPTER,
  PATCH_NOVEL_CHAPTER_SUCCESS,
  PATCH_NOVEL_CHAPTER_FAILURE,
  DELETE_MP3_FILE,
  DELETE_MP3_FILE_SUCCESS,
  DELETE_MP3_FILE_FAILURE
} from "./type"

const initialState = {

  newChapterInfo: false,
  requesting: false,
  error: false,

  newSubmissionInfo: false,
  subRequesting: false,
  subError: false,

  postSubmissionInfo: false,
  postSubRequesting: false,
  postSubError: false,

  uploadFileInfo: false,
  uploadFileRequesting: false,
  uploadFileError: false,

  patchChapter: false,
  patchChapterLoad: false,
  patchChapterError: false,

  deleteFile: false,
  deleteFileLoad: false,
  deleteFileError: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case CREATE_NEW_CHAPTER:
      return {
        ...state,
        requesting: true,
      }
    case CREATE_NEW_CHAPTER_SUCCESS:
      return {
        ...state,
        requesting: false,
        newChapterInfo: action.data
      }
    case CREATE_NEW_CHAPTER_FAILURE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }
    case CREATE_NEW_SUBMISSION:
      return {
        ...state,
        subRequesting: true,
      }
    case CREATE_NEW_SUBMISSION_SUCCESS:
      return {
        ...state,
        subRequesting: false,
        newSubmissionInfo: action.data
      }
    case CREATE_NEW_SUBMISSION_FAILURE:
      return {
        ...state,
        subRequesting: false,
        subError: action.data
      }
    case POST_CREATE_NEW_SUBMISSION:
      return {
        ...state,
        postSubRequesting: true,
      }
    case POST_CREATE_NEW_SUBMISSION_SUCCESS:
      return {
        ...state,
        postSubRequesting: false,
        postSubmissionInfo: action.data
      }
    case POST_CREATE_NEW_SUBMISSION_FAILURE:
      return {
        ...state,
        postSubRequesting: false,
        postSubError: action.data
      }
      case SUBMISSION_UPLOAD_FILE:
      return {
        ...state,
        uploadFileRequesting: true,
      }
    case SUBMISSION_UPLOAD_FILE_SUCCESS:
      return {
        ...state,
        uploadFileRequesting: false,
        uploadFileInfo: action.data
      }
    case SUBMISSION_UPLOAD_FILE_FAILURE:
      return {
        ...state,
        uploadFileRequesting: false,
        uploadFileError: action.data
      }
      case PATCH_NOVEL_CHAPTER:
      return {
        ...state,
        patchChapterLoad: true,
      }
    case PATCH_NOVEL_CHAPTER_SUCCESS:
      return {
        ...state,
        patchChapterLoad: false,
        patchChapter: action.data
      }
    case PATCH_NOVEL_CHAPTER_FAILURE:
      return {
        ...state,
        patchChapterLoad: false,
        patchChapterError: action.data
      }
      case DELETE_MP3_FILE:
      return {
        ...state,
        deleteFileLoad: true,
      }
    case DELETE_MP3_FILE_SUCCESS:
      return {
        ...state,
        deleteFileLoad: false,
        deleteFile: action.data
      }
    case DELETE_MP3_FILE_FAILURE:
      return {
        ...state,
        deleteFileLoad: false,
        deleteFileError: action.data
      }
    default:
      return state
  }
}
