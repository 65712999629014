import {
  GET_PRODUCER_HOME,
  GET_PRODUCER_HOME_FAILURE,
  GET_PRODUCER_HOME_SUCCESS,
  SET_PRODUCER_INDEX,
  SET_CHARACTER_INDEX,
  GET_NOVEL_ID,
  GET_NOVEL_ID_SUCCESS,
  GET_NOVEL_ID_FAILURE,
  PATCH_NOVEL_REQUEST,
  PATCH_NOVEL_REQUEST_SUCCESS,
  PATCH_NOVEL_REQUEST_FAILURE
} from "./type"

const initialState = {
  data: false,
  requesting: false,
  error: false,
  
  producerIndex: 0,
  characterIndex: 0,

  novelData: false,
  novelDataLoad: false,
  novelDataError: false,

  patchNovel: false,
  patchNovelLoad: false,
  patchNovelError: false
}

export default (state = initialState, action) => {
  switch (action.type) {
    case GET_PRODUCER_HOME:
      return {
        ...state,
        requesting: true
      }
    case GET_PRODUCER_HOME_SUCCESS:
      return {
        ...state,
        requesting: false,
        data: action.data
      }
    case GET_PRODUCER_HOME_FAILURE:
      return {
        ...state,
        requesting: false,
        error: action.data
      }
      case GET_NOVEL_ID:
      return {
        ...state,
        novelDataLoad: true
      }
    case GET_NOVEL_ID_SUCCESS:
      return {
        ...state,
        novelDataLoad: false,
        novelData: action.data
      }
    case GET_NOVEL_ID_FAILURE:
      return {
        ...state,
        novelDataLoad: false,
        novelDataError: action.data
      }
      case PATCH_NOVEL_REQUEST:
      return {
        ...state,
        patchNovelLoad: true
      }
    case PATCH_NOVEL_REQUEST_SUCCESS:
      return {
        ...state,
        patchNovelLoad: false,
        patchNovel: action.data
      }
    case PATCH_NOVEL_REQUEST_FAILURE:
      return {
        ...state,
        patchNovelLoad: false,
        patchNovelError: action.data
      }
      case SET_PRODUCER_INDEX:
      return {
        ...state,
        producerIndex: action.data
      }
      case SET_CHARACTER_INDEX:
        return {
          ...state,
          characterIndex: action.data
        }
    default:
      return state
  }
}
