import React from 'react';
import goldenLike from '../../../../assets/images/like-golden.svg';
import goldenToken from '../../../../assets/images/token-golden.svg';
import closeIcon from '../../../../assets/images/close-icon-modal.svg';
import greaterWhiteIcon from '../../../../assets/images/greater-white.svg';
import { saveBookID, saveMemberID } from '../../../../pages/AuthScreens/Login/redux/action';
import { history } from '../../../../reduxStore/store';
import { connect } from 'react-redux';

const NovelDetails = ({ setModalShow, setCurrentTab, readerHomeData, saveMemberID, saveBookID, loginInfo }) => {


    const onSaveData = (id) => {
        if (id) {
            saveMemberID(id)
            history.push({ pathname: `/${loginInfo.user.mode}/member_profile/${readerHomeData?.author?.name.toLocaleLowerCase().replace(/\s+/g, '_')}`})
        }
    }

    const setBookID = () => {
        if(readerHomeData?.id){
            saveBookID(readerHomeData?.id)
            history.push({pathname: `/${loginInfo.user.mode}/reading_experience/`})
        }
      }

    return (
        <div className="modal-body-right">
            <div className="modal-right-header">
                <h3>{readerHomeData?.genre}</h3>
                <div className="novel">{readerHomeData?.name}</div>
               
                <a className="author-details" >
                    <p className="author"   onClick={() => onSaveData(readerHomeData?.author?.id)}>{readerHomeData?.author?.name || ''}</p>
                    <p className="writer"  onClick={() => onSaveData(readerHomeData?.character && readerHomeData?.character[0]?.writer?.id)}>{readerHomeData?.character && readerHomeData?.character[0]?.writer?.name || ''}</p>
                </a>
                <div className="close-icon">
                    <img src={closeIcon} alt="" onClick={() =>
                        setModalShow(false)
                    } />
                </div>
            </div>
            <div className="modal-right-body">
                {/* <div className="body-text-wrapper">
                    <div className="text-wrapper">
                        <p>{readerHomeData?.about_novel}</p>
                    </div>
                </div> */}
            <div className="body-text-wrapper">
              <div className="text-wrapper">
                <h3>Opening Scene</h3>
                <p>{readerHomeData?.opening_scene}</p>
              </div>
              <div className="text-wrapper">
                <h3>Synopsis</h3>
                <p>{readerHomeData?.synopsis}</p>
              </div>
            </div>
                <div className="read-details-wrapper">
                    <div className="icon-wrapper">
                        <img src={goldenLike} alt="" />
                        <span>Likes ({readerHomeData?.total_likes})</span>
                    </div>
                    <div className="icon-wrapper">
                        <img src={goldenToken} alt="" />
                        <span>Good Read Tokens ({readerHomeData?.grt ? readerHomeData?.grt.toFixed(0) : 0})</span>
                    </div>
                </div>
            </div>
            <div
                className="modal-right-footer" onClick={setBookID}>
                <div className="text">
                   View Reading Experience
                </div>
                <div className="image-wrapper">
                    <img src={greaterWhiteIcon} alt="" />
                </div>
            </div>
        </div>
    )

}

const mapStateToProps = (state) => ({
    loginInfo: state.login.loginInfo
})

const mapDispatchToProps = (dispatch) => ({
    saveBookID: data => dispatch(saveBookID(data)),
    saveMemberID: data => dispatch(saveMemberID(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(NovelDetails)