export const GET_PRODUCER_HOME = 'GET_PRODUCER_HOME';
export const GET_PRODUCER_HOME_SUCCESS = 'GET_PRODUCER_HOME_SUCCESS';
export const GET_PRODUCER_HOME_FAILURE = 'GET_PRODUCER_HOME_FAILURE';
// 
export const SET_PRODUCER_INDEX = 'SET_PRODUCER_INDEX';
export const SET_CHARACTER_INDEX = 'SET_CHARACTER_INDEX';
// 
export const GET_NOVEL_ID = 'GET_NOVEL_ID';
export const GET_NOVEL_ID_SUCCESS = 'GET_NOVEL_ID_SUCCESS';
export const GET_NOVEL_ID_FAILURE = 'GET_NOVEL_ID_FAILURE';
//
export const PATCH_NOVEL_REQUEST = 'PATCH_NOVEL_REQUEST';
export const PATCH_NOVEL_REQUEST_SUCCESS = 'PATCH_NOVEL_REQUEST_SUCCESS';
export const PATCH_NOVEL_REQUEST_FAILURE = 'PATCH_NOVEL_REQUEST_FAILURE';