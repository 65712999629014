import React from "react"
import goldenLike from "../../../assets/images/like-golden.svg"
import goldenToken from "../../../assets/images/token-golden.svg"
import closeIcon from "../../../assets/images/close-icon-modal.svg"
import greaterWhiteIcon from "../../../assets/images/greater-white.svg"
import blueHeart from "../../../assets/images/filled-favorite-active.svg"
import { history } from "../../../reduxStore/store"
import { saveBookID } from "../../../pages/AuthScreens/Login/redux/action"
import { connect } from "react-redux"


const NovelDetails = ({
  setModalShow,
  loginInfo,
  saveBookID,
  openAdvertIndex,
  adventureData
}) => {

  const setBookID = () => {
    if (adventureData[openAdvertIndex]?.id) {
      saveBookID(adventureData[openAdvertIndex]?.id)
      history.push({
        pathname: `/${loginInfo.user.mode}/reading_experience/`
      })
    }
  }

  return (
    <div className="modal-body-right">
      <div className="modal-right-header">
        <img src={blueHeart} />
        <div className="novel">{adventureData[openAdvertIndex]?.name}</div>
        <div className="close-icon">
          <img src={closeIcon} alt="" onClick={() => setModalShow(false)} />
        </div>
      </div>
      <div className="modal-right-body">
        <div className="body-text-wrapper">
          <div className="text-wrapper">
            <p>{adventureData[openAdvertIndex]?.about_novel}</p>
          </div>
        </div>
        <div className="read-details-wrapper">
          <div className="icon-wrapper">
            <img src={goldenLike} alt="" />
            <span>
              Likes ({adventureData[openAdvertIndex]?.likes?.length || "0"})
            </span>
          </div>
          <div className="icon-wrapper">
            <img src={goldenToken} alt="" />
            <span>
              Good Read Tokens ({adventureData[openAdvertIndex]?.grt || "0"})
            </span>
          </div>
        </div>
      </div>
      <div className="modal-right-footer" onClick={setBookID}>
        <div className="text">Read Novel</div>
        <div className="image-wrapper">
          <img src={greaterWhiteIcon} alt="" />
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  loginInfo: state.login.loginInfo
})

const mapDispatchToProps = dispatch => ({
  saveBookID: data => dispatch(saveBookID(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(NovelDetails)
