import {
  GET_WORK_ROOM_DATA,
  GET_WORK_ROOM_DATA_SUCCESS,
  GET_WORK_ROOM_DATA_FAILURE,
  PATCH_CHARACTER_DETAILS,
  PATCH_CHARACTER_DETAILS_SUCCESS,
  PATCH_CHARACTER_DETAILS_FAILURE,
  GET_UPCOMING_DATA,
  GET_UPCOMING_DATA_SUCCESS,
  GET_UPCOMING_DATA_FAILURE,
  PATCH_UPCOMING_DATA,
  PATCH_UPCOMING_DATA_SUCCESS,
  PATCH_UPCOMING_DATA_FAILURE
} from "./type"

export const getWorkRoom = (data) => ({
  type: GET_WORK_ROOM_DATA,
  data
})
export const getWorkRoomSuccess = data => ({
  type: GET_WORK_ROOM_DATA_SUCCESS,
  data
})
export const getWorkRoomFailure = data => ({
  type: GET_WORK_ROOM_DATA_FAILURE,
  data
})
// PATCH
export const patchWorkRoomRequest = (id, data) => ({
  type: PATCH_CHARACTER_DETAILS,
  id,
  data
})
export const patchWorkRoomSuccess = data => ({
  type: PATCH_CHARACTER_DETAILS_SUCCESS,
  data
})
export const patchWorkRoomFailure = data => ({
  type: PATCH_CHARACTER_DETAILS_FAILURE,
  data
})
// get upcoming.
export const getUpcomingData = (data) => ({
  type: GET_UPCOMING_DATA,
  data
})
export const getUpcomingDataSuccess = data => ({
  type: GET_UPCOMING_DATA_SUCCESS,
  data
})
export const getUpcomingDataFailure = data => ({
  type: GET_UPCOMING_DATA_FAILURE,
  data
})
// patch upcoming
export const patchUpcomingData = (id, data) => ({
  type: PATCH_UPCOMING_DATA,
  id,
  data
})
export const patchUpcomingDataSuccess = data => ({
  type: PATCH_UPCOMING_DATA_SUCCESS,
  data
})
export const patchUpcomingDataFailure = data => ({
  type: PATCH_UPCOMING_DATA_FAILURE,
  data
})