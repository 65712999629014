import React, { useState } from "react";
import natureAudio from '../../assets/nature.mp3';
import Button from '../Button';
import playIcon from "../../assets/images/play-icon.svg"
import stopIcon from "../../assets/images/stop-icon.svg"


const BoardAudioPlayer = ({
  src
}) => {
    const [isPlaying, setIsPlaying] = useState(false);
    const [audioElement, setAudioElement] = useState(new Audio());

    const handlePlay = () => {
        audioElement.play();
        setIsPlaying(true);
    };

    const handlePause = () => {
        audioElement.pause();
        setIsPlaying(false);
    };

    return (
        <>
            <audio
                src={src}
                ref={(audio) => {
                    setAudioElement(audio);
                }}
            />
            <div className="button-wrapper">
                {!isPlaying ? <Button
                    title="Play sound effects"
                    showIcon={true}
                    src={playIcon}
                    onClick={handlePlay}
                /> :
                <Button
                    title="Stop sound effects"
                    showIcon={true}
                    src={stopIcon}
                    className="dark-purple"
                    onClick={handlePause}
                />}
            </div>
        </>
    );
};

export default BoardAudioPlayer;
