import Header from "../../components/Header"
import React from "react"
import SidebarMenu from "../../components/SidebarMenu"

import "./style.scss"

const HomeLayout = props => {


  return (
    <section className="homepage-section">
      <SidebarMenu
        cardFlex={props.cardFlex}
        setCardFlex={props.setCardFlex}
      />
      <div className="main-section">
        <Header
          className={props.className}
          name={props.headerName}
          showSearch={props.searchBarWrapper}
          showBack={props.showBack}
          showRightWrapper={props.showRightWrapper}
          showFavorite={props.showFavorite}
          addFav={props.addFav}
          favOnClick={props.favOnClick}
          showChatButton={props.showChatButton}
          showSreachAndButton={props.showSreachAndButton}
          onClick={props.onClick}
          showTitle={props.showTitle}
          onChange={props.onChange}
          tokenOnClick={props.tokenOnClick}
          tokenLoader={props.tokenLoader}
          showAwardBtn={props.showAwardBtn}
          proOnChange={props.proOnChange}
          addCardOnClick={props.addCardOnClick}
          addCard={props.addCard}
          awardBtnTitle={props.awardBtnTitle}
          awardBtnImg={props.awardBtnImg}
        />
        {props.children}
      </div>
    </section>
  )
}

export default HomeLayout
