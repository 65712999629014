import { all, call, put, delay, takeLatest } from "redux-saga/effects"
import { BASE_URL } from "../../../../config/app"
import XHR from "../../../../utils/XHR"
import {
  POST_REQUEST_LOGIN,
  SET_FIREBASE_TOKEN,
  TOGGLE_USER_TYPE
} from "./type"
import {
  loginRequestSuccess,
  loginRequestFailure,
  setAccessToken,
  setFirebaseToken,
  toggleUserTypeSuccess,
  toggleUserTypeFailure
} from "./action"
import toast from "react-hot-toast"
import { fetchToken } from "../../../../firebase"

async function postLoginAPI(data) {
  const URL = `${BASE_URL}api/v1/login/`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    method: "POST",
    data
  }
  return XHR(URL, options)
}

async function toggleUserAPI(id, data) {
  const URL = `${BASE_URL}api/v1/reader_mode/${id}/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "PATCH",
    data
  }
  return XHR(URL, options)
}

function* toggleUser({ id, data }) {
  try {
    const response = yield call(toggleUserAPI, id, data)
    yield put(toggleUserTypeSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(toggleUserTypeFailure(response.data))
  }
}

function* postLoginInfo({ data }) {
  try {
    const response = yield call(postLoginAPI, data)
    yield put(loginRequestSuccess(response.data))
    localStorage.setItem("token", response.data.token)
    localStorage.setItem("userId", response.data.user.id.toString())
    yield put(setAccessToken(response.data.token))
    yield put(loginRequestFailure(false))
    try {
      const token = yield fetchToken()
      yield delay(3000)
      if (token) {
        yield put(
          setFirebaseToken({
            registration_id: token,
            type: "web"
          })
        )
      }
    } catch (e) {
      toast.error(
        "You have blocked your notifications, You will not receive new notifications"
      )
    }
  } catch (e) {
    const { response } = e
    yield put(loginRequestFailure(response.data))
    localStorage.setItem("token", response.data.token)
    response?.data?.non_field_errors &&
      toast.error(response.data.non_field_errors[0], {
        id: "card fields"
      })
  }
}

async function postFireTokenAPI(data) {
  const token = await localStorage.getItem("token")
  const URL = `${BASE_URL}api/v1/user_fcm_device_add/`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "POST",
    data
  }
  return XHR(URL, options)
}

function* postFireToken({ data }) {
  try {
    yield call(postFireTokenAPI, data)
  } catch (e) {
    const { response } = e
  }
}

export default all([
  takeLatest(POST_REQUEST_LOGIN, postLoginInfo),
  takeLatest(SET_FIREBASE_TOKEN, postFireToken),
  takeLatest(TOGGLE_USER_TYPE, toggleUser)
])
