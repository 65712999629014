import { all, call, put, takeLatest } from "redux-saga/effects"
// import AsyncStorage from '@react-native-community/async-storage';
// config
import { BASE_URL } from "../../../../config/app"
// utils
import XHR from "../../../../utils/XHR"
// types
import { PRIVACY_POLICY_DATA } from "./type"
// actions
import { getPrivacySuccess, getPrivacyFailure } from "./action"
import toast from "react-hot-toast"

async function getPrivacyDataAPI(data) {
  const token = await localStorage.getItem("token")
  const URL = `${BASE_URL}modules/privacy-policy/`
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
    },
    method: "GET"
    // data
  }
  return XHR(URL, options)
}

function* getPrivacyData({ data }) {
  try {
    const response = yield call(getPrivacyDataAPI, data)
    yield put(getPrivacySuccess(response?.data))
  } catch (e) {
    const { response } = e
    toast.error("Something Went Wrong", {
      id: 'card fields',
    })
    yield put(getPrivacyFailure(response.data))
  }
}

export default all([takeLatest(PRIVACY_POLICY_DATA, getPrivacyData)])
