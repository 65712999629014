import React, { useEffect, useState } from "react"
import Button from "../../../Button"
import { Images } from "../../../../theme/Images"
import { connect } from "react-redux"
import { getReaderClubData, savePlanId } from "./redux/action"
import { Spinner } from "react-bootstrap"
import { getTokenStore } from "../../../../pages/HomeScreens/TokenStore/redux/action"

const PriceCard = props => {
  const {
    getReaderClubData,
    savePlanId,
    readerData,
    requesting,
    error,
    setJoinClubTab,
    availgrt,
    getTokenStore,
    activePlan
  } = props

  const [activeId, setActiveId] = useState(null)


  useEffect(() => {
    if (!readerData) {
      getReaderClubData()
    }
  }, [])

  useEffect(() => {
    if(availgrt == 0){
      getTokenStore()
    }
  },[readerData])

  useEffect(() => {
    activePlan && setActiveId(activePlan?.active_subscription?.default_price)
  }, [activePlan])

  const postPlanRequest = id => {
      savePlanId(id)
      setJoinClubTab("PaymentInfo")
  }

  return (
    <div className="price-card-section">
      <div className="container">
        <div className="row">
          {requesting && (
            <div className="spinner-style">
              <Spinner variant="primary" />
            </div>
          )}
          {readerData &&
            readerData?.map(item => (
              <div className={`col-lg-3 col-sm-6 price-card-wrapper ${activeId == item.id ? "subscribed-card" : ""}`}>
                <div className="price-card">
                {activeId && activeId == item.id ? (
                  <div className="active-subscription">
                    Current Subscription
                  </div>
                ) : null}
                  <h3>{item.product.name}</h3>
                  <img
                    src={item.product.images}
                    className="img-fluid book-img"
                    alt=""
                  />
                  <div className="price">
                    {"$" + item.amount_decimal}
                    <span className="month">/{item.interval}</span>
                  </div>
                  <div className="button-wrapper">
                    <Button
                      title={activeId == item.id ? "Selected Option" : availgrt == 0 ? "Upgrade Plan" : "Select Option"}
                      className="transparent-btn"
                      onClick={() => postPlanRequest(item.id)}
                      disabled={activeId == item.id}
                    />
                  </div>
                  <div className="list-wrapper">
                    <ul>
                      <li>
                        <span className="icon">
                          <img src={Images.listCheckIcon} alt="" />
                        </span>
                        <span className="text">
                          <span className="blue-text">Follow Story from</span>
                          {`${item.product.metadata.characters_perspective} Characters Perspective`}
                        </span>
                      </li>
                      <li>
                        <span className="icon">
                          <img src={Images.listCheckIcon} alt="" />
                        </span>
                        <span className="text">
                          <span className="blue-text">Receive</span>
                          {`${item.product.metadata.good_read_tokens} Good Read Tokens`}
                        </span>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </div>
  )
}

const mapStateToProps = state => ({
  readerData: state.priceCard.readerData.plans,
  requesting: state.priceCard.requesting,
  error: state.priceCard.error,
  planId: state.priceCard.planId,
  activePlan: state.tokenStore.data,
})
const mapDispatchToProps = dispatch => ({
  getReaderClubData: data => dispatch(getReaderClubData(data)),
  savePlanId: planId => dispatch(savePlanId(planId)),
  getTokenStore: data => dispatch(getTokenStore(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(PriceCard)
