import {
  POST_FORGOT_PASSWORD,
  POST_FORGOT_PASSWORD_SUCCESS,
  POST_FORGOT_PASSWORD_FAILURE
} from "./type"

export const postForgotRequest = (data, history) => ({
  type: POST_FORGOT_PASSWORD,
  data,
  history
})

export const postForgotRequestSuccess = (data) => ({
  type: POST_FORGOT_PASSWORD_SUCCESS,
  data
})

export const postForgotRequestFailure = (error) => ({
  type: POST_FORGOT_PASSWORD_FAILURE,
  error
})
