import { all, call, put, takeLatest } from "redux-saga/effects"
// import AsyncStorage from '@react-native-community/async-storage';
// config
import { BASE_URL } from "../../../../../config/app"
// utils
import XHR from "../../../../../utils/XHR"
// types
import { GET_READER_CLUB_DATA, POST_READER_CLUB_DATA } from "./type"
// actions
import {
  readerClubDataSuccess,
  readerClubDataFailure,
  postReaderClubDataSuccess,
  postReaderClubDataFailure
} from "./action"

async function postPlansAPI(data) {
  const URL = `${BASE_URL}api/v1/subscription/`
  const token = await localStorage.getItem("token")

  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "POST",
    data
  }
  return XHR(URL, options)
}

function* postPlansInfo({ history, data }) {
  try {
    const response = yield call(postPlansAPI, data)
    yield put(postReaderClubDataSuccess(response))
    history.push("/auth/login")
  } catch (e) {
    const { response } = e
    yield put(postReaderClubDataFailure(response.data))
  }
}

async function getReadersClubAPI() {
  const URL = `${BASE_URL}api/v1/plan/`

  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    method: "GET"
  }
  return XHR(URL, options)
}

function* getReadersClub() {
  try {
    const response = yield call(getReadersClubAPI)
    yield put(readerClubDataSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(readerClubDataFailure(response.data))
  }
}
export default all([
  takeLatest(GET_READER_CLUB_DATA, getReadersClub),
  takeLatest(POST_READER_CLUB_DATA, postPlansInfo)
])
