import React, { useEffect, useState } from "react"
import Input from "../../../Input"
import personalInfoImg from "../../../../assets/images/personal-info-img.svg"
import inputMailIcon from "../../../../assets/images/input-mail-icon.svg"
import inputProfileIcon from "../../../../assets/images/input-profile-icon.svg"
import inputLockIcon from "../../../../assets/images/lock-icon.svg"
import Button from "../../../Button"
import useForm from "../../../../utils/useForm"
import validator from "../../../../utils/validation"
import { addYourInformation, addYourInformationFailure, addYourInformationSuccess } from "./redux/action"
import { connect } from "react-redux"
import { Toaster } from "react-hot-toast"
import BoardPhoneInput from "../../../PhoneInput"
import { history } from "../../../../reduxStore/store"

const PersonalInfo = props => {
  const {
    addYourInformation,
    personalInfo,
    requesting,
    error,
    addYourInformationFailure,
    addYourInformationSuccess
  } = props

  const [phoneValue, setPhoneValue] = useState("");
  const [passMatched, setPassMatched] = useState(false);

  useEffect(() => {
    if(personalInfo.token){
      addYourInformationSuccess(false)
      history.push("/auth/login")
    }
  },[personalInfo.token])

  const onSubmit = async () => {
    const data = {
      name: state.userName.value,
      phone_number: `+${state.phoneNumber.value}`,
      email: state.email.value,
      password: state.password.value,
      reffering_member: state.memberId.value
    }
    if (data.password == state.confirmPassword.value) {
      await addYourInformation(data)
    } else if(!data.password){
      setPassMatched('Please enter password')
    } else {
      setPassMatched(
        "The password and confirmation password do not match."
      )
    }
  }

  const handleFormatChanger = (value) => {
    setPhoneValue(handleOnChange("phoneNumber", value));
  }

  const stateSchema = {
    email: {
      value: "",
      error: ""
    },
    userName: {
      value: "",
      error: ""
    },
    phoneNumber: {
      value: "",
      error: ""
    },
    memberId: {
      value: "",
      error: ""
    },
    password: {
      value: "",
      error: ""
    },
    confirmPassword: {
      value: "",
      error: ""
    }
  }

  const validationStateSchema = {
    email: {
      required: true,
      validator: validator.email
    },
    userName: {
      required: true
    },
    phoneNumber: {
      required: false,
      validator: validator.phone
    },
    memberId: {
      required: false
    },
    password: {
      required: true,
      validator: validator.password
    },
    confirmPassword: {
      required: true
    }
  }
  const { state, handleOnChange, disable, setState } = useForm(
    stateSchema,
    validationStateSchema
  )

  const matchPassword = (value) => {
    if(!state.password.value){
      setPassMatched(false)
    } else if (value == state.password.value) {
      setPassMatched(false)
    }
    else {
      setPassMatched("The password and confirmation password do not match.")
    }
  }

  return (
    <section className="personal-info-section">
      <div className="personal-info-header">
        <div>
          <Toaster position="top-center" />
        </div>
        <img src={personalInfoImg} alt="" />
        <h3>Add your information:</h3>
      </div>
      <div className="personal-info-input-wrapper">
        <Input
          type="email"
          placeholder="Enter Email Address"
          showIcon={true}
          src={inputMailIcon}
          value={state.email.value}
          onChange={val => handleOnChange("email", val.target.value)}
          onFocus={() => addYourInformationFailure(false)}
          showError={true}
          errorMessage={state.email.error}
          BackendErrorMessage={error?.email}
        />
        <Input
          type="text"
          placeholder="Enter Username"
          showIcon={true}
          src={inputProfileIcon}
          value={state.userName.value}
          onChange={val => handleOnChange("userName", val.target.value)}
          showError={true}
          onFocus={() => addYourInformationFailure(false)}
          errorMessage={error?.name && error?.name}
        />
        <BoardPhoneInput
          placeholder={"+1 234 567-2345"}
          value={phoneValue}
          onChange={handleFormatChanger}
          showError={true}
          errorMessage={error.phone_number && error.phone_number[0] || state.phoneNumber.error}
        />
        <Input
          type="text"
          placeholder="Referring Member ID (Optional)"
          showIcon={true}
          src={inputProfileIcon}
          value={state.memberId.value}
          onChange={val => handleOnChange("memberId", val.target.value)}
          onFocus={() => addYourInformationFailure(false)}
          showError={true}
          errorMessage={state.memberId.error}
          BackendErrorMessage={error?.refer}
        />
        <Input
          type="password"
          placeholder="Enter Password"
          showHideIcon={true}
          showIcon={true}
          src={inputLockIcon}
          value={state.password.value}
          onChange={val => {
            handleOnChange("password", val.target.value)
          }}
          showError={true}
          errorMessage={state.password.error}
          BackendErrorMessage={error?.password}
        />
        <Input
          type="password"
          placeholder="Confirm Password"
          showHideIcon={true}
          showIcon={true}
          src={inputLockIcon}
          value={state.confirmPassword.value}
          onChange={val => {
            handleOnChange("confirmPassword", val.target.value)
            matchPassword(val.target.value)
          }}
          showError={true}
          errorMessage={ passMatched && passMatched}
        />
      </div>
      <div className="button-wrapper">
        <Button
          title="Submit"
          showSpinner={requesting}
          onClick={() => onSubmit()}
        />
      </div>
    </section>
  )
}

const mapStateToProps = state => ({
  personalInfo: state.personalInfo.personalInfo,
  requesting: state.personalInfo.requesting,
  error: state.personalInfo.error
})

const mapDispatchToProps = dispatch => ({
  addYourInformation: (data) =>
    dispatch(addYourInformation(data)),
  addYourInformationFailure: (data) =>
    dispatch(addYourInformationFailure(data)),
    addYourInformationSuccess: data => dispatch(addYourInformationSuccess(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(PersonalInfo)
