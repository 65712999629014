import { all, call, put, takeLatest } from "redux-saga/effects"
import { BASE_URL } from "../../../config/app"
import XHR from "../../../utils/XHR"
import {
  GET_EDIT_ADVERTS,
  GET_EDIT_CURRENT,
  GET_EDIT_SAVED,
  GET_EDIT_WORKROOMS
} from "./type"
import {
  getEditAdvertsSuccess,
  getEditAdvertsFailure,
  getEditCurrentSuccess,
  getEditCurrentFailure,
  getEditSaveSuccess,
  getEditSaveFailure,
  getEditWorkroomSuccess,
  getEditWorkroomFailure
} from "./action"

const getEditorAdvertsAPI = async (name = "") => {
  const URL = `${BASE_URL}api/v1/open_adverts/?name=${name}`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

const getEditorCurrentAPI = async () => {
  const URL = `${BASE_URL}api/v1/editor_current_application/ `
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

const getEditorSaveAPI = async () => {
  const URL = `${BASE_URL}api/v1/saved_by_review_producer/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

const getEditorWorkroomAPI = async () => {
  const URL = `${BASE_URL}api/v1/editor_available_workroom/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

function* getEditorAdverts({ data }) {
  try {
    const response = yield call(getEditorAdvertsAPI, data)
    yield put(getEditAdvertsSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(getEditAdvertsFailure(response.data))
  }
}

function* getEditorCurrent() {
  try {
    const response = yield call(getEditorCurrentAPI)
    yield put(getEditCurrentSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(getEditCurrentFailure(response.data))
  }
}

function* getEditorSave() {
  try {
    const response = yield call(getEditorSaveAPI)
    yield put(getEditSaveSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(getEditSaveFailure(response.data))
  }
}

function* getEditorWorkroom() {
  try {
    const response = yield call(getEditorWorkroomAPI)
    yield put(getEditWorkroomSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(getEditWorkroomFailure(response.data))
  }
}

export default all([
  takeLatest(GET_EDIT_ADVERTS, getEditorAdverts),
  takeLatest(GET_EDIT_CURRENT, getEditorCurrent),
  takeLatest(GET_EDIT_SAVED, getEditorSave),
  takeLatest(GET_EDIT_WORKROOMS, getEditorWorkroom)
])
