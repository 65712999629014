import React, { useEffect, useState } from "react"
import HomeLayout from "../../../layout/HomeLayout"
import dummyPic from "../../../assets/images/dummyPic.png"
import "./style.scss"
import ProfileCard from "../../../components/ProfileCard"
import ImageCard from "../../../components/ImageCard"
import { getFavorites } from "./redux/action"
import { connect } from "react-redux"
import AdventureModal from "../../../components/AdventureModal"
import { getAdventureLog } from "./redux/action"
import { setOpenAdvertIndex } from "../../WriterHome/redux/action"

const Favorites = props => {
  const {
    getFavorites,
    getAdventureLog,
    adventureLog,
    favoritesData,
    requesting,
    advRequesting,
    setOpenAdvertIndex
  } = props

  const [active, setActive] = useState("Authors")
  const [modalShow, setModalShow] = useState(false)
  const toggleItems = ["Authors", "Adventure Logs"]

  useEffect(() => {
    getFavorites("")
    getAdventureLog("")
  }, [])
  const filterBySearch = value => {
    if (active == "Authors") {
      getFavorites(value)
    } else {
      getAdventureLog(value)
    }
  }

  const handelImageClick = index => {
    setOpenAdvertIndex(index)
    setModalShow(true)
  }

  return (
    <HomeLayout
      headerName={"Favorites"}
      searchBarWrapper={true}
      onChange={filterBySearch}
    >
      <section className="content-section favorite-section">
        <div className="home-card-wrapper author-wrapper">
          <div className="container-fluid home-card-container">
            <div className="toggle-select">
              {toggleItems.map(item => (
                <>
                  <div
                    key={item.id}
                    className={`title ${active == item ? "active" : ""}`}
                    onClick={() => setActive(item)}
                  >
                    {item}
                  </div>
                </>
              ))}
            </div>
            {active == "Authors" ? (
              <div className="row home-card-row author-card-row">
                {requesting ? (
                  [1, 2, 3, 4, 5, 6, 7].map(item => (
                    <ProfileCard isLoader={true} />
                  ))
                ) : favoritesData && favoritesData[0]?.writer?.length > 0 ? (
                  favoritesData[0]?.writer.map(item => (
                    <ProfileCard
                      key={item.id}
                      src={
                        item.profile_picture ? item.profile_picture : dummyPic
                      }
                      author={item.name}
                      data={item.id}
                    />
                  ))
                ) : (
                  <h6 className="no-data">No Data</h6>
                )}
              </div>
            ) : (
              <div className="row home-card-row author-card-row">
                {advRequesting ? (
                  [1, 2, 3, 4, 5].map((item, index) => (
                    <div className="card-data" key={item?.id}>
                      <ImageCard isLoader={true} />
                    </div>
                  ))
                ) : adventureLog && adventureLog?.length > 0 ? (
                  adventureLog.map((item, index) => (
                    <div className="card-data" key={item?.id}>
                      <ImageCard
                        onClick={() => handelImageClick(index)}
                        src={item?.thumbnail ? item?.thumbnail : dummyPic}
                        hoverName={item?.name}
                      />
                    </div>
                  ))
                ) : (
                  <h6 className="no-data">No Data</h6>
                )}
              </div>
            )}
          </div>
        </div>
      </section>
      <AdventureModal
        modalShow={modalShow}
        setModalShow={setModalShow}
        adventureData={adventureLog}
        controls={true}
      />
    </HomeLayout>
  )
}

const mapStateToProps = state => ({
  favoritesData: state.Favorites.favoritesData,
  requesting: state.Favorites.requesting,
  adventureLog: state.Favorites.adventureLog.novels,
  advRequesting: state.Favorites.advRequesting
})

const mapDispatchToProps = dispatch => ({
  getFavorites: data => dispatch(getFavorites(data)),
  getAdventureLog: data => dispatch(getAdventureLog(data)),
  setOpenAdvertIndex: data => dispatch(setOpenAdvertIndex(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Favorites)
