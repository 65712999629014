import {
  POST_BECOME_WRITER,
  POST_BECOME_WRITER_SUCCESS,
  POST_BECOME_WRITER_FAILURE,
  GET_BECOME_WRITER_DATA,
  GET_BECOME_WRITER_DATA_SUCCESS,
  GET_BECOME_WRITER_DATA_FAILURE,
  GET_BECOME_NOVEL_DATA,
  GET_BECOME_NOVEL_DATA_SUCCESS,
  GET_BECOME_NOVEL_DATA_FAILURE
} from "./type"

export const postBecomeWriter = (data) => ({
  type: POST_BECOME_WRITER,
  data
})

export const postBecomeWriterSuccess = data => ({
  type: POST_BECOME_WRITER_SUCCESS,
  data
})

export const postBecomeWriterFailure = (data) => ({
  type: POST_BECOME_WRITER_FAILURE,
  data
})
// GET BECOME WRITER
export const getBecomeWriterData = (data) => ({
  type: GET_BECOME_WRITER_DATA,
  data
})

export const getBecomeWriterDataSuccess = (data) => ({
  type: GET_BECOME_WRITER_DATA_SUCCESS,
  data
})

export const getBecomeWriterDataFailure = (data) => ({
  type: GET_BECOME_WRITER_DATA_FAILURE,
  data
})
// GET BECOME NOVEL
export const getBecomeNovelData = (data) => ({
  type: GET_BECOME_NOVEL_DATA,
  data
})
export const getBecomeNovelSuccess = (data) => ({
  type: GET_BECOME_NOVEL_DATA_SUCCESS,
  data
})
export const getBecomeNovelFailure = (data) => ({
  type: GET_BECOME_NOVEL_DATA_FAILURE,
  data
})