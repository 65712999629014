import {
  GET_CURRENT_NOVELS,
  GET_CURRENT_NOVELS_FAILURE,
  GET_CURRENT_NOVELS_SUCCESS,
  SET_CURRENT_NOVEL_INDEX
} from "./types"

export const getCurrentNovel = (name) => ({
  type: GET_CURRENT_NOVELS,
  name
})

export const getCurrentNovelSuccess = data => ({
  type: GET_CURRENT_NOVELS_SUCCESS,
  data
})

export const getCurrentNovelFailure = data => ({
  type: GET_CURRENT_NOVELS_FAILURE,
  data
})

export const setCurrentNovelIndex = data => ({
  type: SET_CURRENT_NOVEL_INDEX,
  data
})