import React, { useEffect } from "react"
import { connect } from "react-redux"
import { Link } from "react-router-dom"
import forgotPasswordSideImage from "../../assets/images/forgot-password-img.png"
import emailIcon from "../../assets/images/mail-icon.svg"
import backIcon from '../../assets/images/less-white-icon.svg'
import Button from "../../components/Button"
import Input from "../../components/Input"
import useForm from "../../utils/useForm"
import validator from "../../utils/validation"
import { postForgotRequest, postForgotRequestFailure } from "./redux/action"
import { useHistory } from "react-router-dom"
import { Toaster } from 'react-hot-toast';


const ForgotPassword = props => {

  const { forgotRequest, forgotInfo, requesting, error } = props
  const history = useHistory();

  const stateSchema = {
    email: {
      value: "",
      error: ""
    }
  }
  const validationStateSchema = {
    email: {
      required: true,
      validator: validator.email
    }
  }

  const { state, handleOnChange, disable } = useForm(
    stateSchema,
    validationStateSchema
  )

  const onSubmit = async () => {
    if(state.email.value){
      const data = {
        email: state.email.value
      }
      await forgotRequest(data, history)
    }
  }
  useEffect(() => {
    props.postForgotRequestFailure(false)
  }, [])
  return (

    <section className="landing-page-section auth-page-section">
      <div className="forgot-back-icon" onClick={() => history.push('login')}>
        <img src={backIcon} alt="" />
      </div>
      <div className="container-fluid landing-content-container">
        <div className="row auth-content-row">
          <div className="col-lg-6">
            <div className="landing-left-content auth-left-content">
              <div>
                <Toaster position='top-center' />
              </div>
              <div className="content-details">
                <h1>Story Host</h1>
                <h2 className="forgot-title">
                  Forgot your Password? Add your email below.
                </h2>
              </div>
              <div className="input-wrapper">
                <Input
                  type="email"
                  showIcon={true}
                  src={emailIcon}
                  placeholder="Enter Registered Email Address"
                  onChange={val => handleOnChange("email", val.target.value)}
                  errorMessage={state.email.error || error?.email}
                  showError={true}
                />
              </div>
              <div className="button-wrapper forgot-password-button-wrapper">
                <Button
                  title="Reset Password?"
                  className="reset-btn"
                  onClick={onSubmit}
                  showSpinner={requesting}
                />
                <Button
                  onClick={() => { history.push("/auth/login") }}
                  title="Cancel"
                  className="transparent-btn"
                />
              </div>
            </div>
          </div>
          <div className="col-lg-6">
            <div className="auth-right-content">
              <img
                src={forgotPasswordSideImage}
                className="img-fluid"
                alt="Image"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

const mapStateToProps = state => ({
  forgotInfo: state.forgotPassword,
  requesting: state.forgotPassword.requesting,
  error: state.forgotPassword.error
})

const mapDispatchToProps = dispatch => ({
  forgotRequest: (data, history) => dispatch(postForgotRequest(data, history)),
  postForgotRequestFailure: (data, history) => dispatch(postForgotRequestFailure(data, history))


})

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)
