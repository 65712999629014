// Admin Subscription GET
export const GET_ADMIN_SUBSCRIPTION = 'GET_ADMIN_SUBSCRIPTION';
export const GET_ADMIN_SUBSCRIPTION_SUCCESS = 'GET_ADMIN_SUBSCRIPTION_SUCCESS';
export const GET_ADMIN_SUBSCRIPTION_FAILURE = 'GET_ADMIN_SUBSCRIPTION_FAILURE';
// POST
export const POST_ADMIN_SUBSCRIPTION = 'POST_ADMIN_SUBSCRIPTION';
export const POST_ADMIN_SUBSCRIPTION_SUCCESS = 'POST_ADMIN_SUBSCRIPTION_SUCCESS';
export const POST_ADMIN_SUBSCRIPTION_FAILURE = 'POST_ADMIN_SUBSCRIPTION_FAILURE';
// POST EDIT PLAN
export const POST_EDIT_PLANS = 'POST_EDIT_PLANS';
export const POST_EDIT_PLANS_SUCCESS = 'POST_EDIT_PLANS_SUCCESS';
export const POST_EDIT_PLANS_FAILURE = 'POST_EDIT_PLANS_FAILURE';
// SUBSCRIBED USER
export const GET_SUBSCRIBED_USER = 'GET_SUBSCRIBED_USER';
export const GET_SUBSCRIBED_USER_SUCCESS = 'GET_SUBSCRIBED_USER_SUCCESS';
export const GET_SUBSCRIBED_USER_FAILURE = 'GET_SUBSCRIBED_USER_FAILURE';
// SUB CONCAT
export const SET_SUB_USER_CONCAT = 'SET_SUB_USER_CONCAT';
// FINANCES TOP BOX
export const GET_FINANCES_TOP_BOX = 'GET_FINANCES_TOP_BOX';
export const GET_FINANCES_TOP_BOX_SUCCESS = 'GET_FINANCES_TOP_BOX_SUCCESS';
export const GET_FINANCES_TOP_BOX_FAILURE = 'GET_FINANCES_TOP_BOX_FAILURE';
// GRT
export const GET_GRT_DATA = 'GET_GRT_DATA';
export const GET_GRT_DATA_SUCCESS = 'GET_GRT_DATA_SUCCESS';
export const GET_GRT_DATA_FAILURE = 'GET_GRT_DATA_FAILURE';
// FINANCES GRT
export const GRT_DATA_CONCAT = 'GRT_DATA_CONCAT';
