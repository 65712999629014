import { all, call, put, takeLatest } from "redux-saga/effects"
import { BASE_URL } from "../../../../config/app"
import XHR from "../../../../utils/XHR"
import {
  GET_ADMIN_CHAPTERS,
  ADMIN_CHAPTERS_DELETE,
  FLAGS_UPDATE,
  ADD_ADMIN_CHAPTERS,
  UPDATE_ADMIN_CHAPTERS
} from "./type"
import {
  getAdminChaptersData,
  getAdminChaptersSuccess,
  getAdminChaptersFailure,
  adminChaptersDeleteSuccess,
  adminChaptersDeleteFailure,
  flagsUpdateSuccess,
  flagsUpdateFailure,
  addAdminChaptersSuccess,
  addAdminChaptersFailure,
  updateAdminChaptersSuccess,
  updateAdminChaptersFailure,
  setCurrentPages,
} from "./action"
import toast from "react-hot-toast"

async function getAdminChaptersAPI(data = "", search = "", page = 1) {
  
  const URL = `${BASE_URL}api/v1/admin-chapter-list/?name=${search}&Chapters_type=${data}&page=${page}`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    }
  }
  return XHR(URL, options)
}

async function deleteAdminChaptersAPI(id) {
  const URL = `${BASE_URL}api/v1/admin-Chapters-list/${id}/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "DELETE"
  }
  return XHR(URL, options)
}

async function addAdminChaptersAPI(data) {
  const URL = `${BASE_URL}api/v1/admin_add_Chapters/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "POST",
    data
  }
  return XHR(URL, options)
}

async function updateAdminChaptersAPI(data, id) {
    const URL = `${BASE_URL}api/v1/admin-Chapters-list/${id}/`
    const token = localStorage.getItem("token")
    const options = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      },
      method: "PATCH",
      data
    }
    return XHR(URL, options)
  }

  async function flagChaptersAPI(data, id) {
    const URL = `${BASE_URL}api/v1/admin-Chapters-list/${id}/`
    const token = localStorage.getItem("token")
    const options = {
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        Authorization: `Token ${token}`
      },
      method: "PATCH",
      data
    }
    return XHR(URL, options)
  }

  function* updateAdminChapters({ data, id, setModalShow, setShow, setUpdateData }) {
    try {
      const response = yield call(updateAdminChaptersAPI, data, id)
      yield put(updateAdminChaptersSuccess(response.data))
      toast.success("Updated Successfully", {
        id: 'card fields',
      })
      setModalShow(false)
       setShow(false)
        setUpdateData({})
      yield put(getAdminChaptersData())
    } catch (e) {
      const { response } = e
      yield put(updateAdminChaptersFailure(response?.data))
    }
  }

function* addAdminChapters({ data, setModalShow }) {
  try {
    const response = yield call(addAdminChaptersAPI, data)
    yield put(addAdminChaptersSuccess(response.data))
    toast.success("Chapters Added Successfully", {
      id: 'card fields',
    })
    yield put(getAdminChaptersData())
    setModalShow(false)
  } catch (e) {
    const { response } = e
    yield put(addAdminChaptersFailure(response?.data))
  }
}

function* getAdminChapters({ data, search, page}) {
  try {
    const response = yield call(getAdminChaptersAPI, data, search, page)
    yield put(getAdminChaptersSuccess(response?.data.results))
    yield put(setCurrentPages(response?.data))
  } catch (e) {
    const { response } = e
    yield put(getAdminChaptersFailure(response?.data))
  }
}
function* deleteAdminChapters({ data }) {
  try {
    const response = yield call(deleteAdminChaptersAPI, data)
    yield put(adminChaptersDeleteSuccess(response.data))
    toast.success("Delete Successful", {
      id: 'card fields',
    })
    yield put(getAdminChaptersData())
  } catch (e) {
    const { response } = e
    yield put(adminChaptersDeleteFailure(response?.data))
    toast.error("Something Went Wrong", {
      id: 'card fields',
    })
  }
}

function* flagsChaptersUpdate({ data, id }) {
  try {
    const response = yield call(flagChaptersAPI, data, id)
    yield put(flagsUpdateSuccess(response.data))
    toast.success("Flags Update Successful", {
      id: 'card fields',
    })
    yield put(getAdminChaptersData())
  } catch (e) {
    const { response } = e
    yield put(flagsUpdateFailure(response?.data))
    toast.error("Something Went Wrong", {
      id: 'card fields',
    })
  }
}

export default all([
  takeLatest(GET_ADMIN_CHAPTERS, getAdminChapters),
  takeLatest(ADMIN_CHAPTERS_DELETE, deleteAdminChapters),
  takeLatest(FLAGS_UPDATE, flagsChaptersUpdate),
  takeLatest(ADD_ADMIN_CHAPTERS, addAdminChapters),
  takeLatest(UPDATE_ADMIN_CHAPTERS, updateAdminChapters),
])
