import {
  POST_AWARD_GRT,
  POST_AWARD_GRT_SUCCESS,
  POST_AWARD_GRT_FAILURE,
} from "./type"

const initialState = {
  grtInfo: false,
  grtRequesting: false,
  grtError: false,
}

export default (state = initialState, action) => {
  switch (action.type) {
    case POST_AWARD_GRT:
      return {
        ...state,
        grtRequesting: true
      }
    case POST_AWARD_GRT_SUCCESS:
      return {
        ...state,
        grtRequesting: false,
        grtInfo: action.data
      }
    case POST_AWARD_GRT_FAILURE:
      return {
        ...state,
        grtRequesting: false,
        grtError: action.data
      }
    default:
      return state
  }
}
