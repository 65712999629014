import React from "react"
import imageIcon from "../../../../../assets/images/image-icon.svg"
import closeIcon from "../../../../../assets/images/close-icon-modal.svg"
import Button from "../../../../Button"
import { useState } from "react"
import { useEffect } from "react"
import { connect } from "react-redux"
import { saveMemberID } from "../../../../../pages/AuthScreens/Login/redux/action"
import { history } from "../../../../../reduxStore/store";


const CurrentApplications = (
  {
    setModalShow,
    currentAppData,
    saveMemberID,
    loginInfo,
    openAdvertIndex
  }
) => {
  const [status, setStatus] = useState("")

  const onSaveData = () => {
    const authId = currentAppData && currentAppData[openAdvertIndex]?.character?.novel?.author?.id
    if (authId) {
      saveMemberID(authId)
      const authName = currentAppData && currentAppData[openAdvertIndex]?.character?.novel?.author?.name.toLowerCase().replace(/\s+/g, '_')
      history.push({ pathname: `/${loginInfo.user.mode}/member_profile/${authName}`})
    }
  }

  useEffect(() => {
    if (currentAppData?.length) {
      currentAppData && currentAppData[openAdvertIndex]?.status == "accepted"
        ? setStatus("Accepted")
        : currentAppData && currentAppData[openAdvertIndex]?.status == "rejected"
          ? setStatus("Rejected")
          : currentAppData && currentAppData[openAdvertIndex]?.status == "saved"
            ? setStatus("Saved")
            : setStatus("Pending")
    }
  }, [openAdvertIndex])

  return (
    <>
      <div className="modal-body-left">
        <div className={`image-wrapper ${currentAppData && currentAppData[openAdvertIndex]?.character?.novel?.thumbnail ? '' : 'no-img-wrapper'}`}>
          <img
            src={
              currentAppData && currentAppData[openAdvertIndex]?.character?.novel
                ? currentAppData[openAdvertIndex]?.character?.novel?.thumbnail
                : imageIcon
            }
            alt=""
          />
        </div>
      </div>
      <div className="modal-body-right">
        <div className="modal-right-header">
          <h3>{currentAppData && currentAppData[openAdvertIndex]?.character?.novel?.genre}</h3>

          <div className="novel">{currentAppData && currentAppData[openAdvertIndex]?.character?.novel?.name}</div>
          <div className="author-details">
            <p className="author"
              onClick={() => onSaveData()}
            >
              {currentAppData && currentAppData[openAdvertIndex]?.character?.novel?.author?.name || "---"}
            </p>
            <p className="writer">
              {currentAppData && currentAppData[openAdvertIndex]?.character?.writer?.name || "---"}
            </p>
          </div>
          <div className="close-icon">
            <img
              src={closeIcon}
              alt=""
              onClick={() => {
                setModalShow(false)
              }}
            />
          </div>
        </div>
        <div className="modal-right-body">
          <div className="body-text-wrapper">
            <div className="text-wrapper">
              <p>{currentAppData && currentAppData[openAdvertIndex]?.character?.novel?.opening_scene}</p>
            </div>
          </div>
        </div>
        <div className="button-wrapper">
          <Button
            title={status}
            className={`radius-btn no-cursor ${status == "Rejected" ? "black-btn" : ''} ${status == "Pending" ? "inactive-btn no-cursor" : ''
              }`}
          />
        </div>
      </div>
    </>
  )
}

const mapStateToProps = (state) => ({
  loginInfo: state.login.loginInfo
})

const mapDispatchToProps = (dispatch) => ({
  saveMemberID: data => dispatch(saveMemberID(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(CurrentApplications)
