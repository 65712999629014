import React, { useEffect, useState } from "react"
import { connect } from "react-redux"
import AdminApplicaticationCard from "../../../components/AdminApplicationCard"
import HomeModal from "../../../components/HomeModal"
import HomeLayout from "../../../layout/HomeLayout"
import "./style.scss"
import {
  writerAdminAppli,
  editorAdminAppli,
  saveAdminAppli
} from "./redux/action"
import { Spinner } from "reactstrap"
import Skeleton, { SkeletonTheme } from "react-loading-skeleton"

const AdminApplications = props => {
  const {
    writerAdminAppli,
    editorAdminAppli,
    saveAdminAppli,
    writerAppli,
    writerAppliLoad,
    editorAppli,
    editorAppliLoad,
    saveAppli,
    saveAppliLoad,
    patchWriterLoad,
    patchEditorLoad,
    patchSaveLoad
  } = props

  const [active, setActive] = useState("Editors")
  const toggleItems = ["Editors", "Producers", "Saved"]

  useEffect(() => {
    if (active == "Editors") {
      writerAdminAppli()
    } else if (active == "Producers") {
      editorAdminAppli()
    } else if (active == "Saved") {
      saveAdminAppli()
    }
  }, [active])

  return (
    <HomeLayout
      headerName={"Applications"}
      searchBarWrapper={true}
      className="application-header-section"
    >
      <section className="content-section open-application-section">
        <div className="home-card-wrapper open-application-wrapper">
          <div className="container-fluid open-application-container">
            <div className="toggle-select">
              {toggleItems.map(item => (
                <div
                  key={item.id}
                  className={`title ${active == item ? "active" : ""}`}
                  onClick={() => setActive(item)}
                >
                  {item}
                </div>
              ))}
            </div>
            <div className="row">
              {active == "Editors" ? (
                !writerAppli && writerAppliLoad ? (
                  <SkeletonTheme baseColor="#6f8bdf40" highlightColor="#6F8BDF">
                    <h3 className="application-heading"> <Skeleton width="10%" height='10px' /></h3>
                    {[1, 2, 3, 4].map((item) => (
                      <div className="col-md-6 application-card-wrapper">
                        <div className="application-card-details">
                          <div className="application-card-head w-100">
                            <div className="writer-details w-100">
                              <div className="info w-100">
                                <p className="w-100"><Skeleton width="20%" height='10px' /></p>
                              </div>
                              <h3><Skeleton width="15%" height='10px' /></h3>
                            </div>
                          </div>
                          <div className="application-card-body">
                            <h3><Skeleton width="15%" height='10px' /></h3>
                            <p><Skeleton width="20%" height='10px' /></p>
                          </div>
                          <div className="application-card-footer">
                            <div className="button-wrapper justify-content-around" >
                              <span style={{ width: '30%' }}><Skeleton width="100%" height='10px' /></span>
                              <span style={{ width: '30%' }}><Skeleton width="100%" height='10px' /></span>
                              <span style={{ width: '30%' }}><Skeleton width="100%" height='10px' /></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </SkeletonTheme>

                ) : (
                  <>
                    {writerAppli?.length > 0 ? (
                      writerAppli?.map(item => (
                        <>
                          <h3 className="application-heading">
                            {item?.novel_name}
                          </h3>
                          {item.application.map(item => (
                            <AdminApplicaticationCard
                              item={item}
                              id={item.id}
                              usertype={item.user.name || ""}
                              name={item?.novels?.name || ""}
                              title={item?.title || ""}
                              content={item?.content || ""}
                              active={active}
                              patchRequesting={patchWriterLoad}
                            />
                          ))}
                        </>
                      ))
                    ) : (
                      <h6 className="no-data">No Data</h6>
                    )}
                  </>
                )
              ) : active == "Producers" ? (
                !editorAppli && editorAppliLoad ? (
                  <SkeletonTheme baseColor="#6f8bdf40" highlightColor="#6F8BDF">
                    <h3 className="application-heading"> <Skeleton width="10%" height='10px' /></h3>
                    {[1, 2, 3, 4].map((item) => (
                      <div className="col-md-6 application-card-wrapper">
                        <div className="application-card-details">
                          <div className="application-card-head w-100">
                            <div className="writer-details w-100">
                              <div className="info w-100">
                                <p className="w-100"><Skeleton width="20%" height='10px' /></p>
                              </div>
                              <h3><Skeleton width="15%" height='10px' /></h3>
                            </div>
                          </div>
                          <div className="application-card-body">
                            <h3><Skeleton width="15%" height='10px' /></h3>
                            <p><Skeleton width="20%" height='10px' /></p>
                          </div>
                          <div className="application-card-footer">
                            <div className="button-wrapper justify-content-around" >
                              <span style={{ width: '30%' }}><Skeleton width="100%" height='10px' /></span>
                              <span style={{ width: '30%' }}><Skeleton width="100%" height='10px' /></span>
                              <span style={{ width: '30%' }}><Skeleton width="100%" height='10px' /></span>
                            </div>
                          </div>
                        </div>
                      </div>
                    ))}
                  </SkeletonTheme>
                ) : (
                  <>
                    {editorAppli?.length > 0 ? (
                      editorAppli?.map(item => (
                        <>
                          <h3 className="application-heading">
                            {item?.novel_name}
                          </h3>
                          {item?.application.map(item => (
                            <AdminApplicaticationCard
                              item={item}
                              id={item.id}
                              usertype={item.user.name || ""}
                              name={item?.novels?.name || ""}
                              title={item?.title || ""}
                              content={item?.content || ""}
                              active={active}
                              patchRequesting={patchEditorLoad}
                            />
                          ))}
                        </>
                      ))
                    ) : (
                      <h6 className="no-data">No Data</h6>
                    )}
                  </>
                )
              ) : (
                active == "Saved" &&
                (!saveAppli && saveAppliLoad ? (
                  <SkeletonTheme baseColor="#6f8bdf40" highlightColor="#6F8BDF">
                    {[1, 2].map((item) => (
                      <>
                        <h3 className="application-heading"> <Skeleton width="10%" height='10px' /></h3>
                        <div className="col-md-6 application-card-wrapper">
                          <div className="application-card-details">
                            <div className="application-card-head w-100">
                              <div className="writer-details w-100">
                                <div className="info w-100">
                                  <p className="w-100"><Skeleton width="20%" height='10px' /></p>
                                </div>
                                <h3><Skeleton width="15%" height='10px' /></h3>
                              </div>
                            </div>
                            <div className="application-card-body">
                              <h3><Skeleton width="15%" height='10px' /></h3>
                              <p><Skeleton width="20%" height='10px' /></p>
                            </div>
                            <div className="application-card-footer">
                              <div className="button-wrapper justify-content-around" >
                                <span style={{ width: '30%' }}><Skeleton width="100%" height='10px' /></span>
                                <span style={{ width: '30%' }}><Skeleton width="100%" height='10px' /></span>
                                <span style={{ width: '30%' }}><Skeleton width="100%" height='10px' /></span>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6 application-card-wrapper">
                          <div className="application-card-details">
                            <div className="application-card-head w-100">
                              <div className="writer-details w-100">
                                <div className="info w-100">
                                  <p className="w-100"><Skeleton width="20%" height='10px' /></p>
                                </div>
                                <h3><Skeleton width="15%" height='10px' /></h3>
                              </div>
                            </div>
                            <div className="application-card-body">
                              <h3><Skeleton width="15%" height='10px' /></h3>
                              <p><Skeleton width="20%" height='10px' /></p>
                            </div>
                            <div className="application-card-footer">
                              <div className="button-wrapper justify-content-around" >
                                <span style={{ width: '30%' }}><Skeleton width="100%" height='10px' /></span>
                                <span style={{ width: '30%' }}><Skeleton width="100%" height='10px' /></span>
                                <span style={{ width: '30%' }}><Skeleton width="100%" height='10px' /></span>
                              </div>
                            </div>
                          </div>
                        </div>
                      </>
                    ))}
                  </SkeletonTheme>
                ) : (
                  <>
                    {saveAppli?.length > 0 ? (
                      saveAppli?.map(item => (
                        <>
                          <h3 className="application-heading">
                            {item?.novel_name}
                          </h3>
                          {item.novel_application.map(item => (
                            <AdminApplicaticationCard
                              item={item}
                              id={item.id}
                              usertype={item.user.name || ""}
                              name={item?.novels?.name || ""}
                              title={item?.title || ""}
                              content={item?.content || ""}
                              active={active}
                              patchRequesting={patchSaveLoad}
                            />
                          ))}
                        </>
                      ))
                    ) : (
                      <h6 className="no-data">No Data</h6>
                    )}
                  </>
                ))
              )}
            </div>
          </div>
        </div>
      </section>
      <HomeModal controls={true} />
    </HomeLayout>
  )
}

const mapStateToProps = state => ({
  writerAppli: state.adminApplications.writerAppli,
  writerAppliLoad: state.adminApplications.writerAppliLoad,
  editorAppli: state.adminApplications.editorAppli,
  editorAppliLoad: state.adminApplications.editorAppliLoad,
  saveAppli: state.adminApplications.saveAppli,
  saveAppliLoad: state.adminApplications.saveAppliLoad,
  patchWriterLoad: state.adminApplications.patchWriterLoad,
  patchEditorLoad: state.adminApplications.patchEditorLoad,
  patchSaveLoad: state.adminApplications.patchSaveLoad
})

const mapDispatchToProps = dispatch => ({
  writerAdminAppli: () => dispatch(writerAdminAppli()),
  editorAdminAppli: () => dispatch(editorAdminAppli()),
  saveAdminAppli: () => dispatch(saveAdminAppli())
})

export default connect(mapStateToProps, mapDispatchToProps)(AdminApplications)
