import {
     ADD_YOUR_INFORMATION,
     ADD_YOUR_INFORMATION_SUCCESS,
     ADD_YOUR_INFORMATION_FAILURE,
    } from "./type";

// ADD_YOUR_INFORMATION
export const addYourInformation = (data) => ({
    type: ADD_YOUR_INFORMATION,
    data
})

export const addYourInformationSuccess = (data) => ({
    type: ADD_YOUR_INFORMATION_SUCCESS,
    data
})

export const addYourInformationFailure = (data) => ({
    type: ADD_YOUR_INFORMATION_FAILURE,
    data
})