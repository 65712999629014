import {
  APPLY_FOR_POSITION,
  APPLY_FOR_POSITION_SUCCESS,
  APPLY_FOR_POSITION_FAILURE,
  SET_OPEN_ADVERT_INDEX,
  GET_WRITER_ADVERTS,
  GET_WRITER_ADVERTS_SUCCESS,
  GET_WRITER_ADVERTS_FAILURE,
  GET_WRITER_CURRENT,
  GET_WRITER_CURRENT_SUCCESS,
  GET_WRITER_CURRENT_FAILURE,
  GET_WRITER_WORKROOMS,
  GET_WRITER_WORKROOMS_SUCCESS,
  GET_WRITER_WORKROOMS_FAILURE
} from "./type"

// APPLY FOR POSITION
export const postApplyPosition = (data) => ({
  type: APPLY_FOR_POSITION,
  data
})

export const postApplyPositionSuccess = data => ({
  type: APPLY_FOR_POSITION_SUCCESS,
  data
})

export const postApplyPositionFailure = data => ({
  type: APPLY_FOR_POSITION_FAILURE,
  data
})

export const setOpenAdvertIndex = data => ({
  type: SET_OPEN_ADVERT_INDEX,
  data
})
// WRITER OPEN ADVERTS
export const getWriterAdverts = (data, page) => ({
  type: GET_WRITER_ADVERTS,
  data,
  page
})
export const getWriterAdvertsSuccess = data => ({
  type: GET_WRITER_ADVERTS_SUCCESS,
  data
})
export const getWriterAdvertsFailure = data => ({
  type: GET_WRITER_ADVERTS_FAILURE,
  data
})
// WRITER CURRENT APPLICATION
export const getWriterCurrent = (data) => ({
  type: GET_WRITER_CURRENT,
  data
})
export const getWriterCurrentSuccess = data => ({
  type: GET_WRITER_CURRENT_SUCCESS,
  data
})
export const getWriterCurrentFailure = data => ({
  type: GET_WRITER_CURRENT_FAILURE,
  data
})
// WRITER WORKROOMS
export const getWriterWorkroom = (data) => ({
  type: GET_WRITER_WORKROOMS,
  data
})
export const getWriterWorkroomSuccess = data => ({
  type: GET_WRITER_WORKROOMS_SUCCESS,
  data
})
export const getWriterWorkroomFailure = data => ({
  type: GET_WRITER_WORKROOMS_FAILURE,
  data
})