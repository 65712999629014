import {
  GET_READER_APPLICATION,
  GET_READER_APPLICATION_SUCCESS,
  GET_READER_APPLICATION_FAILURE,
  GET_WRITER_APPLICATION,
  GET_WRITER_APPLICATION_SUCCESS,
  GET_WRITER_APPLICATION_FAILURE,
  GET_EDITORS_APPLICATION,
  GET_EDITORS_APPLICATION_SUCCESS,
  GET_EDITORS_APPLICATION_FAILURE,
  GET_SAVE_APPLICATION,
  GET_SAVE_APPLICATION_SUCCESS,
  GET_SAVE_APPLICATION_FAILURE,
  PATCH_WRITER_DATA,
  PATCH_WRITER_DATA_SUCCESS,
  PATCH_WRITER_DATA_FAILURE,
  PATCH_EDITOR_DATA,
  PATCH_EDITOR_DATA_SUCCESS,
  PATCH_EDITOR_DATA_FAILURE,
  PATCH_PRODUCER_DATA,
  PATCH_PRODUCER_DATA_SUCCESS,
  PATCH_PRODUCER_DATA_FAILURE,
  PATCH_SAVE_DATA,
  PATCH_SAVE_DATA_SUCCESS,
  PATCH_SAVE_DATA_FAILURE
} from "./type"

// READER
export const getReaderApplication = () => ({
  type: GET_READER_APPLICATION
})
export const getReaderApplicationSuccess = data => ({
  type: GET_READER_APPLICATION_SUCCESS,
  data
})
export const getReaderApplicationFailure = data => ({
  type: GET_READER_APPLICATION_FAILURE,
  data
})
// WRITER
export const getWriterApplication = () => ({
  type: GET_WRITER_APPLICATION
})
export const getWriterApplicationSuccess = data => ({
  type: GET_WRITER_APPLICATION_SUCCESS,
  data
})
export const getWriterApplicationFailure = data => ({
  type: GET_WRITER_APPLICATION_FAILURE,
  data
})
// EDITOR
export const getEditorApplication = () => ({
  type: GET_EDITORS_APPLICATION
})
export const getEditorApplicationSuccess = data => ({
  type: GET_EDITORS_APPLICATION_SUCCESS,
  data
})
export const getEditorApplicationFailure = data => ({
  type: GET_EDITORS_APPLICATION_FAILURE,
  data
})
// SAVE
export const getSaveApplication = () => ({
  type: GET_SAVE_APPLICATION
})
export const getSaveApplicationSuccess = data => ({
  type: GET_SAVE_APPLICATION_SUCCESS,
  data
})
export const getSaveApplicationFailure = data => ({
  type: GET_SAVE_APPLICATION_FAILURE,
  data
})
// PATCH WRITER
export const patchWriterData = (id, status) => ({
  type: PATCH_WRITER_DATA,
  id,
  status
})
export const patchWriterDataSuccess = data => ({
  type: PATCH_WRITER_DATA_SUCCESS,
  data
})
export const patchWriterDataFailure = data => ({
  type: PATCH_WRITER_DATA_FAILURE,
  data
})
// PATCH EDITOR
export const patchEditorData = (id, status) => ({
  type: PATCH_EDITOR_DATA,
  id,
  status
})
export const patchEditorDataSuccess = data => ({
  type: PATCH_EDITOR_DATA_SUCCESS,
  data
})
export const patchEditorDataFailure = data => ({
  type: PATCH_EDITOR_DATA_FAILURE,
  data
})
// PATCH PRODUCER
export const patchProducerData = (id, status) => ({
  type: PATCH_PRODUCER_DATA,
  id,
  status
})
export const patchProducerDataSuccess = data => ({
  type: PATCH_PRODUCER_DATA_SUCCESS,
  data
})
export const patchProducerDataFailure = data => ({
  type: PATCH_PRODUCER_DATA_FAILURE,
  data
})
// PATCH SAVE
export const patchSaveData = (id, status) => ({
  type: PATCH_SAVE_DATA,
  id,
  status
})
export const patchSaveDataSuccess = data => ({
  type: PATCH_SAVE_DATA_SUCCESS,
  data
})
export const patchSaveDataFailure = data => ({
  type: PATCH_SAVE_DATA_FAILURE,
  data
})