import React from 'react';
import { Space, Table } from 'antd';
import avatar from '../../../assets/images/avatar.svg'
import Button from '../../Button';
import moment from 'moment/moment';
import { history } from '../../../reduxStore/store';

import './style.scss';
import { connect } from 'react-redux';
import { saveUserID } from '../../../pages/AuthScreens/Login/redux/action';


const FeedbackTable = ({ saveUserID, feedbackData }) => {

    const setUserID = (id) => {
        saveUserID(id)
        history.push({pathname:`/admin/feedback_details/`})
    }

    const columns = [
        {
            title: 'ID',
            dataIndex: 'id',
            key: 'id',
            render: (_, value) => (
                <div>
                    {`ID${value.id}`}
                </div>
            )
        },
        {
            title: 'User',
            dataIndex: 'user',
            key: 'user',
            render: (_, user) => (
                <Space size="middle">
                    <img src={avatar} alt="" />
                    <p className='mb-0' >{user.user}</p>
                </Space>
            ),
        },
        {
            title: 'Date',
            key: 'created_at',
            render: (_, date) => (
                <Space size="middle" className='phone-details'>
                    <p className='mb-0'>{moment(date.created_at).format("DD MMM, YYYY")}</p>
                    <Button title='View' onClick={() => setUserID(date.id)} />
                </Space>
            ),
        },
    ];

    return (
        <Table
            columns={columns}
            dataSource={feedbackData}
            pagination={false}
            scroll={{x: 1000}}
        />
    )
}

const mapDispatchToProps = dispatch => ({
    saveUserID: data => dispatch(saveUserID(data))
})

export default connect(null, mapDispatchToProps)(FeedbackTable)