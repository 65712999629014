import React, { useEffect, useState } from "react"
import ChaptersTable from "../../../components/AdminComponents/ChaptersTable"
import AdminLayout from "../../../layout/AdminLayout"
import filterIcon from "../../../assets/images/filter.svg"
import filterActiveIcon from "../../../assets/images/filter-blue.svg"
import "./style.scss"
import Button from "../../../components/Button"
import ChaptersModals from "../../../components/AdminComponents/ChaptersModals"
import { getAdminChaptersData } from "./redux/action"
import { connect } from "react-redux"
import { setConcatChapterss } from "./redux/action"

const Chapters = props => {
  const { getUserRequest, setConcatChapterss, requesting } = props

  const [showFilters, setShowFilters] = useState(false)
  const [modalShow, setModalShow] = useState(false)
  const [search, setSearch] = useState("")
  const [param, setParam] = useState("")
  const [show, setShow] = useState(false)
  const [updateData, setUpdateData] = useState({})

  const [filters, setFilters] = useState({
    reader: false,
    writer: false,
    editor: false,
    producer: false
  })


  useEffect(() => {
    if (param == "") {
      getUserRequest("", "", 1)
      setConcatChapterss(false)
    } else {
      param && getUserRequest(param, search, 1)
      setConcatChapterss(false)
    }
  }, [param])

  useEffect(() => {
    if (!search) {
      getUserRequest("", "", 1)
      setConcatChapterss(false)
    } else {
      search && getUserRequest(param, search, 1)
      setConcatChapterss(false)
    }
  }, [search])

  return (
    <>
      <AdminLayout
        onChange={setSearch}
        headerName={"Story Chapters"}
        searchBarWrapper={true}
      >
        <>
          <div className="content-section admin-users-section">
            <div className="admin-users-wrapper">
              <div className="container-fluid admin-users-container">
                <div className="row admin-users-row">
                  <div className="table-wrapper">
                    <div className="users-title-wrapper">
                      <div className="title-wrapper">
                        <h3>All Chapters</h3>
                      </div>
                    </div>
                    <ChaptersTable
                      setModal={setModalShow}
                      setUpdateData={setUpdateData}
                      setShow={setShow}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <ChaptersModals
            update={show}
            updateData={updateData}
            setUpdateData={setUpdateData}
            modalShow={modalShow}
            setShow={setShow}
            setModalShow={setModalShow}
          />
        </>
      </AdminLayout>
    </>
  )
}

const mapStateToProps = state => ({
  userInfo: state.adminUsers.userInfo,
  requesting: state.adminUsers.requesting,
  error: state.adminUsers.error,
  currentPage: state.adminUsers.currentPage,
  concatUsers: state.adminUsers.concatUsers
})

const mapDispatchToProps = dispatch => ({
  getUserRequest: (data, search, page) =>
    dispatch(getAdminChaptersData(data, search, page)),
  setConcatChapterss: data => dispatch(setConcatChapterss(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Chapters)
