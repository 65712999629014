import casualBook from "../assets/images/casual-book.svg"
import activeBook from "../assets/images/active-book.svg"
import excitedBook from "../assets/images/excited-book.svg"
import bingeBook from "../assets/images/binge-book.svg"
import listCheckIcon from "../assets/images/list-check.svg"
import homeIcon from "../assets/images/home-icon.svg"
import homeIconActive from "../assets/images/home-icon-active.svg"
import profileIcon from "../assets/images/profile.svg"
import profileIconActive from "../assets/images/profile-icon-active.svg"
import bookIcon from "../assets/images/book.svg"
import bookIconActive from "../assets/images/book-icon-active.svg"
import writerIcon from "../assets/images/writer.svg"
import writerIconActive from "../assets/images/writer-blue.svg"
import tokensIcon from "../assets/images/tokens.svg"
import tokensIconActive from "../assets/images/tokens-icon-active.svg"
import favoriteIcon from "../assets/images/favorites.svg"
import favoriteIconActive from "../assets/images/favorites-icon-active.svg"
import message from "../assets/images/mail-icon.svg"
import messageActive from "../assets/images/mail-icon-active.svg"
import editor from "../assets/images/editor.svg"
import edotorActive from "../assets/images/editor-active.svg"
import application from "../assets/images/doc-icon.svg"
import activeApp from "../assets/images/doc-icon-active.svg"
import producer from "../assets/images/producer.svg"
import produceActive from "../assets/images/producer-active.svg"
import activeStory from "../assets/images/active stories.svg"
import review from "../assets/images/review.svg"
import storyActive from "../assets/images/storyActive.svg"
import reviewActive from "../assets/images/reviewActive.svg"
import dummyPic from "../assets/images/dummyPic.png"
import pushNotifications from "../assets/images/push-notofications.svg";
import pushNotificationsActive from "../assets/images/push-notifications-active.svg";
import finances from "../assets/images/finances.svg";
import financesActive from "../assets/images/finances-active.svg";
import adminUsers from "../assets/images/users.svg";
import adminUsersActive from "../assets/images/usersActive.svg";
import helpIcon from '../assets/images/question.svg';
import helpIconAtive from '../assets/images/question.svg';
import Logo from "../assets/images/mainLogo.png";


export const Images = {
  casualBook,
  activeBook,
  excitedBook,
  bingeBook,
  listCheckIcon,
  homeIcon,
  profileIcon,
  bookIcon,
  writerIcon,
  tokensIcon,
  favoriteIcon,
  homeIconActive,
  profileIconActive,
  bookIconActive,
  writerIconActive,
  tokensIconActive,
  favoriteIconActive,
  message,
  editor,
  application,
  activeApp,
  edotorActive,
  messageActive,
  producer,
  produceActive,
  activeStory,
  review,
  storyActive,
  reviewActive,
  dummyPic,
  pushNotifications,
  pushNotificationsActive,
  finances,
  financesActive,
  adminUsers,
  adminUsersActive,
  helpIconAtive,
  helpIcon,
  Logo
}
