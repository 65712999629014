import React, { useEffect } from "react"

import backIcon from "../../../assets/images/less-white-icon.svg"
import { useHistory } from "react-router-dom"

import "./style.scss"
import { getPrivacyRequest } from "./redux/action"
import { connect } from "react-redux"
import { Spinner } from "reactstrap"

const PrivacyPolicy = props => {
  const { getPrivacyRequest, privacyData, requesting } = props
  const history = useHistory()

  useEffect(() => {
    getPrivacyRequest()
  }, [])

  return (
    <>
      {requesting ? (
        <div className="spinner-style">
          <Spinner color="primary" />
        </div>
      ) : (
        <section className="landing-page-section policy-page-section">
          <div className="container-fluid landing-content-container">
            <div className="row landing-content-row policy-content-row">
              <div className="col-md-12">
                <div className="policy-wrapper">
                  <div className="header">
                    <div className="back-icon" onClick={() => history.goBack()}>
                      <img src={backIcon} alt="" />
                    </div>
                    <h2>Story Host</h2>
                    <h3>Privacy Policy</h3>
                  </div>

                  {privacyData.length > 0 ? (
                    privacyData.map(item => (
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item.body
                        }}
                      ></div>
                    ))
                  ) : (
                    <div>No data available</div>
                  )}
                  {/* <div className="policy-content">
                  <div className="policy-details">
                    <h3>Lorem Ipsum</h3>
                    <p>
                      Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
                      sed diam nonummy nibh euismod tincidunt ut laoreet dolore
                      magna aliquam erat volutpat. Ut wisi enim ad minim veniam,
                      quis nostrud exerci tation ullamcorper suscipit lobortis
                      nisl ut aliquip ex ea commodo consequat. Duis autem vel
                      eum iriure dolor in hendrerit in vulputate velit esse
                      molestie consequat, vel illum dolore eu feugiat nulla
                      facilisis at vero eros et accumsan et iusto odio dignissim
                      qui blandit praesent luptatum zzril delenit augue duis
                      dolore te feugait nulla facilisi. Lorem ipsum dolor sit
                      amet, consectetuer adipiscing elit, sed diam nonummy nibh
                      euismod tincidunt ut laoreet dolore magna aliquam erat
                      volutpat. Ut wisi enim ad minim veniam, quis nostrud
                      exerci tation ullamcorper suscipit lobortis nisl ut
                      aliquip ex ea commodo consequat. Duis autem vel eum iriure
                      dolor in hendrerit in vulputate velit esse molestie
                      consequat, vel illum dolore eu feugiat nulla facilisis at
                      vero eros et. Duis autem vel eum iriure dolor in hendrerit
                      in vulputate velit esse molestie consequat, vel illum
                      dolore eu feugiat nulla facilisis at vero eros et.
                    </p>
                    <h3>Lorem Ipsum</h3>
                    <p>
                      Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
                      sed diam nonummy nibh euismod tincidunt ut laoreet dolore
                      magna aliquam erat volutpat. Ut wisi enim ad minim veniam,
                      quis nostrud exerci tation ullamcorper suscipit lobortis
                      nisl ut aliquip ex ea commodo consequat. Duis autem vel
                      eum iriure dolor in hendrerit in vulputate velit esse
                      molestie consequat, vel illum dolore eu feugiat nulla
                      facilisis at vero eros et. Duis autem vel eum iriure dolor
                      in hendrerit in vulputate velit esse molestie consequat,
                      vel illum dolore eu feugiat nulla facilisis at vero eros
                      et.
                    </p>
                    <h3>Lorem Ipsum</h3>
                    <p>
                      Lorem ipsum dolor sit amet, consectetuer adipiscing elit,
                      sed diam nonummy nibh euismod tincidunt ut laoreet dolore
                      magna aliquam erat volutpat. Ut wisi enim ad minim veniam,
                      quis nostrud exerci tation ullamcorper suscipit lobortis
                      nisl ut aliquip ex ea commodo consequat. Duis autem vel
                      eum iriure dolor in hendrerit in vulputate velit esse
                      molestie consequat, vel illum dolore eu feugiat nulla
                      facilisis at vero eros et. Ut wisi enim ad minim veniam,
                      quis nostrud exerci tation ullamcorper suscipit lobortis
                      nisl ut aliquip ex ea commodo consequat. Duis autem vel
                      eum iriure dolor in hendrerit in vulputate velit esse
                      molestie consequat, vel illum dolore eu feugiat nulla
                      facilisis at vero eros et accumsan et iusto odio dignissim
                      qui blandit praesent luptatum zzril delenit augue duis
                      dolore te feugait nulla facilisi.
                    </p>
                  </div>
                </div> */}
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  )
}

const mapStateToProps = state => ({
  privacyData: state.PrivacyPolicy.privacyData,
  requesting: state.PrivacyPolicy.requesting,
  error: state.PrivacyPolicy.error
})

const mapDispatchToProps = dispatch => ({
  getPrivacyRequest: () => dispatch(getPrivacyRequest())
})

export default connect(mapStateToProps, mapDispatchToProps)(PrivacyPolicy)