import { combineReducers } from "redux"
import priceCard from "../components/JoinClub/components/PriceCard/redux/reducer"
import personalInfo from "../components/JoinClub/components/Personal/redux/reducer"
import paymentInfo from "../components/JoinClub/components/Payment/redux/reducer"
import login from "../pages/AuthScreens/Login/redux/reducer"
import home from "../pages/HomeScreens/ReaderHome/redux/reducer"
import landing from "../pages/AuthScreens/Landing/redux/reducer"
import forgotPassword from "../pages/ForgotPassword/redux/reducer"
import confirmPassword from "../pages/ConfirmPassword/redux/reducer"
import tokenStore from "../pages/HomeScreens/TokenStore/redux/reducer"
import CurrentNovels from "../pages/HomeScreens/CurrentNovels/redux/reducer"
import myProfile from "../pages/HomeScreens/MyProfile/redux/reducer"
import Favorites from "../pages/HomeScreens/Favorites/redux/reducer"
import PrivacyPolicy from "../pages/AuthScreens/PrivacyPolicy/redux/reducer"
import becomeWriter from "../pages/HomeScreens/BecomeWriter/redux/reducer"
import TermsConditions from "../pages/AuthScreens/TermsConditions/redux/reducer"
import writerHome from "../pages/WriterHome/redux/reducer"
import editorHome from "../pages/EditorHome/redux/reducer"
import workRoom from "../pages/Workroompage/redux/reducer"
import { persistReducer } from "redux-persist"
import storage from "redux-persist/lib/storage"
import selectAccount from "../pages/HomeScreens/SelectAccount/redux/reducer"
import feedback from "../pages/HomeScreens/Feedback/redux/reducer"
import producerHome from "../pages/ProducerHome/redux/reducer"
import openApplications from "../pages/OpenApplications/redux/reducer"
import PubNubChat from "../pages/PubNubChat/redux/reducer"
import createNovel from "../pages/CreateNovel/redux/reducer"
import createChapter from "../pages/CreateChapter/redux/reducer"
import adminUsers from "../pages/AdminScreens/Users/redux/reducer";
import adminHome from "../pages/AdminScreens/AdminHome/redux/reducer";
import adminApplications from "../pages/AdminScreens/AdminApplications/redux/reducer";
import adminFeeback from "../pages/AdminScreens/AdminFeedback/redux/reducer"
import adminFinances from "../pages/AdminScreens/AdminFinances/redux/reducer"
import pushNotifications from '../pages/AdminScreens/PushNotifications/redux/reducer'
import readingPerspective from '../pages/HomeScreens/CharacterPerspective/redux/reducer'
import NewChapterModal from '../components/NewChapterModal/redux/reducer'
import adminChapters from "../pages/AdminScreens/Chapters/redux/reducer";

const presistConfig = {
  key: "login",
  storage
}

export const combinedReducers = combineReducers({
  login: persistReducer(presistConfig, login),
  priceCard,
  personalInfo,
  paymentInfo,
  home,
  landing,
  forgotPassword,
  confirmPassword,
  tokenStore,
  CurrentNovels,
  myProfile,
  Favorites,
  PrivacyPolicy,
  becomeWriter,
  TermsConditions,
  writerHome,
  editorHome,
  workRoom,
  selectAccount,
  feedback,
  producerHome,
  openApplications,
  PubNubChat,
  createNovel,
  createChapter,
  adminUsers,
  adminChapters,
  adminHome,
  adminApplications,
  adminFeeback,
  adminFinances,
  pushNotifications,
  readingPerspective,
  NewChapterModal
})
