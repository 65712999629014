import { all, call, put, takeLatest } from "redux-saga/effects"
import { BASE_URL } from "../../../../config/app"
import XHR from "../../../../utils/XHR"
import {
    getAdminFeedbackSuccess,
    getAdminFeedbackFailure,
    getFeedbackByIdSuccess,
    getFeedbackByIdFailure,
    putAdminFeedbackSuccess,
    putAdminFeedbackFailure,
} from "./action"
import { GET_ADMIN_FEEDBACK, GET_FEEDBACK_BY_ID, PUT_ADMIN_FEEDBACK } from "./type"
import toast from "react-hot-toast";


const adminFeedbackDataAPI = async (data = "") => {
    const URL = `${BASE_URL}api/v1/feedback/?user__name=${data}`
    const token = localStorage.getItem("token")
    const options = {
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `Token ${token}`,
        },
        method: "GET",
    }
    return XHR(URL, options);
}

const feedbackDataAPI = async (id) => {
    const URL = `${BASE_URL}api/v1/feedback/${id}/`
    const token = localStorage.getItem("token")
    const options = {
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `Token ${token}`,
        },
        method: "GET",
    }
    return XHR(URL, options);
}

function* adminFeedbackData({ data }) {
    try {
        const response = yield call(adminFeedbackDataAPI, data);
        yield put(getAdminFeedbackSuccess(response.data));
    } catch (e) {
        const { response } = e;
        yield put(getAdminFeedbackFailure(response.data));
    }
}

function* feedbackData({ id }) {
    try {
        const response = yield call(feedbackDataAPI, id);
        yield put(getFeedbackByIdSuccess(response.data));
    } catch (e) {
        const { response } = e;
        yield put(getFeedbackByIdFailure(response.data));
    }
}


const adminFeedbackPutAPI = async (id, data) => {
    const URL = `${BASE_URL}api/v1/feedback/${id}/`
    const token = localStorage.getItem("token")
    const options = {
        headers: {
            "Accept": "application/json",
            "Content-Type": "application/json",
            "Authorization": `Token ${token}`
        },
        method: "PUT",
        data,
    }
    return XHR(URL, options);
}

function* adminFeedbackPutData({ id, data, setShowReplyFeedback, setReplyFeedback }) {
    try {
        const response = yield call(adminFeedbackPutAPI, id, data);
        yield put(putAdminFeedbackSuccess(response.data));
        toast.success('Feedback Sent Successfully', {
            id: 'card fields',
        });
        setShowReplyFeedback(false);
        setReplyFeedback('')
    } catch (e) {
        const { response } = e;
        yield put(putAdminFeedbackFailure(response.data));
        toast.error('Something Went Wrong', {
            id: 'card fields',
        });
    }
}


export default all([
    takeLatest(GET_ADMIN_FEEDBACK, adminFeedbackData),
    takeLatest(GET_FEEDBACK_BY_ID, feedbackData),
    takeLatest(PUT_ADMIN_FEEDBACK, adminFeedbackPutData),
]);
