import React, { useState } from "react"
import mailIcon from "../../../assets/images/mail-icon.svg"
import textAreaWriteIcon from "../../../assets/images/textarea-write.svg"
import writerSideImg from "../../../assets/images/feedback-side-img.svg"
import Input from "../../../components/Input"
import HomeLayout from "../../../layout/HomeLayout"
import Button from "../../../components/Button"
import "./style.scss"
import { connect } from "react-redux"
import { postFeedback } from "./redux/action"
import { Toaster } from "react-hot-toast"

const Feedback = props => {
  const [feedbackMessage, setFeedbackMessage] = useState("")
  const [errorMsg, setErrorMsg] = useState(false)
  const { postFeedback, loginInfo, requesting } = props

  const sendFeedback = () => {
    const data = {
      message: feedbackMessage,
      email: loginInfo.user.email
    }
    if (!feedbackMessage) {
      setErrorMsg("Message is missing")
      return
    }
    postFeedback(data)
    setFeedbackMessage("")
    setErrorMsg(false)
  }

  return (
    <HomeLayout headerName={"Support/Send Feedback"}>
      <section className="content-section feedback-section">
        {/* <div>
          <Toaster position="top-center" />
        </div> */}
        <div className="feedback-details-section">
          <div className="container-fluid information-wrapper">
            <h3>Input your information:</h3>
            <div className="input-row">
              <div className="input-row">
                <Input
                  showIcon={true}
                  src={mailIcon}
                  placeholder="Enter Email Address"
                  type="email"
                  value={loginInfo.user.email}
                  disabled={true}
                />
              </div>
            </div>
            <div className="text-area-field">
              <span>
                <img src={textAreaWriteIcon} />
              </span>
              <textarea
                name="textarea"
                placeholder="Message"
                value={feedbackMessage}
                onChange={val => setFeedbackMessage(val.target.value)}
                onFocus={() => setErrorMsg('')}
              ></textarea>
              <div className={`error-message ${errorMsg ? 'opacity-1' : ''}`}>{errorMsg}</div>
            </div>
            <div className="button-wrapper">
              <Button
                title="Send Feedback"
                showSpinner={requesting}
                onClick={sendFeedback}
              />
            </div>
          </div>
        </div>
        <div className="side-img">
          <img src={writerSideImg} alt="Image" />
        </div>
      </section>
    </HomeLayout>
  )
}

const mapStateToProps = state => ({
  loginInfo: state.login.loginInfo,
  requesting: state.feedback.requesting
})

const mapDispatchToProps = dispatch => ({
  postFeedback: data => dispatch(postFeedback(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(Feedback)
