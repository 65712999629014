import React from "react"

import "./style.scss"
import Skeleton from "react-loading-skeleton"

const AnalyticsDetailCard = ({
  key,
  iconImg,
  title,
  subtitle,
  hoverTitle,
  isLoading
}) => {
  return isLoading ? (
    <div className="analytic-card-wrapper" key={key}>
      <div className="analytic-card">
        <div className="icon-wrapper">
          <img src={iconImg} alt="" />
        </div>

        <div className="analytic-details">
          <div className="title">
            <Skeleton height={10} width={133.848} />
          </div>
          <div className="sub-title">
            <div className="sub-title">{subtitle}</div>
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="analytic-card-wrapper" key={key}>
      <div className="analytic-card">
        <div className="icon-wrapper">
          <img src={iconImg} alt="" />
        </div>
        <div className="analytic-details">
          <div className="title" title={hoverTitle}>
            {title}
          </div>
          <div className="sub-title">{subtitle}</div>
        </div>
      </div>
    </div>
  )
}

export default AnalyticsDetailCard
