import { all, call, put, takeLatest } from "redux-saga/effects"
import { BASE_URL } from "../../../../config/app"
import XHR from "../../../../utils/XHR"
import {
  GET_ADMIN_PUSH_NOTIFICATION,
  POST_USER_PUSH_NOTIFICATION,
  GET_ADMIN_GROUP,
  POST_ADMIN_GROUP,
  PUT_FOR_ACTIVE,
  DELETE_ADMIN_NOTIFICATION
} from "./type"
import {
  getAdminPushNotification,
  getAdminPushNotificationSuccess,
  getAdminPushNotificationFailure,
  postUserPushNotificationSuccess,
  postUserPushNotificationFailure,
  getAdminGroupSuccess,
  getAdminGroupFailure,
  postAdminGroupSuccess,
  postAdminGroupFailure,
  putRequestActiveSuccess,
  putRequestActiveFailure,
  deleteNotificationSuccess,
  deleteNotificationFailure,
  concatNotification
} from "./action"
import toast from "react-hot-toast"
import { setCurrentPages } from "../../Users/redux/action"

async function getGroupAPI(name = "") {
  const URL = `${BASE_URL}api/v1/notification/group/?name=${name}`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

async function postGroupAPI(data) {
  const URL = `${BASE_URL}api/v1/notification/group/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "POST",
    data
  }
  return XHR(URL, options)
}

async function getPushNotificationAPI(name = "", page = 1) {
  const URL = `${BASE_URL}api/v1/notification/get_notification_list/?group__name=${name}&page=${page}`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    }
  }
  return XHR(URL, options)
}

async function userPushNotificationAPI(data) {
  const URL = `${BASE_URL}api/v1/notification/push_notification/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "POST",
    data
  }
  return XHR(URL, options)
}

async function activeInactiveAPI(data, id) {
  const URL = `${BASE_URL}api/v1/notification/push_notification/${id}/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "PATCH",
    data
  }
  return XHR(URL, options)
}

async function deleteAPI(id) {
  const URL = `${BASE_URL}api/v1/notification/get_notification_list/${id}/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "DELETE"
  }
  return XHR(URL, options)
}

function* userPushNotification({ data, setShowModal, setModalShow, nType }) {
  try {
    const response = yield call(userPushNotificationAPI, data)
    yield put(postUserPushNotificationSuccess(response.data))
    if(nType == "send_now"){
      toast.success("Successfully Sent", {
        id: "card fields"
      })
    }else{
      toast.success("Successfully Set", {
        id: "card fields"
      })
    }
    setShowModal(false)
    setModalShow(false)
    getAdminPushNotification("", 1)
  } catch (e) {
    const { response } = e
    yield put(postUserPushNotificationFailure(response?.data))
  }
}

function* getPushNotification({ data, page }) {
  try {
    const response = yield call(getPushNotificationAPI, data, page)
      yield put(getAdminPushNotificationSuccess(response.data.results))
      yield put(setCurrentPages(response.data))
  } catch (e) {
    const { response } = e
    yield put(getAdminPushNotificationFailure(response?.data))
  }
}

function* getAdminGroup({ data }) {
  try {
    const response = yield call(getGroupAPI, data)
    yield put(getAdminGroupSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(getAdminGroupFailure(response?.data))
  }
}

function* postAdminGroup({ data, setShowModal }) {
  try {
    const response = yield call(postGroupAPI, data)
    yield put(postAdminGroupSuccess(response.data))
    setShowModal("selectGroup")
    toast.success("Group Created", {
      id: "card fields"
    })
  } catch (e) {
    const { response } = e
    yield put(postAdminGroupFailure(response?.data))
  }
}

function* patchActive({ data, id }) {
  try {
    const response = yield call(activeInactiveAPI, data, id)
    yield put(putRequestActiveSuccess(response.data))
    yield put(getAdminPushNotification("", 1))
    toast.success(response.data.msg, {
      id: "card fields"
    })
  } catch (e) {
    const { response } = e
    yield put(putRequestActiveFailure(response?.data))
    toast.error("Something went wrong", {
      id: "card fields"
    })
  }
}

function* deleteRequest({ data }) {
  try {
    const response = yield call(deleteAPI, data)
    yield put(deleteNotificationSuccess(response.data))
    toast.success("Deleted Successful", {
      id: "card fields"
    })
    yield put(getAdminPushNotification("", 1))
  } catch (e) {
    const { response } = e
    yield put(deleteNotificationFailure(response?.data))
    toast.error("Something went wrong", {
      id: "card fields"
    })
  }
}

export default all([
  takeLatest(GET_ADMIN_PUSH_NOTIFICATION, getPushNotification),
  takeLatest(POST_USER_PUSH_NOTIFICATION, userPushNotification),
  takeLatest(GET_ADMIN_GROUP, getAdminGroup),
  takeLatest(POST_ADMIN_GROUP, postAdminGroup),
  takeLatest(PUT_FOR_ACTIVE, patchActive),
  takeLatest(DELETE_ADMIN_NOTIFICATION, deleteRequest)
])
