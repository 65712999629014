import {
    GET_READER_CLUB_DATA, 
    GET_READER_CLUB_SUCCESS,
    GET_READER_CLUB_FAILURE,
    POST_READER_CLUB_DATA,
    POST_READER_CLUB_SUCCESS,
    POST_READER_CLUB_FAILURE,
    SAVE_PLAN_ID,
   } from "./type";

   const initialState = {
    readerData: [],
    requesting: false,
    error: false,
    planId: false
  };

  export default (state = initialState, action) => {
    switch (action.type) {
      case GET_READER_CLUB_DATA:
        return {
          ...state,
          requesting: true,
        };
      case GET_READER_CLUB_SUCCESS:
        return {
          ...state,
          requesting: false,
          readerData: action.data,
        };
        case SAVE_PLAN_ID: 
        return {
          ...state,
          planId: action.planId
        };
      case GET_READER_CLUB_FAILURE:
        return {
          ...state,
          requesting: false,
          error: action.data,
        };
      case POST_READER_CLUB_DATA:
        return {
          ...state,
          requesting: true,
        };
      case POST_READER_CLUB_SUCCESS:
        return {
          ...state,
          requesting: false,
        };
      case POST_READER_CLUB_FAILURE:
        return {
          ...state,
          requesting: false,
          error: action.data,
        };
      default:
        return state;
    }
  };
  
  
  
  
  
  
  
  