import { 
    POST_BECOME_WRITER,
    POST_BECOME_WRITER_SUCCESS,
    POST_BECOME_WRITER_FAILURE,
    GET_BECOME_WRITER_DATA,
    GET_BECOME_WRITER_DATA_SUCCESS,
    GET_BECOME_WRITER_DATA_FAILURE,
    GET_BECOME_NOVEL_DATA,
    GET_BECOME_NOVEL_DATA_SUCCESS,
    GET_BECOME_NOVEL_DATA_FAILURE
} from "./type";

const initialState = {
    data: false,
    datarequesting: false,
   
    error: false,
    becomeWriterData: false,
    requesting:false,

    becomeNovel: false,
    becomeNovelLoad: false,
    becomeNovelError: false
  }
  
  export default (state = initialState, action) => {
    switch (action.type) {
      case POST_BECOME_WRITER:
        return {
          ...state,
          datarequesting: true
        }
      case POST_BECOME_WRITER_SUCCESS:
        return {
          ...state,
          datarequesting: false,
          data: action.data,
          error: false
        }
      case POST_BECOME_WRITER_FAILURE:
        return {
          ...state,
          datarequesting: false,
          error: action.data
        }
        case GET_BECOME_WRITER_DATA:
          return {
            ...state,
            requesting: true
          }
          case GET_BECOME_WRITER_DATA_SUCCESS:
            return {
              ...state,
              requesting: false,
              becomeWriterData: action.data
            }
            case GET_BECOME_WRITER_DATA_FAILURE:
              return {
                ...state,
                requesting: false,
                error: action.data
              }
              case GET_BECOME_NOVEL_DATA:
          return {
            ...state,
            becomeNovelLoad: true
          }
          case GET_BECOME_NOVEL_DATA_SUCCESS:
            return {
              ...state,
              becomeNovelLoad: false,
              becomeNovel: action.data
            }
            case GET_BECOME_NOVEL_DATA_FAILURE:
              return {
                ...state,
                becomeNovelLoad: false,
                becomeNovelError: action.data
              }
      default:
        return state;
    }
  }
  