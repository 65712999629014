import React, { useRef } from "react"
import Input from "../../../Input"
import staticImg from "../../../../assets/images/static-img.png"
import pencilIcon from "../../../../assets/images/textarea-write.svg"
import TextArea from "../../../TextArea"
import Search from "../../../Search"
import { Toaster } from "react-hot-toast"

const GroupChat = props => {
  const {
    groupName,
    setGroupName,
    groupDisc,
    setGroupDisc,
    onPictureChange,
    groupImage
  } = props

  const fileRef = useRef(null)

  const handleUploadClick = () => {
    fileRef.current?.click()
    return false
  }

  return (
    <>
      <Toaster position="top-center" />
      <section className="group-chat-section">
        <div className="group-photo-wrapper">
          <div className="group-photo">
            <img
              src={
                groupImage
                  ? groupImage
                  : "https://cdn.pixabay.com/photo/2017/11/10/05/46/group-2935521_960_720.png"
              }
              onClick={handleUploadClick}
            />
            <input
              name="file"
              type="file"
              ref={fileRef}
              onChange={onPictureChange}
              hidden
              value=""
            />
          </div>
          <div className="text">Upload Group Photo</div>
        </div>
        <div className="group-creation-wrapper">
          <div className="group-input-wrapper">
            <Input
              type="text"
              placeholder="Create Group Name"
              showIcon={true}
              src={pencilIcon}
              value={groupName}
              onChange={e => setGroupName(e.target.value)}
              errorMessage="Please"
            />
            <TextArea
              placeholder="Create Group Description"
              value={groupDisc}
              onChange={e => setGroupDisc(e.target.value)}
            />
          </div>
        </div>
        <Search placeholder="Search to Add Recipients" isGroupChat={true} />
      </section>
    </>
  )
}

export default GroupChat
