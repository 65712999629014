import React, { useEffect, useState } from "react"
import ImageCard from "../../components/ImageCard"
import HomeLayout from "../../layout/HomeLayout"
import { connect } from "react-redux"
import { getProducerHome, setProducerIndex, getNovelDataSuccess } from "./redux/action"
import ProducerModal from "../../components/ProducerModal"
import { history } from "../../reduxStore/store"
import { setUpdateNovelID } from "../AuthScreens/Login/redux/action"

const ProducerHome = props => {
  const { getProducerHome, producerData, setProducerIndex, requesting, setUpdateNovelID, getNovelDataSuccess } = props
  const [modalShow, setModalShow] = useState(false)
  const [activeCardFlex, setActiveCardFlex] = useState(false)

  useEffect(() => {
    getProducerHome()
    setUpdateNovelID(false)
    getNovelDataSuccess(false)
  }, [])

  const openHomeModal = index => {
    setProducerIndex(index)
    setModalShow(true)
  }

  const filterBySearch = value => {
    getProducerHome(value)
  }

  return (
    <HomeLayout
      headerName="Home"
      className="producer-header-section"
      showSreachAndButton={true}
      onClick={() => history.push("/producer/create_novel")}
      cardFlex={activeCardFlex}
      setCardFlex={setActiveCardFlex}
      proOnChange={filterBySearch}
    >
      <section className="content-section">
        <div className="home-card-wrapper upload-wrapper">
          <div className="container-fluid home-card-container">
            <div className="section-description">
              <p>
                The producer is the only member that can setup a new novel.<br />
                Once the producer has identified the editor for the novel, both the Editor and Producer can edit the novel and enter and edit all related workrooms.  </p>
            </div>
            <h2 className="title">Active Novels</h2>
            <div className="row home-card-row">
              {requesting ? (
                [1, 2, 3, 4, 5, 6, 7].map((item, index) => (
                  <div className={`card-data`}>
                    <ImageCard isLoader={true} />
                  </div>
                ))
              ) : producerData?.length > 0 ? (
                producerData?.map((item, index) => (
                  <div
                    className={`card-data ${activeCardFlex ? "card-toggle-flex" : ""
                      }`}
                    key={item.id}
                  >
                    <ImageCard
                      src={item.thumbnail}
                      hoverName={item.name}
                      onClick={() => openHomeModal(index)}
                    />
                  </div>
                ))
              ) : (
                <p className="no-data text-center">No data</p>
              )}
            </div>
          </div>
        </div>
      </section>
      <ProducerModal
        modalShow={modalShow}
        setModalShow={setModalShow}
        homeData={producerData}
      />
    </HomeLayout>
  )
}

const mapStateToProps = state => ({
  producerData: state.producerHome.data,
  requesting: state.producerHome.requesting,
  error: state.producerHome.error
})

const mapDispatchToProps = dispatch => ({
  getProducerHome: data => dispatch(getProducerHome(data)),
  setProducerIndex: data => dispatch(setProducerIndex(data)),
  setUpdateNovelID: data => dispatch(setUpdateNovelID(data)),
  getNovelDataSuccess: data => dispatch(getNovelDataSuccess(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(ProducerHome)
