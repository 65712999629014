import React from 'react';
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import './style.scss';
import { connect } from 'react-redux';
import { saveMemberID } from '../../pages/AuthScreens/Login/redux/action';
import { history } from '../../reduxStore/store';


const BookDetailCard = ({
    key,
    bookName,
    characterName,
    writer, 
    headTitle,
    paraTitle,
    onClick,
    className,
    isLoader,
    chapter,
    loginInfo,
    data,
    author = '',
    saveMemberID
}) => {
    const onSaveData = () => {
        if(data){
            saveMemberID(data)
            history.push({pathname:`/${loginInfo.user.mode}/member_profile/${author.toLocaleLowerCase().replace(/\s+/g, '_')}`})
        }
    }
    return (
        <div className={`book-detail-card-wrapper ${className}`} key={key} onClick={onClick}>
            <div className="card-details">
                <div className="card-description">
                    <h3 title={headTitle} > {isLoader ? <Skeleton highlightColor='#ddd' width={100} /> : bookName}</h3>
                    <p className='para-title' title={paraTitle}>{isLoader ? <Skeleton highlightColor='#ddd' width={150} /> : characterName}</p>
                    <a onClick={()=>onSaveData()} className='details'> {isLoader ? (<Skeleton highlightColor='#ddd'  width={200} />) : `${chapter} Written by ${writer}`}</a>
                </div>
            </div>
        </div>
    )
}
const mapStateToProps = (state) => ({
    loginInfo: state.login.loginInfo
})

const mapDispatchToProps = dispatch => ({
    saveMemberID: data => dispatch(saveMemberID(data)) 
})

export default connect(mapStateToProps, mapDispatchToProps)(BookDetailCard)
