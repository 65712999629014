import { all, call, put, takeLatest } from "redux-saga/effects"
import { BASE_URL } from "../../../../config/app"
import XHR from "../../../../utils/XHR"
import {
  getCardRequestSuccess,
  getCardRequestFailure,
  getActiveCardSuccess,
  getActiveCardFailure,
  postCardRequestSuccess,
  postCardRequestFailure,
  patchActiveCardRequestSuccess,
  patchActiveCardRequestFailure,
  getCardRequest,
  getActiveCardRequest,
  deleteCardRequestSuccess,
  deleteCardRequestFailure,
  postNewCardRequestSuccess,
  postNewCardRequestFailure,
} from "./action"
import { GET_CARD_DATA, GET_ACTIVE_CARD_DATA, POST_CARD_DATA, PATCH_ACTIVE_CARD_DATA, DELETE_CARD_DATA ,POST_NEW_CARD_DATA} from "./type"
import toast from "react-hot-toast"

// Card API Saga
const getCardDataAPI = async () => {
  const URL = `${BASE_URL}api/v1/cards/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

// Active Card API Saga
const getActiveCardDataAPI = async () => {
  const URL = `${BASE_URL}api/v1/cards/active/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

// POST CARD API
const postCardDataAPI = async (data) => {
  const URL = `${BASE_URL}api/v1/cards/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "POST",
    data
  }
  return XHR(URL, options)
}
// POST NEW CARD API
const postNewCardDataAPI = async (data) => {
  const URL = `${BASE_URL}api/v1/add_card/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "POST",
    data
  }
  return XHR(URL, options)
}
// PATCH CARD API
const patchActiveCardAPI = async (data) => {
  const URL = `${BASE_URL}api/v1/cards/modify_card/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "PATCH",
    data
  }
  return XHR(URL, options)
}

// DELETE CARD API
const deleteCardAPI = async (data) => {
  const URL = `${BASE_URL}api/v1/cards/delete/`
  const token = localStorage.getItem("token")
  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "DELETE",
    data
  }
  return XHR(URL, options)
}

function* getCardData() {
  try {
    const response = yield call(getCardDataAPI)
    yield put(getCardRequestSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(getCardRequestFailure(response.data))
  }
}

function* getActiveCardData() {
  try {
    const response = yield call(getActiveCardDataAPI)
    yield put(getActiveCardSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(getActiveCardFailure(response.data))
  }
}

function* postCardData({data,setModalShow}) {
  try {
    const response = yield call(postCardDataAPI, data)
    yield put(postCardRequestSuccess(response.data))
    yield put(getCardRequest())
    yield put(getActiveCardRequest())
    setModalShow(false)
    toast.success('Card added', {
      id: 'card fields',
    })
  } catch (e) {
    const { response } = e
    yield put(postCardRequestFailure(response.data))
    toast.error(response?.data?.msg, {
      id: 'card fields',
    })
  }
}
function* postNewCardData({data,setModalShow}) {
  try {
    const response = yield call(postNewCardDataAPI, data)
    yield put(postNewCardRequestSuccess(response.data))
    yield put(getCardRequest())
    yield put(getActiveCardRequest())
    setModalShow(false)
    toast.success('Card added', {
      id: 'card fields',
    })
  } catch (e) {
    const { response } = e
    yield put(postNewCardRequestFailure(response.data))
    toast.error(response?.data?.msg, {
      id: 'card fields',
    })
  }
}

function* patchActiveCardData({data}) {
  try {
    const response = yield call(patchActiveCardAPI, data)
    yield put(patchActiveCardRequestSuccess(response.data))
    yield put(getCardRequest())
    yield put(getActiveCardRequest())
    toast.success('Account linked', {
      id: 'card fields',
    })
  } catch (e) {
    const { response } = e
    yield put(patchActiveCardRequestFailure(response.data))
  }
}

function* deleteCardData({data}) {
  try {
    const response = yield call(deleteCardAPI, data)
    yield put(deleteCardRequestSuccess(response.data))
    yield put(getCardRequest())
    toast.success('Card Deleted', {
      id: 'card fields',
    })
  } catch (e) {
    const { response } = e
    yield put(deleteCardRequestFailure(response.data))
  }
}

export default all([
  takeLatest(GET_CARD_DATA, getCardData),
  takeLatest(GET_ACTIVE_CARD_DATA, getActiveCardData),
  takeLatest(POST_CARD_DATA,postCardData),
  takeLatest(PATCH_ACTIVE_CARD_DATA,patchActiveCardData),
  takeLatest(DELETE_CARD_DATA,deleteCardData),
  takeLatest(POST_NEW_CARD_DATA,postNewCardData),
])
