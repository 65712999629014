import { all, call, put, takeLatest } from "redux-saga/effects"
import { BASE_URL } from "../../../../config/app"
import XHR from "../../../../utils/XHR"
import { getCurrentNovelFailure, getCurrentNovelSuccess } from "./action"

import { GET_CURRENT_NOVELS } from "./types"

const currentNovelDataAPI = async (name = '') => {
  const URL = `${BASE_URL}api/v1/current-novels/?novel__name=${name}`
  const token = await localStorage.getItem("token")

  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "GET"
  }
  return XHR(URL, options)
}

function* currentNovelData({name}) {
  try {
    const response = yield call(currentNovelDataAPI, name)
    yield put(getCurrentNovelSuccess(response.data))
  } catch (e) {
    const { response } = e
    yield put(getCurrentNovelFailure(response.data))
  }
}
export default all([takeLatest(GET_CURRENT_NOVELS, currentNovelData)])
