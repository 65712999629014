import {
  GET_PRODUCER_HOME,
  GET_PRODUCER_HOME_FAILURE,
  GET_PRODUCER_HOME_SUCCESS,
  SET_PRODUCER_INDEX,
  SET_CHARACTER_INDEX,
  GET_NOVEL_ID,
  GET_NOVEL_ID_SUCCESS,
  GET_NOVEL_ID_FAILURE,
  PATCH_NOVEL_REQUEST,
  PATCH_NOVEL_REQUEST_SUCCESS,
  PATCH_NOVEL_REQUEST_FAILURE,
} from "./type"

export const getProducerHome = (data) => ({
  type: GET_PRODUCER_HOME,
  data
})

export const producerHomeSuccess = data => ({
  type: GET_PRODUCER_HOME_SUCCESS,
  data
})

export const producerHomeFailure = data => ({
  type: GET_PRODUCER_HOME_FAILURE,
  data
})

// GET DATA WITH ID
export const getNovelData = (data) => ({
  type: GET_NOVEL_ID,
  data
})

export const getNovelDataSuccess = data => ({
  type: GET_NOVEL_ID_SUCCESS,
  data
})

export const getNovelDataFailure = data => ({
  type: GET_NOVEL_ID_FAILURE,
  data
})
// PATCH NOVEL DATA

export const patchNovelData = (id, data) => ({
  type: PATCH_NOVEL_REQUEST,
  id,
  data
})

export const patchNovelDataSuccess = data => ({
  type: PATCH_NOVEL_REQUEST_SUCCESS,
  data
})

export const patchNovelDataFailure = data => ({
  type: PATCH_NOVEL_REQUEST_FAILURE,
  data
})

export const setProducerIndex = data => ({
  type: SET_PRODUCER_INDEX,
  data
})

export const setCharacterIndex = data => ({
  type: SET_CHARACTER_INDEX,
  data
})