import React from 'react';
import loginSideImage from '../../../assets/images/forgot-password-img.png';
import Input from '../../../components/Input';
import emailIcon from '../../../assets/images/mail-icon.svg';
import lockIcon from '../../../assets/images/lock-icon.svg';
import Button from '../../../components/Button';
import useForm from '../../../utils/useForm'
import validator from '../../../utils/validation'
import { connect } from 'react-redux';
import { loginRequest } from '../Login/redux/action';
import { Toaster } from 'react-hot-toast';
import {history} from '../../../reduxStore/store';
import './style.scss';

const AdminLogin = (props) => {
    
    const { loginRequest, loginInfo, requesting, error} = props
    
    const stateSchema = {
        email: {
          value: "",
          error: ""
        },
        password: {
            value: "",
            error: ""
          },
      }
      const validationStateSchema = {
        email: {
          required: false,
          validator: validator.email
        },
        password: {
            required: false,
          }
      }
      const { state, handleOnChange, disable } = useForm(
        stateSchema,
        validationStateSchema
      )

        const onSubmit = () => {
          const data = {
            username: state.email.value,
            password: state.password.value
          }
          loginRequest(data)
        }

        return (
        <section className='landing-page-section admin-auth-page-section'>
            <div className="container-fluid landing-content-container">
                <div className="row auth-content-row">
                    <div className="col-lg-6">
                        <div className="landing-left-content auth-left-content">
                            <div className="content-details">
                                <h1>Admin Sign In</h1>
                                <h2>Lorem ipsum dolor sit amet consecte</h2>
                            </div>
                            <Toaster position='top-center' />
                            <div className="input-wrapper">
                                <Input
                                    type='email'
                                    showIcon={true}
                                    src={emailIcon}
                                    placeholder='Enter Email Address'
                                    value={state.email.value}
                                    onChange={(val) => handleOnChange('email', val.target.value)}
                                    showError={true}
                                    errorMessage={error.username && error.username[0]}
                                />
                                <Input
                                    type='password'
                                    showHideIcon={true}
                                    showIcon={true}
                                    src={lockIcon}
                                    placeholder='Enter Password'
                                    value={state.password.value}
                                    onChange={(val) => handleOnChange('password', val.target.value)}
                                    showError={true}
                                    errorMessage={error.password && error.password[0]}
                                />
                            </div>
                            <div className="button-wrapper">
                                <Button
                                    title="Sign In"
                                    onClick={onSubmit}
                                    showSpinner={requesting}
                                />               
                                    <Button
                                        title="Forgot Password?"
                                        className="transparent-btn"
                                        onClick={()=> history.push('/auth/forgot_password')}
                                    />            
                            </div>
                            </div>
                        </div>        
                    <div className="col-lg-6">
                        <div className="auth-right-content">
                            <img src={loginSideImage} className="img-fluid" alt="Image" />
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}

const mapStateToProps = state => ({
    loginInfo: state.login,
    requesting: state.login.requesting,
    error: state.login.error
  })
  
  const mapDispatchToProps = dispatch => ({
    loginRequest: data => dispatch(loginRequest(data)),
  })

export default  connect(mapStateToProps, mapDispatchToProps)(AdminLogin);
