import React, { useState, useEffect } from "react"
import Button from "../../../components/Button"
import HomeLayout from "../../../layout/HomeLayout"
import linkIcon from "../../../assets/images/link-icon.svg"
import linkIconBlue from "../../../assets/images/link-icon-blue.svg"
import accountSideImg from "../../../assets/images/account-side-img.svg"
import { getCardRequest, getActiveCardRequest, patchActiveCardRequest, deleteCardRequest } from "./redux/action"
import "./style.scss"
import { connect } from "react-redux"
import { Spinner } from "reactstrap"
import { firstLetterCapital } from "../../../utils/commonFunction"
import JoinClubModal from "../../../components/JoinClub/"
import toast from 'react-hot-toast'

const SelectAccount = props => {
  const {
    getActiveCardRequest,
    patchActiveCardRequest,
    deleteCardRequest,
    deleteRequesting,
    getCardRequest,
    accountCardData,
    activeCardData,
    requesting,
    patchRequesting,
    error
  } = props

  const [modalShow, setModalShow] = useState(false)
  const [checkIndex, setCheckIndex] = useState(0)

  useEffect(() => {
    getCardRequest()
    getActiveCardRequest()
  }, [])

  const addNewCard = () => {
    if(accountCardData?.data.length == 3 || accountCardData?.data.length > 3){
      toast.error("Max 3 cards are allowed. Please remove one existing card.", {
        id: "card fields"
      })
    }else{
    setModalShow(true)
    }
  }

  const patchRequest = (data, index) => {
    setCheckIndex(index)
    if(data !== activeCardData?.active_card?.id){
      patchActiveCardRequest({payment_method_id: data})
    }
  }

  const deleteCard = (id, index) => {
    setCheckIndex(index)
    deleteCardRequest({payment_method_id: id})
  }

  return (
    <HomeLayout headerName={"Link Bank Account"} showBack={true} addCard={true} addCardOnClick={addNewCard}>
      {!activeCardData && requesting ? (
        <div className="spinner-style">
          <Spinner color="primary" className="table-spinner" size="lg" />
        </div>
      ) : (
        <section className="content-section account-section">
          <div className="home-card-wrapper account-details-section">
            <div className="container-fluid home-card-container account-wrapper">
              <div className="bank-heading">
                <h3>Which Bank Account do you want to link with Story Host?</h3>
              </div>
              <div className="row home-card-row bank-cards-wrapper">
                {accountCardData?.data?.length > 0 ? (
                  accountCardData?.data?.map((item, index) => (
                    <div className="bank-card-wrapper" key={item.id}>
                      <div
                        className={`bank-card ${item.id == activeCardData?.active_card?.id ? "blue-bg" : ""}`}
                      >
                        <h4>{firstLetterCapital(item.card.brand)}</h4>
                        <p> Last 4 Digits {item.card.last4}</p>
                      </div>
                      <div className="button-wrapper">
                      {item.id !== activeCardData?.active_card?.id && (
                        <Button
                          onClick={() => deleteCard(item.id, index)}
                          showSpinner={checkIndex == index && deleteRequesting}
                          title={"Delete"}
                          className='black-btn'
                        />)}
                        <Button
                          onClick={() => patchRequest(item.id, index)}
                          title={`${item.id == activeCardData?.active_card?.id ? "UnLink" : "Link"}`}
                          showIcon={true}
                          showSpinner={item.id !== activeCardData?.active_card?.id &&
                            checkIndex == index && patchRequesting}
                          src={item.id == activeCardData?.active_card?.id ? linkIcon : linkIconBlue}
                          className={`${item.id == activeCardData?.active_card?.id ? "" : "transparent-btn"}`}
                        />
                      </div>
                    </div>
                  ))
                ) : (
                  <div
                    style={{
                      textAlign: "center",
                      fontSize: "25px",
                      fontWeight: "600"
                    }}
                  >
                    No Card
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="side-img">
            <img src={accountSideImg} alt="Image" />
          </div>
        </section>
      )}
      <JoinClubModal modalShow={modalShow} setModalShow={setModalShow} cardNoShow={true} addCard={"PaymentInfo"} />
    </HomeLayout>
  )
}

const mapStateToProps = state => ({
  accountCardData: state.selectAccount.data.cards,
  activeCardData: state.selectAccount.activeCardData,
  requesting: state.selectAccount.requesting,
  error: state.selectAccount.error,
  deleteRequesting: state.selectAccount.deleteRequesting,
  patchRequesting: state.selectAccount.patchRequest
})
const mapDispatchToProps = dispatch => ({
  getCardRequest: data => dispatch(getCardRequest(data)),
  getActiveCardRequest: () => dispatch(getActiveCardRequest()),
  patchActiveCardRequest: data => dispatch(patchActiveCardRequest(data)),
  deleteCardRequest: data => dispatch(deleteCardRequest(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(SelectAccount)
