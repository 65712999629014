import React, { useEffect, useState } from "react"
import HomeLayout from "../../../layout/HomeLayout"
import "./style.scss"
import { connect } from "react-redux"
import tokenIcon from "../../../assets/images/token-white.svg"
import bookIcon from "../../../assets/images/book white.svg"
import { getNovelChapter } from "../ReaderHome/redux/action"
import { Spinner } from "reactstrap"
import { postAwardGRT, postAwardGRTSuccess } from "./redux/action"
import {
  patchFavoriteNovel,
  updateAdventureLog
} from "../Favorites/redux/action"
import BoardAudioPlayer from "../../../components/BoardAudioPlayer"
import ReactQuill from "react-quill"
import "react-quill/dist/quill.bubble.css"
import JoinClubModal from "../../../components/JoinClub"
import Skeleton from "react-loading-skeleton"
import "react-loading-skeleton/dist/skeleton.css"
import {
  loginRequestSuccess,
  saveChapterID
} from "../../AuthScreens/Login/redux/action"
import { postPaymentInformationSuccess } from "../../../components/JoinClub/components/Payment/redux/action"
import { history } from "../../../reduxStore/store"

const ReadingPerspective = props => {
  const {
    getNovelChapter,
    patchFavoriteNovel,
    updateAdventureLog,
    postAwardGRT,
    grtRequesting,
    loginInfo,
    chapterID,
    chapterData,
    chapterLoading,
    loginRequestSuccess,
    paymentResponse,
    postPaymentInformationSuccess,
    saveChapterID,
    grtInfo,
    postAwardGRTSuccess
  } = props

  const [activeCardFlex, setActiveCardFlex] = useState(false)
  const [modalShow, setModalShow] = useState(false)
  const [checkModal, setCheckModal] = useState(false)

  useEffect(() => {
    chapterID && getNovelChapter(chapterID)
  }, [chapterID])

  useEffect(() => {
    if (grtInfo?.next_chapter_id) {
      saveChapterID(grtInfo?.next_chapter_id)
      postAwardGRTSuccess(false)
      history.push({
        pathname: `/reader/chapter/${grtInfo?.next_chapter_id}`
      })
    }
  }, [grtInfo?.next_chapter_id])

  useEffect(() => {
    if (paymentResponse?.user) {
      loginRequestSuccess({
        user: paymentResponse?.user
      })
      postPaymentInformationSuccess(false)
    }
  }, [paymentResponse?.user])

  const onSubmit = () => {
    if (!chapterData?.Unlock && chapterData?.available_grt > 0) {
      const data = {
        character_id: chapterData?.character[0]?.id || null,
        writer_id: chapterData?.character[0]?.writer?.id || null,
        chapter_id: chapterData?.next_chapter_id
      }
      postAwardGRT(data)
    } else if (chapterData?.Unlock && chapterData?.next_chapter_id) {
      saveChapterID(chapterData?.next_chapter_id)
      history.push({
        pathname: `/reader/chapter/${chapterData?.next_chapter_id}`
      })
    } else {
      setCheckModal("PriceCard")
      setModalShow(true)
    }
  }

  const updateLog = () => {
    if (loginInfo.user.mode == "reader") {
      const data = {
        character: [chapterData?.character[0]?.id]
      }
      patchFavoriteNovel(data, "", "", chapterID)
    } else {
      const data = {
        novels: [chapterData?.character[0]?.novel?.id] || [],
        authors: [chapterData?.character[0]?.novel?.author?.id] || []
      }
      updateAdventureLog(data, "", chapterID)
    }
  }

  return (
    <HomeLayout
      className="perspective-header-section"
      headerName={
        chapterLoading ? (
          <Skeleton
            highlightColor="#ddd"
            borderRadius={30}
            height={30}
            width={150}
          />
        ) : (
          <>
            <a
              style={{
                color: "#6F8BDF",
                cursor: "pointer",
                textDecoration: "underline"
              }}
              onClick={() => history.push("/reader/reading_experience")}
            >
              {chapterData?.novel_name}
            </a>
            <a style={{ textDecoration: "none" }}>•</a>
            <a
              onClick={() => history.push("/reader/reading_experience")}
              style={{
                color: "#6F8BDF",
                cursor: "pointer",
                textDecoration: "underline"
              }}
            >
              {(chapterData?.character && chapterData?.character[0]?.name) ||
                ""}
            </a>
          </>
        )
      }
      showBack={true}
      showRightWrapper={true}
      showTitle={`${
        (chapterData?.character && chapterData?.character[0]?.name) || ""
      }`}
      tokenOnClick={onSubmit}
      tokenLoader={grtRequesting}
      addFav={chapterData?.favorite}
      cardFlex={activeCardFlex}
      setCardFlex={setActiveCardFlex}
      favOnClick={updateLog}
      showAwardBtn={
        loginInfo.user.mode == "reader" && chapterData?.Unlock == null
          ? false
          : true
      }
      awardBtnImg={chapterData?.Unlock ? bookIcon : tokenIcon}
      awardBtnTitle={
        chapterData?.Unlock
          ? "Read next chapter"
          : "Award GRT to unlock next chapter"
      }
    >
      {chapterLoading ? (
        <div className="spinner-style">
          <Spinner color="primary" />
        </div>
      ) : (
        <section className="reading-section perspective-section">
          <div className="chapter-button-wrapper"></div>
          <div className="container-fluid perspective-section-container">
            <div className="top-actions d-flex justify-content-between">
              <h3>{(chapterData && chapterData?.name) || ""}</h3>
              <div className="button-wrapper d-flex">
                {chapterData?.upload_mp3 &&
                  chapterData?.upload_mp3.map(item => (
                    <BoardAudioPlayer src={item?.upload_mp3} />
                  ))}
              </div>
            </div>
            <div className="row">
              <div className="col-md-12 perspective-details-left">
                <div className="perspective-details">
                  <p>
                    {
                      <ReactQuill
                        theme="bubble"
                        value={chapterData && chapterData?.content}
                        readOnly={true}
                      />
                    }
                  </p>
                </div>
              </div>
            </div>
          </div>
        </section>
      )}
      <JoinClubModal
        modalShow={modalShow}
        setModalShow={setModalShow}
        addCard={checkModal}
        availgrt={
          loginInfo?.user?.subscription ? chapterData?.available_grt : null
        }
      />
    </HomeLayout>
  )
}

const mapStateToProps = state => ({
  chapterData: state.home.chapterData,
  chapterLoading: state.home.chapterLoading,
  chapterError: state.home.chapterError,
  chapterID: state.login.chapterID,
  nextChapterID: state.login.nextChapterID,
  grtRequesting: state.readingPerspective.grtRequesting,
  grtInfo: state.readingPerspective.grtInfo,
  loginInfo: state.login.loginInfo,
  patchData: state.Favorites.patchData,
  updateAdvData: state.Favorites.updateAdvData,
  chapterRequesting: state.readingPerspective.chapterRequesting,
  chapterError: state.readingPerspective.chapterError,
  paymentResponse: state.paymentInfo.paymentResponse
})

const mapDispatchToProps = dispatch => ({
  getNovelChapter: data => dispatch(getNovelChapter(data)),
  postAwardGRT: data => dispatch(postAwardGRT(data)),
  patchFavoriteNovel: (data, memberID, bookID, chapterID) =>
    dispatch(patchFavoriteNovel(data, memberID, bookID, chapterID)),
  updateAdventureLog: (data, bookID, chapterID) =>
    dispatch(updateAdventureLog(data, bookID, chapterID)),
  loginRequestSuccess: data => dispatch(loginRequestSuccess(data)),
  postPaymentInformationSuccess: data =>
    dispatch(postPaymentInformationSuccess(data)),
  saveChapterID: data => dispatch(saveChapterID(data)),
  postAwardGRTSuccess: data => dispatch(postAwardGRTSuccess(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(ReadingPerspective)
