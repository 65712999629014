import React from "react";
import notificationMsg from "../../../../../assets/images/notification-msg.svg";
import Button from "../../../../Button";
import { Toaster } from 'react-hot-toast';



const SelectNotification = ({ setShowModal }) => {


    return (
        <section className="add-user-section new-push-notification-section">
            <div className="add-user-header">
                <div>
                    <Toaster position='top-center' />
                </div>
                <img src={notificationMsg} alt="" />
                <h3>New Push Notification</h3>
            </div>
            <div className="button-wrapper">
                <Button title="Select User" onClick={() => setShowModal('selectUser')} />
                <Button title="Select Group"
                    className="transparent-btn"
                    onClick={() => setShowModal('selectGroup')}
                />
            </div>
        </section>
    )
}

export default SelectNotification
