import { all, call, put, takeLatest } from "redux-saga/effects"
import { BASE_URL } from "../../../../config/app"
import XHR from "../../../../utils/XHR"
import {
  postAwardGRTSuccess,
  postAwardGRTFailure,
} from "./action"
import { POST_AWARD_GRT } from "./type"
import toast from 'react-hot-toast'


const awardGRTAPI = async (data) => {
  const URL = `${BASE_URL}api/v1/award_token/`
  const token = await localStorage.getItem('token')

  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "POST",
    data
  }
  return XHR(URL, options)
}

function* awardGRTData({ data }) {
  try {
    const response = yield call(awardGRTAPI, data)
    yield put(postAwardGRTSuccess(response.data))
    toast.success(response?.data?.msg, {
      id: 'card fields'
    })
  } catch (e) {
    const { response } = e
    yield put(postAwardGRTFailure(response.data))
    toast.error(response?.data?.msg, {
      id: 'card fields'
    })
  }
}

export default all([
  takeLatest(POST_AWARD_GRT, awardGRTData),
])
