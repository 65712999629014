import React from "react"
import Input from "../../../Input"
import Button from "../../../Button"
import searchIcon from "../../../../assets/images/search-icon.svg"
import Search from "../../../Search"
import { Toaster } from "react-hot-toast"

const NewChat = props => {
  const { singleChatData } = props
  return (
    <>
      <Toaster position="top-center" />
      <section className="new-chat-section">
        <div className="input-wrapper">
          <Search
            showSearch={true}
            placeholder="Search to Recipient"
            usersData={singleChatData}
          />
        </div>
      </section>
    </>
  )
}

export default NewChat
