import React from 'react';
import { DatePicker, Space } from 'antd';
import moment from 'moment';

import './style.scss';

const BoardDatePicker = ({
  className = "board-date-picker-item",
  defaultValue,
  placeholder,
  onChange,
  value,
  format,
}) => {
  // Disable past days
  const disabledDate = (current) => {
    return current && current < moment().startOf('day');
  };

  return (
    <div className="date-picker-space">
      <Space direction="vertical">
        <DatePicker
          defaultValue={defaultValue}
          className={className}
          allowClear
          placeholder={placeholder}
          suffixIcon={false}
          onChange={onChange}
          value={value}
          format={format}
          showToday={false}
          disabledDate={disabledDate}
        />
      </Space>
    </div>
  );
};

export default BoardDatePicker;
