import React, { useEffect, useState } from "react"
import dummyPic from "../../assets/images/dummyPic.png"
import centerImg from "../../assets/images/gallery.svg"
import ImageCard from "../../components/ImageCard"
import HomeLayout from "../../layout/HomeLayout"
import nextIcon from "../../assets/images/greater-blue-icon.svg"
import WorkRoomCard from "../../components/WorkRoomCard"
import {
  setOpenAdvertIndex,
  getWriterAdverts,
  getWriterCurrent,
  getWriterWorkroom
} from "./redux/action"
import { connect } from "react-redux"
import { Spinner } from "reactstrap"
import { useHistory } from "react-router-dom"
import PositionPopUp from "../../components/WriterModal/PositionPopUp"
import { saveWorkroomID } from "../AuthScreens/Login/redux/action"
import { uploadedDataConcat } from "../HomeScreens/ReaderHome/redux/action"
import seeIcon from "../../assets/images/greater-white.svg"

const WriterHome = props => {
  const {
    saveWorkroomID,
    getWriterAdverts,
    getWriterCurrent,
    getWriterWorkroom,
    uploadDataConcat,
    uploadedDataConcat,
    setOpenAdvertIndex,
    writerAdverts,
    writerAdvertsLoad,
    writerCurrent,
    writerCurrentLoad,
    writerWorkroom,
    writerWorkroomLoad
  } = props

  const [modalShow, setModalShow] = useState(false)
  const [currentApp, setCurrentApp] = useState({})
  const [checkModal, setCheckModal] = useState(false)
  const [activeCardFlex, setActiveCardFlex] = useState(false)
  const [checkPage, setCheckPage] = useState(true)
  const [concatUser, setConcatUser] = useState([])

  const history = useHistory()

  useEffect(() => {
    getWriterAdverts()
    getWriterCurrent()
    getWriterWorkroom()
  }, [])

  useEffect(() => {
    const uploadedData = writerAdverts?.results
    if (uploadedData && writerAdverts?.current_page == 1) {
      setConcatUser(uploadedData)
    } else if (uploadDataConcat) {
      setConcatUser([...concatUser, ...uploadedData])
    } else {
      setConcatUser(uploadedData)
    }
    setCheckPage(true)
  }, [writerAdverts?.results])

  useEffect(() => {
    const data = writerAdverts
    if (data?.current_page == data?.total_page) {
      setCheckPage(false)
    }
  }, [writerAdverts])
  console.log('writerWorkroom', writerWorkroom);
  const homeModal = index => {
    setOpenAdvertIndex(index)
    setCheckModal(false)
    setCurrentApp({})
    setModalShow(true)
  }

  const setWorkroomID = id => {
    if (id) {
      saveWorkroomID(id)
      history.push({
        pathname: `/writer/workroom/${id}`
      })
    }
  }

  const openCurrentApplication = index => {
    setOpenAdvertIndex(index)
    setModalShow(true)
    setCheckModal(true)
  }

  const filterBySearch = value => {
    getWriterAdverts(value)
  }

  const onPagination = () => {
    const pagination = writerAdverts
    const currentPage = pagination?.current_page + 1
    if (currentPage <= pagination?.total_page) {
      uploadedDataConcat(true)
      getWriterAdverts("", currentPage)
    }
  }

  return (
    <HomeLayout
      headerName={"Home"}
      searchBarWrapper={true}
      cardFlex={activeCardFlex}
      setCardFlex={setActiveCardFlex}
      onChange={filterBySearch}
    >
      <section className="content-section">
        <div className="home-card-wrapper upload-wrapper">
          <div className="container-fluid home-card-container">
            <div className="section-description">
              <p>
                Welcome to the story development area of the site. <br />
                The idea is to have several related stories told from different points of view related to a single event.<br />
                You will be playing a roll and writing in that person's voice.<br />
                You will have access to the workroom related to the roll your playing.<br />
                An editor member has been assigned to help you with learning the writing process.
              </p>
            </div>
            <h2 className="title">Open Adverts</h2>
            <div className="row home-card-row">
              {writerAdvertsLoad
                ? [1, 2, 3, 4, 5, 6, 7].map((item, index) => (
                  <div className={`card-data`}>
                    <ImageCard isLoader={true} />
                  </div>
                ))
                : concatUser?.map((item, index) => (
                  <div
                    className={`card-data ${activeCardFlex ? "card-toggle-flex" : ""
                      }`}
                    key={item.id}
                  >
                    <ImageCard
                      src={item?.thumbnail ? item.thumbnail : dummyPic}
                      hoverName={item.name}
                      onClick={() => homeModal(index)}
                    />
                  </div>
                ))}
              {concatUser && checkPage && (
                <div className="see-more" onClick={onPagination}>
                  {writerAdvertsLoad ? (
                    <Spinner size={"sm"} />
                  ) : (
                    <img src={seeIcon} alt="" />
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="home-card-wrapper favorite-wrapper">
          <div className="container-fluid home-card-container">
            <h2 className="title">Current Applications</h2>
            <div className="row home-card-row">
              {writerCurrentLoad
                ? [1, 2, 3, 4, 5, 6, 7].map((item, index) => (
                  <div className={`card-data`}>
                    <ImageCard isLoader={true} />
                  </div>
                ))
                : writerCurrent &&
                writerCurrent?.map((item, index) => (
                  <div
                    className={`card-data ${activeCardFlex ? "card-toggle-flex" : ""
                      }`}
                    key={item.id}
                  >
                    <ImageCard
                      src={
                        item?.character?.novel
                          ? item?.character?.novel?.thumbnail
                          : dummyPic
                      }
                      hoverName={item.character?.name}
                      onClick={() => openCurrentApplication(index)}
                    />
                  </div>
                ))}
            </div>
          </div>
        </div>
        <div className="home-card-wrapper workroom-wrapper">
          <div className="container-fluid home-card-container">
            <h2 className="title">Available Workrooms</h2>
            <div className="row home-card-row">
              {writerWorkroomLoad
                ? [1, 2, 3].map((item) => (
                  <WorkRoomCard isLoader={true} />
                ))
                : writerWorkroom && writerWorkroom[0] && writerWorkroom[0].character?.map((item) => (
                  <WorkRoomCard
                    src={writerWorkroom[0]?.character ? writerWorkroom[0] && writerWorkroom[0].thumbnail : centerImg}
                    className="center-img"
                    iconSrc={nextIcon}
                    novelName={item?.name}
                    nextText={"Enter Workroom"}
                    key={item.id}
                    onClick={() => setWorkroomID(item?.id)}
                    userPermission={!item.permission && "Read only"}
                  />
                ))}
            </div>
          </div>
        </div>
      </section>
      {checkModal ? (
        <PositionPopUp
          modalShow={modalShow}
          setModalShow={setModalShow}
          currentApp={writerCurrent}
          checkModal={checkModal}
        />
      ) : (
        <PositionPopUp
          modalData={writerAdverts?.results}
          modalShow={modalShow}
          setModalShow={setModalShow}
          currentApp={currentApp}
        />
      )}
    </HomeLayout>
  )
}

const mapStateToProps = state => ({
  errorMsg: state.writerHome.errorMsg,
  uploadDataConcat: state.home.uploadDataConcat,
  writerAdverts: state.writerHome.writerAdverts,
  writerAdvertsLoad: state.writerHome.writerAdvertsLoad,
  writerCurrent: state.writerHome.writerCurrent,
  writerCurrentLoad: state.writerHome.writerCurrentLoad,
  writerWorkroom: state.writerHome.writerWorkroom,
  writerWorkroomLoad: state.writerHome.writerWorkroomLoad
})

const mapDispatchToProps = dispatch => ({
  getWriterAdverts: (data, page) => dispatch(getWriterAdverts(data, page)),
  getWriterCurrent: data => dispatch(getWriterCurrent(data)),
  getWriterWorkroom: data => dispatch(getWriterWorkroom(data)),
  saveWorkroomID: data => dispatch(saveWorkroomID(data)),
  setOpenAdvertIndex: data => dispatch(setOpenAdvertIndex(data)),
  uploadedDataConcat: data => dispatch(uploadedDataConcat(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(WriterHome)
