import { all, call, put, takeLatest } from "redux-saga/effects"
import { BASE_URL } from "../../../../config/app"
import XHR from "../../../../utils/XHR"
import { postFeedbackSuccess, postFeedbackFailure } from "./action"
import { POST_FEEDBACK } from "./type"
import toast from 'react-hot-toast';


const postFeedbackAPI = async (data) => {
  const URL = `${BASE_URL}api/v1/feedback/`
  const token =  localStorage.getItem("token")

  const options = {
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json",
      Authorization: `Token ${token}`
    },
    method: "POST",
    data
  }
  return XHR(URL, options)
}

function* postFeedbackData({data}) {
  try {
    const response = yield call(postFeedbackAPI, data)
    yield put(postFeedbackSuccess(response.data))
    toast.success('Feedback Sent', {
      id: 'card fields',
    })
  } catch (e) {
    const { response } = e
    yield put(postFeedbackFailure(response.data))
    toast.error(response.data.message[0], {
      id: 'card fields',
    })
  }
}
export default all([takeLatest(POST_FEEDBACK, postFeedbackData)])
